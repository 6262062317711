import dayjs from "dayjs";

export class Evenements {
    public id: string;
    public attachments: any[];
    public description: string;
    public descriptionEn: string;
    public name: string;
    public nameEn: string;
    public startDate: dayjs.Dayjs;
    public endDate: dayjs.Dayjs;
    public type: number

    constructor(data: any) {
        this.id = data.id;
        this.attachments = data.attachments;
        this.name = data.name;
        this.nameEn = data.nameEn;
        this.description = data.description;
        this.descriptionEn = data.descriptionEn;
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.type = data.type
    }

}


