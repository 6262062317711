import dayjs from "dayjs";
import {PublicationStatus} from "../enums/PublicationStatus";

export default class Incident {
    public id: string;
    public description: string;
    public descriptionEn: string;
    public categoryName: string;
    public name: string;
    public nameEn: string;
    public Nom: string;
    public Prenom: string;
    public attachement: any[];
    public state: number;
    public createdAt: dayjs.Dayjs;
    public updatedAt: dayjs.Dayjs;
    public longitude: string;
    public latitude: string;
    public phoneNumber: string;

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.nameEn = data.nameEn;
        this.Nom = data.declarerName;
        this.Prenom = data.declarerSurname;
        this.description = data.description;
        this.descriptionEn = data.descriptionEn;
        this.attachement = data.attachments;
        this.categoryName = data.categoryName;
        this.state = data.state;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.longitude = data.longitude;
        this.latitude = data.latitude;
        this.phoneNumber = data.declarerPhoneNumber;
    }

    getStatusInfo = () => {
        switch (this.state) {
            case PublicationStatus.pending.value:
                return {
                    color: 'dark',
                    name: 'incident.pending'
                }

            case PublicationStatus.validate.value:
                return {
                    color: 'success',
                    name: 'incident.valid'
                }
            case PublicationStatus.invalidate.value:
                return {
                    color: 'danger',
                    name: 'incident.invalid'
                }
        }
    }
}