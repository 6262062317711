import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import * as actions from "./actions";

export const INITIAL_STATE: { botPhone: string | null } = {
    botPhone: null,
};

const reducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(actions.botPhone, (state, action) => {
            state.botPhone = action.payload;
        })
});

export default reducer;
