import React, { useState } from "react";
import { Evenements } from "../../commons/models/Evenements";
import { Campagne } from "../../commons/models/Campagne";
import Card, { CardBody, CardHeader } from "../../components/bootstrap/Card";
import dayjs from "dayjs";
import "./Event.scss";
import { globalT } from "../../lang";
import DialogComponent from "../../components/dialogs/DialogComponent";
import Button from "../../components/bootstrap/Button";
import {useAppSelector} from "../../store/redux.types";
import LANG from "../../lang/language";

type propsEvent = {
  data: Evenements | Campagne;
  type: "campagne" | "evenement";
};

const EventComponent = ({ data, type }: propsEvent) => {
    const { settings, authUserData } = useAppSelector(({ settings, authUser, }) => ({
        settings,
        authUserData: authUser.data
    }));
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div className="card-event position-relative ms-2">
      <p className="fw-bold">{ settings.language === LANG.fr.locale ? data?.name : data?.nameEn ? data.nameEn : data.name}</p>
      <p>
        {" "}
        <span className="fw-bold">{globalT("form.field.endate")}:</span>{" "}
        {dayjs(data?.startDate).format("ll")}{" "}
      </p>
      <p>
        {" "}
        <span className="fw-bold">{globalT("form.field.startdate")}:</span>{" "}
        {dayjs(data?.endDate).format("ll")}{" "}
      </p>
      <div className="position-absolute bottom-0 right-0">
        <Button
          type="button"
          color="primary"
          icon="ArrowForward"
          onClick={() => setShowInfo(true)}
        >
          {globalT("button.details")}
        </Button>
      </div>

      <DialogComponent
        show={showInfo}
        size="lg"
        title={globalT("button.details")}
        onClose={() => setShowInfo(false)}
      >
          <div className="ms-2">
              <p className="fw-bold">{ settings.language === LANG.fr.locale ? data?.name : data?.nameEn ? data?.nameEn : data?.name}</p>
              <p>
                  {" "}
                  <span className="fw-bold">{globalT("form.field.endate")}:</span>{" "}
                  {dayjs(data?.startDate).format("ll")}{" "}
              </p>
              <p>
                  {" "}
                  <span className="fw-bold">{globalT("form.field.startdate")}:</span>{" "}
                  {dayjs(data?.endDate).format("ll")}{" "}
              </p>
              {type === "evenement" ? (
                  <p>
                      {" "}
                      <span className="fw-bold">{globalT("form.field.type")}:</span>{" "}
                      {(data as Evenements)?.type === 1 ? "Séminaire" : "Atélier"}{" "}
                  </p>
              ) : (
                  <div>
                      <p>
                          {" "}
                          <span className="fw-bold">{globalT("form.field.goal")}:</span> {(data as Campagne)?.goal}{" "}
                      </p>
                      <p>
                          {" "}
                          <span className="fw-bold">{globalT("form.field.place")}:</span> {(data as Campagne)?.place}{" "}
                      </p>
                  </div>


              )}

              <p>
                  {" "}
                  <span className="fw-bold">description:</span> { settings.language === LANG.fr.locale ? data?.description : data?.descriptionEn ? data?.descriptionEn : data?.description}{" "}
              </p>
          </div>

      </DialogComponent>
    </div>
  );
};

export default EventComponent;
