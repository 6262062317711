import React, {useEffect, useReducer, useState} from 'react';
import dayjs from "dayjs";
import ReactApexChart from 'react-apexcharts';
import {capitalize, stringifyDigit} from "../../commons/helpers/funcHelper";
import {globalT} from "../../lang";
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle
} from "../../components/bootstrap/Card";
import ErrorRequestComponent from "../../components/errors/ErrorRequestComponent";
import CircularLoader from "../../components/loaders/CircularLoader";
import IntlMessages from "../../components/IntlMessages";
import SingleItemTransition from "../../components/transitions/SingleItemTransition";
import Button, {ButtonGroup} from "../../components/bootstrap/Button";
import {Select} from "../../components/forms/select/ExtendedSelect";
import {FetchResource} from "../../network/network.types";
import mintClient from "../../network";


const monthOptions = Array(12).fill(0).map((_, index) => ({
    label: <span className='text-capitalize'>{dayjs().set('month', index).format('MMMM')}</span>,
    value: index,
}));

type ReducerState = {
    year: number,
    type: 'yearly' | 'monthly',
    month: number
}

const Config = {
    minYear: 2023,
    maxYear: new Date().getFullYear()
}

type ActionType =
    | { type: 'type/update', payload: ReducerState['type'] }
    | { type: 'year/update', payload: number }
    | { type: 'month/update', payload: number | undefined }

const IncidentOverTime = () => {

    const [statsControl, statsControlDispatch] = useReducer((state: ReducerState, action: ActionType) => {
        switch (action.type) {
            case 'type/update':
                return { ...state, type: action.payload }
            case 'year/update':
                return { ...state, year: action.payload }
            case 'month/update':
                return { ...state, month: action.payload !== undefined ? action.payload : 1 }
        }
    }, {
        month: new Date().getMonth(),
        type: 'yearly',
        year: new Date().getFullYear(),
    });
    const [params, setParams] = useState<any>(null)

    const [resource, setResource] = useState<FetchResource<Object>>({
        loading: true,
        content: null,
        error: null,
    });

    const loadData = () => {
        setResource({
            ...resource,
            loading: true,
        });

        const queryParams: { year: number, month?: number } = {
            year: statsControl.year
        };

        if (statsControl.type === 'monthly')
            queryParams.month = statsControl.month + 1 // +1 since JS starts month at 0

        mintClient
            .dash
            .incidentDash(queryParams, params)
            .then((res) => {
                const data = res.data.data;

                setResource({
                    loading: false,
                    content: data,
                    error: null,
                });
            })
            .catch((error) => {
                setResource({
                    loading: false,
                    content: null,
                    error,
                });
            });
    }

    useEffect(() => {
        loadData();
    }, [statsControl, params]);

    const chartOptions = {
        chart: {
            height: 400,
            type: 'area',
            toolbar: {
                show: false,
            },
        },
        colors: ['#56d93e'],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm',
            },
            theme: 'dark',
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.5,
                opacityTo: 1,
                // stops: [0, 100],
            },
        },
        xaxis: {
            categories: statsControl.type === 'yearly'
                ? Array(12).fill(0).map((_, index) => capitalize(dayjs(`${statsControl.year}-${stringifyDigit(index + 1)}-10`).format('MMM YY')))
                : Array(Number(dayjs(`${statsControl.year}-${stringifyDigit(statsControl.month + 1)}-10`).daysInMonth()))
                    .fill(0)
                    .map((_, index) => dayjs(`${statsControl.year}-${stringifyDigit(statsControl.month + 1)}-${stringifyDigit(index + 1)}`).format('DD MMM')),
            labels: {
                style: {
                    colors: 'black'
                }
            }
        },
        yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#56d93e',
                },
                labels: {
                    style: {
                        colors: '#56d93e',
                    },
                },
                title: {
                    text: globalT('incident.statistics.proportions.yaxis.label'),
                    style: {
                        color: '#8d968b',
                    },
                },
                tooltip: {
                    enabled: true,
                },
            }
        ]
    };

    console.log('content :', resource.content)

    const selectedMonth = monthOptions.find(mo => mo.value === statsControl.month)

    return (
        <div>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Card stretch>
                <CardHeader>
                    <CardLabel icon='' className='text-black'>
                        <CardTitle tag='h4' className='h5'>
                            <IntlMessages id='incident.statistics.overtime.title' />
                        </CardTitle>
                        {/*<CardSubTitle tag='h5' className='h6'>*/}
                        {/*    <IntlMessages id='enrollment.statistics.overtime.subtitle' />*/}
                        {/*</CardSubTitle>*/}
                    </CardLabel>
                    <CardActions>
                        <div className='center-ver'>
                            <div className='me-4'>
                                <ButtonGroup>
                                    <Button
                                        aria-label='Previous Year'
                                        isLight={statsControl.type !== 'monthly'}
                                        color='success'
                                        onClick={() => statsControlDispatch({ type: 'type/update', payload: 'monthly' })}
                                    >
                                        <IntlMessages id="date.monthly" />
                                    </Button>
                                    <Button
                                        color='primary'
                                        isLight={statsControl.type !== 'yearly'}
                                        onClick={() => statsControlDispatch({ type: 'type/update', payload: 'yearly' })}
                                    >
                                        <IntlMessages id="date.yearly" />
                                    </Button>
                                </ButtonGroup>
                            </div>
                            <div>
                                <div className='center-ver'>
                                    <ButtonGroup className="me-2">
                                        <Button
                                            isLight
                                            color='primary'
                                            icon='ChevronLeft'
                                            aria-label='Previous Year'
                                            isDisable={statsControl.year < Config.minYear}
                                            onClick={() => statsControlDispatch({ type: 'year/update', payload: statsControl.year - 1 })}
                                        />
                                        <Button color='primary' isLight isDisable>
                                            {statsControl.year}
                                        </Button>
                                        <Button
                                            color='primary'
                                            isLight
                                            icon='ChevronRight'
                                            aria-label='Next Year'
                                            isDisable={statsControl.year > Config.maxYear}
                                            onClick={() => statsControlDispatch({ type: 'year/update', payload: statsControl.year + 1 })}
                                        />
                                    </ButtonGroup>
                                    <SingleItemTransition show={statsControl.type === 'monthly'}>
                                        <Select
                                            name='month'
                                            value={selectedMonth}
                                            options={monthOptions}
                                            placeholder={globalT('incident.date.month')}
                                            onChange={(d => statsControlDispatch({ type: 'month/update', payload: d?.value }))}
                                        />
                                    </SingleItemTransition>
                                </div>
                            </div>
                        </div>
                        <div className=' d-flex justify-content-center'>

                        </div>
                    </CardActions>
                </CardHeader>
                <CardBody>
                    <div className='row'>
                        <div className='col-sm-12'>
                            {(resource.error) ? (
                                <ErrorRequestComponent error={resource.error} loadData={loadData} />
                            ) : (
                                <div className='position-relative'>
                                    <SingleItemTransition show={resource.loading}>
                                        <div className='bg-200 bottom-0 center-hor-ver end-0 position-absolute start-0 top-0'>
                                            <CircularLoader />
                                        </div>
                                    </SingleItemTransition>
                                    <ReactApexChart
                                        type='area'
                                        series={ resource.content ? [
                                            {
                                                name: globalT('incident.statistics.proportions.yaxis.tooltip.label'),
                                                data: Object.values(resource.content),
                                            }
                                        ] : [
                                            {
                                                data: Array(31).fill(0),
                                            }
                                        ]}
                                        // @ts-ignore
                                        options={chartOptions}
                                        height={chartOptions.chart?.height}
                                    />
                                </div>
                            )}

                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default IncidentOverTime;