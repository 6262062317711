
export default class Usager {
    public id: string;
    public email: string;
    public ville: string;
    public phone: string;



    public constructor(data: any) {

        this.id = data.id;
        this.phone = data.phone;
        this.ville = data.ville;
        this.email = data.email;

    }
}