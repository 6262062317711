export default class BotCategorie {

    public id: string;
    public name: string;
    public nameEn: string;
    constructor(data: any) {
        this.id = data.id
        this.name = data.name;
        this.nameEn = data.nameEn
    }
}