import React, { useEffect, useState, useRef } from "react";
import "react-chatbot-kit/build/main.css";

import "../chatBot/chatbot.scss";
import "./index.scss";
import Chart from "react-apexcharts";
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from "../../components/bootstrap/Card";
import IntlMessages from "../../components/IntlMessages";
import IncidentOverTime from "./IncidentOverTime";
import { globalT } from '../../lang';
import ProfilUser from "../Profil/ProfilUser";
import mintClient from "../../network";
import {FetchResource} from "../../network/network.types";
import CircularLoader from "../../components/loaders/CircularLoader";
import SingleItemTransition from "../../components/transitions/SingleItemTransition";
import ErrorRequestComponent from "../../components/errors/ErrorRequestComponent";

const Dashboard = () => {


	const [totalDash, setTotalDah] = useState<FetchResource<any>>({
		loading: true,
		content: null,
		error: null,
	});

	const [totalDashPub, setTotalDahPub] = useState<FetchResource<Object>>({
		loading: true,
		content: null,
		error: null,
	});

	const chartOptions: ApexCharts.ApexOptions = {
		chart: {
			type: "donut",
		},
		series: totalDashPub.content ? Object.values(totalDashPub.content) : [1, 1, 1],
		labels: [globalT("valid"),globalT("invalid"), globalT("pending")],
		colors: ["#56d93e", "#d70756", "#252323"],
		dataLabels: {
			enabled: true,
			formatter: function (val) {
				return (
					(parseFloat(val as string) *
					((totalDashPub.content) ? Object.values(totalDashPub.content) : [1, 1, 1]).reduce(
							(accumulator, currentValue) => accumulator + currentValue,
							0
						)) /
					100 +
					""
				);
			},
		},
		plotOptions: {
			pie: {
				donut: {
					labels: {
						show: false,
					},
				},
			},
		},
	};

	const loadTotalDashboard = () => {
		mintClient.dash.getTotal()
			.then((resp) => {
				setTotalDah({
					...totalDash,
					content: resp.data.data,
					loading: false
				})
			})
			.catch(() => null)
	}

	const loadTotalDashboardPub = () => {
		mintClient.dash.getTotalPublication()
			.then((resp) => {
				setTotalDahPub({
					...totalDashPub,
					content: resp.data.data,
					loading: false
				})
			})
			.catch(() => null)
	}

	useEffect(() => {
		loadTotalDashboard()
		loadTotalDashboardPub()
	}, [])

	// @ts-ignore
	return (
		<>

			<div className='mt-3 container position-relative'>
				{/*<SingleItemTransition show={totalDashPub.loading }>*/}
				{/*	<div className='bg-200 bottom-0 center-hor-ver end-0 position-absolute start-0 top-0'>*/}
				{/*		<CircularLoader />*/}
				{/*	</div>*/}
				{/*</SingleItemTransition>*/}
				<div className="row">
					<div className="col-10 ms-5">
						<Card stretch>
							<CardHeader>
								<CardLabel>
									<CardTitle tag='h4' className='text-black'>
										<IntlMessages id='dashboad.stat.all' />
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className="col-12">
									{totalDash.error ? (
										<ErrorRequestComponent error={totalDash.error} loadData={loadTotalDashboard()} />
									) : (
										<div className="card-list position-relative">
											<SingleItemTransition show={totalDash.loading }>
												<div className='bg-200 bottom-0 z-index-10 center-hor-ver end-0 position-absolute start-0 top-0'>
													<CircularLoader />
												</div>
											</SingleItemTransition>
											<div className="row">
												<div className="col-4 mb-4">
													<div className="cardss  bluee">
														<div className="atitle">{globalT("incident.management")}</div>
														<i className="zmdi zmdi-upload"></i>
														<div className="value">{totalDash.content?.totalIncident}</div>
													</div>
												</div>
												<div className="col-4 mb-4">
													<div className="cardss  greene">
														<div className="atitle">{globalT("campagne.management")}</div>
														<i className="zmdi zmdi-upload"></i>
														<div className="value">{totalDash.content?.totalCampaign}</div>
													</div>
												</div>
												<div className="col-4 mb-4">
													<div className="cardss  orangee">
														<div className="atitle">{globalT("evenement.management")}</div>
														<i className="zmdi zmdi-download"></i>
														<div className="value">{totalDash.content?.totalEvent}</div>
													</div>
												</div>
												<div className="col-12 d-flex justify-content-center">
													<span className='text-black display-6 fw-bold'>{globalT("landing.actualite.title")}</span>
													<div>
														<Chart options={chartOptions} series={chartOptions.series} type="donut" width="380" />
													</div>
												</div>
											</div>
										</div>
									)}

								</div>
							</CardBody>
						</Card>
					</div>
					<div className="col-2"></div>
				</div>

				<IncidentOverTime />
			</div>

		</>
	);
};
export default Dashboard;