import 'firebase/database';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyAO7k-NXSqh4R3hew8nsbdXqcZKkH7dklo",
    authDomain: "apprs-mint.firebaseapp.com",
    projectId: "apprs-mint",
    storageBucket: "apprs-mint.appspot.com",
    messagingSenderId: "571399328175",
    appId: "1:571399328175:web:d7006d44ba570f46c59f5a",
    measurementId: "G-FN021HZWPL"
};

const app = initializeApp(firebaseConfig);

export const database = getFirestore(app);