import React, { useEffect, useRef, useState } from "react";
import Navnav from "../../Navnav/Navnav";
import Footfoot from "../../Navnav/Footfoot";
import { globalT } from "../../../lang";
import DocumentLanding from "../../../assets/img/landing2/DocumentLanding12.webp";
import SearchComponent from "../../../components/SearchComponent";
import CardComponent from "../../../components/CardComponent";
import Button from "../../../components/bootstrap/Button";
import CardImg from "../../../components/bootstrap/CardImg";
import IntlMessages from "../../../components/IntlMessages";
import SelectFromRemote from "../../../components/forms/select/SelectFromRemote";
import { joinUrlWithParamsId } from "../../../commons/helpers/funcHelper";
import DataImage from "../../../assets/img/landing2/vide.webp";
import PaginationComponent from "../../../components/PaginationComponent";
import Publication from "../../../commons/models/Publication";
import mintClient from "../../../network";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../../components/bootstrap/Dropdown";
import { useNavigate, useParams } from "react-router-dom";
import Documentation from "../../../commons/models/Documentation";
import * as yup from "yup";
import RevueDocumentaire from "../../../components/bootstrap/RevueDocumentaire";
import Select from "../../../components/bootstrap/forms/Select";
import Option from "../../../components/bootstrap/Option";
import Spinner from "../../../components/bootstrap/Spinner";
import CategoryDoc from "../../../commons/models/CategorieDoc";
import "./doc.scss";
import Header from "../Header";
import Footer from "../Footer";
import Nav, { NavItem } from "../../../components/bootstrap/Nav";
import { useAppDispatch, useAppSelector } from "../../../store/redux.types";
import Tooltips from "../../../components/bootstrap/Tooltips";
import { LanguageDict } from "../../../commons/types";
import { setLanguage } from "../../../store/slices/settings/actions";
import LANG from "../../../lang/language";
import classNames from "classnames";
import logo from "../../../assets/img/landing2/nouv/logo3.png";
import logo2 from "../../../assets/img/landing2/nouv/mintlogo.png";
import DialogComponent from "../../../components/dialogs/DialogComponent";
import FrontAdd from "../../Incident/incident/FrontAdd";
import DrawerComponent from "../../../components/DrawerComponent";
import DetailFunc from "../../../components/DetailFunc";
import { AUTH } from "../../../commons/urls/front";
import intero from "../../../assets/img/intero.png";


interface OptionType {
  label: string;
  value: string;
  id: number;
}
interface IFormInput {
  categorie: OptionType | null;
}

const schema = yup.object().shape({
  categorie: yup.object().nullable().required("This field is required"),
});

type headerProps = {
  currentMenu?:
    | "acceuil"
    | "actualite"
    | "about"
    | "presentation"
    | "analyse"
    | "revue"
    | "service"
    | "education";
  hasAutherImage?: boolean;
  image?: string;
  title?: string;
  subtitle?: string;
};

const DocumentEducation = ({
  currentMenu = "acceuil",
  hasAutherImage = false,
  image,
  title,
  subtitle,
}: headerProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });
  const [page, setPage] = useState(1);
  const total = useRef<number>();
  const [categorie, setCategorie] = useState<CategoryDoc[] | null>(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [documentationDocument, setDocumentationDocument] = useState<
    Documentation[] | null
  >();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("1");
  const sectionRef = React.useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const navigate = useNavigate();
  const [showInfpanel, setShowInfPanel] = useState<boolean>(false);
  const [showMobile, setShowMobile] = useState<boolean>(false);


  const { catActualite } = useAppSelector(({ catActualite }) => catActualite);
  const educationCat = useAppSelector(({ catRevue }) => catRevue);



  const [active, setActive] = useState<
    | "acceuil"
    | "actualite"
    | "presentation"
    | "about"
    | "analyse"
    | "revue"
    | "service"
    | "education"
  >(currentMenu);

  const dispatch = useAppDispatch();

  const { settings, authUserData } = useAppSelector(
    ({ settings, authUser }) => ({
      settings,
      authUserData: authUser.data,
    })
  );

  const handleDocumentClick = (file: string) => {
    // Handle the document click here
  };
  const scrollToSection = () => {
    const section = sectionRef.current;
    if (section) {
      const sectionTop =
        section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: sectionTop, behavior: "smooth" });
    }
  };

  const loadDocumentationDocument = () => {
    setLoading(true);
    // dispatch(setRequestGlobalLoader(true))
    mintClient.documentation
      .getAll({
        page: page,
        pageSize: 6,
        categoryId: selectedCategoryId,
        order: true,
        orderDirection: "ascending",
        validationState: 1,
        researchValue: search,
        eduCategory: true,
        isDocument: true,
      })
      .then((resp) => {
        const allDocumentationDocuments = resp.data.data;
        total.current = resp.data.total;
        setDocumentationDocument(allDocumentationDocuments);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };
  const loadCategorie = () => {
    mintClient.category
      .getAll({ isParent: true, parentId: 1, page: 1, pageSize: 100 })
      .then((resp) => {
        setCategorie(resp.data.data);
      })
      .catch(() => null);
  };

  useEffect(() => {
    if (!categorie) loadCategorie();
    loadDocumentationDocument();
  }, [selectedCategoryId, search]);

  const handleSearch = (value: any) => {
    setSearch(value);
    setPage(1);
  };

  const changeLanguage = (locale: LanguageDict) => {
    if (locale !== settings.language) {
      dispatch(setLanguage(locale));
    }
  };

  return (
    <>
      <div>
        <div className="z-index-10 position-fixed w-100 ">
          <div className="">
            <div className="">
              <div className="row home-subNav z-index-10 ps-2 pe-3">
                <div className="col-3">
                  <div className="d-flex flex-column flex-sm-row">
                    <div className="d-flex align-items-center">
                      <img
                        src={logo2}
                        alt="logo2"
                        className="home-subNav__image2"
                      />
                      <img
                        src={logo}
                        alt="logo"
                        className="home-subNav__image1"
                      />
                      <span className="home-subNav__texte">APSR</span>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <Tooltips
                    title={"Vous êtes témoin d'un incident, signalez-le"}
                    placement={"right"}
                  >
                    {!authUserData && (
                      <div className="col-md-7 text-end">
                        <Button
                          isOutline
                          color="danger"
                          icon="ReportProblem"
                          onClick={() => setShow(true)}
                          className="text-danger text-hover-black"
                        >
                          <span
                            className="text-danger text-hover-black text-center"
                            style={{ fontSize: "1.3rem" }}
                          >
                            {globalT("incident.add.title")}
                          </span>
                        </Button>
                      </div>
                    )}
                  </Tooltips>
                </div>
                <div className="col-3 text-end">
                  <Button
                    isOutline
                    color="white"
                    onClick={() => changeLanguage(LANG.en.locale)}
                    aria-label="Change language"
                    className="text-hover-black"
                    data-tour="lang-selector"
                  >
                    <span className="text-hover-black">
                      {globalT(LANG.en.text)}
                    </span>
                  </Button>
                  <Button
                    isOutline
                    color="white"
                    onClick={() => changeLanguage(LANG.fr.locale)}
                    aria-label="Change language"
                    className="ms-2 mt-1 text-hover-black"
                    data-tour="lang-selector"
                  >
                    <div className="text-hover-black">
                      {globalT(LANG.fr.text)}
                    </div>
                  </Button>
                </div>
              </div>
              <div className="home-nav">
                <div className="row w-100 hideNav">
                  <div className="col-10 d-flex justify-content-end ">
                    <div className="d-flex align-items-center ">
                      {/*<Icon onClick={() => null} className="cursor-pointer d-xl-block d-xxl-none" color={"dark"} icon="ChevronLeft"></Icon>*/}
                      <Nav design={"pills"}>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "acceuil"}
                          onClick={() => {
                            setActive("acceuil");
                            navigate("*");
                          }}
                        >
                          <span>
                            <a
                              className={classNames("btn", {
                                "text-orange": active === "acceuil",
                              })}
                              onClick={() => navigate("/")}
                              role="button"
                            >
                              {globalT("home").toUpperCase()}
                            </a>
                          </span>
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "actualite"}
                          onClick={() => {
                            setActive("actualite");
                          }}
                        >
                          <span className="">
                            <Dropdown canUseClick={false} direction={"down"}>
                              <DropdownToggle>
                                <span
                                  className={classNames("btn", {
                                    "text-orange": active === "actualite",
                                  })}
                                >
                                  {globalT(
                                    "landing.actualite.title"
                                  ).toUpperCase()}
                                </span>
                              </DropdownToggle>
                              <DropdownMenu>
                                {catActualite?.map((cat) => (
                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange"
                                      onClick={() =>
                                        navigate(
                                          joinUrlWithParamsId(
                                            "/actualite/categorie/:id",
                                            cat.id
                                          ),
                                          {
                                            state: {
                                              categorie: {
                                                en: cat.nameEn,
                                                fr: cat.name,
                                              },
                                            },
                                          }
                                        )
                                      }
                                    >
                                      {(settings.language === "fr"
                                        ? cat.name
                                        : cat.nameEn
                                        ? cat.nameEn
                                        : cat.nameEn
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </span>

                          {/*<span>*/}
                          {/*    <a className={classNames("btn", {"text-orange": active === "actualite"})} onClick={() => navigate('/')} role="button">Actualité</a>*/}
                          {/*</span>*/}
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "presentation"}
                          onClick={() => {
                            setActive("presentation");
                          }}
                        >
                          <span>
                            <a
                              className={classNames("btn", {
                                "text-orange": active === "presentation",
                              })}
                              onClick={() => navigate("/presentation")}
                              role="button"
                            >
                              {globalT("nav.presentation").toUpperCase()}
                            </a>
                          </span>
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "education"}
                          onClick={() => {
                            setActive("education");
                          }}
                        >
                          <span className="">
                            <Dropdown canUseClick={false} direction={"down"}>
                              <DropdownToggle>
                                <span
                                  className={classNames("btn", {
                                    "text-orange": active === "education",
                                  })}
                                >
                                  {globalT(
                                    "education.management"
                                  ).toUpperCase()}
                                </span>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem>
                                  <span>
                                    <Dropdown
                                      canUseClick={false}
                                      direction={"start"}
                                    >
                                      <DropdownToggle>
                                        <span
                                          className={classNames(
                                            "btn text-hover-orange"
                                          )}
                                        >
                                          {globalT(
                                            "nav.education.sensibilisation"
                                          ).toUpperCase()}
                                        </span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem>
                                          <span
                                            className="text-hover-orange"
                                            onClick={() =>
                                              navigate("/campagne/after")
                                            }
                                          >
                                            {globalT(
                                              "campagne.management"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownItem>
                                        <DropdownItem>
                                          <span
                                            className="text-hover-orange"
                                            onClick={() =>
                                              navigate("/evenement/after")
                                            }
                                          >
                                            {globalT(
                                              "nav.events"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                </DropdownItem>
                                <DropdownItem>
                                  <span>
                                    <Dropdown
                                      canUseClick={false}
                                      direction={"down"}
                                    >
                                      <DropdownToggle>
                                        <span
                                          className={classNames(
                                            "btn text-hover-orange"
                                          )}
                                        >
                                          {globalT(
                                            "nav.education.media"
                                          ).toUpperCase()}
                                        </span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem>
                                          <span
                                            className="text-hover-orange"
                                            onClick={() =>
                                              navigate("/education/audio")
                                            }
                                          >
                                            {globalT(
                                              "education.audio"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownItem>
                                        <DropdownItem>
                                          <span
                                            className="text-hover-orange"
                                            onClick={() =>
                                              navigate("/education/video")
                                            }
                                          >
                                            {globalT(
                                              "education.video"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownItem>
                                        <DropdownItem>
                                          <span
                                            className="text-hover-orange"
                                            onClick={() =>
                                              navigate("/education/depliant")
                                            }
                                          >
                                            {globalT(
                                              "education.depliant"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "analyse"}
                          onClick={() => {
                            setActive("analyse");
                          }}
                        >
                          <span>
                            <a
                              className={classNames("btn", {
                                "text-orange": active === "analyse",
                              })}
                              onClick={() => navigate("/analyse")}
                              role="button"
                            >
                              {globalT("nav.analyse").toUpperCase()}
                            </a>
                          </span>
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "service"}
                          onClick={() => {
                            setActive("service");
                          }}
                        >
                          <span className="">
                            <Dropdown canUseClick={false} direction={"down"}>
                              <DropdownToggle>
                                <span
                                  className={classNames("btn", {
                                    "text-orange": active === "service",
                                  })}
                                >
                                  {globalT("nav.service").toUpperCase()}
                                </span>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem>
                                  <span
                                    className="text-hover-orange ms-4 text-black"
                                    onClick={() =>
                                      navigate("/immatriculation/usager")
                                    }
                                  >
                                    {globalT(
                                      "nav.service.immatriculation"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownItem>
                                <DropdownItem>
                                  <span
                                    className="text-hover-orange ms-4 text-black"
                                    onClick={() => navigate("/service/permis")}
                                  >
                                    {globalT(
                                      "nav.service.permis"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownItem>

                                <DropdownItem>
                                  <span
                                    className="text-hover-orange ms-4 text-black"
                                    onClick={() =>
                                      navigate("/services/autoEcole")
                                    }
                                  >
                                    {globalT("nav.service.ecole").toUpperCase()}
                                  </span>
                                </DropdownItem>
                                <DropdownItem>
                                  <span
                                    className="text-hover-orange ms-4 text-black"
                                    onClick={() => navigate("/services/centre")}
                                  >
                                    {globalT(
                                      "nav.service.centre"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownItem>
                                <DropdownItem>
                                  <span
                                    className="text-hover-orange ms-4 text-black"
                                    onClick={() => navigate("/services/ong")}
                                  >
                                    {globalT(
                                      "nav.service.demande"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "revue"}
                          onClick={() => {
                            setActive("revue");
                          }}
                        >
                          <span className="">
                            <Dropdown canUseClick={false} direction={"down"}>
                              <DropdownToggle>
                                <span
                                  className={classNames("btn", {
                                    "text-orange": active === "revue",
                                  })}
                                >
                                  {globalT("document.management").toUpperCase()}
                                </span>
                              </DropdownToggle>
                              <DropdownMenu>
                                {educationCat.catRevue?.map((cat) => (
                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange"
                                      onClick={() =>
                                        navigate(
                                          joinUrlWithParamsId(
                                            "/revue/categorie/:id",
                                            cat.id
                                          ),
                                          {
                                            state: {
                                              categorie: {
                                                en: cat.nameEn,
                                                fr: cat.name,
                                              },
                                            },
                                          }
                                        )
                                      }
                                    >
                                      {(settings.language === "fr"
                                        ? cat.name
                                        : cat.nameEn
                                        ? cat.nameEn
                                        : cat.nameEn
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                        </NavItem>
                      </Nav>
                      {/*<Icon onClick={() => null} className="cursor-pointer d-xl-block d-xxl-none" color={"dark"} icon={"ChevronRight"}></Icon>*/}
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="d-flex justify-content-end">
                      <Button onClick={() => navigate("/About")}>
                        <img
                          src={intero}
                          alt="imf"
                          className="img-fluid imageIntero me-3"
                        />
                      </Button>
                      <Button
                        color={"secondaire"}
                        onClick={() => navigate(AUTH.LOGIN)}
                      >
                        <span className="text-white">
                          {globalT("se.connecter")}
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="home-nav-moble">
                <div className="hideMobileButton w-100 mt-2 d-flex justify-content-end">
                  <div className="me-2 mb-2">
                    <div className="d-flex justify-content-end">
                      <Button onClick={() => setShowInfPanel(true)}>
                        <img
                          src={intero}
                          alt="imf"
                          className="img-fluid imageIntero me-3"
                        />
                      </Button>
                      <Button
                        color={"secondaire"}
                        onClick={() => navigate(AUTH.LOGIN)}
                      >
                        <span className="text-white mb-1">
                          {globalT("se.connecter")}
                        </span>
                      </Button>
                    </div>
                  </div>
                  <Button
                    color={"secondaire"}
                    icon={"Menu"}
                    className="me-3"
                    onClick={() => setShowMobile(!showMobile)}
                  />
                </div>
                {showMobile && (
                  <div className={classNames("row w-100 hideMobileButton")}>
                    <div className="col-12 d-flex justify-content-center">
                      <div className="d-flex align-items-center">
                        {/*<Icon onClick={() => null} className="cursor-pointer d-xl-block d-xxl-none" color={"dark"} icon="ChevronLeft"></Icon>*/}
                        <Nav
                          verticalBreakpoint={"sm"}
                          tag={"ul"}
                          design={"pills"}
                        >
                          <NavItem
                            className="cursor-pointer"
                            isActive={active === "acceuil"}
                            onClick={() => {
                              setActive("acceuil");
                              navigate("*");
                            }}
                          >
                            <span>
                              <a
                                className={classNames("btn", {
                                  "text-orange": active === "acceuil",
                                })}
                                onClick={() => navigate("/")}
                                role="button"
                              >
                                {globalT("home").toUpperCase()}
                              </a>
                            </span>
                          </NavItem>
                          <NavItem
                            className="cursor-pointer"
                            isActive={active === "actualite"}
                            onClick={() => {
                              setActive("actualite");
                            }}
                          >
                            <span className="">
                              <Dropdown direction={"down"}>
                                <DropdownToggle>
                                  <span
                                    className={classNames("btn", {
                                      "text-orange": active === "actualite",
                                    })}
                                  >
                                    {globalT(
                                      "landing.actualite.title"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {catActualite?.map((cat) => (
                                    <DropdownItem>
                                      <span
                                        className="text-hover-orange"
                                        onClick={() =>
                                          navigate(
                                            joinUrlWithParamsId(
                                              "/actualite/categorie/:id",
                                              cat.id
                                            ),
                                            {
                                              state: {
                                                categorie: {
                                                  en: cat.nameEn,
                                                  fr: cat.name,
                                                },
                                              },
                                            }
                                          )
                                        }
                                      >
                                        {(settings.language === "fr"
                                          ? cat.name
                                          : cat.nameEn
                                          ? cat.nameEn
                                          : cat.nameEn
                                        ).toUpperCase()}
                                      </span>
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </Dropdown>
                            </span>

                            {/*<span>*/}
                            {/*    <a className={classNames("btn", {"text-orange": active === "actualite"})} onClick={() => navigate('/')} role="button">Actualité</a>*/}
                            {/*</span>*/}
                          </NavItem>
                          <NavItem
                            className="cursor-pointer"
                            isActive={active === "presentation"}
                            onClick={() => {
                              setActive("presentation");
                            }}
                          >
                            <span>
                              <a
                                className={classNames("btn", {
                                  "text-orange": active === "presentation",
                                })}
                                onClick={() => navigate("/presentation")}
                                role="button"
                              >
                                {globalT("nav.presentation").toUpperCase()}
                              </a>
                            </span>
                          </NavItem>
                          <NavItem
                            className="cursor-pointer"
                            isActive={active === "education"}
                            onClick={() => {
                              setActive("education");
                            }}
                          >
                            <span className="">
                              <Dropdown direction={"down"}>
                                <DropdownToggle>
                                  <span
                                    className={classNames("btn", {
                                      "text-orange": active === "education",
                                    })}
                                  >
                                    {globalT(
                                      "education.management"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem>
                                    <span>
                                      <Dropdown direction={"start"}>
                                        <DropdownToggle>
                                          <span
                                            className={classNames(
                                              "btn text-hover-orange"
                                            )}
                                          >
                                            {globalT(
                                              "nav.education.sensibilisation"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem>
                                            <span
                                              className="text-hover-orange"
                                              onClick={() =>
                                                navigate("/campagne/after")
                                              }
                                            >
                                              {globalT(
                                                "campagne.management"
                                              ).toUpperCase()}
                                            </span>
                                          </DropdownItem>
                                          <DropdownItem>
                                            <span
                                              className="text-hover-orange"
                                              onClick={() =>
                                                navigate("/evenement/after")
                                              }
                                            >
                                              {globalT(
                                                "nav.events"
                                              ).toUpperCase()}
                                            </span>
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </span>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <span>
                                      <Dropdown direction={"down"}>
                                        <DropdownToggle>
                                          <span
                                            className={classNames(
                                              "btn text-hover-orange"
                                            )}
                                          >
                                            {globalT(
                                              "nav.education.media"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem>
                                            <span
                                              className="text-hover-orange"
                                              onClick={() =>
                                                navigate("/education/audio")
                                              }
                                            >
                                              {globalT(
                                                "education.audio"
                                              ).toUpperCase()}
                                            </span>
                                          </DropdownItem>
                                          <DropdownItem>
                                            <span
                                              className="text-hover-orange"
                                              onClick={() =>
                                                navigate("/education/video")
                                              }
                                            >
                                              {globalT(
                                                "education.video"
                                              ).toUpperCase()}
                                            </span>
                                          </DropdownItem>
                                          <DropdownItem>
                                            <span
                                              className="text-hover-orange"
                                              onClick={() =>
                                                navigate("/education/depliant")
                                              }
                                            >
                                              {globalT(
                                                "education.depliant"
                                              ).toUpperCase()}
                                            </span>
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </span>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </span>
                          </NavItem>
                          <NavItem
                            className="cursor-pointer"
                            isActive={active === "analyse"}
                            onClick={() => {
                              setActive("analyse");
                            }}
                          >
                            <span>
                              <a
                                className={classNames("btn", {
                                  "text-orange": active === "analyse",
                                })}
                                onClick={() => navigate("/analyse")}
                                role="button"
                              >
                                {globalT("nav.analyse").toUpperCase()}
                              </a>
                            </span>
                          </NavItem>
                          <NavItem
                            className="cursor-pointer"
                            isActive={active === "service"}
                            onClick={() => {
                              setActive("service");
                            }}
                          >
                            <span className="">
                              <Dropdown direction={"start"}>
                                <DropdownToggle>
                                  <span
                                    className={classNames("btn", {
                                      "text-orange": active === "service",
                                    })}
                                  >
                                    {globalT("nav.service").toUpperCase()}
                                  </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange ms-4 text-black"
                                      onClick={() =>
                                        navigate("/immatriculation/usager")
                                      }
                                    >
                                      {globalT(
                                        "nav.service.immatriculation"
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange ms-4 text-black"
                                      onClick={() =>
                                        navigate("/service/permis")
                                      }
                                    >
                                      {globalT(
                                        "nav.service.permis"
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>

                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange ms-4 text-black"
                                      onClick={() =>
                                        navigate("/services/autoEcole")
                                      }
                                    >
                                      {globalT(
                                        "nav.service.ecole"
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange ms-4 text-black"
                                      onClick={() =>
                                        navigate("/services/centre")
                                      }
                                    >
                                      {globalT(
                                        "nav.service.centre"
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange ms-4 text-black"
                                      onClick={() => navigate("/services/ong")}
                                    >
                                      {globalT(
                                        "nav.service.demande"
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </span>
                          </NavItem>
                          <NavItem
                            className="cursor-pointer"
                            isActive={active === "revue"}
                            onClick={() => {
                              setActive("revue");
                            }}
                          >
                            <span className="">
                              <Dropdown direction={"down"}>
                                <DropdownToggle>
                                  <span
                                    className={classNames("btn", {
                                      "text-orange": active === "revue",
                                    })}
                                  >
                                    {globalT(
                                      "document.management"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {educationCat.catRevue?.map((cat) => (
                                    <DropdownItem>
                                      <span
                                        className="text-hover-orange"
                                        onClick={() =>
                                          navigate(
                                            joinUrlWithParamsId(
                                              "/revue/categorie/:id",
                                              cat.id
                                            ),
                                            {
                                              state: {
                                                categorie: {
                                                  en: cat.nameEn,
                                                  fr: cat.name,
                                                },
                                              },
                                            }
                                          )
                                        }
                                      >
                                        {(settings.language === "fr"
                                          ? cat.name
                                          : cat.nameEn
                                          ? cat.nameEn
                                          : cat.nameEn
                                        ).toUpperCase()}
                                      </span>
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </Dropdown>
                            </span>
                          </NavItem>
                        </Nav>
                        {/*<Icon onClick={() => null} className="cursor-pointer d-xl-block d-xxl-none" color={"dark"} icon={"ChevronRight"}></Icon>*/}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

      

        <DialogComponent
          show={show}
          title={globalT("incident.add.title")}
          onClose={() => setShow(false)}
          size="lg"
        >
          <FrontAdd onClose={() => setShow(false)} />
        </DialogComponent>
        <DrawerComponent
          type={"right"}
          onclose={() => setShowInfPanel(false)}
          onOpen={() => setShowInfPanel(true)}
          data={null}
          label={globalT("detail.info")}
          open={showInfpanel}
        >
          <DetailFunc />
        </DrawerComponent>
      </div>



      <div
        className="containers section-13 d-flex align-items-center justify-content-start"
        style={{
          marginTop: "10%",
          marginLeft: "60px",
          backgroundImage: `url(${DocumentLanding})`,
          backgroundSize: "70vw auto",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          height: "500px", // Change this value according to your needs
        }}
      >
        <div
          className="custom-container"
          style={{
            marginBottom: "120px",
            maxWidth: "70vw", // Set the max-width of the container
            height: "auto", // Height of the container
            padding: "20px", // Padding around the content
            borderRadius: "10px", // Radius of the border (making the corners rounded)
          }}
        >
          <div
            className="row mb-0 "
            style={{ marginBottom: "30px", position: "relative", zIndex: 2 }}
          >
            {/* Code for the first section */}
            <div className="col-md-7" style={{ marginRight: "40px" }}>
              <h1
                className="mb-3"
                style={{
                  marginBottom: "20px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {globalT("edu.document.para.1")}
              </h1>
              <p
                style={{
                  fontSize: "17px",
                  marginBottom: "20px",
                  fontWeight: 500,
                  color: "black",
                }}
              >
                {globalT("edu.document.para.2")}
              </p>
              <button
                type="button"
                className="btn btn-danger btn-lg"
                style={{ marginTop: "20px", backgroundColor: "#FFA500" }}
                onClick={scrollToSection}
              >
                <i
                  className="fas fa-file-alt"
                  style={{ marginRight: "10px", color: "white" }}
                ></i>
                {globalT("landing.header.document")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid section-2"
        style={{ marginTop: "20px", backgroundColor: "#e5eae4" }}
      >
        <div
          className="container section-2"
          style={{ marginTop: "80px", marginBottom: "100px" }}
        >
          <div className="row">
            <div className="col-lg-5 d-flex align-items-center justify-content-center flex-column">
              <h1 className="font-weight-bold">
                {globalT("edu.document.header.card.1")}
              </h1>
              <p className="font-weight-bold">
                {globalT("edu.document.hearder.card.2")}
              </p>
              <div
                className="grid-column"
                style={{
                  fontSize: "25px",
                  color: "#AAE0AA",
                  display: "inline-block",
                  borderRadius: "40%",
                  padding: "20px",
                  marginBottom: "20px",
                }}
              >
                <i className="fas fa-file-alt fa-2x text-black-50"></i>{" "}
                <i className="fas fa-file-pdf fa-2x m-lg-4 text-black-50"></i>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="row">
                <div className="col-sm">
                  <CardComponent
                    title={globalT("edu.document.card.title.1")}
                    description={globalT("edu.document.card.desc.1")}
                    icon="fas fa-book"
                  />
                </div>
                <div className="col-sm">
                  <CardComponent
                    title={globalT("edu.document.card.title.2")}
                    description={globalT("edu.document.card.desc.2")}
                    icon="fas fa-first-aid"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <CardComponent
                    title={globalT("edu.document.card.title.3")}
                    description={globalT("edu.document.card.desc.3")}
                    icon="fas fa-leaf"
                  />
                </div>
                <div className="col-sm">
                  <CardComponent
                    title={globalT("edu.document.card.title.4")}
                    description={globalT("edu.document.card.desc.4")}
                    icon="fas fa-balance-scale"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container section-3"
        ref={sectionRef}
        style={{ marginTop: "80px" }}
      >
        <div className="row mb-4 align-items-center">
          <div className="col-md-7">
            {/*<h2 className="mb-3">{globalT("educ.research.document")}</h2>*/}
          </div>
          <div className="col-md-5 d-flex row mb-4">
            {/* Replace with your search component */}
            <div className="col-md-6 mt-10">
              <p className="fw-bold" style={{ marginLeft: "40px" }}>
                {globalT("educ.research.vid")}
              </p>
              <SearchComponent onSearch={handleSearch} />
            </div>
            {/* Directly place SelectFromRemote component here */}
            <div className="col-md-5 ms-3">
              {categorie ? (
                <div className="d-flex flex-column">
                  <span className="text-start fw-bold mb-3">
                    {globalT("video.category")}
                  </span>
                  <Select
                    size="sm"
                    ariaLabel="Per"
                    onChange={(e: { target: { value: string } }) => {
                      setSelectedCategoryId(e.target.value);
                    }}
                    value={selectedCategoryId}
                  >
                    <Option key="all" value="1">
                      tous
                    </Option>

                    {categorie.map((cat) => (
                      <Option key={cat.id} value={cat.id}>
                        {cat.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              ) : (
                <div
                  className="spinner-border"
                  style={{ height: "3rem", width: "3rem", color: "#aae0aa" }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row py-4">
          <>
            {loading && (
              <div
                className="d-flex justify-content-between"
                style={{ color: "red" }}
              >
                <span></span>
                <div
                  className="spinner-border"
                  style={{
                    height: "3rem",
                    width: "3rem",
                    color: "#aae0aa",
                    marginTop: "40px",
                    marginRight: "10px",
                  }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </>
          {documentationDocument && documentationDocument.length > 0 ? (
            <>
              {documentationDocument.map((doc, index) => (
                <div className="col-lg-4 col-md-6 mb-4" id={`no${index}`}>
                  <RevueDocumentaire
                    name={doc.name}
                    nameEn={doc.nameEn}
                    file={doc.file}
                    id={doc.id}
                    type="pdf"
                    onClick={() => handleDocumentClick(doc.file)}
                  />
                </div>
              ))}
            </>
          ) : (
            <div className="imageContainer" style={{ marginTop: "100px" }}>
              <div className="text-center mt-5 mb-5">
                <div
                  style={{
                    width: "400px", // or any size you want
                    height: "400px", // make it a square
                    backgroundImage: `url(${DataImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    margin: "0 auto", // this will center the div if the parent div has a text-align: center
                  }}
                  className="image"
                />
                {loading ? (
                  <div className="d-flex justify-content-center spinnerContainer">
                    <div
                      className="spinner-border"
                      style={{
                        height: "6rem",
                        width: "6rem",
                        color: "#aae0aa",
                        marginTop: "85px",
                        marginRight: "15px",
                      }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <span
                    className="fw-bold"
                    style={{
                      marginTop: "20px",
                      fontSize: "24px",
                    }}
                  >
                    {globalT("empty.doc")}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        <PaginationComponent
          pages={Math.ceil((total.current || 0) / 6)}
          currentPage={page}
          onPageChange={(page) => setPage(page)}
          rangeLimit={5}
        />
      </div>

      <Footer />
    </>
  );
};
export default DocumentEducation;
