import React from 'react';

const Question2 = () => {
    return (
        <div className='d-flex flex-column text-start ms-3'>
            <span>1- es accidents Matériels.</span>
            <span>2- Les accidents Corporels.</span>
            <span>3- Les accidents Mortels.</span>
        </div>
    );
};

export default Question2;