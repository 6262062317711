export const USERS = {
    AUTH: {
        LOGIN: '/Users/login',
        // LOGOUT: '/auth/logout/',
    },
    CREATE: '/Users',
    GET_ALL: '/Users',
    GET_ONE: '/Users/{id}',
    DELETE: '/Users/{id}',
    UPDATE: '/Users/{id}',
    CHANGE_PASSWORD: '/Users/{id}/changepasswd',
};

export  const CLAIMS = {
    MENU_ALL: '/Claims/menus',
    MENU_JOB_ALL: '/Claims/menujob',
    JOB_ALL: '/Claims/jobs',
    JOB_ONE: '/Claims/jobs/{id}',
}

export const ROLE = {
    CREATE: '/Roles',
    GET_ALL: '/Roles', 
    GET_ONE: '/Roles/{id}',
    DELETE: '/Roles/{id}',
    UPDATE: '/Roles/{id}',
}

export const PUBLICATION = {
    CREATE: '/Publication',
    GET_ALL: '/Publication/search',
    GET_IMAGE: '/Publication/searchInImage',
    GET_ONE: '/Publication/{id}',
    GET_VIDEO: '/Publication/searchInVideo',
    DELETE: '/Publication/{id}',
    UPDATE: '/Publication/{id}',
    VALIDATE: '/Publication/{id}/validate',
    INVALIDATE: '/Publication/{id}/invalidate',
    GET_PARAGRAPH: '/Publication/{id}/paragraphs',
}

export const INCIDENT = {
    CREATE: '/Incident',
    GET_ALL: '/Incident/search',
    GET_LAST_ALL: '/Incident/searchInLastTwoDay',
    GET_ONE: '/Incident/{id}',
    DELETE: '/Incident/{id}',
    UPDATE: '/Incident/{id}',
    VALIDATE: '/Incident/{id}/validate',
    INVALIDATE: '/Incident/{id}/invalidate',
    // GET_PARAGRAPH: '/Incident/{id}/paragraphs',
}

export const CATEGORY = {
    CREATE: '/Category',
    GET_ALL: '/Category/search',
    GET_ONE: '/Category/{id}',
    DELETE: '/Category/{id}',
    UPDATE: '/Category/{id}',
}

export const INCIDENT_CATEGORY = {
    CREATE: '/IncidentCategory',
    GET_ALL: '/IncidentCategory',
    GET_ONE: '/IncidentCategory/{id}',
    DELETE: '/IncidentCategory/{id}',
    UPDATE: '/IncidentCategory/{id}',
}

export const CATEGORY_PUBLICATION = {
    CREATE: '/PublicationCategory',
    GET_ALL: '/PublicationCategory',
    GET_ONE: '/PublicationCategory/{id}',
    DELETE: '/PublicationCategory/{id}',
    UPDATE: '/PublicationCategory/{id}',
}


export const DOCUMENTATION = {
    CREATE: '/Document',
    GET_ALL: '/Document/search',
    GET_ALL_ON_DOC: '/Document/get_one_document_by_category',
    GET_ONE: '/Document/{id}',
    DELETE: '/Document/{id}',
    UPDATE: '/Document/{id}'
}

export const PARAGRAPH = {
    CREATE: '/Paragraph',
    GET_ALL: '/Paragraph',
    GET_ONE: '/Paragraph/{id}',
    DELETE: '/Paragraph/{id}',
    UPDATE: '/Paragraph/{id}'
}

export const EVENEMENT = {
    CREATE: '/Event',
    GET_ALL: '/Event/search',
    GET_ONE: '/Event/{id}',
    DELETE: '/Event/{id}',
    UPDATE: '/Event/{id}'
}

export const BOOT = {
    CREATE: '/ChatBot',
    CREATE_USER_QUESTION: '/ChatBot/CreateUserQuestion',
    GET_ALL: '/ChatBot',
    GET_ALL_QUESTION_USER: '/ChatBot/GetUserQuestion',
    GET_QUESTION_USER_BY_ID: '/ChatBot/GetUserQuestionById/{id}',
    GET_ONE: '/ChatBot/{id}',
    GET_RESPONSE_QUESTION: '/ChatBot/GetResponseByQuestion',
    DELETE: '/ChatBot/{id}',
    UPDATE: '/ChatBot/{id}',
    GET_BOT_BY_CATHEGORIE: '/ChatBot/byCategory'
}

export const BOOT_CATEGORIE = {
    CREATE: '/ChatBotMessageCategory',
    GET_ALL: '/ChatBotMessageCategory',
    GET_ONE: '/ChatBotMessageCategory/{id}',
    DELETE: '/ChatBotMessageCategory/{id}',
    UPDATE: '/ChatBotMessageCategory/{id}'
}


export const CAMPAGNE = {
    CREATE: '/Campain',
    GET_ALL: '/Campain/search',
    GET_ONE: '/Campain/{id}',
    DELETE: '/Campain/{id}',
    UPDATE: '/Campain/{id}'
}

export const ALERT = {
    CREATE: '/Alert',
    GET_ALL: '/Alert',
    GET_ONE: '/Alert/{id}',
    GET_LAST:'Alert/GetLastAlert',
    DELETE: '/Alert/{id}',
    UPDATE: '/Alert/{id}'
}


export const ALERT_CATEGORY = {
    CREATE: '/AlertCategory',
    GET_ALL: '/AlertCategory',
    GET_ONE: '/AlertCategory/{id}',
    DELETE: '/AlertCategory/{id}',
    UPDATE: '/AlertCategory/{id}'
}


export const DISCUSION = {
    CREATE: '/Discussion',
    GET_ALL: '/Discussion/search',
    GET_ONE: '/Discussion/{id}',
    DELETE: '/Discussion/{id}',
    UPDATE: '/Discussion/{id}'
}

export const MESSAGE_DISCUSSION = {
    CREATE: '/MessageControlleur',
    GET_ALL: '/MessageControlleur/search',
    GET_ONE: '/MessageControlleur/{id}',
    GET_ALL_ID: '/MessageControlleur/MessageByDiscussionId',
    DELETE: '/MessageControlleur/{id}',
    UPDATE: '/MessageControlleur/{id}'
}

export const DASH = {
    INCIDENT_DASH: '/Dashboard/GetIncidentInTimeAsync',
    TOTAL_DASH: '/Dashboard/GetDashboardTotalAsync',
    TOTAL_DASH_PUB: '/Dashboard/GetTotalOfPublicationTypeAsync',
}

export const MODERATEUR = {

    CREATE: '/Moderator/CreateModerator',
    GET_ALL: '/Moderator/FilterModerator',
    GET_ONE: '/Moderator/GetModeratorById/{id}',
    SUSPENDRE_MODERATOR: 'Moderator/SuspendModerator/{id}',
    ACTIVER_MODERATOR: '/Moderator/ActivateModerator/{id}',
    DESACTIVER_MODERATOR: '/Moderator/DesactivateModerator/{id}'
    
}

export const NEWSLETTER = {
    CREATE: '/NewsLetter',
    GET_ONE: '/NewsLetter/{id}',
    GET_ALL:'/NewsLetter',
    DELETE: '/NewsLetter/{id}',
    UPDATE: '/NewsLetter/{id}',
}



