
export default class QuestionUser {
    public id: string;
    public phoneNumber: string;
    public question: string;

    constructor(data: any) {
        this.id = data.id;
        this.phoneNumber = data.phoneNumber;
        this.question = data.question;
    }
}