import React from 'react';
import dayjs, {Dayjs} from "dayjs";
import './message.scss'
import {getFirstLetter, randomColor} from "../../../../helpers/helpers";
import Icon from "../../../../components/icon/Icon";
import {messagePhone} from "../../../../store/slices/MessagePhone/actions";
import {useAppSelector} from "../../../../store/redux.types";

type messagPros = {
    isAdmin?: boolean,
    message: string,
    date: any,
    nom: string,
    currentName?: string,
    repondElement: (name: string) => void,
    deleteElement: (id: string) => void,
    phone?: string,
    idDoc: string

}
const Message = ({message, nom, date, isAdmin, deleteElement, repondElement, phone, idDoc}: messagPros) => {
    const classeMessage = isAdmin ? 'message-admin' : 'message-utilisateur';
    const classeConteneur = isAdmin ? 'conteneur-admin' : 'conteneur-utilisateur';
    const gestionButton = isAdmin ? 'Button-admin' : 'Button-utilisateur';
    const classeStatut = isAdmin ? 'statut-admin' : '';
    const messagePhoneUser = useAppSelector(({messagePhone}) => messagePhone.messagePhone)

    let dateAvecNanosecondes = null
    if (typeof date !== 'string') {
        const dateEnSecondes = dayjs.unix(date.seconds);
        dateAvecNanosecondes = dateEnSecondes.add(date.nanoseconds / 1e6, 'millisecond');
    }

    let nameRequest = null
    let lenght = null
    if (message.startsWith('@')) {
        nameRequest = message.split(' ')
        lenght = nameRequest[0].length
    }

    return (
        <div className="row">
            <div className={`col-1 firstLetter bg-${randomColor()}`}>{getFirstLetter(nom || 'n b')}</div>

            <div className={`message ${classeMessage} d-flex flex-column col-11`}>
                <div className={`${classeConteneur}`}>
                    <span className={`message-nom ${classeConteneur}`}>{nom} {isAdmin && <span className={`message-statut ${classeStatut}`}><Icon icon='CheckCircle' size='lg' ></Icon></span>}</span>
                    <span className="message-date">{ dateAvecNanosecondes ? dateAvecNanosecondes.format('llll') : dayjs(date).format('llll')}</span>
                </div>
                <div className={`message-contenu ${classeConteneur}`}>
                    {nameRequest ? (<span className='text-primary'>{nameRequest[0]} <span className='text-black'>{message.substring(lenght as number)}</span></span> ) : message}
                    {/*{message}*/}
                </div>
                <div className={`${gestionButton} d-flex`}>
                    <button type="button" className="btn btn-light" onClick={() => repondElement(nom)}>Repondre</button>
                    {(messagePhoneUser?.isAdmin || messagePhoneUser?.phone === phone?.substring(1)) && (
                        <button type="button" className="btn btn-danger" onClick={() => deleteElement(idDoc)}>Suprimer</button>
                    )}

                </div>
            </div>
        </div>

    );
};

export default Message;