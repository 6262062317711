import React from 'react';
import dayjs, {Dayjs} from "dayjs";
import Carousel from "../bootstrap/Carousel";
import {CardMedia} from "@mui/material";
import Dropdown, {DropdownItem, DropdownMenu, DropdownToggle} from "../bootstrap/Dropdown";
import Icon from "../icon/Icon";
import Button from "../bootstrap/Button";
import {useAppSelector} from "../../store/redux.types";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import {joinUrlWithParamsId} from "../../commons/helpers/funcHelper";
import './YourStylesheet.scss'; 
import { borderRadius } from '@mui/system';
import {PublicationStatus} from "../../commons/enums/PublicationStatus";
import {globalT} from "../../lang";
import LANG from "../../lang/language";


interface CardProps {
    images?:any[],
    title: string,
    titleEn: string,
    description: string,
    newLabel?: string,
    date?: Dayjs,
    date1?: Dayjs,
    id?: string,
    categoryName?: string,
    action?: {  detail?:() => void,delete?: () => void, edit?: () => void, valider?: () => void, invalider?: () => void },
    hasAnotherAction?: boolean,
    isNew?: boolean,
    state?: number,
    ability?: {edit: boolean, delete: boolean, validate?: boolean, invalidate?: boolean}

}

const CardImg: React.FC<CardProps> = ({images, ability, title,titleEn, description, newLabel, date,date1, id, categoryName, action, hasAnotherAction, state, isNew}) => {
    const { settings, authUserData } = useAppSelector(({ settings, authUser, }) => ({
        settings,
        authUserData: authUser.data
    }));

    const navigate = useNavigate()

    const getStatusInfo = (states: number) => {
        switch (states) {
            case PublicationStatus.pending.value:
                return {
                    color: 'dark',
                    name: 'publication.pending'
                }

            case PublicationStatus.validate.value:
                return {
                    color: 'success',
                    name: 'publication.valid'
                }
            case PublicationStatus.invalidate.value:
                return {
                    color: 'danger',
                    name: 'publication.invalid'
                }
        }
    }



    return (
        <div className="card h-100" id={id}>
             <Carousel  
                items={images?.map(img => ({src: img.attachmentFile, type: img.attachmentTypeName, isDetail: false, tomail: img.thumbsnailsFile}))}
                className="custom-carousel"/>
            {newLabel ? (
                <div style={{ fontWeight: 'bold',  borderRadius: '8px'}} className="position-absolute top-5 start-5 bg-danger text-warning p-2" >
                    {newLabel}
                </div>
            ): (<div  style={{ fontWeight: 'bold', borderRadius: '8px'}} className="position-absolute top-5 end-5 bg-danger text-warning p-2">
            {dayjs(date1).format('ll')}
         </div>) 
            
            }
            {date && (
                <div  style={{ fontWeight: 'bold', borderRadius: '8px'}} className="position-absolute top-5 end-5 bg-danger text-warning p-2">
                   {dayjs(date).format('ll')}
                </div>
            )}

            <div className="card-body">
                <h5 className="card-title">{ settings.language === LANG.fr.locale ? title : titleEn ? titleEn : title}</h5>
                {/*<p className="card-text">{description}</p>*/}
            </div>

            <div className="card-footer  ">

                <div className="col-10 d-flex flex-column justify-content-between">
                    {/*{!authUserData && (*/}
                    {/*    <i className="fas fa-comments"> {globalT("incident.comment")}</i>*/}
                    {/*)}*/}
                    {state !== undefined && (
                        <div  style={{ fontWeight: 'bold', borderRadius: '8px'}} className=" p-2">
                            <><span className={classNames(`border border-${getStatusInfo(state)?.color} border-2 text-${getStatusInfo(state)?.color} fw-bold px-3 py-2 rounded`)}>{globalT(getStatusInfo(state)?.name as string)}</span></>
                        </div>
                    )}
                </div>
                <div className="col-2 text-start">
                    { action && (
                        <Dropdown>
                          <DropdownToggle hasIcon={false}>
                            <Button>
                                <Icon icon='MoreVert' size='2x'  />
                            </Button>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <button className="btn text-black" role="button" onClick={action.detail}>{globalT("button.detail.pub")}</button>
                            </DropdownItem>
                            { authUserData && (
                                <>
                                    {ability?.edit && (
                                        <DropdownItem>
                                            <button onClick={action.edit} className="btn text-primary" role="button">{globalT("button.edit.pub")}</button>
                                        </DropdownItem>
                                    )}
                                    {ability?.delete && (
                                        <DropdownItem>
                                            <button onClick={action.delete} className="btn text-red" role="button">{globalT("button.delete.pub")}</button>
                                        </DropdownItem>
                                    )}

                            {hasAnotherAction && (
                                <>
                                    {[PublicationStatus.pending.value, PublicationStatus.invalidate.value].includes(state) && (
                                        ability?.validate && (
                                            <DropdownItem>
                                                <button onClick={action.valider} className="btn text-success" role="button">{globalT("button.valid.pub")}</button>
                                            </DropdownItem>
                                        )
                                    )}
                                    {PublicationStatus.validate.value === state && (
                                        ability?.invalidate && (
                                            <DropdownItem>
                                                <button onClick={action.invalider} className="btn text-red" role="button">{globalT("button.invalid.pub")}</button>
                                            </DropdownItem>
                                        )
                                    )}
                                </>
                            )}
                                </>
                                
                            )}
                            
                        </DropdownMenu>
                        </Dropdown>
                    )}
                </div>
            </div>
            
            
        </div>
    )
}

export default CardImg;
