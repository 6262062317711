import React from "react";
import Navnav from "../Navnav/Navnav";
import Footfoot from "../Navnav/Footfoot";
import { globalT } from "../../lang";
import './confi.scss'

const Confidentialite = () => {
  return (
    <>

   
      <Navnav />

      <div className="espacement">
      <section className="container" style={{ marginTop: "7vw" }}>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xl-12">
            <div className="fs-1 confidentialite-title my-5 fw-bold" style={{marginLeft:'0.2vw', marginBottom:'10vw'}}>
               {globalT("poli.co")}
            </div>

            <p className="fs-3 mx-3 fw-bold confidentialite-title">{globalT("intro")}</p>

            <div >
              <p>{globalT("poli.co.doc")}</p>
              <p>{globalT("ain.ce")}</p>
              <p>{globalT("cet.pre")}</p>
              <p>{globalT("le.sit.di")}</p>
              <p>{globalT("en.sa.qua")}</p>
            </div>

            <ul className="ms-3">
              <li>{globalT("de.ma")}</li>
              <li>{globalT("co.pour")}</li>
              <li>{globalT("ad.per")}</li>
              <li>{globalT("trai.fa")}</li>
            </ul>


            <div className="fs-3 fw-bold mt-5">
            1. {globalT("qui.som")}
            </div>

            <div className="my-2">
              {globalT("ad.sit")} <a href="https://mintransports.cm/">https://apsr-mint.cm</a>
            </div>

            <div className="fs-3 mt-5 my-2 fw-bold" >
            2. {globalT("uti.don")}

            </div>

            <div className="mt-4 confidentialite-title fw-bold" >
            {globalT("for.con")}

            </div>

            <div>
            {globalT("info.recc.via")}
            </div>

            <div>
            {globalT("elles.sont")}
            </div>
      

            <div className="mt-4 confidentialite-title fw-bold" >
            {globalT("com")}
            </div>

            <div>
            {globalT("qu.vous")}
            </div>

            <div className="my-4">
            {globalT("une.cha")}
            </div>

            <div className="mt-4 confidentialite-title fw-bold" >
            {globalT("media")}
            </div>

            <div>
            {globalT("si.vous")}
            </div>

            <div className="mt-4 confidentialite-title fw-bold" >
           {globalT("pro.info")}
            </div>

            <div>
            {globalT("ordi.utilise")}
            </div>

            <div className="mt-4 confidentialite-title fw-bold" >
            {globalT("consen")}
            </div>

            <div>
            {globalT("en.utili")}
            </div>

            <div className="mt-4 confidentialite-title fw-bold" >

            {globalT("con.em")}
            </div>

            <div>
              {globalT("art.sit.pe")}
            </div>

            <div className="my-4">
            {globalT("ces.site")}
            </div>

            <div className="fw-bold mt-4 confidentialite-title" >
              {globalT("dur.stock")}
            </div>

            <div>
            {globalT("si.vous.lai")}
            </div>

            <div>
            {globalT("po.les.ut")}
            </div>

            <div className="mt-4 confidentialite-title fw-bold" >
            {globalT("les.droits")}
            </div>

            <div>
            {globalT("les.droits.1")}
            </div>

            <div className="mt-4 confidentialite-title fw-bold" >
           
            {globalT("trans.vos")}
            </div>
            <div>

            {globalT("les.com.vis")}
            </div>

            {/*<div className="fw-bold" style={{marginTop:'7vw'}}>*/}


            {/*{globalT("info.de")}*/}
            {/*</div>*/}

            {/*<div className="my-3 fw-bold" >*/}
            {/*{globalT("info.supp")}*/}
            {/*</div>*/}

            {/*<div className="my-3 fw-bold" >*/}


            {/*{globalT("co.nous")}*/}

            {/*</div>*/}

            {/*<div className="my-3 fw-bold" >*/}
            {/*{globalT("pro.mis")}*/}
            {/*</div>*/}

            {/*<div className="my-3 fw-bold" >*/}

            {/*{globalT("les.serv")}*/}

            {/*</div>*/}

            {/*<div className="my-3 fw-bold" >*/}
            {/*{globalT("ope.mark")}*/}
            {/*</div>*/}

            {/*<div className="my-3 fw-bold" >*/}

            {/*{globalT("aff.info")}*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
      </div>
   
      <Footfoot />
    </>
  );
};

export default Confidentialite;
