import React from 'react';

const Question1 = () => {
    return (
        <div className='d-flex flex-column text-start ms-3'>
            <span>1- Les panneaux de danger.</span>
            <span>2- Les panneaux d'interdiction.</span>
            <span>3- Les panneaux d'indication.</span>
            <span>4- Les panneaux d'obligation.</span>
            <span>5- Les panneaux de direction.</span>
        </div>
    );
};

export default Question1;