import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import mintClient from "../../../network";
import Page from "../../../layout/Page/Page";
import DataImage from "../../../assets/img/landing2/vide.webp";
import { useAppDispatch, useAppSelector } from "../../../store/redux.types";
import ContentSkeletonLoader from "../../../components/loaders/ContentSkeletonLoader";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Carousel from "../../../components/bootstrap/Carousel";
import Publication from "../../../commons/models/Publication";
import { Paragraph } from "../../../commons/models/Paragraph";
import { setRequestGlobalLoader } from "../../../store/slices/requestGlobalLoader/actions";
import Navnav from "../../Navnav/Navnav";
import Footfoot from "../../Navnav/Footfoot";
import Spinner from "../../../components/bootstrap/Spinner";
import CardVid from "../../../components/bootstrap/CardVid";
import dayjs from "dayjs";
import { globalT } from "../../../lang";
// import styled from 'styled-components';
import { BsCalendar, BsFlag } from "react-icons/bs"; // Importer des icônes

// Ajouter des styles CSS pour le composant Description    maxHeight: '200px',

// const ScrollableDiv = styled.div`
//     height: 200px;  // Ajustez la hauteur en fonction de vos besoins
//     overflow: auto;
// `;

const VideoDetail = () => {
  const [publication, setPublication] = useState<Publication | null>(null);
  const [attachment, setAttachment] = useState<object[] | null>();
  const [paragraph, setParagraph] = useState<Paragraph[] | null>(null);
  const [video, setVideo] = useState<Publication[] | null>(null);
  const dispatch = useAppDispatch();
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const VideoDetailId = useParams().id;
  const navigate = useNavigate();
  const loadCampagne = () => {
    if (!VideoDetailId) return;
    // dispatch(setRequestGlobalLoader(true))
    mintClient.publication
      .getOne(VideoDetailId as string)
      .then((resp) => {
        const allData = resp.data.data;
        const itemCarousel = allData.attachement.map((attachment: any) => ({
          src: attachment.attachmentFile,
          type: attachment.attachmentTypeName,
          isDetail: true,
        }));
        setAttachment(itemCarousel);
        setPublication(allData);
        setCategoryId(Number(allData.categoryId));
      })
      .catch((error) => null)
      .finally(() => dispatch(setRequestGlobalLoader(false)));
  };
  const loadVideoG = () => {
    setLoading(true); // Start loading
    mintClient.publication
      .getAllVideo({
        page: 1,
        pageSize: 4,
        orderDirection: "ascending",
        validationState: 1,
        hasPagination: true,
        categoryId: categoryId,
        order: true,
        langue: "fr",
      })
      .then((resp) => {
        const allPublications = resp.data.data;
        setVideo(allPublications);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const loadParagraph = () => {
    if (!VideoDetailId) return;

    mintClient.publication
      .getParagraphByPublication(VideoDetailId as string)
      .then((resp) => {
        const data = resp.data.data;
        setParagraph(data);
      })
      .catch((error) => null);
  };

  useEffect(() => {
    loadCampagne();
    loadParagraph();
  }, []);

  useEffect(() => {
    if (categoryId !== null) {
      loadVideoG();
    }
  }, [categoryId]);

  return (
    <>
      <Navnav />
      <div className="container">
        {publication && paragraph ? (
          <div className="mb-3" style={{ marginTop: "150px" }}>
            <div className="pt-2 pb-3 d-flex flex-column align-items-start">
              <h1 className="display-4 fw-bold me-3">{publication.name}</h1>
            </div>
            <div style={{ marginBottom: "30px", marginLeft: "20px" }}>
              <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                <span>
                  <BsFlag color="red" />
                </span>
                <span>
                  {" "}
                  {globalT("categ.pub")} : {publication.categoryName}
                </span>
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                <span>
                  <BsCalendar color="blue" />
                </span>
                <span>
                  {" "}
                  {globalT("update.on")} :{" "}
                  {dayjs(publication.createdAt).format("DD/MM/YYYY")}
                </span>
              </p>
            </div>
            <div className="row pb-4">
              {attachment && (
                <div>
                  <div className="col-10 align-items-center-center ps-3 pb-3 ">
                    <Carousel items={attachment} isControl={true} />
                  </div>
                </div>
              )}

              <div className="des mt-4">
                <Card
                  style={{
                    marginLeft: "10px",
                    width: "120%",
                    marginTop: "20px",
                  }}
                >
                  <CardBody
                    style={{
                      height: "300px",
                      overflow: "auto",
                      fontSize: "15px",
                    }}
                  >
                    {publication.description}
                  </CardBody>
                </Card>
              </div>
            </div>
            <div className="">
              {paragraph.map((para) => (
                <>
                  <h2 className="display-4 fw-bold me-3">Paragraphes</h2>
                  <div className="row mt-4">
                    {para.images.length > 0 && (
                      <div className="col-5 mt-2">
                        <Carousel
                          items={para.images.map((img) => ({
                            src: img,
                            type: "IMAGE",
                            isDetail: true,
                          }))}
                        />
                      </div>
                    )}
                    <div className="col-7">
                      <span className=" fw-bold me-3 ms-3 display-6">
                        {para.name}
                      </span>
                      <div className="trait" />
                      <Card>
                        <CardBody>{para.description}</CardBody>
                      </Card>
                      {/*<div className="mt-3">*/}
                      {/*    <span>{para.description}</span>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        ) : (
          <div className="imageContainer" style={{ marginTop: "100px" }}>
            <div className="text-center mt-5 mb-5">
              <div
                style={{
                  width: "400px", // or any size you want
                  height: "400px", // make it a square
                  backgroundImage: `url(${DataImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  margin: "0 auto", // this will center the div if the parent div has a text-align: center
                }}
                className="image"
              />
              <div className="d-flex justify-content-center spinnerContainer">
                <div
                  className="spinner-border"
                  style={{
                    height: "6rem",
                    width: "6rem",
                    color: "#aae0aa",
                    marginTop: "85px",
                    marginRight: "15px",
                  }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
              <span
                className="fw-bold"
                style={{
                  marginTop: "20px",
                  fontSize: "24px",
                }}
              >
                {globalT("empty.patient")}
              </span>
            </div>
          </div>
        )}
        <section className="container pt-5 composant3">
          <h1 style={{ fontWeight: "bold" }}>
            {globalT("paragraph.management.list.video.similar")}
          </h1>
          <br></br>
          <div className="text-end">
            {loading && (
              <>
                <Spinner customColor="#aae0aa" size="6rem" />
              </>
            )}{" "}
            <br />
          </div>

          <div className="row py-4">
            {video && video.length > 0 ? (
              <>
                {video.map((pub, index) => (
                  <div className="col-lg-4 col-md-6 mb-4" id={`no${index}`}>
                    <CardVid
                      title={pub.name}
                      titleEn={pub.nameEn}
                      images={pub?.attachement}
                      id={pub.id}
                      date={pub.createdAt}
                    />
                  </div>
                ))}
              </>
            ) : (
              <div className="text-center mt-5 mb-5">
                <div
                  style={{
                    width: "400px", // or any size you want
                    height: "400px", // make it a square
                    backgroundImage: `url(${DataImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    margin: "0 auto", // this will center the div if the parent div has a text-align: center
                  }}
                />
                <span
                  className="fw-bold"
                  style={{
                    display: "block",
                    marginTop: "20px",
                    fontSize: "24px",
                  }}
                >
                  {globalT("empty.video.similar")}
                </span>
              </div>
            )}
          </div>
        </section>
      </div>
      <Footfoot />
    </>
  );
};

export default VideoDetail;
