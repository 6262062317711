import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import * as actions from "./actions";

export const INITIAL_STATE: { messagePhone: { phone: string, lastName?: string, name: string , isAdmin: boolean} | null } = {
    messagePhone: null,
};

const reducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(actions.messagePhone, (state, action) => {
            state.messagePhone = action.payload;
        })
});

export default reducer;
