import {createReducer} from "@reduxjs/toolkit";
import * as actions from "./actions";

export const INITIAL_STATE: { incident: any[] | null } = {
    incident: null
}

const reducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(actions.inciddentAlert, (state, action) => {
            state.incident = action.payload;
        })
});

export default reducer;
