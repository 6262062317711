import dayjs from "dayjs";

export class MessageDiscusion {
    public id: string;
    public attachment: string;
    public message: string;
    public lastName: string
    public email: string
    public phoneNumber: string
    public discussionId: string
    public firstName: string
    public endDate: string;
    public createdAt: dayjs.Dayjs;
    public updatedAt: dayjs.Dayjs;

    constructor(data: any) {
        this.id = data.id;
        this.attachment = data.attachment;
        this.message = data.message;
        this.email = data.email;
        this.phoneNumber = data.phoneNumber;
        this.discussionId = data.discussionId;
        this.lastName = data.lastName;
        this.firstName = data.firstName;
        this.endDate = data.endDate;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt
    }

}


