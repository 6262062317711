import React, {useState, useRef, useEffect} from 'react';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import * as YupShema from '../../components/forms/yupShema'
import {setRequestGlobalLoader} from "../../store/slices/requestGlobalLoader/actions";
import csuClient from "../../network";
import NotificationManager from "../../components/notifications/NotificationManager";
import {globalT} from "../../lang";
import {FileItem} from "../../components/DragAndDrop";
import {useAppDispatch} from "../../store/redux.types";

import IntlMessages from "../../components/IntlMessages";
import Page from "../../layout/Page/Page";
import Card, {CardBody, CardHeader, CardTitle} from "../../components/bootstrap/Card";
import InputComponent from "../../components/forms/InputComponent";
import * as yupSchema from "../../components/forms/yupShema";
import Button from "../../components/bootstrap/Button";
import {useNavigate} from "react-router-dom";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";




type FormType = {
    email: string;
    lastName?: string,
    fistName?: string,
    phone?:string,
    town?:string
}




const schema = yup.object().shape({
    lastName: YupShema.name,
    town: YupShema.name,
    phone: yupSchema.phone,
})

type AddContact = {
    onClose: () => void
}

const Add = ({onClose}: AddContact) => {

    const [files, setFiles] = useState<FileItem[]>([])
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormType>({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });    

    const isFormValid = (data: FormType) => {
        let isValid = true;

        return isValid
    }

    const onSubmit: SubmitHandler<FormType> = (data) => {
        if (!isFormValid(data))
            return;

            const formData = new FormData();
            formData.append('LastName', data.lastName as string);
            formData.append('FirstName', data.fistName as string);
            formData.append('phone', data.phone as string);
            formData.append('Town', data.town as string);
            formData.append('Email', data.email as string);

        // return;

        dispatch(setRequestGlobalLoader(true))


        csuClient
            .newsletter
            .create(formData)
            .then((res) => {
                NotificationManager.success(globalT('contact.add.success'))
                onClose()
            })
            .catch(() => null)
            .finally(() => dispatch(setRequestGlobalLoader(false)))
    }

    return (
        <PageWrapper>
            <Page className='px-sm-3'>
                <div className='row'>
                    <div className='col-12'>
                        <Card>
                            <CardHeader>
                                <CardTitle tag='h3'>
                                    <IntlMessages id='form.field.contact.label'/>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <form className='row g-4 mx-3' onSubmit={handleSubmit(onSubmit)}>

                                    <div className='col-sm-12 '>
                                        <InputComponent
                                            name="lastName"
                                            errors={errors}
                                            control={control}
                                            displayRequiredAsterisk
                                            label={<IntlMessages id='form.field.lastName'/>}
                                        />
                                    </div>

                                    <div className='col-sm-12 '>
                                        <InputComponent
                                            name="fistName"
                                            errors={errors}
                                            control={control}
                                            isRequired={false}
                                            label={<IntlMessages id='form.field.firstName'/>}
                                        />
                                    </div>

                                    <div className='col-md-12'>
                                        <InputComponent
                                            errors={errors}
                                            control={control}
                                            displayRequiredAsterisk
                                            isRequired={true}
                                            name='phone'
                                            type='number'
                                            label={<IntlMessages id='form.field.phoneNumber'/>}
                                            otherValidators={{
                                                validate: (val: string | undefined) => !val ? true : yupSchema.regex.cameroonPhoneNumber.test(val || '')
                                                    || (<IntlMessages id="form.errors.phone.cmr" />)
                                            }}
                                        />
                                    </div>

                                    <div className='col-sm-12 '>
                                        <InputComponent
                                            name="town"
                                            errors={errors}
                                            control={control}
                                            isRequired={true}
                                            displayRequiredAsterisk
                                            label={<IntlMessages id='form.field.city'/>}
                                        />
                                    </div>

                                    <div className='col-12'>
                                        <InputComponent
                                            name="email"
                                            type='email'
                                            errors={errors}
                                            control={control}
                                            isRequired={false}
                                            label={<IntlMessages id='form.field.email' />}
                                        />
                                              
                                    </div>
                                                            

                                    <div className="col-sm-12 col-md-10">
                                        <Button
                                            icon='Save'
                                            type='submit'
                                            color='primary'
                                           
                                            // iconPosition='right'
                                            className="text-nowrap bg-gradient ms-2 bg-orange">
                                            <IntlMessages id={globalT("button.send")} />
                                        </Button>
                                    </div>

                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
};

export default Add;