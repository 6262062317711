import React, {useState} from 'react';
import {Evenements} from "../../../commons/models/Evenements";
import {Campagne} from "../../../commons/models/Campagne";
import neutre3 from "../../../assets/img/landing2/nouv/blackNeutre.jpeg"
import LANG from "../../../lang/language";
import {useAppSelector} from "../../../store/redux.types";
import dayjs from "dayjs";
import {globalT} from "../../../lang";
import DialogComponent from "../../../components/dialogs/DialogComponent";

type propsEvent = {
    data: Evenements | Campagne;
    type: "campagne" | "evenement";
};

const CardCampagne = ({data, type}: propsEvent) => {

    const { settings, authUserData } = useAppSelector(({ settings, authUser, }) => ({
        settings,
        authUserData: authUser.data
    }));
    const [showInfo, setShowInfo] = useState(false);

    return (
        <div className="row campaingnEventCard mb-2">
            <div className="col-4">
                <img src={neutre3} alt="neutre" className="img-fluid"/>
            </div>
            <div className="col-8">
                <div className="d-flex flex-column">
                    <span onClick={() => setShowInfo(true)} className="campaingnEventCard__title cursor-pointer">{ settings.language === LANG.fr.locale ? data?.name : data?.nameEn ? data.nameEn : data.name}</span>
                    <span className="campaingnEventCard__date">{ `${dayjs(data.startDate).format("ll")} - ${dayjs(data.endDate).format("ll")}`}</span>
                    {/*{type === "evenement" ? (*/}
                    {/*    <p>*/}
                    {/*        <span className="fw-bold">{globalT("form.field.type")}: </span>*/}
                    {/*        {(data as Evenements)?.type === 1 ? " Séminaire" : " Atélier"}*/}
                    {/*    </p>*/}
                    {/*) : (*/}
                    {/*    <p>*/}
                    {/*        <span className="fw-bold">{(data as Campagne)?.goal}:</span>*/}
                    {/*    </p>*/}
                    {/*)}*/}
                </div>
            </div>
            <DialogComponent
                show={showInfo}
                size="lg"
                title={globalT("button.details")}
                onClose={() => setShowInfo(false)}
            >
                <div className="ms-2">
                    <p className="fw-bold">{ settings.language === LANG.fr.locale ? data?.name : data?.nameEn ? data?.nameEn : data?.name}</p>
                    <p>
                        {" "}
                        <span className="fw-bold">{globalT("form.field.endate")}:</span>{" "}
                        {dayjs(data?.startDate).format("ll")}{" "}
                    </p>
                    <p>
                        {" "}
                        <span className="fw-bold">{globalT("form.field.startdate")}:</span>{" "}
                        {dayjs(data?.endDate).format("ll")}{" "}
                    </p>
                    {type === "evenement" ? (
                        <p>
                            {" "}
                            <span className="fw-bold">{globalT("form.field.type")}:</span>{" "}
                            {(data as Evenements)?.type === 1 ? "Séminaire" : "Atélier"}{" "}
                        </p>
                    ) : (
                        <div>
                            <p>
                                {" "}
                                <span className="fw-bold">{globalT("form.field.goal")}:</span> {(data as Campagne)?.goal}{" "}
                            </p>
                            <p>
                                {" "}
                                <span className="fw-bold">{globalT("form.field.place")}:</span> {(data as Campagne)?.place}{" "}
                            </p>
                        </div>
                    )}

                    <p>
                        {" "}
                        <span className="fw-bold">description:</span> { settings.language === LANG.fr.locale ? data?.description : data?.descriptionEn ? data?.descriptionEn : data?.description}{" "}
                    </p>
                </div>

            </DialogComponent>
        </div>
    );
};

export default CardCampagne;