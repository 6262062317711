
import React, { lazy, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../views/acceuill/Home";
import OnlyUnAuthRouteMiddleware from "./OnlyUnAuthRouteMiddleware";
import { AUTH } from "../commons/urls/front";
import Actualite from "../views/Actualite-recente/Actualite";
import EvenementCurrent from "../views/evenement/EvenementCurrent";
import ActualiteCategorie from "../views/Actualite-recente/ActualiteCategorie";
import Detail from "../views/Actualite-recente/Detail";
import Incident from "../views/Incident/Incident";
import { useAppDispatch, useAppSelector } from "../store/redux.types";
import mintClient from "../network";
import { inciddentAlert } from "../store/slices/alert/actions";
import DetailIncident from "../views/acceuill/incident/Detail";
import { catActualite } from "../store/slices/catActualite/actions";
import { catRevue } from "../store/slices/catDocument/actions";
import { catAlert } from "../store/slices/catAlert/actions";
import Revue from "../views/acceuill/revue/Revue";
import { bot } from "../store/slices/bot/actions";
import IndexChat from "../views/acceuill/forum";
import DiscusionMessage from "../views/acceuill/forum/DiscusionMessage";
//import RevueCategories from "../views/acceuill/revue/RevueCategories";
import Video from "../views/acceuill/Video/Video";
import VideoCategorie from "../views/acceuill/Video/VideoCategorie";
import CampagneHome from "../views/acceuill/campagne/campagne";
import CampagneDetail from "../views/acceuill/campagne/campagneDetail";
import VideoDetail from "../views/acceuill/Video/VideoDetail";
import DetailEvenement from "../views/evenement/DetailEvenement";
import DetailActualite from "../views/Actualite-recente/DetailActualite";
import DetailEv from "../views/acceuill/evenement/Detail";
import AlertList from "../views/Alert/alert/AlertList";
import Confidentialite from "../views/Confidentialite/Confidentialite";
import Terms from "../views/Confidentialite/Terms";
import ProfilUser from "../views/Profil/ProfilUser";
import DocumentEducation from "../views/acceuill/education/Document";
import VideoEducation from "../views/acceuill/education/Video";
import ChangePasswordForm from "../views/ChangePassWord/ChangPass";
import EvenementAfter from "../views/evenement/EvenementAfter";
import CampagneCurrent from "../views/acceuill/campagne/CampagneCurrent";
import CampagneAfter from "../views/acceuill/campagne/CampagneAfter";
import { lastAlert } from "../store/slices/alertLast/actions";
import About from "../views/acceuill/About";
import Audio from "../views/acceuill/education/Audio";
import Depliant from "../views/acceuill/education/Depliant";
import Education from "../views/acceuill/education/Education";
import Presentation from "../views/acceuill/presentation/Presentation";
const LazyLogin = lazy(() => import("../views/auth/Login"));
import Ong from "../views/acceuill/services/Ong"

import Immatriculation from "../views/immatriculationUsager/Immatriculation";
import PermisConduire from "../views/acceuill/permis/PermisConduire";
import Centre from "../views/acceuill/services/Centre"
import Ecole from "../views/acceuill/services/Ecole";
import RevueCategories from "../views/acceuill/revue/RevueCategories";
import Analyse from "../views/acceuill/Analyse";

const UnauthRoutes = () => {
  const { incident } = useAppSelector(({ alert }) => alert);

  const actualite = useAppSelector(({ catActualite }) => catActualite);

  const alertCat = useAppSelector(({ catAlert }) => catAlert);

  const evenementCat = useAppSelector(({ catRevue }) => catRevue);

  const botChat = useAppSelector(({ bot }) => bot.bot);

  const alertlast = useAppSelector(({ alertLast }) => alertLast.lastAlert);

  const dispatch = useAppDispatch();

  const loadAlert = () => {
    if (!incident) {
      mintClient.alerte
        .getAll({
          page: 1,
          pageSize: 15,
          // "orderDirection": "ascending",
          hasPagination: true,
          // "validationState": 1
        })
        .then((res) => {
          const data = res.data.data;
          dispatch(inciddentAlert(data));
        })
        .catch(() => null);
    }
  };

  const loadMessage = () => {
    if (!botChat)
      mintClient.botCategorie
        .getAll()
        .then((resp) => {
          const data = resp.data.data;
          dispatch(bot(data));
        })
        .then(() => null);
  };

  const loadCathegorie = () => {
    // dispatch(setRequestGlobalLoader(true))
    if (!actualite.catActualite)
      mintClient.categoryPublication
        .getAll()
        .then((resp) => {
          const data = resp.data.data;
          dispatch(catActualite(data));
        })
        .catch((error) => console.log(error));
    // .finally(() => dispatch(setRequestGlobalLoader(false)))
  };

  const loadCathegorieAlert = () => {
    // dispatch(setRequestGlobalLoader(true))
    if (!alertCat.catAlert)
      mintClient.categoryAlert
        .getAll()
        .then((resp) => {
          const data = resp.data.data;
          dispatch(catAlert(data));
        })
        .catch((error) => console.log(error));
    // .finally(() => dispatch(setRequestGlobalLoader(false)))
  };

  const loadCathegorieRevue = () => {
    // dispatch(setRequestGlobalLoader(true))
    if (!evenementCat.catRevue)
      mintClient.category
        .getAll({ isParent: false, page: 1, pageSize: 100 })
        .then((resp) => {
          const data = resp.data.data;
          const dataFilter = data.filter((d) => d.id.toString() !== "1");
          dispatch(catRevue(dataFilter));
        })
        .catch((error) => console.log(error));
    // .finally(() => dispatch(setRequestGlobalLoader(false)))
  };

  const loadLastAlert = () => {
    if (!alertlast)
      mintClient.alerte
        .getAllLast()
        .then((res) => {
          const data = res.data.data;
          dispatch(lastAlert(data));
        })
        .catch(() => null);
  };

  useEffect(() => {
    loadCathegorie();
    loadCathegorieRevue();
    loadAlert();
    loadMessage();
    loadCathegorieAlert();
    loadLastAlert();
  }, []);

    useEffect(() => {
        loadCathegorie()
        loadCathegorieRevue()
        loadAlert()
        loadMessage()
        loadCathegorieAlert()
        loadLastAlert()
    }, [])

    return (
        <>

            <Routes>
                
                <Route path={AUTH.LOGIN} element={<OnlyUnAuthRouteMiddleware component={<LazyLogin />} />} />
                <Route path='/evenement/current' element={<EvenementCurrent />} />
                <Route path='/evenement/after' element={<EvenementAfter />} />
                <Route path='/campagne/current' element={<CampagneCurrent />} />
                <Route path='/campagne/after' element={<CampagneAfter />} />
                <Route path='/Campagne' element={<CampagneHome />} />
                <Route path='/actualite' element={<Actualite />} />
                <Route path='/revue' element={<Revue />} />
                <Route path="/revue/categorie/:id" element={<RevueCategories />} />
                <Route path='/Chat' element={<IndexChat />} />
                <Route path='/About' element={<About />} />
                <Route path='/Discussion/:id' element={<DiscusionMessage />} />
                <Route path='/education/document' element={<DocumentEducation />} />
                <Route path='/education/video' element={<VideoEducation />} />
                <Route path='/education/audio' element={<Audio />} />
                <Route path='/education/depliant' element={<Depliant />} />
                <Route path='/education/education' element={<Education />} />
                <Route path='/services/ong' element={<Ong />}/>
                <Route path='/services/autoEcole' element={<Ecole />}/>
                <Route path='/campagne/:id' element={<CampagneDetail />} />
                <Route path='/evenement/:id' element={<DetailEv />} />
                <Route path='/actualite/:id' element={<DetailActualite />} />
                <Route path='/incident/:id' element={<DetailIncident />} />
                <Route path='/actualite/categorie/:id' element={<ActualiteCategorie />} />
                <Route path='*' element={<Home />} />
                <Route path='/incident' element={<Incident />} />
                <Route path='/video' element={<Video />} />
                <Route path='/video/categorie/:id' element={<VideoCategorie />} />
                <Route path='/video/:id' element={<VideoDetail />} />
                <Route path='/listalert' element={<AlertList />} />
                <Route path='/confidentialite' element={<Confidentialite />} />
                <Route path='/terms' element={<Terms />} />
                <Route path="/presentation" element={<Presentation />} />
                <Route path="/analyse" element={<Analyse />} />
                <Route path="/immatriculation/usager" element={<Immatriculation />} />
                <Route path="/service/permis" element={<PermisConduire />} />
                <Route path='/services/centre' element={<Centre/>}/>
            </Routes>
        </>

    );

};

export default UnauthRoutes;



