import React from 'react'
import './CardVid.scss'
import dayjs, { Dayjs } from "dayjs";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../bootstrap/Dropdown";
import Button from "../bootstrap/Button";
import { useAppSelector } from '../../store/redux.types';
import { useNavigate } from 'react-router-dom';
import { PublicationStatus } from '../../commons/enums/PublicationStatus';
import classNames from "classnames";
import { globalT } from "../../lang";
import Icon from "../icon/Icon";
import { joinUrlWithParamsId } from "../../commons/helpers/funcHelper";
import Carousel from "../bootstrap/Carousel";
import LANG from "../../lang/language";



interface VideoProps {
    images: any[],
    title: string,
    titleEn: string,
    newLabel?: string,
    date?: Dayjs,
    thomails?: any,
    state?: number,
    action?: { delete?: () => void, edit?: () => void, valider?: () => void, invalider?: () => void, detail?: () => void },
    hasAnotherAction?: boolean,
    id?: string


}

const CardVid: React.FC<VideoProps> = ({ title, titleEn, thomails, date, images, state, action, hasAnotherAction, id }) => {

    const { settings, authUserData } = useAppSelector(({ settings, authUser, }) => ({
        settings,
        authUserData: authUser.data
    }));

    const navigate = useNavigate()

    const getStatusInfo = (states: number) => {
        switch (states) {
            case PublicationStatus.pending.value:
                return {
                    color: 'dark',
                    name: 'publication.pending'
                }

            case PublicationStatus.validate.value:
                return {
                    color: 'success',
                    name: 'publication.valid'
                }
            case PublicationStatus.invalidate.value:
                return {
                    color: 'danger',
                    name: 'publication.invalid'
                }
        }
    }


    return (

        <>
            <div className="card h-100" id={id}>
                <Carousel
                    items={images.map(img => ({ src: img.attachmentFile, type: img.attachmentTypeName, isDetail: false, tomail: img.thumbsnailsFile || thomails}))}
                    className="custom-carousel" />
                <div className="card-body">
                    {date && (
                        <div style={{ fontWeight: 'bold', borderRadius: '8px' }} className=" position-absolute top-1 start-5 bg-danger text-warning ml-2 fs-6" >
                            {dayjs(date).format('ll')}
                        </div>
                    )}
                    <h5 className="card-title fs-6 mt-5">{ settings.language === LANG.fr.locale ? title : titleEn ? titleEn : title}</h5>
                    {/* <p className="card-text fs-7">{props.des}</p> */}
                    {/* <a href="#" className="text-dark fs-8 text-decoration-none">
                        <i className="fas fa-list-alt me-2"></i>Commentaire
                        </a> */}
                </div>
                <div className="card-footer  ">

                    <div className="col-10 d-flex flex-column justify-content-between">
                        {/*{!authUserData && (*/}
                        {/*    <i className="fas fa-comments"> {globalT("incident.comment")}</i>*/}
                        {/*)}*/}
                        {state !== undefined && (
                            <div style={{ fontWeight: 'bold', borderRadius: '8px' }} className=" p-2">
                                <><span className={classNames(`border border-${getStatusInfo(state)?.color} border-2 text-${getStatusInfo(state)?.color} fw-bold px-3 py-2 rounded`)}>{globalT(getStatusInfo(state)?.name as string)}</span></>
                            </div>
                        )}
                    </div>
                    <div className="col-2 text-start">
                        {action && (
                            <Dropdown>
                                <DropdownToggle hasIcon={false}>
                                    <Button>
                                        <Icon icon='MoreVert' size='2x' />
                                    </Button>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {action.detail && (
                                        <DropdownItem>
                                            <button className="btn text-black" role="button" onClick={action.detail}>{globalT("button.detail.pub")}</button>
                                        </DropdownItem>
                                    )}
                                    {authUserData && (
                                        <>
                                            <DropdownItem>
                                                <button onClick={action.edit} className="btn text-primary" role="button">{globalT("button.edit.pub")}</button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <button onClick={action.delete} className="btn text-red" role="button">{globalT("button.delete.pub")}</button>
                                            </DropdownItem>
                                            {hasAnotherAction && (
                                                <>
                                                    {[PublicationStatus.pending.value, PublicationStatus.invalidate.value].includes(state) && (
                                                        <DropdownItem>
                                                            <button onClick={action.valider} className="btn text-success" role="button">{globalT("button.valid.pub")}</button>
                                                        </DropdownItem>
                                                    )}
                                                    {PublicationStatus.validate.value === state && (
                                                        <DropdownItem>
                                                            <button onClick={action.invalider} className="btn text-red" role="button">{globalT("button.invalid.pub")}</button>
                                                        </DropdownItem>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardVid