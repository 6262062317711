import dayjs from "dayjs";
import Category from "./Category";

export default class Alert {
    
    public id: string;
    public ville: string;
    public value: string;
    public categoryName: string;
    public description: string;
    public descriptionEn: string;
    public categoryId: string;
    public villeEn: string;
    public valueEn: string;
    
    constructor(data: any) {
        this.id = data.id;
        this.ville = data.ville;
        this.value = data.value;
        this.description = data.description;
        this.descriptionEn = data.descriptionEn;
        this.categoryId = data.categoryId;
        this.categoryName = data.categoryName;
        this.villeEn = data.villeEn;
        this.valueEn = data.valueEn;
    }

}

