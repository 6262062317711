import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import * as actions from "./actions";

export const INITIAL_STATE: { catActualite: any[] | null } = {
    catActualite: null,
};

const reducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(actions.catActualite, (state, action) => {
            state.catActualite = action.payload;
        })
});

export default reducer;
