import React from "react";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import Header from "../Header";
import { globalT } from "../../../lang";
import Button from "../../../components/bootstrap/Button";
import { useNavigate } from "react-router-dom";
import ngale from "../../../assets/img/minsiter/0.jpg";
import img2 from "../../../assets/img/minsiter/2.jpg";
import gounoko from "../../../assets/img/minsiter/gounoko.jpg";
import dakole from "../../../assets/img/minsiter/dakole.jpg";
import img3 from "../../../assets/img/minsiter/3.jpg";
import img4 from "../../../assets/img/minsiter/4.jpg";
import img5 from "../../../assets/img/minsiter/5.jpg";
import img6 from "../../../assets/img/minsiter/6.jpg";
import img7 from "../../../assets/img/minsiter/7.jpg";
import img8 from "../../../assets/img/minsiter/8.jpg";
import img9 from "../../../assets/img/minsiter/9.jpg";
import img10 from "../../../assets/img/minsiter/10.jpg";
import img11 from "../../../assets/img/minsiter/11.jpg";
import img12 from "../../../assets/img/minsiter/12.jpg";
import img13 from "../../../assets/img/minsiter/13.jpg";
import img14 from "../../../assets/img/minsiter/14.jpg";
import img15 from "../../../assets/img/minsiter/15.jpg";
import img16 from "../../../assets/img/minsiter/16.jpg";
import jean from "../../../assets/img/minsiter/jean.webp";
import benjamin from "../../../assets/img/minsiter/benjamin.jpg";
import albert from "../../../assets/img/minsiter/albert.jpg";
import paul from "../../../assets/img/minsiter/paul.jpeg";
import vincent from "../../../assets/img/minsiter/vincent.jpg";
import bernard from "../../../assets/img/minsiter/bernard.jpeg";
import solomon from "../../../assets/img/minsiter/solomon.jpeg";
import oumarou from "../../../assets/img/minsiter/oumarou.jpeg";
import okaka from "../../../assets/img/minsiter/okala.webp";
import michel from "../../../assets/img/minsiter/michel.jpeg";
import njoya from "../../../assets/img/minsiter/njoya.jpeg";
import mefiro from "../../../assets/img/minsiter/mefiro.jpeg";
import badel from "../../../assets/img/minsiter/badel.jpg";
import nana from "../../../assets/img/minsiter/nana.jpeg";
import img17 from "../../../assets/img/minsiter/17.jpg";
import img18 from "../../../assets/img/minsiter/img18.jpeg";
import CardMinister from "../CardMinister";
import Footer from "../Footer";
import newImage from "../../../assets/img/landing2/back_copie.png";
import ImageSensibilisation from "../../../components/ImageSensibilisation";

const Presentation = () => {
  const navigate = useNavigate();
  const tabMinister = [
    {
      id: 1,
      image: ngale,
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "2 mars 2018 -",
      nomination: "02/03/2018",
      name: "Jean Ernest Masséna NGALLÈ BIBÉHÈ",
    },
    {
      id: 2,
      image: img3,
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "2015-2018",
      nomination: "02/03/2018",
      name: "Edgard Alain MEBE NGO",
    },

    {
      id: 3,
      image: img5,
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "2011-2015",
      nomination: "02/03/2018",
      name: "Robert NKILI",
    },
    {
      id: 4,
      image: img18,
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "2009-2011",
      nomination: "02/03/2018",
      name: "Bello BOUBA MAIGARI",
    },
    {
      id: 56,
      image: gounoko,
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "2007-2009",
      nomination: "02/03/2018",
      name: "GOUNOKO HAOUNAYE",
    },
    {
      id: 5,
      image: dakole,
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "2004-2007",
      nomination: "02/03/2018",
      name: "DAKOLE DAISSALA",
    },
    {
      id: 6,
      image: img11,
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "2004-2004",
      nomination: "02/03/2018",
      name: "Charles SALE",
    },
    {
      id: 7,
      image: img12,
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "2002-2004",
      nomination: "02/03/2018",
      name: "John BEGHENI NDEH",
    },
    {
      id: 8,
      image: img14,
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "2001-2002",
      nomination: "02/03/2018",
      name: "Christopher NSAHLAI",
    },
    {
      id: 9,
      image: "",
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "1996-2001",
      nomination: "02/03/2018",
      name: "Joseph TSANGA ABANDA",
    },
    {
      id: 10,
      image: img16,
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "1992-1996",
      nomination: "02/03/2018",
      name: "Issa TCHIROMA BAKARI",
    },
    {
      id: 11,
      image: jean,
      functiion: "Travaux publics et Transports",
      functiionEnglish: "Public works and Transport",
      period: "1992-1992",
      nomination: "02/03/2018",
      name: "Jean Baptiste BOKAM",
    },
    {
      id: 11,
      image: paul,
      functiion: "Travaux publics et Transports",
      functiionEnglish: "Public works and Transport",
      period: "1991-1992",
      nomination: "02/03/2018",
      name: "Paul TESSA",
    },
    {
      id: 12,
      image: "",
      functiion: "Travaux publics et Transports",
      functiionEnglish: "Public works and Transport",
      period: "1988-1991",
      nomination: "02/03/2018",
      name: "Claude TCHEPANOU",
    },
    {
      id: 13,
      image: "",
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "1985-1988",
      nomination: "02/03/2018",
      name: "André Bosco TCHEUOUA",
    },
    {
      id: 14,
      image: benjamin,
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "1984-1985",
      nomination: "02/03/2018",
      name: "Benjamin ITOE",
    },
    {
      id: 15,
      image: albert,
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "1979-1984",
      nomination: "02/03/2018",
      name: "Albert NGOME KOME",
    },
    {
      id: 16,
      image: "",
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "1975-1979",
      nomination: "02/03/2018",
      name: "John MONI NKENGONG",
    },
    {
      id: 17,
      image: "",
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "1972-1975",
      nomination: "02/03/2018",
      name: "Christian SONGWE BONGWA",
    },
    {
      id: 18,
      image: vincent,
      functiion: "Ministre des Transports",
      functiionEnglish: "Minister of Transport",
      period: "1970-1972",
      nomination: "02/03/2018",
      name: "Vincent EFON",
    },
    {
      id: 19,
      image: bernard,
      functiion: "Transports, Postes et Télécommunications",
      functiionEnglish: "Transport, Posts and Telecommunications",
      period: "1968-1970",
      nomination: "02/03/2018",
      name: "Bernard FONLON",
    },
    {
      id: 20,
      image: solomon,
      functiion: "Transports, Mines et Postes",
      functiionEnglish: "Transport, Mines and Posts",
      period: "1961-1968",
      nomination: "02/03/2018",
      name: "Solomon TANDENG MUNA",
    },
    {
      id: 21,
      image: oumarou,
      functiion: "Transports, Mines et Postes",
      functiionEnglish: "Transport, Mines and Posts",
      period: "1959-1961",
      nomination: "02/03/2018",
      name: "Oumarou SANDA",
    },
    {
      id: 22,
      image: "",
      functiion: "Transports, Mines et Postes",
      functiionEnglish: "Transport, Mines and Posts",
      period: "1972-1975",
      nomination: "02/03/2018",
      name: "Christian SONGWE BONGWA",
    },
    {
      id: 22,
      image: okaka,
      functiion: "Ministre des Travaux Publics, Transports et Mines",
      functiionEnglish: "Minister of Public Works, Transport and Mines",
      period: "1958-1959",
      nomination: "02/03/2018",
      name: "René-Guy Charles OKALA",
    },
    {
      id: 17,
      image: michel,
      functiion: "Transports, Mines et Postes",
      functiionEnglish: "Transport, Mines and Posts",
      period: "1957-1958",
      nomination: "02/03/2018",
      name: "Michel NJINE",
    },
  ];

  const tabdelegue = [
    {
      id: 1,
      image: njoya,
      functiion: "Ministre délégué auprès du ministre du Transport",
      functiionEnglish: "Minister Delegate to the Minister of Transport ",
      period: "2019-",
      nomination: "02/03/2018",
      name: "NJOYA ZAKARIOU",
    },
    {
      id: 2,
      image: mefiro,
      functiion: "Ministre délégué auprès du ministre du Transport",
      functiionEnglish: "Minister Delegate to the Minister of Transport ",
      period: "2011-2019",
      nomination: "02/03/2018",
      name: "Oumarou MEFIRO",
    },
  ];

  const tabSecretaire = [
    {
      id: 188,
      image: mefiro,
      functiion: "Secrétaire d'Etat aux transports",
      functiionEnglish: "Secretary of State for Transport",
      period: "2007-2011",
      nomination: "02/03/2018",
      name: "Oumarou MEFIRO",
    },
    {
      id: 2,
      image: badel,
      functiion: "Secrétaire d'Etat aux transports",
      functiionEnglish: "Secretary of State for Transport",
      period: "2006-2007",
      nomination: "02/03/2018",
      name: "Badel NDANGA NDINGA",
    },
    {
      id: 3,
      image: "",
      functiion: "Secrétaire d'Etat aux transports",
      functiionEnglish: "Secretary of State for Transport",
      period: "2004-2006",
      nomination: "02/03/2018",
      name: "Edmond MOAMPEA MBIO",
    },
    {
      id: 4,
      image: nana,
      functiion: "Secrétaire d'Etat aux transports",
      functiionEnglish: "Secretary of State for Transport",
      period: "1994-1997",
      nomination: "02/03/2018",
      name: "Nana Aboubakar DJALLO",
    },

    {
      id: 5,
      image: img17,
      functiion: "Secrétaire d'Etat aux Travaux publics aux Transports",
      functiionEnglish: "Secretary of State for Public Works and Transport",
      period: "1992-1992",
      nomination: "02/03/2018",
      name: "Shey Jones YEMBE",
    },
  ];

  return (
    <>
      {" "}
      <PageWrapper>
        <Header currentMenu={"presentation"} />
        <Page>
          <div className="" id={"sectionId"}>
            <ImageSensibilisation image={newImage} texte="Organisation du ministère des transports"/>
          </div>
          <div className="container">
            <div className="row">
              <div className="mb-5 global-presentation-style">
                <p className="fw-bold" style={{ textAlign: "center" }}>
                  {globalT("about.minister")}
                </p>
                <p>{globalT("about.minister.1")}</p>

                {/*<div className="">*/}
                {/*    <p className="fw-bold">{globalT("about.minister.2")}</p>*/}
                {/*    <p>{globalT('about.minister.3')}</p>*/}
                {/*</div>*/}

                <div className="">
                  <p className="fw-bold" style={{ textAlign: "center" }}>
                    {globalT("about.minister.4")}
                  </p>
                  <p>{globalT("about.minister.5.1")}</p>
                  <ul>
                    <li>{globalT("about.minister.6.1")}</li>
                    <li>{globalT("about.minister.7.1")}</li>
                    <li>{globalT("about.minister.8.1")}</li>
                    <li>{globalT("about.minister.9.1")}</li>
                    <li>{globalT("about.minister.10.1")}</li>
                    <li>{globalT("about.minister.11.1")}</li>
                    <li>{globalT("about.minister.11.2")}</li>
                  </ul>
                  <p>{globalT("about.minister.11.3")}</p>
                  <p>{globalT("about.minister.11.4")}</p>
                  <div className="mt-5" style={{ textAlign: "center" }}>
                    <Button
                        tag={"a"}
                        href={"https://mintransports.cm/"}
                        target={"_blank"}
                        color={"secondaire"}
                    >
                      <span>Cliquez ici pour plus d'information</span>
                    </Button>
                  </div>
                </div>
              </div>

              <div>
                <div className="global-presentation-style">
                  {/*<div className="row">*/}
                  {/*    <div className="col-md-3">*/}
                  {/*        <img src={""} alt="Ngale" className='img-fluid'/>*/}
                  {/*    </div>*/}
                  {/*    <div className="col-md-9">*/}
                  {/*        <h2>"" BIBEHE Jean Ernest Massena</h2>*/}
                  {/*        <p>Ministre des Transports</p>*/}
                  {/*    </div>*/}
                  {/*</div>*/}

                  <div className="text-center">
                    <h1>{globalT("about.history.minister")}</h1>
                  </div>
                  <div className="row mt-5">
                    {tabMinister.map((m) => (
                      <div className="col-4 mb-4">
                        <CardMinister
                          functiion={m.functiion}
                          nomination={m.nomination}
                          period={m.period}
                          name={m.name}
                          image={m.image}
                          functiionEnglish={m.functiionEnglish}
                        />
                      </div>
                    ))}
                  </div>

                  <div className="text-center" style={{ marginTop: "10rem" }}>
                    <h1>{globalT("about.history.delegue")}</h1>
                  </div>
                  <div className="row mt-5">
                    {tabdelegue.map((m) => (
                      <div className="col-4 mb-4">
                        <CardMinister
                          functiion={m.functiion}
                          nomination={m.nomination}
                          period={m.period}
                          name={m.name}
                          image={m.image}
                          functiionEnglish={m.functiionEnglish}
                        />
                      </div>
                    ))}
                  </div>

                  <div className="text-center" style={{ marginTop: "10rem" }}>
                    <h1>{globalT("about.history.secretaire")}</h1>
                  </div>
                  <div className="row mt-5">
                    {tabSecretaire.map((m) => (
                      <div className="col-4 mb-4">
                        <CardMinister
                          id={m.id}
                          functiion={m.functiion}
                          nomination={m.nomination}
                          period={m.period}
                          name={m.name}
                          image={m.image}
                          functiionEnglish={m.functiionEnglish}
                        />
                      </div>
                    ))}
                  </div>

                  <div className="mt-5" style={{ textAlign: "center" }}>
                    <Button
                      onClick={() => navigate("/actualite")}
                      color={"secondaire"}
                    >
                      <span>Cliquez ici pour plus d'information</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Page>
      </PageWrapper>
      <Footer />
    </>
  );
};

export default Presentation;
