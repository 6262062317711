import React, {useState} from 'react';

import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import { WrapperLayout } from '../layout/Wrapper/Wrapper';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/PageWrapper/Page';
import Card, { CardBody, CardHeader, CardTitle } from './bootstrap/Card';
import metadata from '../commons/data/metadata';
import IntlMessages from './IntlMessages';
import InputComponent from './forms/InputComponent';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yupSchema from "../components/forms/yupShema";
import NotificationManager from './notifications/NotificationManager';
import Button from './bootstrap/Button';
import { globalT } from '../lang';
import "./ContactUs.scss"


const schema = yup.object().shape({
    firstName: yupSchema.stringDefault,
    lastName: yupSchema.stringDefault,
    // email: yupSchema.email,
    phoneNumber: yupSchema.phone,
    message: yupSchema.stringDefault,
});

const defaultValues: any = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber:'',
    message: '',
}


type FormType = {
    firstName?: string,
    lastName?: string,
    email?: string,
    phoneNumber:string,
    message?: string,
}

const ContactUs = () => {

    const {
        control,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm<FormType>({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });


    const onSubmit: SubmitHandler<FormType> = async (data) => {
        // if (!isFormValid()) return
        reset(defaultValues)
    }

    return (
        <div className='col-12'>
            <form className='' onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                    <div className='col'>
                        <InputComponent
                            name="lastName"
                            errors={errors}
                            control={control}
                            isLight={true}
                            displayRequiredAsterisk
                            placeholder={globalT("form.field.lastName")}
                            className="forminputstyle"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <InputComponent
                            name="firstName"
                            errors={errors}
                            isRequired={false}
                            isLight={true}
                            control={control}
                            placeholder={globalT("form.field.firstName")}
                            className="forminputstyle"
                        />
                    </div>
                </div>

                <div className="row forminputstyledivrow">
                    <div className='col'>
                        <InputComponent
                            errors={errors}
                            control={control}
                            displayRequiredAsterisk
                            isLight={true}
                            isRequired={true}
                            name='phoneNumber'
                            type='number'
                            placeholder={globalT("form.field.phoneNumber")}
                            className="forminputstyle"
                            otherValidators={{
                                validate: (val: string | undefined) => !val ? true : yupSchema.regex.cameroonPhoneNumber.test(val || '')
                                    || (<IntlMessages id="form.errors.phone.cmr" />)
                            }}
                        />
                    </div>

                    <div className='col-md-6'>
                        <InputComponent
                            name="email"
                            errors={errors}
                            isLight={true}
                            isRequired={false}
                            control={control}
                            placeholder={globalT("form.field.email")}
                            className="forminputstyle"
                        />
                    </div>
                </div>


                <div className='col-12'>
                    <InputComponent
                        name="message"
                        errors={errors}
                        isLight={true}
                        control={control}
                        displayRequiredAsterisk
                        placeholder={globalT("form.field.message")}
                        componentType='textarea'
                        classN="formtextareastyle"
                    />
                </div>

                <div className="col-sm-12 col-md-10">
                    <Button
                        type='submit'
                        // iconPosition='right'
                        className="text-nowrap bg-gradient ms-2 mb-2 ContactUsButton">
                        <span className="ContactUsButtonspan">{globalT("button.send")}</span>
                    </Button>
                </div>
            </form>
        </div>

    );
};

export default ContactUs;
