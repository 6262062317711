

export default class NewsLetter {
    public id: string;
    public town: string;
    public lastName: string;
    public fistName: string;
    public phone: string;
    public email:string;

    constructor(data: any) {
        this.id = data.id;
        this.lastName = data.lastName;
        this.fistName = data.fistName;
        this.town = data.town;
        this.phone = data.phone;
        this.email = data.email;
    }
}