import React from 'react';
import dayjs, {Dayjs} from "dayjs";
import { borderRadius } from '@mui/system';
import './CardImgNoBorder.scss';
import { globalT } from '../lang';
import LANG from "../lang/language";
import {useAppSelector} from "../store/redux.types";

interface CardImgNoBorderProps {
  title: string;
  titleEn: string;
  images: string;
  date: dayjs.Dayjs | null;
  id: string;
  type?: string
  newLabel?: string;
  description?: string;
  views?: number;
  comments?: number;
  action? : { detail?:()=>void}
}

const CardImgNoBorder: React.FC<CardImgNoBorderProps> = ({type= 'image',titleEn, title, images, date, id, newLabel, description, views, action, comments }) => {
    const { settings, authUserData } = useAppSelector(({ settings, authUser, }) => ({
        settings,
        authUserData: authUser.data
    }));
    return (
    <div className="card-img-no-border">
      {type === 'image' ? (
          <img src={images} alt={settings.language === LANG.fr.locale ? title : titleEn ? titleEn : title} className="card-img-top"
          />
      ) : (
          <video controls className="card-img-top"
          >
            <source src={images} type="video/mp4" className="card-img-top"/>
            Votre navigateur ne prend pas en charge les vidéos HTML5.
          </video>
      )}
        <div className="card-body d-flex flex-column align-items-center">
        <h5 className="card-title">{settings.language === LANG.fr.locale ? title : titleEn ? titleEn : title}</h5>
        <p className="card-text" style={{ marginTop:'10px', display: 'flex', alignItems: 'center'}}>
          {/*<i className="fas fa-eye"></i> {views} {globalT("vues")} |*/}
          {date && (
            <span style={{ fontWeight: 'bold', borderRadius: '8px', padding: '0.5rem', backgroundColor: 'yellow', color: 'black', marginLeft: '0.5rem', marginRight: '0.5rem'}}>
              {dayjs(date).format('ll')}
            </span>
          )}
          {/*<i className="fas fa-comments"></i> {comments}*/}
        </p>
        {/*<p className="card-text">{description}</p>*/}
        {action && (
              <button style={{backgroundColor :"#FFA500"}}  className="btn btn-success" role="button" onClick={action.detail}>{globalT("landing.header.savoir")}</button>
        )}

      </div>
    </div>
  );
};

export default CardImgNoBorder;
