import React, { useState } from 'react';
import Nav, { NavItem } from "../../components/bootstrap/Nav";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../components/bootstrap/Dropdown";
import { joinUrlWithParamsId } from "../../commons/helpers/funcHelper";
import { AUTH } from "../../commons/urls/front";
import { useAppSelector } from "../../store/redux.types";
import { useNavigate } from "react-router-dom";
import { getJwtData } from "../../commons/helpers/jwtHelper";

const AceuillNav = () => {
    const { catActualite } = useAppSelector(({ catActualite }) => catActualite)
    const { catRevue } = useAppSelector(({ catRevue }) => catRevue)
    const navigate = useNavigate()
    const { authUserData } = useAppSelector(({ authUser }) => ({
        authUserData: authUser.data
    }));
    const jwtData = getJwtData()
    const [active, setActive] = useState<'acceuil' | 'actualite' | 'video' | 'evenement' | 'revue'>()
    return (

        <>
            
 <Nav design='pills' className='tete d-flex'>



                    <NavItem
                    >
                        <span>
                            <button type="button" className="btn btn-white text-black">APSR</button>
                        </span>
                    </NavItem>

                    <NavItem
                        className="cursor-pointer"
                        isActive={active === 'acceuil'}
                        onClick={() => {
                            setActive('acceuil')

                        }}
                    >
                        <span>
                            <a className="btn text-white" onClick={() => navigate('/')} role="button">Accueil</a>
                        </span>
                    </NavItem>

                    <NavItem
                        className="cursor-pointer"
                        isActive={active === 'actualite'}
                        onClick={() => {
                            setActive('actualite')

                        }}
                    >
                        <span>



                        </span>
                    </NavItem>
                    
                    <NavItem
                        className="cursor-pointer"
                        isActive={active === 'video'}
                        onClick={() => {
                            setActive('video')
                            //    reset({newPassword: '', confirmNewPassword: ''})
                            // setValue('confirmNewPassword', '')
                        }}
                    >
                        <span>
                            <Dropdown>
                                <DropdownToggle>
                                    <span className="btn text-white " role="button">video</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {catActualite && catActualite.map(cat => (
                                        <DropdownItem>
                                            <button className="btn text-black" onClick={() => navigate(joinUrlWithParamsId('/actualite/categorie/:id', cat.id), { state: { categorie: cat.name } })} role="button">{cat.name}</button>
                                        </DropdownItem>
                                    ))}

                                    <DropdownItem>
                                        <button className="btn text-black" role="button">Tous</button>
                                    </DropdownItem>

                                </DropdownMenu>
                            </Dropdown>
                        </span>
                    </NavItem>



                    <NavItem
                        className="cursor-pointer"
                        isActive={active === 'evenement'}
                        onClick={() => {
                            setActive('evenement')
                            //    reset({newPassword: '', confirmNewPassword: ''})
                            // setValue('confirmNewPassword', '')
                        }}
                    >

                        <span>
                            <Dropdown>
                                <DropdownToggle>
                                    <span className="btn text-white" role="button">Evenement</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem>
                                        <button className="btn text-black" onClick={() => navigate('/evenement')} role="button" >Tous les evenements</button>
                                    </DropdownItem>

                                </DropdownMenu>
                            </Dropdown>
                        </span>
                    </NavItem>


                    <NavItem
                        className="cursor-pointer"
                        isActive={active === 'revue'}
                        onClick={() => {
                            setActive('revue')
                            //    reset({newPassword: '', confirmNewPassword: ''})
                            // setValue('confirmNewPassword', '')
                        }}
                    >
                        <span>
                            <Dropdown>
                                <DropdownToggle>
                                    <span className="btn text-white" role="button">Revue Documentaire</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {catRevue && catRevue.map(cat => (
                                        <DropdownItem>
                                            <button className="btn text-black" onClick={() => navigate(joinUrlWithParamsId('/actualite/categorie/:id', cat.id), { state: { categorie: cat.name } })} role="button">{cat.name}</button>
                                        </DropdownItem>
                                    ))}

                                    <DropdownItem>
                                        <button className="btn text-black" role="button">Tous</button>
                                    </DropdownItem>

                                </DropdownMenu>
                            </Dropdown>
                        </span>
                    </NavItem>

                        <div className='gauche'>
                               <NavItem
                       className=''
                    >
                        <span>
                            {!(jwtData && authUserData) && (
                                <button
                                    type="button"
                                    className="btn btn-success rounded-3 mx-3 btn-sm"
                                    onClick={() => navigate(AUTH.LOGIN)}
                                >
                                    Se connecter
                                </button>
                            )}
                        </span>
                    </NavItem>

                        </div>                
                 


                </Nav>
          
            
      
               


        </>

    );
};

export default AceuillNav;