import config from '../config';
import customAxios from './axiosConfig';
import Client from './mintClient';

/**
 * Client class generic for centralization purpose
 * Some general code can be written here
 */
class MintClient extends Client {
    document: any;
}

// Create client class
const mintClient = new MintClient(config.mintClient, customAxios);

// Use csuClient by default
export default mintClient;
