import React from 'react';
import Card, {CardBody} from "../../../components/bootstrap/Card";
import IntlMessages from "../../../components/IntlMessages";
import {formatOptionalText, joinUrlWithParamsId} from "../../../commons/helpers/funcHelper";
import Button from "../../../components/bootstrap/Button";
import {useLocation, useNavigate} from "react-router-dom";

type cardProps = {
    id: string,
    name: string,
    nbreMessage?: number,
    description?: string
}

const CardDiscusion = ({id, name, nbreMessage, description}: cardProps) => {

    const navigate = useNavigate()

    return (
        <div className='mb-3'>
           <Card className=''>
               <CardBody>
                   <div className="d-flex flex-column" style={{fontSize: "1.4rem"}}>
                       <span className='text-success'>{name}</span>
                       <span className=''>{description}</span>
                       <p>
                           {/*<strong><IntlMessages id="message" />: </strong>*/}
                           {/*{formatOptionalText(nbreMessage)}*/}
                       </p>
                       <span className='text-center'>
                           <Button
                               onClick={() => navigate(joinUrlWithParamsId('/Discussion/:id', id), { state: { categorieName: name, total: nbreMessage } })}
                               color='light'
                           >
                                acceder a la discussion
                            </Button>
                       </span>

                   </div>
               </CardBody>
           </Card>
        </div>
    );
};

export default CardDiscusion;