import React, { useEffect, useRef, useState } from "react";
import Navnav from "../../Navnav/Navnav";
import Button from "../../../components/bootstrap/Button";
import IntlMessages from "../../../components/IntlMessages";
import Page from "../../../layout/PageWrapper/Page";
import { globalT } from "../../../lang";
import Agenda from "../../../components/Agendar";
import Spinner from "../../../components/bootstrap/Spinner";
import SearchComponent from "../../../components/SearchComponent";
import EventComponent from "../../evenement/EventComponent";
import DataImage from "../../../assets/img/landing2/vide.webp";
import PaginationComponent from "../../../components/PaginationComponent";
import Footfoot from "../../Navnav/Footfoot";
import { useNavigate } from "react-router-dom";
import { Evenements } from "../../../commons/models/Evenements";
import { useAppDispatch, useAppSelector } from "../../../store/redux.types";
import mintClient from "../../../network";
import { setRequestGlobalLoader } from "../../../store/slices/requestGlobalLoader/actions";
import { Campagne } from "../../../commons/models/Campagne";
import Header from "../Header";
import Footer from "../Footer";
import vid02 from "../../../assets/img/vid02.png";
import neutre from "../../../assets/img/landing2/nouv/background2.jpeg";
import neutre2 from "../../../assets/img/landing2/nouv/backgroundDesign.jpeg";
import "./campagne.scss";
import dayjs from "dayjs";
import CardCampagne from "./CardCampagne";
import Publication from "../../../commons/models/Publication";
import CardActu from "./CardActu";
import LANG from "../../../lang/language";
import DialogComponent from "../../../components/dialogs/DialogComponent";
import newImage from "../../../assets/img/landing2/back_copie.png";
import ImageSensibilisation from "../../../components/ImageSensibilisation";

const CampagneAfter = () => {
  const navigate = useNavigate();
  const [campagne, setCampagne] = useState<Campagne[] | null>(null);
  const [campagneHeader, setCampagneHeader] = useState<Campagne[] | null>(null);
  const [page, setPage] = useState(1);
  const total = useRef<number>();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const [showCalendar, setShowCalendar] = useState(true);
  const [showDetail, setShowDetail] = useState(false);
  const [publicationImage, setPublicationImage] = useState<{
    value: Publication[] | [];
    loading: boolean;
  }>({ value: [], loading: false });
  const { settings, authUserData } = useAppSelector(
    ({ settings, authUser }) => ({
      settings,
      authUserData: authUser.data,
    })
  );

  const loadPublication = async () => {
    setPublicationImage({ ...publicationImage, loading: true });
    await mintClient.publication
      .getAllImage({
        page: 1,
        pageSize: 6,
        order: true,
        orderDirection: "ascending",
        validationState: 1,
      })
      .then((resp) => {
        setPublicationImage({ value: resp.data.data, loading: false });
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(setRequestGlobalLoader(false)));
  };

  const loadCampagneHeader = () => {
    // dispatch(setRequestGlobalLoader(true))
    mintClient.campagne
      .getAll({
        page: 1,
        pageSize: 100,
        orderDirection: "ascending",
        validationState: 1,
        hasPagination: true,
        langue: "fr",
      })
      .then((resp) => {
        const allCampagnes = resp.data.data;
        setCampagneHeader(allCampagnes);
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(setRequestGlobalLoader(false)));
  };

  const loadCampagne = () => {
    setLoading(true);
    mintClient.campagne
      .getAll({
        page: page,
        pageSize: 6,
        orderDirection: "ascending",
        validationState: 1,
        researchValue: search,
        hasPagination: true,
        langue: "fr",
      })
      .then((resp) => {
        const allCampagne = resp.data.data;
        console.log("aaaaaaaaaaaaa :", allCampagne);
        total.current = resp.data.total;
        setCampagne(allCampagne);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  const handleSearch = (e: any) => {
    setLoading(true);
    setSearch(e);
  };

  useEffect(() => {
    // @ts-ignore
    document.getElementById("sectionId").scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (!campagneHeader) loadCampagneHeader();
    if (publicationImage.value.length === 0) loadPublication();
    loadCampagne();
  }, [search, page]);
  return (
    <>
      <Header currentMenu={"education"} />
      <div className="container" id={"sectionId"}>
        <ImageSensibilisation
          image={newImage}
          texte="Apres du ministère des transports !!!"
        />{" "}
      </div>
      {showCalendar && campagneHeader && (
        <div className="App mb-5 mt-5">
          <Page>
            <header className="App-header mb-4">
              <h1 className="App-header__title" style={{ color: "#FA6400" }}>
                {globalT("event.agenda.after")}
              </h1>
            </header>
            <main>
              <Agenda type={"campagne"} data={campagneHeader} />
            </main>
          </Page>
        </div>
      )}

      <section className="container pt-5">
        <div className="d-flex justify-content-sm-between">
          <h1 style={{ fontWeight: "bold" }}>
            {globalT("campagne.management.list")}
          </h1>
          <div className="">
            {loading && campagne && (
              <Spinner customColor="#aae0aa" size="3rem" />
            )}{" "}
            <div className="">
              <SearchComponent onSearch={handleSearch} />
            </div>
          </div>
        </div>

        <div className="row py-4">
          <div className="col-8 mb-2">
            <div className="row homeDoc">
              {campagne && campagne.length > 0 && (
                <div className="col-12">
                  <div
                    className="position-relative w-100 h-100 cursor-pointer"
                    onClick={() => setShowDetail(true)}
                  >
                    <div className="homeDoc__image1"></div>

                    <div className="homeDoc__subtitle">{campagne[0].name}</div>
                    <div className="homeDoc__categorie">{`${dayjs(
                      campagne[0].startDate
                    ).format("ll")} - ${dayjs(campagne[0].endDate).format(
                      "ll"
                    )}`}</div>
                  </div>
                </div>
              )}
              {/*<div className="col-4">*/}
              {/*    */}
              {/*</div>*/}
            </div>
          </div>
          <div className="col-4"></div>

          <div className="lineGlobal"></div>
          <div className="row">
            <div className="col-8 pe-5">
              {campagne && campagne.length > 0 ? (
                <>
                  {campagne.map((c) => (
                    <CardCampagne data={c} type={"campagne"} />
                  ))}
                </>
              ) : (
                <div className="imageContainer" style={{ marginTop: "100px" }}>
                  <div className="text-center mt-5 mb-5">
                    <div
                      style={{
                        width: "400px", // or any size you want
                        height: "400px", // make it a square
                        backgroundImage: `url(${DataImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        margin: "0 auto", // this will center the div if the parent div has a text-align: center
                      }}
                      className="image"
                    />
                    {loading ? (
                      <div className="d-flex justify-content-center spinnerContainer">
                        <div
                          className="spinner-border"
                          style={{
                            height: "6rem",
                            width: "6rem",
                            color: "#FFA500",
                            marginTop: "85px",
                            marginRight: "15px",
                          }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <span
                        className="fw-bold"
                        style={{
                          marginTop: "20px",
                          fontSize: "24px",
                        }}
                      >
                        {globalT("empty.campagne")}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div
              className="col-4 ps-5"
              style={{ paddingLeft: "5rem !important" }}
            >
              <div className="d-flex flex-column">
                <img src={neutre} alt="neutre" className="img-fluid" />
                <div className="recentActu mt-5 mb-2">
                  <span className="recentActu__title">Actualité recentes</span>
                  <div className="pt-3">
                    {!publicationImage.loading ? (
                      <>
                        {publicationImage.value.map((p) => (
                          <CardActu data={p} />
                        ))}
                      </>
                    ) : (
                      <div>
                        <Spinner size={"3rem"} color={"orange"} />
                      </div>
                    )}
                  </div>
                </div>
                <img src={neutre2} alt="neutre" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <PaginationComponent
          pages={Math.ceil((total.current || 1) / 6)}
          currentPage={page}
          onPageChange={(page) => setPage(page)}
          rangeLimit={5}
        />

        {campagne && campagne.length > 0 && (
          <DialogComponent
            show={showDetail}
            size="lg"
            title={globalT("button.details")}
            onClose={() => setShowDetail(false)}
          >
            <div className="ms-2">
              <p className="fw-bold">
                {settings.language === LANG.fr.locale
                  ? campagne[0]?.name
                  : campagne[0]?.nameEn
                  ? campagne[0]?.nameEn
                  : campagne[0]?.name}
              </p>
              <p>
                {" "}
                <span className="fw-bold">
                  {globalT("form.field.endate")}:
                </span>{" "}
                {dayjs(campagne[0]?.startDate).format("ll")}{" "}
              </p>
              <p>
                {" "}
                <span className="fw-bold">
                  {globalT("form.field.startdate")}:
                </span>{" "}
                {dayjs(campagne[0]?.endDate).format("ll")}{" "}
              </p>
              <div>
                <p>
                  {" "}
                  <span className="fw-bold">
                    {globalT("form.field.goal")}:
                  </span>{" "}
                  {(campagne[0] as Campagne)?.goal}{" "}
                </p>
                <p>
                  {" "}
                  <span className="fw-bold">
                    {globalT("form.field.place")}:
                  </span>{" "}
                  {(campagne[0] as Campagne)?.place}{" "}
                </p>
              </div>

              <p>
                {" "}
                <span className="fw-bold">description:</span>{" "}
                {settings.language === LANG.fr.locale
                  ? campagne[0]?.description
                  : campagne[0]?.descriptionEn
                  ? campagne[0]?.descriptionEn
                  : campagne[0]?.description}{" "}
              </p>
            </div>
          </DialogComponent>
        )}
      </section>

      <Footer />
    </>
  );
};

export default CampagneAfter;
