import React, { useEffect, useRef, useState } from "react";
import mintClient from "../../network";
import Publication from "../../commons/models/Publication";
import { useAppDispatch, useAppSelector } from "../../store/redux.types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setRequestGlobalLoader } from "../../store/slices/requestGlobalLoader/actions";
import Footfoot from "../Navnav/Footfoot";
import Navnav from "../Navnav/Navnav";
import Spinner from "../../components/bootstrap/Spinner";
import CardImg from "../../components/bootstrap/CardImg";
import CardImgNoBorder from "../../components/CardImgNoBorder";
import RecentPublicationComponent from "../../components/publication/RecentPublicationComponent";
import SearchComponent from "../../components/SearchComponent";
import { globalT } from "../../lang";
import DataImage from "../../assets/img/landing2/vide.webp";
import PaginationComponent from "../../components/PaginationComponent";
import { joinUrlWithParamsId } from "../../commons/helpers/funcHelper";
import { PUBLICATION } from "../../commons/urls/front";
import LANG from "../../lang/language";
import Header from "../acceuill/Header";
import Footer from "../acceuill/Footer";
import CardImage from "../acceuill/card/CardImage";
import newImage from "../../assets/img/landing2/back_copie.png";
import ImageSensibilisation from "../../components/ImageSensibilisation";

const ActualiteCategorie = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const categorieName = location.state?.categorie || "";
  const total = useRef<number>();
  const [publication, setPublication] = useState<Publication[] | null>(null);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const { settings, authUserData } = useAppSelector(
    ({ settings, authUser }) => ({
      settings,
      authUserData: authUser.data,
    })
  );

  const categorieId = useParams().id;

  const loadPublication = () => {
    setLoading(true);
    // dispatch(setRequestGlobalLoader(true))
    mintClient.publication
      .getAll({
        page: page,
        pageSize: 9,
        orderDirection: "ascending",
        validationState: 1,
        order: true,
        researchValue: search,
        hasPagination: true,
        categoryId: categorieId,
      })
      .then((resp) => {
        const allPublications = resp.data.data;
        total.current = resp.data.total;
        setPublication(allPublications);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };
  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    setPage(1);
    setLoading(true);
    loadPublication();
  };

  useEffect(() => {
    loadPublication();
  }, [page, search, categorieId]);

  useEffect(() => {
    // @ts-ignore
    document.getElementById("sectionId").scrollIntoView({
      behavior: "smooth",
    });
  }, [categorieId]);

  return (
    <>
      <Header currentMenu={"actualite"} />

      <div className="container" id={"sectionId"}>
        <ImageSensibilisation
          image={newImage}
          texte="Retroucez les différentes Actualité !!!"
        />{" "}
      </div>

      <section className="container actualiteContent">
        <div className="d-flex justify-content-sm-between">
          <h1>
            <span className="fw-bold">
              {globalT("paragraph.management.list.news")} :{" "}
            </span>
            <span>
              {settings.language === LANG.fr.locale
                ? categorieName.fr
                : categorieName.en
                ? categorieName.en
                : categorieName.fr}
            </span>
          </h1>
          <div className="">
            {loading && publication && (
              <Spinner customColor="#aae0aa" size="3rem" />
            )}{" "}
            <div className="">
              <SearchComponent onSearch={handleSearch} />
            </div>
          </div>
        </div>

        <div className="row py-4">
          {publication && publication.length > 0 ? (
            <>
              <div className="row">
                {publication.map((pub) => (
                  <CardImage
                    name={pub.name}
                    nameEn={pub.nameEn}
                    images={pub.attachement}
                    id={pub.id}
                    categorie={pub.categoryName}
                    createAt={pub.createdAt}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="d-flex align-content-center">
              <div className="imageContainer" style={{ marginTop: "100px" }}>
                <div className="text-center mt-5 mb-5">
                  <div
                    style={{
                      width: "400px", // or any size you want
                      height: "400px", // make it a square
                      backgroundImage: `url(${DataImage})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      margin: "0 auto", // this will center the div if the parent div has a text-align: center
                    }}
                    className="image"
                  />
                  {loading ? (
                    <div className="d-flex justify-content-center spinnerContainer">
                      <div
                        className="spinner-border"
                        style={{
                          height: "6rem",
                          width: "6rem",
                          color: "#aae0aa",
                          marginTop: "85px",
                          marginRight: "15px",
                        }}
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <span
                      className="fw-bold"
                      style={{
                        marginTop: "20px",
                        fontSize: "24px",
                      }}
                    >
                      {globalT("empty.actualite")}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <PaginationComponent
          pages={Math.ceil((total.current || 0) / 9)}
          currentPage={page}
          onPageChange={(page) => setPage(page)}
          rangeLimit={5}
        />
      </section>
      <Footer />
    </>
  );
};

export default ActualiteCategorie;
