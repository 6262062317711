import {createReducer} from "@reduxjs/toolkit";
import * as actions from "./actions";

export const INITIAL_STATE: { lastAlert: any | null } = {
    lastAlert: null
}

const reducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(actions.lastAlert, (state, action) => {
            state.lastAlert = action.payload;
        })
});

export default reducer;
