import React, {useContext, useEffect, useLayoutEffect, useState} from 'react';
import classNames from 'classnames';
import { globalT } from '../../../lang';
import LogoText from '../../../components/LogoText';
import useDarkMode from '../../../hooks/useDarkMode';
import { LanguageDict } from '../../../commons/types';
import {dashboardMenu, mainMenu} from '../../../menu';
import IntlMessages from '../../../components/IntlMessages';
import LANG, { getLangWithKey } from '../../../lang/language';
import { AUTH, DASHBOARD } from '../../../commons/urls/front';
import { logoutUser } from '../../../store/slices/authUser/actions';
import { setLanguage } from '../../../store/slices/settings/actions';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import { useAppDispatch, useAppSelector } from '../../../store/redux.types';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import {getJwtData} from "../../../commons/helpers/jwtHelper";
import dialog from '../../../assets/img/question.png'
import notif from '../../../assets/img/notification.png'
import DrawerComponent from "../../../components/DrawerComponent";
import FrontAdd from "../../../views/Incident/incident/FrontAdd";
import DialogComponent from "../../../components/dialogs/DialogComponent";
import '../../../views/chatBot/chatbot.scss';
import Chatbot from "react-chatbot-kit";
import config from "../../../views/chatBot/config";
import ActionProvider from "../../../views/chatBot/ActionProvider";
import MessageParser from "../../../views/chatBot/MessageParser";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormGroup from '@mui/material/FormGroup';
import './DefaultHeader.scss';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import ProfilUser from '../../../views/Profil/ProfilUser';
import { setBool } from '../../../store/slices/variable/actions';




const DefaultHeader = () => {
	const dispatch = useAppDispatch();
	const [showPanel, setShowPanel] = useState<boolean>(false)
	const [showxx, setShowxx] = useState<boolean>(false)
	const handleClose = () => setShowxx(false);
	const variable = useAppSelector(({variable}) => variable.isBoolean)

	const { settings, authUserData } = useAppSelector(({ settings, authUser, }) => ({
		settings,
		authUserData: authUser.data
	}));

	//console.log("usersssssss", authUserData.user.id);

	
	



	const jwtData = getJwtData()
	const Android12Switch = styled(Switch)(({ theme }) => ({
		padding: 8,
		'& .MuiSwitch-track': {
			borderRadius: 22 / 2,
			'&:before, &:after': {
				content: '""',
				position: 'absolute',
				top: '50%',
				transform: 'translateY(-50%)',
				width: 16,
				height: 16,
			},
			'&:before': {
				backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
					theme.palette.getContrastText(theme.palette.primary.main),
				)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
				left: 12,
			},
			'&:after': {
				backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
					theme.palette.getContrastText(theme.palette.primary.main),
				)}" d="M19,13H5V11H19V13Z" /></svg>')`,
				right: 12,
			},
		},
		'& .MuiSwitch-thumb': {
			boxShadow: 'none',
			width: 16,
			height: 16,
			margin: 2,
		},
	}));

	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};
	const currentPath =  window.location.href;

	const changeLanguage = (locale: LanguageDict) => {
		if (locale !== settings.language) {
			dispatch(setLanguage(locale));
		}
	};
	const {incident} = useAppSelector(({alert}) => alert)
	const [show, setShow] = useState<boolean>(false)
	const [showBot, setShowBot] = useState(false);



	const onLogin = () => {
		dispatch(setBool(true));
	}

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', settings.language);
	});

	return (
		<div className="">
			<Header>
				<HeaderLeft>
					<div className='align-items-center col-auto d-block d-md-flex text-sm-left text-center'>
						<div className="d-flex">
							{!authUserData && !currentPath?.includes(DASHBOARD.INDEX) && (
								<LogoText className="fw-bold me-2" hideSubtitle={true}/>
							)}

							{!authUserData && !currentPath?.includes(DASHBOARD.INDEX) && (
								<LogoText className="fw-bold" title={"APSR"} hideSubtitle={true} />
							)}
						</div>


						{!authUserData && (
							<div className='col-md-9'>
								<Button
									isOutline
									color="white"
									icon='ReportProblem'
									onClick={() => setShow(true)}
									className="text-white text-hover-black"
								>
									<span className="text-white text-hover-black">{globalT("incident.add.title")}</span>
								</Button>
							</div>
						)}
						{authUserData && document.location.href.includes(DASHBOARD.INDEX) &&(
							<Button
								tag='a'
								isLink
								color='primary'
								to={mainMenu.home.path}
								icon={mainMenu.home.icon}
								aria-label={globalT(mainMenu.home.text as string)}
								isLight={currentPath === mainMenu.home.path}
								className={classNames('ms-md-3 mb-md-0')}>
								<IntlMessages id={mainMenu.home.text as string} />
							</Button>
						)}

						{authUserData && !currentPath?.includes(dashboardMenu.dashboard.path as string) && (
							<>
								<Button
									tag='a'
									isLink
									color='primary'
									to={dashboardMenu.dashboard.path}
									icon={dashboardMenu.dashboard.icon}
									aria-label={globalT(dashboardMenu.dashboard.text as string)}
									className={classNames('ms-md-3 mb-md-0')}
								>
									<IntlMessages id={dashboardMenu.dashboard.text} />
								</Button>
							</>
						)}

					</div>
				</HeaderLeft>

				<HeaderRight>
					<div className='row g-3 align-items-center'>
						{authUserData && (
							<div className="col-auto me-3 circle-border" onClick={onLogin} style={{ cursor: 'pointer'}}>
								<img src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjxivAs4UknzmDfLBXGMxQkayiZDhR2ftB4jcIV7LEnIEStiUyMygioZnbLXCAND-I_xWQpVp0jv-dv9NVNbuKn4sNpXYtLIJk2-IOdWQNpC2Ldapnljifu0pnQqAWU848Ja4lT9ugQex-nwECEh3a96GXwiRXlnGEE6FFF_tKm66IGe3fzmLaVIoNL/s1600/img_avatar.png" alt=""/>
							</div>
						)}

						{/* Lang Selector */}
						<div className='col-auto'>
							<div className="">
								<Button
									isOutline
									onClick={() => changeLanguage(LANG.en.locale)}
									aria-label='Change language'
									className=""
									data-tour='lang-selector'
								>
									<span className="text-hover-orange">{ globalT(LANG.en.text) }</span>
								</Button>
								<Button
									isOutline
									onClick={() => changeLanguage(LANG.fr.locale)}
									aria-label='Change language'
									className="ms-2"
									data-tour='lang-selector'
								>
									<div className="text-hover-orange">{ globalT(LANG.fr.text) }</div>
								</Button>
							</div>


						</div>



						<div
							className='col d-flex align-items-center cursor-pointer'
							// onClick={() => setState(!state)}
							role='presentation'>
							{authUserData && (
								<>
									<div className='me-3'>
										<div className='text-end'>
											<div className='fw-bold fs-6 mb-0'>
												{authUserData.user.email}
											</div>
										</div>
									</div>
									<div className='position-relative'>
										<Button
											color="secondary"
											className="text-white"
											onClick={() => dispatch(logoutUser())}
										>
											{globalT("auth.logout")}
										</Button>
									</div>
								</>

							)}

						</div>

					</div>
				</HeaderRight>
				{incident && incident.length > 0 && (
					<DrawerComponent type={"right"} onclose={() => setShowPanel(false)} onOpen={() => setShowPanel(true)} data={incident.map(d => ({
						name: d.name,
						id: d.id
					}))} open={showPanel}/>
				)}

				{authUserData && (
					<Dialog
						open={variable}

						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"

					>
						{/*  <DialogTitle id="alert-dialog-title">{incident[0].ville}</DialogTitle> */}
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								<ProfilUser
									userName={authUserData.user.userName}
									firstName={authUserData.user.firstName}
									lastName={authUserData.user.lastName}
									phoneNumber={authUserData.user.phoneNumber}
									email={authUserData.user.email}
								/>
								{/*   {incident[0].value} */}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => dispatch(setBool(false))} color="primary">
								{globalT("button.close")}
							</Button>
							{/* <Button onClick={() => { setShowxx(false)  }} color="primary">
                        {globalT("voir")}
                    </Button> */}
						</DialogActions>
					</Dialog>
				)}


				<DialogComponent
					show={show}
					title={globalT("incident.add.title")}
					onClose={() => setShow(false)}
					size='lg'

				>
					<FrontAdd
						onClose={() => setShow(false)}
					/>
				</DialogComponent>
			</Header>
		</div>

	);
};

export default DefaultHeader;
