import './chatbot.scss'
import Button from "../../components/bootstrap/Button";
import {useAppSelector} from "../../store/redux.types";
import LANG from "../../lang/language";

const Options = (props: any) => {
    const { settings, authUserData } = useAppSelector(({ settings, authUser, }) => ({
        settings,
        authUserData: authUser.data
    }));

    return (
        <div className="options">
            <h1 className="options-header">{props.title}</h1>
            <div className="options-container">
                {props.options.map((option: any) => {

                    return (
                        <div
                            className="option-item"
                            onClick={option.handler}
                            key={option.id}
                        >
                            { settings.language === LANG.fr.locale ? option.name : option.nameEn ? option.nameEn : option.name}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Options;
