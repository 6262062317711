import React, { useEffect, useRef, useState } from "react";
import { setRequestGlobalLoader } from "../../store/slices/requestGlobalLoader/actions";
import mintClient from "../../network";
import { globalT } from "../../lang";
import { useAppDispatch, useAppSelector } from "../../store/redux.types";
import { Evenements } from "../../commons/models/Evenements";
import { useNavigate } from "react-router-dom";
import DataImage from "../../assets/img/landing2/vide.webp";
import Spinner from "../../components/bootstrap/Spinner";
import { joinUrlWithParamsId } from "../../commons/helpers/funcHelper";
import SearchComponent from "../../components/SearchComponent";
import PaginationComponent from "../../components/PaginationComponent";
import CardImg from "../../components/bootstrap/CardImg";
import Navnav from "../Navnav/Navnav";
import Footfoot from "../Navnav/Footfoot";
import Agenda from "../../components/Agendar";
import IntlMessages from "../../components/IntlMessages";
import Button from "../../components/bootstrap/Button";
import Page from "../../layout/PageWrapper/Page";
import EventComponent from "./EventComponent";
import Header from "../acceuill/Header";
import Footer from "../acceuill/Footer";
import Publication from "../../commons/models/Publication";
import dayjs from "dayjs";
import CardCampagne from "../acceuill/campagne/CardCampagne";
import neutre from "../../assets/img/landing2/nouv/background2.jpeg";
import CardActu from "../acceuill/campagne/CardActu";
import DialogComponent from "../../components/dialogs/DialogComponent";
import LANG from "../../lang/language";
import { Campagne } from "../../commons/models/Campagne";
import newImage from "../../assets/img/landing2/back_copie.png";
import neutre2 from "../../assets/img/landing2/nouv/backgroundDesign.jpeg";
import ImageSensibilisation from "../../components/ImageSensibilisation";

const EvenementAfter = () => {
  const navigate = useNavigate();
  const [evenement, setEvenement] = useState<Evenements[] | null>(null);
  const [evenementHeader, setEvenementHeader] = useState<Evenements[] | null>(
    null
  );
  const [page, setPage] = useState(1);
  const total = useRef<number>();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const [showCalendar, setShowCalendar] = useState(true);
  const { settings, authUserData } = useAppSelector(
    ({ settings, authUser }) => ({
      settings,
      authUserData: authUser.data,
    })
  );
  const [publicationImage, setPublicationImage] = useState<{
    value: Publication[] | [];
    loading: boolean;
  }>({ value: [], loading: false });
  const [showDetail, setShowDetail] = useState(false);

  const loadPublication = async () => {
    setPublicationImage({ ...publicationImage, loading: true });
    await mintClient.publication
      .getAllImage({
        page: 1,
        pageSize: 6,
        order: true,
        orderDirection: "ascending",
        validationState: 1,
      })
      .then((resp) => {
        setPublicationImage({ value: resp.data.data, loading: false });
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(setRequestGlobalLoader(false)));
  };
  const loadEvenementHeader = () => {
    // dispatch(setRequestGlobalLoader(true))
    mintClient.evenement
      .getAll({
        page: 1,
        pageSize: 100,
        orderDirection: "ascending",
        validationState: 1,
        hasPagination: true,
        langue: "fr",
      })
      .then((resp) => {
        const allEvenements = resp.data.data;
        setEvenementHeader(allEvenements);
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(setRequestGlobalLoader(false)));
  };

  const loadEvenement = () => {
    setLoading(true);
    mintClient.evenement
      .getAll({
        page: page,
        pageSize: 6,
        orderDirection: "ascending",
        validationState: 1,
        researchValue: search,
        hasPagination: true,
        langue: "fr",
      })
      .then((resp) => {
        const allEvenements = resp.data.data;
        total.current = resp.data.total;
        setEvenement(allEvenements);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  const handleSearch = (e: any) => {
    setLoading(true);
    setSearch(e);
  };

  useEffect(() => {
    // @ts-ignore
    document.getElementById("sectionId").scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (!evenementHeader) loadEvenementHeader();
    if (publicationImage.value.length === 0) loadPublication();
    loadEvenement();
  }, [search, page]);

  return (
    <>
      <Header currentMenu={"education"} />
      <div className="container" id={"sectionId"}>
        <ImageSensibilisation
          image={newImage}
          texte="Apres evenement du ministère des transports !!!"
        />{" "}
      </div>
      {showCalendar && evenementHeader && (
        <div className="App mb-5">
          <Page>
            <header className="App-header mb-4">
              <h1 className="App-header__title" style={{ color: "#FA6400" }}>
                {globalT("event.agenda.after")}
              </h1>
            </header>
            <main>
              <Agenda type={"evenement"} data={evenementHeader} />
            </main>
          </Page>
        </div>
      )}

      <section className="container pt-5 ">
        <div className="d-flex justify-content-sm-between">
          <h1 style={{ fontWeight: "bold" }}>
            {globalT("evenement.management.list")}
          </h1>
          <div className="">
            {loading && evenement && (
              <Spinner customColor="#aae0aa" size="3rem" />
            )}{" "}
            <div className="">
              <SearchComponent onSearch={handleSearch} />
            </div>
          </div>
        </div>

        <div className="row py-4">
          <div className="col-8 mb-2">
            <div className="row homeDoc">
              {evenement && evenement.length > 0 && (
                <div className="col-12">
                  <div
                    className="position-relative w-100 h-100 cursor-pointer"
                    onClick={() => setShowDetail(true)}
                  >
                    <div className="homeDoc__image1"></div>

                    <div className="homeDoc__subtitle">{evenement[0].name}</div>
                    <div className="homeDoc__categorie">{`${dayjs(
                      evenement[0].startDate
                    ).format("ll")} - ${dayjs(evenement[0].endDate).format(
                      "ll"
                    )}`}</div>
                  </div>
                </div>
              )}
              {/*<div className="col-4">*/}
              {/*    */}
              {/*</div>*/}
            </div>
          </div>
          <div className="col-4"></div>

          <div className="lineGlobal"></div>
          <div className="row">
            <div className="col-8 pe-5">
              {evenement && evenement.length > 0 ? (
                <>
                  {evenement.map((c) => (
                    <CardCampagne data={c} type={"campagne"} />
                  ))}
                </>
              ) : (
                <div className="imageContainer" style={{ marginTop: "100px" }}>
                  <div className="text-center mt-5 mb-5">
                    <div
                      style={{
                        width: "400px", // or any size you want
                        height: "400px", // make it a square
                        backgroundImage: `url(${DataImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        margin: "0 auto", // this will center the div if the parent div has a text-align: center
                      }}
                      className="image"
                    />
                    {loading ? (
                      <div className="d-flex justify-content-center spinnerContainer">
                        <div
                          className="spinner-border"
                          style={{
                            height: "6rem",
                            width: "6rem",
                            color: "#FFA500",
                            marginTop: "85px",
                            marginRight: "15px",
                          }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <span
                        className="fw-bold"
                        style={{
                          marginTop: "20px",
                          fontSize: "24px",
                        }}
                      >
                        {globalT("empty.event")}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div
              className="col-4 ps-5"
              style={{ paddingLeft: "5rem !important" }}
            >
              <div className="d-flex flex-column">
                <img src={neutre} alt="neutre" className="img-fluid" />
                <div className="recentActu mt-5">
                  <span className="recentActu__title">Actualité recentes</span>
                  <div className="pt-3">
                    {!publicationImage.loading ? (
                      <>
                        {publicationImage.value.map((p) => (
                          <CardActu data={p} />
                        ))}
                      </>
                    ) : (
                      <div>
                        <Spinner size={"3rem"} color={"orange"} />
                      </div>
                    )}
                  </div>
                </div>
                <img src={neutre2} alt="neutre" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <PaginationComponent
          pages={Math.ceil((total.current || 1) / 6)}
          currentPage={page}
          onPageChange={(page) => setPage(page)}
          rangeLimit={5}
        />
        {evenement && evenement.length > 0 && (
          <DialogComponent
            show={showDetail}
            size="lg"
            title={globalT("button.details")}
            onClose={() => setShowDetail(false)}
          >
            <div className="ms-2">
              <p className="fw-bold">
                {settings.language === LANG.fr.locale
                  ? evenement[0]?.name
                  : evenement[0]?.nameEn
                  ? evenement[0]?.nameEn
                  : evenement[0]?.name}
              </p>
              <p>
                {" "}
                <span className="fw-bold">
                  {globalT("form.field.endate")}:
                </span>{" "}
                {dayjs(evenement[0]?.startDate).format("ll")}{" "}
              </p>
              <p>
                {" "}
                <span className="fw-bold">
                  {globalT("form.field.startdate")}:
                </span>{" "}
                {dayjs(evenement[0]?.endDate).format("ll")}{" "}
              </p>
              <p>
                {" "}
                <span className="fw-bold">
                  {globalT("form.field.type")}:
                </span>{" "}
                {(evenement[0] as Evenements)?.type === 1
                  ? "Séminaire"
                  : "Atélier"}{" "}
              </p>

              <p>
                {" "}
                <span className="fw-bold">description:</span>{" "}
                {settings.language === LANG.fr.locale
                  ? evenement[0]?.description
                  : evenement[0]?.descriptionEn
                  ? evenement[0]?.descriptionEn
                  : evenement[0]?.description}{" "}
              </p>
            </div>
          </DialogComponent>
        )}
      </section>
      <Footer />
    </>
  );
};

export default EvenementAfter;
