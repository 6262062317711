import React, {useState} from 'react';
import Nav, {NavItem} from "./bootstrap/Nav";
import Icon from "./icon/Icon";
import {globalT} from "../lang";
import './CartHori.scss'

const DetailFunc = () => {
    const [active, setActive] = useState< 'actualite' | 'evenement' | 'campagne' | 'education' | 'forum' | 'revue' | 'incident'>('actualite')

    return (
        <div>
            <div className="">
                <Nav  className='d-flex mb-4'>
                    <NavItem
                        className="cursor-pointer"
                        isActive={active === 'actualite'}
                        onClick={() => {
                            setActive('actualite')

                        }}
                    >
                        <span><Icon size='2x' color="orange" icon='Post Add'></Icon></span>

                    </NavItem>
                    <NavItem
                        className="cursor-pointer"
                        isActive={active === 'evenement'}
                        onClick={() => {
                            setActive('evenement')

                        }}
                    >
                        <span><Icon size='2x' color="orange" icon='Event'></Icon></span>

                    </NavItem>
                    <NavItem
                        className="cursor-pointer"
                        isActive={active === 'campagne'}
                        onClick={() => {
                            setActive('campagne')

                        }}
                    >
                        <span><Icon size='2x' color="orange" icon='Campaign'></Icon></span>

                    </NavItem>
                    <NavItem
                        className="cursor-pointer"
                        isActive={active === 'education'}
                        onClick={() => {
                            setActive('education')

                        }}
                    >
                        <span><Icon size='2x' color="orange" icon='CastForEducation'></Icon></span>
                    </NavItem>
                    <NavItem
                        className="cursor-pointer"
                        isActive={active === 'revue'}
                        onClick={() => {
                            setActive('revue')

                        }}
                    >
                        <span><Icon size='2x' color="orange" icon='DiscFull'></Icon></span>
                    </NavItem>
                    <NavItem
                        className="cursor-pointer"
                        isActive={active === 'forum'}
                        onClick={() => {
                            setActive('forum')

                        }}
                    >
                        <span><Icon size='2x' color="orange" icon='Forum'></Icon></span>
                    </NavItem>
                    <NavItem
                        className="cursor-pointer"
                        isActive={active === 'incident'}
                        onClick={() => {
                            setActive('incident')

                        }}
                    >
                        <span><Icon size='2x' color="orange" icon='ReportProblem'></Icon></span>
                    </NavItem>
                </Nav>
            </div>
            <div className="globlal-style">
                {active === 'actualite' && (
                    <div className="ms-2 me-3">
                        <h1>{globalT("landing.actualite.title")}</h1>
                        <p>{globalT('detail.actualite')}</p>
                    </div>
                )}
                {active === 'evenement' && (
                    <div className="ms-2 me-3">
                        <h1>{globalT("evenement.management")}</h1>
                        <p>{globalT('detail.event')}</p>
                    </div>
                )}
                {active === 'campagne' && (
                    <div className="ms-2 me-3">
                        <h1>{globalT("campagne.management")}</h1>
                        {globalT('detail.campaign')}
                    </div>
                )}
                {active === 'education' && (
                    <div className="ms-2 me-3 text-start">
                        <h1>{globalT("education.management")}</h1>
                        {globalT('detail.education')}
                    </div>
                )}
                {active === 'revue' && (
                    <div className="ms-2 me-3">
                        <h1>{globalT("recu.doc")}</h1>
                        {globalT('detail.revue')}
                    </div>
                )}
                {active === 'forum' && (
                    <div className="ms-2 me-3">
                        <h1>{globalT('nav.forum')}</h1>
                        {globalT('detail.forum')}
                    </div>
                )}
                {active === 'incident' && (
                    <div className="ms-2 me-3">
                        <h1>Incident</h1>
                        {globalT('detail.incident')}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DetailFunc;