import React, {useState} from 'react';
import Publication from "../../../commons/models/Publication";
import neutre from "../../../assets/img/landing2/nouv/blackNeutre.jpeg"
import LANG from "../../../lang/language";
import {useAppSelector} from "../../../store/redux.types";
import {useNavigate} from "react-router-dom";
import {joinUrlWithParamsId} from "../../../commons/helpers/funcHelper";


type propsEvent = {
    data: Publication;
};

const CardActu = ({data}: propsEvent) => {

    const { settings, authUserData } = useAppSelector(({ settings, authUser, }) => ({
        settings,
        authUserData: authUser.data
    }));
    const navigate = useNavigate()

    return (
        <div className="row campaingnEventCard mb-2">
            <div className="col-3 d-flex justify-content-center align-items-center">
                <img src={neutre} alt="neutre" className="img-fluid campaingnEventCard__image" />
            </div>
            <div className="col-9">
                <div className="d-flex flex-column">
                    <span className="text-primary">{data.categoryName}</span>
                    <span onClick={() => navigate(joinUrlWithParamsId("/actualite/:id", data.id))} className="campaingnEventCard__title cursor-pointer">{ settings.language === LANG.fr.locale ? data?.name : data?.nameEn ? data.nameEn : data.name}</span>
                </div>
            </div>
        </div>
    );
};

export default CardActu;