import React, {useRef, useState} from "react";
import {
  Calendar,
  Event,
  stringOrDate,
  momentLocalizer,
} from "react-big-calendar";
import { addDays, startOfDay } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { Campagne } from "../commons/models/Campagne";
import { Evenements } from "../commons/models/Evenements";
import dayjs from "dayjs";
import DetailEventCampaign from "../views/evenement/DetailEventCampaign";
import DialogComponent from "./dialogs/DialogComponent";
import {globalT} from "../lang";

// Configure moment to use the default locale (you can customize this as needed)
moment.locale("fr");

type agendaProps = {
  data: Campagne[] | Evenements[];
  type: "campagne" | "evenement";
};

const MyEvent = ({ event }: any) => {
  console.log("event :", event)
  return (
      <div>
        <strong>{event.title}</strong>
      </div>
  );
};

const Agenda = ({ data, type }: agendaProps) => {
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const dataEvent = useRef<Campagne | Evenements>()

  const event01: Event[] = (data as Evenements[]).map((d) => ({
    title: d.name,
    start: dayjs(d.startDate).toDate(),
    end: dayjs(d.endDate).toDate(),
    resource: d,
    backgroundColor: '#F7B500',
  }));

  const handleEventClick = (event: any) => {
    dataEvent.current = event.resource
    setShowInfo(true)
  };

  const eventStyleGetter = (event: any) => {
    const style = {
      backgroundColor: event.backgroundColor, // Utilisez la couleur de fond définie pour l'événement
      borderRadius: '5px',
      opacity: 0.8,
      color: 'black',
      border: '0',
      display: 'block',
    };
    return {
      style: style
    };
  };

  return (
    <div style={{ height: "500px" }}>
      <Calendar
        localizer={momentLocalizer(moment)}
        events={event01}
        startAccessor="start"
        endAccessor="end"
        titleAccessor="title"
        defaultDate={new Date()}
        onSelectEvent={handleEventClick}
        eventPropGetter={eventStyleGetter}
        components={{
          event: MyEvent,
        }}
      />
      <DialogComponent size="lg" show={showInfo} title={globalT("button.details")} onClose={() => setShowInfo(false)} >
        <DetailEventCampaign data={dataEvent.current as (Evenements | Campagne)} type={type} />
      </DialogComponent>

    </div>
  );
};

export default Agenda;
