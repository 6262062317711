import React, {useState} from 'react';
import mp3Image from "../../../assets/img/landing2/nouv/itunes.png"
import {useAppSelector} from "../../../store/redux.types";
import LANG from "../../../lang/language";
import {Dayjs} from "dayjs";
import CardAudio from "../../../components/bootstrap/CardAudio";

type cardProps = {
    name: string;
    id: string
    nameEn: string;
    mp3: string;
    createAt: Dayjs
}

const CardMusique = ({mp3, name, id, createAt, nameEn}: cardProps) => {

    const { settings, authUserData } = useAppSelector(({ settings, authUser, }) => ({
        settings,
        authUserData: authUser.data
    }));
    const [showMp3, setShowMp3] = useState(false)

    return (
        <div className="cardAudio">
            {!showMp3 ? (
                    <div className="row cursor-pointer" onClick={() => setShowMp3(true)}>
                        <div className="col-3 text-end">
                            <img src={mp3Image} alt="" className="cardAudio__image"/>
                        </div>

                        <div className="col-9 text-start">
                            { settings.language === LANG.fr.locale ? name : nameEn ? nameEn : name}
                        </div>
                    </div>

            ) : (
                <div className="">
                    <CardAudio id={id} name={name} file={mp3} type={"presse"} />
                </div>
            )}

        </div>
    );
};

export default CardMusique;