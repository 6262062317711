import { globalT } from "../lang";
interface CardComponentProps {

    title: string;
    description: string;
    icon: string;
    iconBackground?: string; // this allows you to pass a background color for the icon
}

const CardComponent: React.FC<CardComponentProps> = ({title, description, icon, iconBackground = '#fff'}) => {
    return (
        <div className="card" >
            <div style={{ padding: '10px', textAlign: 'center', margin: '20px' }}>
                <div style={{fontSize: '40px', color: '#FFFFFF', display: 'inline-block', borderRadius: '40%', padding: '20px', marginBottom: '20px', backgroundColor: '#E5EAE4'}}>
                    <i className={icon}></i>
                </div>
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{description}</p>
                {/*<a style={{backgroundColor :"#FFA500"}} href="#" className="btn btn-primary">{globalT('learning.more')} <i className="fas fa-arrow-right"></i></a>*/}
            </div>
        </div>
    )
}

export default CardComponent;
