import React from 'react';
import "../../styles/components/publicationCard.scss"
import neutre from "../../assets/img/minsiter/neutre.jpg"

type cardM = {
    image: string;
    functiion: string;
    functiionEnglish: string;
    nomination: string;
    period: string;
    name: string;
    id?: number
}

const CardMinister = ({image, period, functiion, functiionEnglish, id, nomination, name}: cardM) => {
    return (
        <div className="card h-100">
            {image?.length > 0 ? (
                <img src={image} alt="img" className= "publicationImage d-block"/>
            ) : (
                <img src={neutre} alt="img" className= "publicationImage d-block"/>
            )}

            <div className="d-flex flex-column text-center">
                <span style={{fontSize: "1.5rem"}} className="fw-bold">{name}</span>
                <span style={{fontSize: "1.1rem"}} className="">{functiion}</span>
                <span style={{fontSize: "1.1rem"}} className="">{functiionEnglish}</span>
                <span style={{fontSize: "1.1rem"}} className="text-primary">{period}</span>
            </div>
        </div>
    );
};

export default CardMinister;