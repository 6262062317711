import React, { useState } from "react";
import { dashboardMenu, mainMenu } from "../../menu";
import { globalT } from "../../lang";
import Button from "../../components/bootstrap/Button";
import { useAppDispatch, useAppSelector } from "../../store/redux.types";
import { useNavigate } from "react-router-dom";
import "./Home.scss";
import FrontAdd from "../Incident/incident/FrontAdd";
import DialogComponent from "../../components/dialogs/DialogComponent";
import DrawerComponent from "../../components/DrawerComponent";
import { logoutUser } from "../../store/slices/authUser/actions";
import { AUTH, DASHBOARD } from "../../commons/urls/front";
import LogoText from "../../components/LogoText";
import classNames from "classnames";
import IntlMessages from "../../components/IntlMessages";
import { getJwtData } from "../../commons/helpers/jwtHelper";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { joinUrlWithParamsId } from "../../commons/helpers/funcHelper";
import LANG, { getLangWithKey } from "../../lang/language";
import { LanguageDict } from "../../commons/types";
import { setLanguage } from "../../store/slices/settings/actions";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import ContactUs from "../../components/ContactUs";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DetailFunc from "../../components/DetailFunc";
import intero from "../../assets/img/intero.png";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  marginTop: "auto",

  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const styles = {
  customTitle: {
    color: "blue", // Remplacez par la couleur souhaitée
  },
};

const Navnav = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [show, setShow] = useState<boolean>(false);
  const [showxx, setShowxx] = useState<boolean>(false);
  const [showInfpanel, setShowInfPanel] = useState<boolean>(false);
  const [showPanel, setShowPanel] = useState<boolean>(false);
  const { incident } = useAppSelector(({ alert }) => alert);

  const { settings, authUserData } = useAppSelector(
    ({ settings, authUser }) => ({
      settings,
      authUserData: authUser.data,
    })
  );
  const { catActualite } = useAppSelector(({ catActualite }) => catActualite);

  const educationCat = useAppSelector(({ catRevue }) => catRevue);

  const [showDialog, setShowDialog] = useState<boolean>(false);

  const currentPath = window.location.href;

  const jwtData = getJwtData();

  const alertCat = useAppSelector(({ catAlert }) => catAlert);

  const changeLanguage = (locale: LanguageDict) => {
    if (locale !== settings.language) {
      dispatch(setLanguage(locale));
    }
  };

  const alertlast = useAppSelector(({ alertLast }) => alertLast.lastAlert);

  const handleClose = () => setShowxx(false);
  const handleShow = () => setShowxx(true);

  return (
    <>
      <Navbar
        expand="xxl"
        className="position-fixed w-100 z-index-10 fnd py-3"
        style={{
          maxHeight: "600px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
        }}
      >
        <Container fluid>
          <Navbar.Brand href="#">
            {!authUserData && !currentPath?.includes(DASHBOARD.INDEX) && (
              <LogoText className="fw-bold" />
            )}
          </Navbar.Brand>
          <span
            className=" nav-link  active fw-bold fs-5 my-auto pt-3"
            aria-current="page"
          >
            APSR
          </span>

          <Navbar.Toggle aria-controls="navbarScroll" />

          <Navbar.Collapse id="navbarScroll">
            <Nav
              className=" my-2 me-auto my-lg-0 FC pt-4"
              style={{
                maxHeight: "400px",
                marginLeft: "1.4vw",
                zIndex: "9999999",
              }}
              navbarScroll
            >
              {/* <Nav.Link href="#action1">Home</Nav.Link> */}
              {/* <Nav.Link href="#action2">ACCUEIL</Nav.Link> */}
              {/* <i className="fas fa-home ml-4 text-success fs-3"></i> */}

              <Nav.Link
                onClick={() => navigate("/")}
                className="me-2 nav-linkI fw-bold fs-6 mb-2"
              >
                {" "}
                {globalT("home")}
              </Nav.Link>

              <NavDropdown
                title={
                  <span className="text-dark my-auto">
                    {globalT("landing.actualite.title")}
                  </span>
                }
                id="navbarScrollingDropdown"
                className="mx-2 nav-linkI fw-bold fs-6 text-dark"
                style={{ zIndex: "9999999", color: "black" }}
              >
                {catActualite &&
                  catActualite.map((cat) => (
                    <NavDropdown.Item
                      onClick={() =>
                        navigate(
                          joinUrlWithParamsId(
                            "/actualite/categorie/:id",
                            cat.id
                          ),
                          {
                            state: {
                              categorie: {
                                en: cat.nameEn,
                                fr: cat.name,
                              },
                            },
                          }
                        )
                      }
                      style={{ color: "black", zIndex: "9999999" }}
                    >
                      {" "}
                      <span className="item-color">
                        {settings.language === LANG.fr.locale
                          ? cat.name
                          : cat.nameEn
                          ? cat.nameEn
                          : cat.name}
                      </span>
                    </NavDropdown.Item>
                  ))}

                <NavDropdown.Item
                  onClick={() => navigate("/actualite")}
                  style={{ color: "black" }}
                >
                  <span className="item-color">
                    {globalT("tout.les.acctual")}
                  </span>
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title={
                  <span className="text-dark my-auto">
                    {globalT("education.management")}
                  </span>
                }
                id="navbarScrollingDropdown"
                className="mx-1 nav-linkI fw-bold fs-6 text-dark"
                style={{ color: "black" }}
              >
                {/*<NavDropdown.Item style={{ color: 'black' }} ><span className="item-color">{globalT("campagne.management")}</span> </NavDropdown.Item>*/}
                <NavDropdown.Item
                  onClick={() => navigate("/campagne/current")}
                  style={{ color: "black" }}
                >
                  <span className="item-color">
                    {globalT("campagne.management")}
                  </span>{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/education/document")}
                  style={{ color: "black" }}
                >
                  <span className="item-color">{globalT("education.doc")}</span>{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/education/video")}
                  style={{ color: "black" }}
                >
                  {" "}
                  <span className="item-color">
                    {globalT("education.video")}
                  </span>{" "}
                </NavDropdown.Item>
                {/*<NavDropdown.Item style={{ color: 'black' }} > <span className="item-color">{globalT("education.depliant")}</span> </NavDropdown.Item>*/}
                <NavDropdown.Item
                  onClick={() => navigate("/education/depliant")}
                  style={{ color: "black" }}
                >
                  {" "}
                  <span className="item-color">
                    {globalT("education.depliant")}
                  </span>{" "}
                </NavDropdown.Item>
                {/*<NavDropdown.Item style={{ color: 'black' }} > <span className="item-color">{globalT("education.audio")}</span> </NavDropdown.Item>*/}
                <NavDropdown.Item
                  onClick={() => navigate("/education/audio")}
                  style={{ color: "black" }}
                >
                  {" "}
                  <span className="item-color">
                    {globalT("education.audio")}
                  </span>{" "}
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title={
                  <span className="text-dark my-auto">
                    {globalT("recu.doc")}
                  </span>
                }
                id="navbarScrollingDropdown"
                className="mx-1 nav-linkI fw-bold fs-6 text-dark"
                style={{ color: "black" }}
              >
                {educationCat.catRevue &&
                  educationCat.catRevue.map((rev) => (
                    <NavDropdown.Item
                      onClick={() =>
                        navigate(
                          joinUrlWithParamsId("/revue/categorie/:id", rev.id),
                          {
                            state: {
                              categorie: {
                                fr: rev.name,
                                en: rev.nameEn,
                              },
                            },
                          }
                        )
                      }
                      style={{ color: "black" }}
                    >
                      <span className="item-color">
                        {settings.language === LANG.fr.locale
                          ? rev.name
                          : rev.nameEn
                          ? rev.nameEn
                          : rev.name}
                      </span>
                    </NavDropdown.Item>
                  ))}
                <NavDropdown.Item
                  onClick={() => navigate("/revue")}
                  style={{ color: "black" }}
                >
                  {" "}
                  <span className="item-color">
                    {globalT("revue.doc.all")}
                  </span>{" "}
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link
                className="me-2 nav-linkI fw-bold fs-6 mb-2"
                onClick={() => navigate("/evenement/after")}
                style={{ color: "black" }}
              >
                {globalT("evenement.management.after")}
              </Nav.Link>

              <Nav.Link
                className="me-2 nav-linkI fw-bold fs-6 mb-2"
                onClick={() => navigate("/campagne/after")}
                style={{ color: "black" }}
              >
                {globalT("campagne.management.after")}
              </Nav.Link>

              <Nav.Link
                onClick={() => navigate("/Chat")}
                className=" nav-linkI fw-bold fs-6 mb-2"
              >
                {globalT("nav.forum")}
              </Nav.Link>
              <Nav.Link
                onClick={() => setShowDialog(true)}
                className=" nav-linkI fw-bold fs-6 mb-2"
              >
                {globalT("contactUs")}
              </Nav.Link>
              <Nav.Link
                onClick={() => navigate("/about")}
                className=" nav-linkI fw-bold fs-6 mb-2"
              >
                {globalT("nav.about")}
              </Nav.Link>

              <DialogComponent
                show={showDialog}
                title={globalT("form.contact")}
                onClose={() => setShowDialog(false)}
                size="xl"
              >
                <ContactUs />
              </DialogComponent>
            </Nav>

            <Form className="resp">
              <div className="align-items-center col-auto d-block d-md-flex text-sm-left text-center">
                {!authUserData && (
                  <Button onClick={() => setShowInfPanel(true)}>
                    <img
                      src={intero}
                      alt="imf"
                      className="img-fluid imageIntero"
                    />
                  </Button>
                )}
                {!authUserData && (
                  <Button
                    icon="Info"
                    onClick={() => setShow(true)}
                    className="btn btn-outline-danger me-2"
                  >
                    {globalT("incident.add.title")}
                  </Button>
                )}
                {authUserData &&
                  document.location.href.includes(DASHBOARD.INDEX) && (
                    <Button
                      tag="a"
                      isLink
                      color="primary"
                      to={mainMenu.home.path}
                      icon={mainMenu.home.icon}
                      aria-label={globalT(mainMenu.home.text as string)}
                      isLight={currentPath === mainMenu.home.path}
                      className={classNames("ms-md-3 mb-md-0")}
                    >
                      <IntlMessages id={mainMenu.home.text as string} />
                    </Button>
                  )}

                {authUserData &&
                  !currentPath?.includes(
                    dashboardMenu.dashboard.path as string
                  ) && (
                    <>
                      <Button
                        tag="a"
                        isLink
                        color="primary"
                        to={dashboardMenu.dashboard.path}
                        icon={dashboardMenu.dashboard.icon}
                        aria-label={globalT(
                          dashboardMenu.dashboard.text as string
                        )}
                        className={classNames("ms-md-3 mb-md-0")}
                      >
                        <IntlMessages id={dashboardMenu.dashboard.text} />
                      </Button>
                    </>
                  )}
              </div>

              <div className="row g-3 align-items-center">
                <div
                  className="col d-flex align-items-center cursor-pointer"
                  // onClick={() => setState(!state)}
                  role="presentation"
                >
                  {authUserData && (
                    <>
                      <div className="me-3">
                        <div className="text-end">
                          <div className="fw-bold fs-6 mb-0">
                            {authUserData.user.email}
                          </div>
                        </div>
                      </div>
                      <div className="position-relative me-2">
                        <button
                          type="button"
                          className="btn fv text-light"
                          onClick={() => dispatch(logoutUser())}
                        >
                          {globalT("auth.logout")}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <DialogComponent
                show={show}
                title={globalT("incident.add.title")}
                onClose={() => setShow(false)}
                size="lg"
              >
                <FrontAdd onClose={() => setShow(false)} />
              </DialogComponent>

              <FormControlLabel
                control={<Android12Switch defaultChecked />}
                label={
                  LANG.fr.text === getLangWithKey(settings.language)?.text
                    ? globalT(LANG.en.text)
                    : globalT(LANG.fr.text)
                }
                onChange={() =>
                  changeLanguage(
                    LANG.fr.text === getLangWithKey(settings.language)?.text
                      ? LANG.en.locale
                      : LANG.fr.locale
                  )
                }
              />

              {!(jwtData && authUserData) && (
                <button
                  type="button"
                  className="btn fv text-light  btn-sm"
                  onClick={() => navigate(AUTH.LOGIN)}
                >
                  {globalT("se.connecter")}
                </button>
              )}
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {incident && incident.length > 0 && (
        <section className="JKL  position-fixed w-100 text-light  blinking-button">
          <div className="container">
            <div className="row">
              <div
                onClick={() => setShowxx(true)}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "1px",
                }}
                className="py-1"
              >
                <BsFillExclamationCircleFill
                  size={15}
                  className="me-2 text-danger"
                />
                <span className="mt-3 fw-bold text-danger">
                  {globalT("alert.management")}
                </span>
                <span className="mx-4 text-danger">
                  {globalT("cliquez.ici")}
                </span>
              </div>
            </div>
          </div>
        </section>
      )}

      {incident && incident.length > 0 && (
        <DrawerComponent
          type={"right"}
          onclose={() => setShowPanel(false)}
          onOpen={() => setShowPanel(true)}
          data={incident.map((d) => ({
            categoryName: d.categoryName,
            ville: d.ville,
            id: d.id,
            value: d.value,
          }))}
          open={showPanel}
        />
      )}

      <DrawerComponent
        type={"right"}
        onclose={() => setShowInfPanel(false)}
        onOpen={() => setShowInfPanel(true)}
        data={null}
        label={globalT("detail.info")}
        open={showInfpanel}
      >
        <DetailFunc />
      </DrawerComponent>

      <Dialog
        open={showxx}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {alertlast && (
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "dark", fontWeight: "bold" }}
          >
            {alertlast.categoryName.charAt(0).toUpperCase() +
              alertlast.categoryName.slice(1)}
          </DialogTitle>
        )}

        {alertlast && (
          <>
            <DialogTitle id="alert-dialog-title">{alertlast.ville}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {alertlast.value}
              </DialogContentText>
            </DialogContent>
          </>
        )}

        <DialogActions>
          <Button
            onClick={() => setShowxx(false)}
            className="bg-orange"
            color="primary"
          >
            {globalT("button.close")}
          </Button>
          <Button
            onClick={() => {
              setShowxx(false);
              setShowPanel(true);
            }}
            className="bg-orange"
            color="primary"
          >
            {globalT("voir")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Navnav;
