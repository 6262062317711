import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import * as actions from "./actions";
import {LanguageDict} from "../../../commons/types";

export const INITIAL_STATE: { isBoolean: boolean } = {
    isBoolean: false,
};

const reducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(actions.setBool, (state, action: PayloadAction<boolean>) => {
            state.isBoolean = action.payload;
        })
});

export default reducer;
