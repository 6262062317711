import React, { useState } from "react";
import { AUTH, DASHBOARD } from "../../commons/urls/front";
import LogoText from "../../components/LogoText";
import { useAppDispatch, useAppSelector } from "../../store/redux.types";
import Button from "../../components/bootstrap/Button";
import { globalT } from "../../lang";
import LANG from "../../lang/language";
import { LanguageDict } from "../../commons/types";
import { setLanguage } from "../../store/slices/settings/actions";
import "./header.scss";
import "./Home.scss";
import FrontAdd from "../Incident/incident/FrontAdd";
import DialogComponent from "../../components/dialogs/DialogComponent";
import Nav, { NavItem } from "../../components/bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import intero from "../../assets/img/intero.png";
import DetailFunc from "../../components/DetailFunc";
import DrawerComponent from "../../components/DrawerComponent";
import Icon from "../../components/icon/Icon";
import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../components/bootstrap/Dropdown";
import { joinUrlWithParamsId } from "../../commons/helpers/funcHelper";
import Carousel from "react-bootstrap/Carousel";
import img3 from "../../assets/img/landing2/LANDING_KK3.webp";
import LandingPage33 from "../../assets/img/landing2/MINT_11.webp";
import LandingPage4 from "../../assets/img/landing2/nouv/acceuill2.webp";
import LandingPage2 from "../../assets/img/landing2/44.jpg";
import logo from "../../assets/img/landing2/nouv/logo3.png";
import logo2 from "../../assets/img/landing2/nouv/mintlogo.png";
import Tooltips from "../../components/bootstrap/Tooltips";

type headerProps = {
  currentMenu?:
    | "acceuil"
    | "actualite"
    | "about"
    | "presentation"
    | "analyse"
    | "revue"
    | "service"
    | "education";
  hasAutherImage?: boolean;
  image?: string;
  title?: string;
  subtitle?: string;
};
const Header = ({
  currentMenu = "acceuil",
  hasAutherImage = false,
  image,
  title,
  subtitle,
}: headerProps) => {
  const { settings, authUserData } = useAppSelector(
    ({ settings, authUser }) => ({
      settings,
      authUserData: authUser.data,
    })
  );
  const [show, setShow] = useState<boolean>(false);
  const [showMobile, setShowMobile] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showInfpanel, setShowInfPanel] = useState<boolean>(false);
  const [active, setActive] = useState<
    | "acceuil"
    | "actualite"
    | "presentation"
    | "about"
    | "analyse"
    | "revue"
    | "service"
    | "education"
  >(currentMenu);
  const { catActualite } = useAppSelector(({ catActualite }) => catActualite);
  const educationCat = useAppSelector(({ catRevue }) => catRevue);

  if (educationCat.catRevue && educationCat.catRevue.length > 0) {
    console.log("educationCat", educationCat.catRevue[0].name);
  }
  const changeLanguage = (locale: LanguageDict) => {
    if (locale !== settings.language) {
      dispatch(setLanguage(locale));
    }
  };

  return (
    <div>
      <div className="z-index-10 position-fixed w-100 ">
        <div className="">
          <div className="">
            <div className="row home-subNav z-index-10 ps-2 pe-3">
              <div className="col-3">
                <div className="d-flex flex-column flex-sm-row">
                  <div className="d-flex align-items-center">
                    <img
                      src={logo2}
                      alt="logo2"
                      className="home-subNav__image2"
                    />
                    <img
                      src={logo}
                      alt="logo"
                      className="home-subNav__image1"
                    />
                    <span className="home-subNav__texte">APSR</span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <Tooltips
                  title={"Vous êtes témoin d'un incident, signalez-le"}
                  placement={"right"}
                >
                  {!authUserData && (
                    <div className="col-md-7 text-end">
                      <Button
                        isOutline
                        color="danger"
                        icon="ReportProblem"
                        onClick={() => setShow(true)}
                        className="text-danger text-hover-danger"
                      >
                        <span
                          className="text-danger text-hover-danger text-center"
                          style={{ fontSize: "1.3rem" }}
                        >
                          {globalT("incident.add.title")}
                        </span>
                      </Button>
                    </div>
                  )}
                </Tooltips>
              </div>
              <div className="col-3 text-end">
                <Button
                  isOutline
                  color="white"
                  onClick={() => changeLanguage(LANG.en.locale)}
                  aria-label="Change language"
                  className="text-hover-black"
                  data-tour="lang-selector"
                >
                  <span className="text-hover-black">
                    {globalT(LANG.en.text)}
                  </span>
                </Button>
                <Button
                  isOutline
                  color="white"
                  onClick={() => changeLanguage(LANG.fr.locale)}
                  aria-label="Change language"
                  className="ms-2 mt-1 text-hover-black"
                  data-tour="lang-selector"
                >
                  <div className="text-hover-black">
                    {globalT(LANG.fr.text)}
                  </div>
                </Button>
              </div>
            </div>
            <div className="home-nav">
              <div className="row w-100 hideNav">
                <div className="col-10 d-flex justify-content-end ">
                  <div className="d-flex align-items-center ">
                    {/*<Icon onClick={() => null} className="cursor-pointer d-xl-block d-xxl-none" color={"dark"} icon="ChevronLeft"></Icon>*/}
                    <Nav design={"pills"}>
                      <NavItem
                        className="cursor-pointer"
                        isActive={active === "acceuil"}
                        onClick={() => {
                          setActive("acceuil");
                          navigate("/");
                        }}
                      >
                        <span>
                          <a
                            className={classNames("btn", {
                              "text-orange": active === "acceuil",
                            })}
                            onClick={() => navigate("/")}
                            role="button"
                          >
                            {globalT("home").toUpperCase()}
                          </a>
                        </span>
                      </NavItem>
                      <NavItem
                        className="cursor-pointer"
                        isActive={active === "actualite"}
                        onClick={() => {
                          setActive("actualite");
                        }}
                      >
                        <span className="">
                          <Dropdown canUseClick={false} direction={"down"}>
                            <DropdownToggle>
                              <span
                                className={classNames("btn", {
                                  "text-orange": active === "actualite",
                                })}
                              >
                                {globalT(
                                  "landing.actualite.title"
                                ).toUpperCase()}
                              </span>
                            </DropdownToggle>
                            <DropdownMenu>
                              {catActualite?.map((cat) => (
                                <DropdownItem>
                                  <span
                                    className="text-hover-orange"
                                    onClick={() =>
                                      navigate(
                                        joinUrlWithParamsId(
                                          "/actualite/categorie/:id",
                                          cat.id
                                        ),
                                        {
                                          state: {
                                            categorie: {
                                              en: cat.nameEn,
                                              fr: cat.name,
                                            },
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {(settings.language === "fr"
                                      ? cat.name
                                      : cat.nameEn
                                      ? cat.nameEn
                                      : cat.nameEn
                                    ).toUpperCase()}
                                  </span>
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </Dropdown>
                        </span>

                        {/*<span>*/}
                        {/*    <a className={classNames("btn", {"text-orange": active === "actualite"})} onClick={() => navigate('/')} role="button">Actualité</a>*/}
                        {/*</span>*/}
                      </NavItem>
                      <NavItem
                        className="cursor-pointer"
                        isActive={active === "presentation"}
                        onClick={() => {
                          setActive("presentation");
                        }}
                      >
                        <span>
                          <a
                            className={classNames("btn", {
                              "text-orange": active === "presentation",
                            })}
                            onClick={() => navigate("/About")}
                            role="button"
                          >
                            {globalT("apropos").toUpperCase()}
                          </a>
                        </span>
                      </NavItem>
                      <NavItem
                        className="cursor-pointer"
                        isActive={active === "education"}
                        onClick={() => {
                          setActive("education");
                        }}
                      >
                        <span className="">
                          <Dropdown canUseClick={false} direction={"down"}>
                            <DropdownToggle>
                              <span
                                className={classNames("btn", {
                                  "text-orange": active === "education",
                                })}
                              >
                                {globalT("education.management").toUpperCase()}
                              </span>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem>
                                <span>
                                  <Dropdown
                                    canUseClick={false}
                                    direction={"start"}
                                  >
                                    <DropdownToggle>
                                      <span
                                        className={classNames(
                                          "btn text-hover-orange"
                                        )}
                                      >
                                        {globalT(
                                          "nav.education.sensibilisation"
                                        ).toUpperCase()}
                                      </span>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem>
                                        <span
                                          className="text-hover-orange"
                                          onClick={() =>
                                            navigate("/campagne/after")
                                          }
                                        >
                                          {globalT(
                                            "campagne.management"
                                          ).toUpperCase()}
                                        </span>
                                      </DropdownItem>
                                      <DropdownItem>
                                        <span
                                          className="text-hover-orange"
                                          onClick={() =>
                                            navigate("/evenement/after")
                                          }
                                        >
                                          {globalT("nav.events").toUpperCase()}
                                        </span>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </span>
                              </DropdownItem>
                              <DropdownItem>
                                <span>
                                  <Dropdown
                                    canUseClick={false}
                                    direction={"down"}
                                  >
                                    <DropdownToggle>
                                      <span
                                        className={classNames(
                                          "btn text-hover-orange"
                                        )}
                                      >
                                        {globalT(
                                          "nav.education.media"
                                        ).toUpperCase()}
                                      </span>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem>
                                        <span
                                          className="text-hover-orange"
                                          onClick={() =>
                                            navigate("/education/audio")
                                          }
                                        >
                                          {globalT(
                                            "education.audio"
                                          ).toUpperCase()}
                                        </span>
                                      </DropdownItem>
                                      <DropdownItem>
                                        <span
                                          className="text-hover-orange"
                                          onClick={() =>
                                            navigate("/education/video")
                                          }
                                        >
                                          {globalT(
                                            "education.video"
                                          ).toUpperCase()}
                                        </span>
                                      </DropdownItem>
                                      <DropdownItem>
                                        <span
                                          className="text-hover-orange"
                                          onClick={() =>
                                            navigate("/education/depliant")
                                          }
                                        >
                                          {globalT(
                                            "education.depliant"
                                          ).toUpperCase()}
                                        </span>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </span>
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </span>
                      </NavItem>
                      <NavItem
                        className="cursor-pointer"
                        isActive={active === "analyse"}
                        onClick={() => {
                          setActive("analyse");
                        }}
                      >
                        <span>
                          <a
                            className={classNames("btn", {
                              "text-orange": active === "analyse",
                            })}
                            onClick={() => navigate("/analyse")}
                            role="button"
                          >
                            {globalT("nav.analyse").toUpperCase()}
                          </a>
                        </span>
                      </NavItem>
                      <NavItem
                        className="cursor-pointer"
                        isActive={active === "service"}
                        onClick={() => {
                          setActive("service");
                        }}
                      >
                        <span className="">
                          <Dropdown canUseClick={false} direction={"down"}>
                            <DropdownToggle>
                              <span
                                className={classNames("btn", {
                                  "text-orange": active === "service",
                                })}
                              >
                                {globalT("nav.service").toUpperCase()}
                              </span>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem>
                                <span
                                  className="text-hover-orange ms-4 text-black"
                                  onClick={() =>
                                    navigate("/immatriculation/usager")
                                  }
                                >
                                  {globalT(
                                    "nav.service.immatriculation"
                                  ).toUpperCase()}
                                </span>
                              </DropdownItem>
                              <DropdownItem>
                                <span
                                  className="text-hover-orange ms-4 text-black"
                                  onClick={() => navigate("/service/permis")}
                                >
                                  {globalT("nav.service.permis").toUpperCase()}
                                </span>
                              </DropdownItem>

                              <DropdownItem>
                                <span
                                  className="text-hover-orange ms-4 text-black"
                                  onClick={() =>
                                    navigate("/services/autoEcole")
                                  }
                                >
                                  {globalT("nav.service.ecole").toUpperCase()}
                                </span>
                              </DropdownItem>
                              <DropdownItem>
                                <span
                                  className="text-hover-orange ms-4 text-black"
                                  onClick={() => navigate("/services/centre")}
                                >
                                  {globalT("nav.service.centre").toUpperCase()}
                                </span>
                              </DropdownItem>
                              <DropdownItem>
                                <span
                                  className="text-hover-orange ms-4 text-black"
                                  onClick={() => navigate("/services/ong")}
                                >
                                  {globalT("nav.service.demande").toUpperCase()}
                                </span>
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </span>
                      </NavItem>
                      <NavItem
                        className="cursor-pointer"
                        isActive={active === "revue"}
                        onClick={() => {
                          setActive("revue");
                        }}
                      >
                        <span className="">
                          <Dropdown canUseClick={false} direction={"down"}>
                            <DropdownToggle>
                              <span
                                className={classNames("btn", {
                                  "text-orange": active === "revue",
                                })}
                              >
                                {globalT("document.management").toUpperCase()}
                              </span>
                            </DropdownToggle>
                            <DropdownMenu>
                              {educationCat.catRevue?.map((cat) => (
                                <DropdownItem>
                                  <span
                                    className="text-hover-orange"
                                    onClick={() =>
                                      navigate(
                                        joinUrlWithParamsId(
                                          "/revue/categorie/:id",
                                          cat.id
                                        ),
                                        {
                                          state: {
                                            categorie: {
                                              en: cat.nameEn,
                                              fr: cat.name,
                                            },
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {(settings.language === "fr"
                                      ? cat.name
                                      : cat.nameEn
                                      ? cat.nameEn
                                      : cat.nameEn
                                    ).toUpperCase()}
                                  </span>
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </Dropdown>
                        </span>
                      </NavItem>
                    </Nav>
                    {/*<Icon onClick={() => null} className="cursor-pointer d-xl-block d-xxl-none" color={"dark"} icon={"ChevronRight"}></Icon>*/}
                  </div>
                </div>
                <div className="col-2">
                  <div className="d-flex justify-content-end">
                    {/*   <Button onClick={() => navigate("/About")}>
                      <img
                        src={intero}
                        alt="imf"
                        className="img-fluid imageIntero me-3"
                      />
                    </Button> */}
                    <Button
                      color={"secondaire"}
                      onClick={() => navigate(AUTH.LOGIN)}
                    >
                      <span className="text-white">
                        {globalT("se.connecter")}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="home-nav-moble">
              <div className="hideMobileButton w-100 mt-2 d-flex justify-content-end">
                <div className="me-2 mb-2">
                  <div className="d-flex justify-content-end">
                    <Button onClick={() => setShowInfPanel(true)}>
                      <img
                        src={intero}
                        alt="imf"
                        className="img-fluid imageIntero me-3"
                      />
                    </Button>
                    <Button
                      color={"secondaire"}
                      onClick={() => navigate(AUTH.LOGIN)}
                    >
                      <span className="text-white mb-1">
                        {globalT("se.connecter")}
                      </span>
                    </Button>
                  </div>
                </div>
                <Button
                  color={"secondaire"}
                  icon={"Menu"}
                  className="me-3"
                  onClick={() => setShowMobile(!showMobile)}
                />
              </div>
              {showMobile && (
                <div className={classNames("row w-100 hideMobileButton")}>
                  <div className="col-12 d-flex justify-content-center">
                    <div className="d-flex align-items-center">
                      {/*<Icon onClick={() => null} className="cursor-pointer d-xl-block d-xxl-none" color={"dark"} icon="ChevronLeft"></Icon>*/}
                      <Nav
                        verticalBreakpoint={"sm"}
                        tag={"ul"}
                        design={"pills"}
                      >
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "acceuil"}
                          onClick={() => {
                            setActive("acceuil");
                            navigate("*");
                          }}
                        >
                          <span>
                            <a
                              className={classNames("btn", {
                                "text-orange": active === "acceuil",
                              })}
                              onClick={() => navigate("/")}
                              role="button"
                            >
                              {globalT("home").toUpperCase()}
                            </a>
                          </span>
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "actualite"}
                          onClick={() => {
                            setActive("actualite");
                          }}
                        >
                          <span className="">
                            <Dropdown direction={"down"}>
                              <DropdownToggle>
                                <span
                                  className={classNames("btn", {
                                    "text-orange": active === "actualite",
                                  })}
                                >
                                  {globalT(
                                    "landing.actualite.title"
                                  ).toUpperCase()}
                                </span>
                              </DropdownToggle>
                              <DropdownMenu>
                                {catActualite?.map((cat) => (
                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange"
                                      onClick={() =>
                                        navigate(
                                          joinUrlWithParamsId(
                                            "/actualite/categorie/:id",
                                            cat.id
                                          ),
                                          {
                                            state: {
                                              categorie: {
                                                en: cat.nameEn,
                                                fr: cat.name,
                                              },
                                            },
                                          }
                                        )
                                      }
                                    >
                                      {(settings.language === "fr"
                                        ? cat.name
                                        : cat.nameEn
                                        ? cat.nameEn
                                        : cat.nameEn
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </span>

                          {/*<span>*/}
                          {/*    <a className={classNames("btn", {"text-orange": active === "actualite"})} onClick={() => navigate('/')} role="button">Actualité</a>*/}
                          {/*</span>*/}
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "presentation"}
                          onClick={() => {
                            setActive("presentation");
                          }}
                        >
                          <span>
                            <a
                              className={classNames("btn", {
                                "text-orange": active === "presentation",
                              })}
                              onClick={() => navigate("/presentation")}
                              role="button"
                            >
                              {globalT("nav.presentation").toUpperCase()}
                            </a>
                          </span>
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "education"}
                          onClick={() => {
                            setActive("education");
                          }}
                        >
                          <span className="">
                            <Dropdown direction={"down"}>
                              <DropdownToggle>
                                <span
                                  className={classNames("btn", {
                                    "text-orange": active === "education",
                                  })}
                                >
                                  {globalT(
                                    "education.management"
                                  ).toUpperCase()}
                                </span>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem>
                                  <span>
                                    <Dropdown direction={"start"}>
                                      <DropdownToggle>
                                        <span
                                          className={classNames(
                                            "btn text-hover-orange"
                                          )}
                                        >
                                          {globalT(
                                            "nav.education.sensibilisation"
                                          ).toUpperCase()}
                                        </span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem>
                                          <span
                                            className="text-hover-orange"
                                            onClick={() =>
                                              navigate("/campagne/after")
                                            }
                                          >
                                            {globalT(
                                              "campagne.management"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownItem>
                                        <DropdownItem>
                                          <span
                                            className="text-hover-orange"
                                            onClick={() =>
                                              navigate("/evenement/after")
                                            }
                                          >
                                            {globalT(
                                              "nav.events"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                </DropdownItem>
                                <DropdownItem>
                                  <span>
                                    <Dropdown direction={"down"}>
                                      <DropdownToggle>
                                        <span
                                          className={classNames(
                                            "btn text-hover-orange"
                                          )}
                                        >
                                          {globalT(
                                            "nav.education.media"
                                          ).toUpperCase()}
                                        </span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem>
                                          <span
                                            className="text-hover-orange"
                                            onClick={() =>
                                              navigate("/education/audio")
                                            }
                                          >
                                            {globalT(
                                              "education.audio"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownItem>
                                        <DropdownItem>
                                          <span
                                            className="text-hover-orange"
                                            onClick={() =>
                                              navigate("/education/video")
                                            }
                                          >
                                            {globalT(
                                              "education.video"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownItem>
                                        <DropdownItem>
                                          <span
                                            className="text-hover-orange"
                                            onClick={() =>
                                              navigate("/education/depliant")
                                            }
                                          >
                                            {globalT(
                                              "education.depliant"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "analyse"}
                          onClick={() => {
                            setActive("analyse");
                          }}
                        >
                          <span>
                            <a
                              className={classNames("btn", {
                                "text-orange": active === "analyse",
                              })}
                              onClick={() => navigate("/analyse")}
                              role="button"
                            >
                              {globalT("nav.analyse").toUpperCase()}
                            </a>
                          </span>
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "service"}
                          onClick={() => {
                            setActive("service");
                          }}
                        >
                          <span className="">
                            <Dropdown direction={"start"}>
                              <DropdownToggle>
                                <span
                                  className={classNames("btn", {
                                    "text-orange": active === "service",
                                  })}
                                >
                                  {globalT("nav.service").toUpperCase()}
                                </span>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem>
                                  <span
                                    className="text-hover-orange ms-4 text-black"
                                    onClick={() =>
                                      navigate("/immatriculation/usager")
                                    }
                                  >
                                    {globalT(
                                      "nav.service.immatriculation"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownItem>
                                <DropdownItem>
                                  <span
                                    className="text-hover-orange ms-4 text-black"
                                    onClick={() => navigate("/service/permis")}
                                  >
                                    {globalT(
                                      "nav.service.permis"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownItem>

                                <DropdownItem>
                                  <span
                                    className="text-hover-orange ms-4 text-black"
                                    onClick={() =>
                                      navigate("/services/autoEcole")
                                    }
                                  >
                                    {globalT("nav.service.ecole").toUpperCase()}
                                  </span>
                                </DropdownItem>
                                <DropdownItem>
                                  <span
                                    className="text-hover-orange ms-4 text-black"
                                    onClick={() => navigate("/services/centre")}
                                  >
                                    {globalT(
                                      "nav.service.centre"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownItem>
                                <DropdownItem>
                                  <span
                                    className="text-hover-orange ms-4 text-black"
                                    onClick={() => navigate("/services/ong")}
                                  >
                                    {globalT(
                                      "nav.service.demande"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "revue"}
                          onClick={() => {
                            setActive("revue");
                          }}
                        >
                          <span className="">
                            <Dropdown direction={"down"}>
                              <DropdownToggle>
                                <span
                                  className={classNames("btn", {
                                    "text-orange": active === "revue",
                                  })}
                                >
                                  {globalT("document.management").toUpperCase()}
                                </span>
                              </DropdownToggle>
                              <DropdownMenu>
                                {educationCat.catRevue?.map((cat) => (
                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange"
                                      onClick={() =>
                                        navigate(
                                          joinUrlWithParamsId(
                                            "/revue/categorie/:id",
                                            cat.id
                                          ),
                                          {
                                            state: {
                                              categorie: {
                                                en: cat.nameEn,
                                                fr: cat.name,
                                              },
                                            },
                                          }
                                        )
                                      }
                                    >
                                      {(settings.language === "fr"
                                        ? cat.name
                                        : cat.nameEn
                                        ? cat.nameEn
                                        : cat.nameEn
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                        </NavItem>
                      </Nav>
                      {/*<Icon onClick={() => null} className="cursor-pointer d-xl-block d-xxl-none" color={"dark"} icon={"ChevronRight"}></Icon>*/}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={classNames("global-home-style", {
          hideSpace: showMobile,
          ShowSpace: !showMobile,
        })}
      >
        <section className="imdeg">
          {!hasAutherImage ? (
            <Carousel>
              <Carousel.Item>
                <div
                  className="containers d-flex align-items-center justify-content-start image-container"
                  style={{
                    backgroundImage: `url(${LandingPage2}`,
                    borderRadius:"10px",
                    // marginTop: '50px',
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    height: "77vh",
                  }}
                >
                  <Carousel.Caption style={{ border: "none" }}>
                    <div
                      style={{
                        width: "100%",
                        marginBottom: "20%",
                        textAlign: "center",
                       // marginLeft:'2%',
                        color:'white'
                      }}
                      className="text-container"
                    >
                      <h3 className="title"> {globalT("accueil.titre.3")}</h3>
                      <p className="description">
                        {globalT("accueuil.desciption.3")}
                      </p>

                      <div>
                        <button
                          type="button"
                          style={{ backgroundColor: "#FFA500" }}
                          className="btn btn-warning btn-lg scin button"
                        >
                          <a href="https://mintransports.cm/" className={"text-hover-black"} style={{textDecoration: "none"}} target={"_blank"}>{globalT("landing.header.savoir")}</a>
                        </button>
                      </div>
                    </div>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  className="containers d-flex align-items-center justify-content-start image-container"
                  style={{
                    borderRadius:"10px",
                    backgroundImage: `url(${LandingPage4}`,
                    // marginTop: '50px',
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    height: "77vh",
                  }}
                >
                  <Carousel.Caption style={{ border: "none" }}>
                    <div
                      style={{
                        width: "100%",
                        marginBottom: "20%",
                        textAlign: "center",
                       // marginLeft:'2%',
                       color:'white'
                      }}
                      className="text-container"
                    >
                      <h3 className="title"> {globalT("accueil.titre.2")}</h3>
                      <p className="description">
                        {globalT("accueuil.desciption.2")}
                      </p>

                      <div>
                        <button
                          type="button"
                          style={{ backgroundColor: "#FFA500" }}
                          className="btn btn-warning btn-lg scin button"
                        >
                          <a href="https://mintransports.cm/" className={"text-hover-black"} style={{textDecoration: "none"}} target={"_blank"}>{globalT("landing.header.savoir")}</a>
                        </button>
                      </div>
                    </div>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div
                  className="containers  d-flex align-items-center justify-content-start image-container"
                  style={{
                    backgroundImage: `url(${LandingPage33}`,
                    borderRadius:"10px",
                    //  marginTop: '50px',
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    height: "77vh",
                  }}
                >
                  <Carousel.Caption style={{ border: "none" }}>
                    <div
                      style={{
                        width: "100%",
                        marginBottom: "20%",
                        textAlign: "center",
                       // marginLeft:'2%',
                       color:'white',
                        fontWeight: "bold"
                      }}
                      className="text-container"
                    >
                      <h3 className="title"> {globalT("accueil.titre.4")}</h3>
                      <p className="description">
                        {globalT("accueuil.desciption.4")}
                      </p>

                      <div>
                        <button
                          type="button"
                          style={{ backgroundColor: "#FFA500" }}
                          className="btn btn-warning btn-lg scin button"
                        >
                          <a href="https://mintransports.cm/" className={"text-hover-black"} style={{textDecoration: "none"}} target={"_blank"}>{globalT("landing.header.savoir")}</a>
                        </button>
                      </div>
                    </div>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
            </Carousel>
          ) : (
            <div
              className=" d-flex align-items-center justify-content-start image-container"
              style={{
                backgroundImage: `url(${image}`,
                // marginTop: '50px',
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                height: "77vh",
              }}
            >
              <div
                style={{
                  width: "100%",
                  // marginBottom: "20%",
                  color: "white",
                  textAlign: "left",
                  marginLeft: "25%",
                }}
                className="text-container"
              >
                <div
                  className="text-secondary"
                  style={{ fontSize: "3.2rem", marginBottom: "3rem" }}
                >
                  {subtitle ? globalT(subtitle) : ""}
                </div>
                <h1 className="" style={{ fontSize: "5.2rem" }}>
                  {" "}
                  {title ? globalT(title) : ""}
                </h1>

                {/*<div>*/}
                {/*    <button*/}
                {/*        type="button"*/}
                {/*        style={{backgroundColor: "#FFA500"}}*/}
                {/*        className="btn btn-warning btn-lg scin button"*/}
                {/*    >*/}
                {/*        {globalT("landing.header.savoir")}*/}
                {/*    </button>*/}
                {/*</div>*/}
              </div>
            </div>
          )}

          <style>{`
  .image-container {
    opacity: 0;
    animation: fadeInImage 1s forwards;
  }

  .text-container {
    opacity: 0;
    transform: translateY(30px);
    animation: fadeInText 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
    animation-delay: 1s;
  }

  @keyframes fadeInImage {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeInText {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`}</style>
        </section>
      </div>

      <DialogComponent
        show={show}
        title={globalT("incident.add.title")}
        onClose={() => setShow(false)}
        size="lg"
      >
        <FrontAdd onClose={() => setShow(false)} />
      </DialogComponent>
      <DrawerComponent
        type={"right"}
        onclose={() => setShowInfPanel(false)}
        onOpen={() => setShowInfPanel(true)}
        data={null}
        label={globalT("detail.info")}
        open={showInfpanel}
      >
        <DetailFunc />
      </DrawerComponent>
    </div>
  );
};

export default Header;
