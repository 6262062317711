import React, {lazy, useEffect, useRef} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Route, Routes} from "react-router-dom";


import {
    ADMINISTRATION,
    CATEGORY,
    DOCUMENTATION,
    HOME,
    PARAGRAPH,
    PUBLICATION,
    EVENEMENT,
    INCIDENT,
    CAMPAGNE,
    BOOT,
    DISCUSION,
    ALERT, 
    EDUCATION, 
    DASHBOARD,
    MODERATEUR
} from "../commons/urls/front";

import {StoreType, useAppDispatch} from '../store/redux.types';
import WrapperLayout from '../layout/Wrapper/Wrapper';
import AsideRoutes from '../layout/Aside/AsideRoutes';
import {loginUser} from '../store/slices/authUser/actions';
import Forbidden403 from '../components/errors/ConnexionExpired403';
import {getJwtData} from "../commons/helpers/jwtHelper";
import StorageHelper, {StorageKeys} from "../commons/helpers/StorageHelper";
import {SET_AUTH_USER_SUCCESS} from "../store/actionTypes";
import User from "../commons/models/User";


const LazyHome = lazy(() => import('../views/dashboard/index'))
const LazyAdministration = lazy(() => import('../views/administration/index'))
const LazyPublication = lazy(() => import('../views/publication/index'))
const LazyDocumentation = lazy(() => import('../views/Document/index'))
const LazyEducation = lazy(() => import('../views/education/index'))
const LazyCategory = lazy(() => import('../views/Category/index'))
const LazyParagraph = lazy(() => import('../views/paragraph/index'))
const LazyEvenement = lazy(() => import('../views/evenement/index'))
const LazyIncident = lazy(() => import('../views/Incident/index'))
const LazyCampagne = lazy(() => import('../views/Campagne/index'))
const LazyChatbot = lazy(() => import('../views/bot/index'))
const LazyAlert = lazy(() => import('../views/Alert/index'))
const LazyDiscussion = lazy(() => import('../views/forum/discusion/index'))
const LazyProfil = lazy(() => import('../views/forum/discusion/index'))
const LazyPass = lazy(() => import('../views/ChangePassWord/ChangPass'))
const LazyModerateur = lazy(() => import('../views/moderateur/index'))


/* Redux Provide */
const mapStateToProps = ({authUser}: StoreType) => ({authUser});

const mapDispatchToProps = {
    loginUser
};
 
/* State and Props Type */
const AuthRoutesConnected = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof AuthRoutesConnected>;

type State = {
    // isAuthValid: undefined | bool
};

const AuthRoutes = ({ authUser }: PropsFromRedux) => {
    const dispatch = useAppDispatch()
    const dataUser = useRef<User | null>(null)

    useEffect(() => {
        const data = JSON.parse(StorageHelper.getItem(StorageKeys.USER_DATA) as string)
        dataUser.current = new User(data)
        dispatch({ type: SET_AUTH_USER_SUCCESS, payload: dataUser.current });
    }, []);

    // const { data, loading, error, fetched } = authUser;

    const jwtData = getJwtData();



    // if (!fetched || loading)
    //     return (
    //         <ContentSkeletonLoader />
    //     );

    if (jwtData && dataUser.current) {
        return (
            <>
                <AsideRoutes />

                <WrapperLayout>
                    <Routes>
                        {/*<Route path='*' element={<MixedRoutes />} />*/}
                        <Route path={HOME} element={<LazyHome />}/>
                        <Route path={ADMINISTRATION.ROUTES_ENGINE.INDEX} element={<LazyAdministration />} />
                        <Route path={PUBLICATION.ROUTES_ENGINE.INDEX} element={<LazyPublication />} />
                        <Route path={DOCUMENTATION.ROUTES_ENGINE.INDEX} element={<LazyDocumentation />} />
                        <Route path={EDUCATION.ROUTES_ENGINE.INDEX} element={<LazyEducation />} />
                        <Route path={CATEGORY.ROUTES_ENGINE.INDEX} element={<LazyCategory />} />
                        <Route path={PARAGRAPH.ROUTES_ENGINE.INDEX} element={<LazyParagraph />} />
                        <Route path={EVENEMENT.ROUTES_ENGINE.INDEX} element={<LazyEvenement />} />
                        <Route path={INCIDENT.ROUTES_ENGINE.INDEX} element={<LazyIncident />} />
                        <Route path={CAMPAGNE.ROUTES_ENGINE.INDEX} element={<LazyCampagne />} />
                        <Route path={BOOT.ROUTES_ENGINE.INDEX} element={<LazyChatbot />} />
                        <Route path={DISCUSION.ROUTES_ENGINE.INDEX} element={<LazyDiscussion />} />
                        <Route path={ALERT.ROUTES_ENGINE.INDEX} element={<LazyAlert />} />
                        <Route path={DASHBOARD.ROUTES_ENGINE.INDEX_CHANGEPASS} element={<LazyPass />} />
                        <Route path={MODERATEUR.ROUTES_ENGINE.INDEX} element={<LazyModerateur />} />
                    </Routes>
                </WrapperLayout>
            </>
        );
    }

    return (
        <Forbidden403 />
    )
};

export default AuthRoutesConnected(AuthRoutes);
