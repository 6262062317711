import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import * as actions from "./actions";

export const INITIAL_STATE: { bot: any[] | null } = {
    bot: null,
};

const reducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(actions.bot, (state, action) => {
            state.bot = action.payload;
        })
});

export default reducer;
