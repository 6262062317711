import React, {useEffect, useRef, useState} from 'react';
import Options from "../Options";

const GlobalMessage = (props : any) => {

    const data = useRef<any[]>(props.payload)


    let finalMessage = null
    const otherOption = useRef([
        {
            name: "Choisir une nouvelle categorie",
            handler: props.actionProvider.handleCategorie,
            id: '1o'
        },
    ])

    const allMessage = data.current?.map(d => ({
        name:d.question,
        id: d.id,
        handler: typeof d.reponse === 'string' ? () => props.actionProvider.handleResponse(d.reponse) : () => props.actionProvider.handleResponseCustum(d.reponse, d.question)
    }))


    if (allMessage) {
        finalMessage = allMessage.concat(otherOption.current)
    }


    return (
        <div>
            {finalMessage && finalMessage.length > 0 && (
                <Options options={ finalMessage } title={""} {...props} />
            )}
        </div>
    );
};

export default GlobalMessage;