import React, { FC } from 'react';
import classNames from 'classnames';
import logo from '../assets/img/landing2/logoMin.webp';

interface ILogoTextProps extends React.HTMLAttributes<HTMLAnchorElement> {
	spanProps?: React.HTMLAttributes<HTMLSpanElement>,
	hideSubtitle?: boolean
	canChangeSize?: boolean
	title?: string
	image?: string
}

const LogoText: FC<ILogoTextProps> = ({title, canChangeSize=false,  image, spanProps = {}, hideSubtitle = false, className, ...restProps }) => {
	const { className: spanClassName, ...restSpanProps } = spanProps;
	return (
		<span
			// href="#"
			className={classNames('d-flex logo-text d-flex align-items-center mb-3 mb-sm-0', className)}
			{...restProps}>
			<img src={ image ? image : logo} alt="logo" className= {classNames("header-scu-logo", {"header-scu-logo2":canChangeSize})}
			/>
			<span className={classNames( 'logo-title', hideSubtitle && 'no-subtitle')}>{title ? title : ""}</span>
			{/*<span className='logo-subtitle'> Card System</span>*/}
		</span>
	);
};


export default LogoText;
