import dayjs from "dayjs";

import CategoryDoc from "./CategorieDoc";


export default class Documentation {
    public id: string;
    public description: string;
    public descriptionEn: string;
    public categoryName: string;
    public categoryId: CategoryDoc[];
   public file:string;
   public thumbnail?: any;
    public hashTag:string[];
    public name: string;
    public nameEn: string;
    public createdAt: dayjs.Dayjs;
    public updatedAt: dayjs.Dayjs;

    
    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.nameEn = data.nameEn;
        this.description = data.description;
        this.descriptionEn = data.descriptionEn;
        this.thumbnail = data.thumbnail;
        this.categoryName = data.categoryName;
        this.categoryId = data.categories;
        this.file = data.file;
        this.hashTag = data.hasTags
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt
    }
}

