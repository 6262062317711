import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AUTH } from "../../../commons/urls/front";
import EducationImg from "../../../assets/img/landing2/Education.webp";
import DataImage from "../../../assets/img/landing2/vide.webp";
import { useAppDispatch, useAppSelector } from "../../../store/redux.types";
import { setRequestGlobalLoader } from "../../../store/slices/requestGlobalLoader/actions";
import mintClient from "../../../network";
import { globalT } from "../../../lang";

import { base64ToFile } from "../../../helpers/helpers";
import DialogComponent from "../../../components/dialogs/DialogComponent";
import PaginationComponent from "../../../components/PaginationComponent";
import CardImg from "../../../components/bootstrap/CardImg";
import SearchComponent from "../../../components/SearchComponent";
import EncryptedPdfViewer from "../../../components/ReadPdf";
import RevueDocumentaire from "../../../components/bootstrap/RevueDocumentaire";
import Navnav from "../../Navnav/Navnav";
import Footfoot from "../../Navnav/Footfoot";
import Spinner from "../../../components/bootstrap/Spinner";
import Header from "../Header";
import Footer from "../Footer";
import CardDocument from "../card/CardDocument";
import newImage from "../../../assets/img/landing2/back_copie.png";
import ImageSensibilisation from "../../../components/ImageSensibilisation";

const Revue = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [documents, setDocuments] = useState<any[] | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const [currentDoc, setCurrentDoc] = useState<string | null>(null);
  const total = useRef<number>();

  const handleFilteredDocumentsPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const loadDocumentation = () => {
    setLoading(true);
    mintClient.documentation
      .getAll({
        page: currentPage,
        pageSize: 6,
        orderDirection: "ascending",
        isDocument: true,
        researchValue: searchValue,
        hasPagination: true,
        langue: "fr",
      })
      .then((resp) => {
        const allDocuments = resp.data.data;
        total.current = resp.data.total;
        setLoading(false);
        setDocuments(allDocuments);
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(setRequestGlobalLoader(false)));
  };

  useEffect(() => {
    loadDocumentation();
  }, [currentPage, searchValue]);

  const downloadFile = (doc: any) => {
    const blob = base64ToFile(doc?.file as string, "application/pdf");

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = "receipt_file.pdf";
    link.style.display = "none";
    link.click();
    link.remove();
  };
  const handleDocumentClick = (file: string) => {
    setCurrentDoc(file);
    setShowPdf(true);
  };

  useEffect(() => {
    // @ts-ignore
    document.getElementById("sectionId").scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header currentMenu={"revue"} />
      <div className="container" id={"sectionId"}>
        <ImageSensibilisation
          image={newImage}
          texte="Revu"
        />{" "}
      </div>

      <div className="container " style={{ marginTop: "150px" }}>
        <h1 className="mb-4" style={{ fontWeight: "bold" }}>
          {globalT("education.management.revue")}
        </h1>

        <div className="row mb-4" style={{ marginTop: "20px" }}>
          <div className="col-md-9">
            {/*<h2 className="mb-3">{globalT("edu.h3.pa")}</h2>*/}
            <p style={{ fontSize: "17px" }}>{globalT("edu.para.1")}</p>
          </div>
          <div className="col-md-3">
            <img
              src={EducationImg}
              alt="Your Image"
              style={{ height: "300px", width: "100%" }}
              className="img-fluid"
            />
          </div>
        </div>

        <div
          style={{
            marginTop: "50px",
            height: "3px",
            background: "linear-gradient(to left , yellow 80%, red 20%)",
          }}
          className="mb-4"
        ></div>

        <div className="row mb-4">
          <div className="col-md-8">
            {/*<h2 className="mb-3">{globalT("educ.research")}</h2>*/}
          </div>
          <div className="col-md-4">
            {/* Replace with your search component */}
            <SearchComponent onSearch={handleSearch} />
          </div>
        </div>
        {documents ? (
          <></>
        ) : (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "200px" }}
          >
            <div
              className="spinner-border"
              style={{ height: "6rem", width: "6rem", color: "#aae0aa" }}
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <section className="container pt-1">
          <div className="row py-4">
            <div className="d-flex justify-content-between mb-4">
              <span></span>
              {loading && documents && (
                <Spinner size="3rem" customColor="#aae0aa" />
              )}
            </div>
            {documents && documents.length > 0 ? (
              <>
                <div className="row">
                  {documents.map((pub) => (
                    <div className="col-4 mb-2">
                      <CardDocument
                        id={pub.id}
                        name={pub.name}
                        nameEn={pub.nameEn}
                        mp3={pub.file}
                        onClick={() => handleDocumentClick(pub.file)}
                        createAt={pub.createdAt}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="text-center mt-5 mb-5">
                <div className="text-center mt-5 mb-5">
                  <div
                    style={{
                      width: "400px", // or any size you want
                      height: "400px", // make it a square
                      backgroundImage: `url(${DataImage})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      margin: "0 auto", // this will center the div if the parent div has a text-align: center
                    }}
                  />
                  <span
                    className="fw-bold"
                    style={{
                      display: "block",
                      marginTop: "20px",
                      fontSize: "24px",
                    }}
                  >
                    {globalT("empty.doc")}
                  </span>
                </div>
              </div>
            )}
          </div>

          {showPdf && currentDoc && (
            <DialogComponent
              show={showPdf}
              size="lg"
              title=""
              onClose={() => setShowPdf(false)}
            >
              <EncryptedPdfViewer base64EncryptedPdf={currentDoc} />
            </DialogComponent>
          )}
          <PaginationComponent
            pages={Math.ceil((total.current || 0) / 6)}
            currentPage={currentPage}
            onPageChange={handleFilteredDocumentsPageChange}
            rangeLimit={5}
          />
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Revue;
