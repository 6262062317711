
export default class Category {
    public id: string;
    public description: string;
    public descriptionEn: string;
    public name: string;
    public nameEn: string;

    constructor(data: any) {

        this.id = data.id;
        this.name = data.name;
        this.nameEn = data.nameEn;
        this.description = data.description;
        this.descriptionEn = data.descriptionEn;
    
    }
}