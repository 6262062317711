import React, {useState} from 'react';
import dayjs, {Dayjs} from "dayjs";
import Carousel from "../../../components/bootstrap/Carousel";
import LANG from "../../../lang/language";
import {useAppSelector} from "../../../store/redux.types";
import "./card.scss"
import {joinUrlWithParamsId} from "../../../commons/helpers/funcHelper";
import {useNavigate} from "react-router-dom";

type imageProps = {
    images: any[]
    name: string
    nameEn: string
    categorie: string
    createAt: Dayjs
    id: string
}

const CardImage = ({images, id, name, nameEn, categorie, createAt}: imageProps) => {
    const { settings, authUserData } = useAppSelector(({ settings, authUser, }) => ({
        settings,
        authUserData: authUser.data
    }));
    const navigate = useNavigate()
    return (
        <div className="col-sm-6 col-md-4 cardImage mb-4">
            <div className="cursor-pointer">
                <Carousel
                    items={images.map(img => ({src: img.attachmentFile, other: {name: settings.language === LANG.fr.locale ? name : nameEn ? nameEn : name, id: id}, type: img.attachmentTypeName, isDetail: false, tomail: img.thumbsnailsFile}))}
                    className="custom-carousel"/>
            </div>

            <div className="d-flex flex-column cursor-pointer" onClick={() => navigate(joinUrlWithParamsId("/actualite/:id", id))}>
                <span className="cardImage__title">{ settings.language === LANG.fr.locale ? name : nameEn ? nameEn : name}</span>
                <span className="cardImage__date">{dayjs(createAt).format('lll')}</span>
                <span className="cardImage__categorie">{categorie}</span>
            </div>

        </div>
    );
};

export default CardImage;