import React, { useEffect, useRef, useState } from "react";
import Navnav from "../../Navnav/Navnav";
import Footfoot from "../../Navnav/Footfoot";
import { globalT } from "../../../lang";
import SearchComponent from "../../../components/SearchComponent";
import DataImage from "../../../assets/img/landing2/vide.webp";
import DepliantLanding from "../../../assets/img/landing2/depliant.png";
import PaginationComponent from "../../../components/PaginationComponent";
import mintClient from "../../../network";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Documentation from "../../../commons/models/Documentation";
import * as yup from "yup";
import CategoryDoc from "../../../commons/models/CategorieDoc";
import "./doc.scss";
import CardVid from "../../../components/bootstrap/CardVid";
import Header from "../Header";
import Footer from "../Footer";
import newImage from "../../../assets/img/landing2/back_copie.png";
import ImageSensibilisation from "../../../components/ImageSensibilisation";

interface OptionType {
  label: string;
  value: string;
  id: number;
}
interface IFormInput {
  categorie: OptionType | null;
}

const schema = yup.object().shape({
  categorie: yup.object().nullable().required("This field is required"),
});

const Depliant = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });
  const [page, setPage] = useState(1);
  const total = useRef<number>();
  const [categorie, setCategorie] = useState<CategoryDoc[] | null>(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [documentationDocument, setDocumentationDocument] = useState<
    Documentation[] | null
  >();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("1");
  const sectionRef = React.useRef<HTMLDivElement | null>(null);

  const handleDocumentClick = (file: string) => {
    // Handle the document click here
  };
  const scrollToSection = () => {
    const section = sectionRef.current;
    if (section) {
      const sectionTop =
        section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: sectionTop, behavior: "smooth" });
    }
  };

  const loadDocumentationDocument = () => {
    setLoading(true);
    // dispatch(setRequestGlobalLoader(true))
    mintClient.documentation
      .getAll({
        page: page,
        pageSize: 6,
        categoryId: selectedCategoryId,
        order: true,
        orderDirection: "ascending",
        validationState: 1,
        researchValue: search,
        eduCategory: true,
        isImage: true,
      })
      .then((resp) => {
        const allDocumentationDocuments = resp.data.data;
        total.current = resp.data.total;
        setDocumentationDocument(allDocumentationDocuments);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };
  const loadCategorie = () => {
    mintClient.category
      .getAll({ isParent: true, parentId: 1, page: 1, pageSize: 100 })
      .then((resp) => {
        setCategorie(resp.data.data);
      })
      .catch(() => null);
  };

  useEffect(() => {
    if (!categorie) loadCategorie();
    loadDocumentationDocument();
  }, [selectedCategoryId, search]);

  useEffect(() => {
    // @ts-ignore
    document.getElementById("sectionId").scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  const handleSearch = (value: any) => {
    setSearch(value);
    setPage(1);
  };

  return (
    <>
      <Header currentMenu={"education"} />
      <div className="container" id={"sectionId"}>
      </div>
      <div className="container section-11" style={{ marginTop: "50x" }}>
        <div className="row mb-4" style={{ marginTop: "20px" }}>
          <div className="row mb-4 ">
            {/* Code for the first section */}
            <div className="col-md-5">
              <h1
                className="mb-3"
                style={{
                  fontSize: "38px",
                  marginBottom: "20px",
                  fontWeight: "blod",
                }}
              >
                {globalT("edu.depliant.para.1")}
              </h1>
              <p
                style={{
                  fontSize: "17px",
                  marginBottom: "20px",
                  fontWeight: 500,
                  color: "black",
                }}
              >
                {globalT("edu.depliant.para.2")}
              </p>
              <button
                type="button"
                className="btn btn-danger btn-lg"
                style={{ marginTop: "20px", backgroundColor: "#FFA500" }}
                onClick={scrollToSection}
              >
                <i
                  className="fas fa-file-alt"
                  style={{ marginRight: "10px", color: "white" }}
                ></i>
                {globalT("landing.header.depliant")}
              </button>
            </div>
            <div className="col-md-6">
              <img
                src={DepliantLanding}
                alt="Your Image"
                style={{ height: "500px", width: "800px" }}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      {/*<div className="container-fluid section-2" style={{ marginTop: '20px', backgroundColor: '#e5eae4' }}>*/}
      {/*    <div className="container section-2" style={{ marginTop: '80px', marginBottom:'100px'}}>*/}
      {/*        <div className="row">*/}
      {/*            <div className="col-lg-5 d-flex align-items-center justify-content-center flex-column">*/}
      {/*                <h1 className="font-weight-bold">{globalT("edu.document.header.card.1")}</h1>*/}
      {/*                <p className="font-weight-bold">{globalT("edu.document.hearder.card.2")}</p>*/}
      {/*                <div className="grid-column"  style={{fontSize: '25px', color: '#AAE0AA', display: 'inline-block', borderRadius: '40%', padding: '20px', marginBottom: '20px'}}>*/}
      {/*                    <i className="fas fa-file-alt fa-2x text-black-50"></i>   <i className="fas fa-file-pdf fa-2x m-lg-4 text-black-50"></i>*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*            <div className="col-lg-7">*/}
      {/*                <div className="row">*/}
      {/*                    <div className="col-sm">*/}
      {/*                        <CardComponent title={globalT("edu.document.card.title.1")} description={globalT("edu.document.card.desc.1")} icon="fas fa-book" />*/}
      {/*                    </div>*/}
      {/*                    <div className="col-sm">*/}
      {/*                        <CardComponent title={globalT("edu.document.card.title.2")} description={globalT("edu.document.card.desc.2")} icon="fas fa-first-aid" />*/}
      {/*                    </div>*/}
      {/*                </div>*/}
      {/*                <div className="row">*/}
      {/*                    <div className="col-sm">*/}
      {/*                        <CardComponent title={globalT("edu.document.card.title.3")} description={globalT("edu.document.card.desc.3")} icon="fas fa-leaf" />*/}
      {/*                    </div>*/}
      {/*                    <div className="col-sm">*/}
      {/*                        <CardComponent title={globalT("edu.document.card.title.4")} description={globalT("edu.document.card.desc.4")} icon="fas fa-balance-scale" />*/}
      {/*                    </div>*/}
      {/*                </div>*/}
      {/*            </div>*/}

      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}

      <div
        className="container section-3"
        ref={sectionRef}
        style={{ marginTop: "80px" }}
      >
        <div
          style={{
            marginTop: "50px",
            height: "3px",
            background: "linear-gradient(to left , yellow 80%, red 20%)",
          }}
          className="mb-4"
        ></div>

        <div className="row mb-4 align-items-center">
          <div className="col-md-7">
            {/*<h2 className="mb-3">{globalT("educ.research.document")}</h2>*/}
          </div>
          <div className="col-md-5 d-flex mb-4">
            {/* Replace with your search component */}
            <SearchComponent onSearch={handleSearch} />
            {/* Directly place SelectFromRemote component here */}
            {/*<div className="col-md-5 ms-3">*/}
            {/*    {categorie ? (*/}
            {/*        <div className='d-flex flex-column'>*/}
            {/*            <span className='text-start fw-bold mb-3'>{globalT('video.category')}</span>*/}
            {/*            <Select*/}
            {/*                size='sm'*/}
            {/*                ariaLabel='Per'*/}
            {/*                onChange={(e: { target: { value: string; }; }) => {*/}
            {/*                    setSelectedCategoryId(e.target.value);*/}
            {/*                }}*/}
            {/*                value={selectedCategoryId}>*/}
            {/*                <Option key='all' value='1'>*/}
            {/*                    tous*/}
            {/*                </Option>*/}

            {/*                {categorie.map((cat) => (*/}
            {/*                    <Option key={cat.id} value={cat.id}>*/}
            {/*                        {cat.name}*/}
            {/*                    </Option>*/}
            {/*                ))}*/}
            {/*            </Select>*/}
            {/*        </div>*/}

            {/*    ) : (*/}
            {/*        <div className="spinner-border" style={{height: "3rem", width: "3rem", color: '#aae0aa'}} role="status">*/}
            {/*            <span className="visually-hidden">Loading...</span>*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}
          </div>
        </div>
        <div className="row py-4">
          <>
            {loading && (
              <div
                className="d-flex justify-content-between"
                style={{ color: "red" }}
              >
                <span></span>
                <div
                  className="spinner-border"
                  style={{
                    height: "3rem",
                    width: "3rem",
                    color: "#aae0aa",
                    marginTop: "40px",
                    marginRight: "10px",
                  }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </>
          {documentationDocument && documentationDocument.length > 0 ? (
            <>
              {documentationDocument.map((doc, index) => (
                // <div className='col-md-3 col-sm-12 mb-4 ' id={`no${index}`}>
                //     <CardVid
                //         thomails={doc.thumbnail}
                //         title={doc.name}
                //         images={[{attachmentFile:doc.file, attachmentTypeName: 'VIDEO'}]}
                //         id={doc.id}
                //         date={doc.createdAt}
                //         hasAnotherAction={true}
                //     />
                // </div>
                <div className="col-lg-4 col-md-6 mb-4" id={`no${index}`}>
                  <CardVid
                    // thomails={doc.thumbnail}
                    title={doc.name}
                    titleEn={doc.nameEn}
                    images={[
                      { attachmentFile: doc.file, attachmentTypeName: "IMAGE" },
                    ]}
                    date={doc.createdAt}
                    id={doc.id}
                    // categoryName={pub.categoryName}
                    hasAnotherAction={true}
                  />
                  {/*<RevueDocumentaire*/}
                  {/*    name={doc.name}*/}
                  {/*    file={doc.file}*/}
                  {/*    id={doc.id}*/}
                  {/*    type="pdf"*/}
                  {/*    onClick={() => handleDocumentClick(doc.file)}*/}
                  {/*/>*/}
                </div>
              ))}
            </>
          ) : (
            <div className="imageContainer" style={{ marginTop: "100px" }}>
              <div className="text-center mt-5 mb-5">
                <div
                  style={{
                    width: "400px", // or any size you want
                    height: "400px", // make it a square
                    backgroundImage: `url(${DataImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    margin: "0 auto", // this will center the div if the parent div has a text-align: center
                  }}
                  className="image"
                />
                {loading ? (
                  <div className="d-flex justify-content-center spinnerContainer">
                    <div
                      className="spinner-border"
                      style={{
                        height: "6rem",
                        width: "6rem",
                        color: "#aae0aa",
                        marginTop: "85px",
                        marginRight: "15px",
                      }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <span
                    className="fw-bold"
                    style={{
                      marginTop: "20px",
                      fontSize: "24px",
                    }}
                  >
                    {globalT("empty.doc")}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        <PaginationComponent
          pages={Math.ceil((total.current || 0) / 6)}
          currentPage={page}
          onPageChange={(page) => setPage(page)}
          rangeLimit={5}
        />
      </div>

      <Footer />
    </>
  );
};
export default Depliant;
