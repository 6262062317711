import React, {useEffect, useRef, useState} from 'react';
import LandingPage33 from "../../../assets/img/landing2/mint2.jpg";
import Header from "../Header";
import Footer from "../Footer";
import Input from "../../../components/bootstrap/forms/Input";
import {globalT} from "../../../lang";
import Icon from "../../../components/icon/Icon";
import PaginationComponent from "../../../components/PaginationComponent";
import Documentation from "../../../commons/models/Documentation";
import mintClient from "../../../network";
import Spinner from "../../../components/bootstrap/Spinner";
import CardDocument from "../card/CardDocument";
import DataImage from "../../../assets/img/landing2/vide.webp";
import DialogComponent from "../../../components/dialogs/DialogComponent";
import EncryptedPdfViewer from "../../../components/ReadPdf";
import newImage from "../../../assets/img/landing2/back_copie.png";
import ImageSensibilisation from '../../../components/ImageSensibilisation';

const autoEcole = [
    {
        name: "Auto École Kef-Secu - Agence de Fougerolle",
        speciality: ["Auto-écoles et Écoles de conduite", "Services en relation aux automobiles"],
        address: "Fougerolle - Yaoundé / Cameroun -",
        telephone: "655221155",
        formation: ["Formation en conduite automobile", "Formation en conduite défensive", "Recyclage automobile"]
    },
    {
        name: "Auto École Kef-Secu - Agence antenne Messamendongo",
        speciality: ["Auto-écoles et Écoles de conduite", "Services en relation aux automobiles"],
        address: "Fougerolle - Yaoundé / Cameroun -",
        telephone: "655221155",
        formation: ["Formation en conduite automobile", "Formation en conduite défensive", "Recyclage automobile"]
    },
    {
        name: "Auto École Kef-Secu - Agence de Terminus Odza",
        speciality: ["Auto-écoles et Écoles de conduite", "Services en relation aux automobiles"],
        address: "Fougerolle - Yaoundé / Cameroun -",
        telephone: "655221155",
        formation: ["Formation en conduite automobile", "Formation en conduite défensive", "Recyclage automobile"]
    },
    {
        name: "Auto École Kef-Secu - Agence du Cradat",
        speciality: ["Auto-écoles et Écoles de conduite", "Services en relation aux automobiles"],
        address: "Fougerolle - Yaoundé / Cameroun -",
        telephone: "655221155",
        formation: ["Formation en conduite automobile", "Formation en conduite défensive", "Recyclage automobile"]
    },
    {
        name: "Services Prestations Sécurité (SPS)",
        speciality: ["Auto-écoles et Écoles de conduite", "Services en relation aux automobiles"],
        address: "Fougerolle - Yaoundé / Cameroun -",
        telephone: "655221155",
        formation: ["Formation en conduite automobile", "Formation en conduite défensive", "Recyclage automobile"]
    },
    {
        name: "Patenteonilineit",
        speciality: ["Auto-écoles et Écoles de conduite", "Services en relation aux automobiles"],
        address: "Fougerolle - Yaoundé / Cameroun -",
        telephone: "655221155",
        formation: ["Formation en conduite automobile", "Formation en conduite défensive", "Recyclage automobile"]
    },
    {
        name: "Auto Ecole Kassap",
        speciality: ["Auto-écoles et Écoles de conduite", "Services en relation aux automobiles"],
        address: "Fougerolle - Yaoundé / Cameroun -",
        telephone: "655221155",
        formation: ["Formation en conduite automobile", "Formation en conduite défensive", "Recyclage automobile"]
    },
    {
        name: "Auto-Ecole Déclik Mobility",
        speciality: ["Auto-écoles et Écoles de conduite", "Services en relation aux automobiles"],
        address: "Fougerolle - Yaoundé / Cameroun -",
        telephone: "655221155",
        formation: ["Formation en conduite automobile", "Formation en conduite défensive", "Recyclage automobile"]
    },
    {
        name: "Auto-école Française (Agence de Yaoundé - Ngousso) (AEF)",
        speciality: ["Auto-écoles et Écoles de conduite", "Services en relation aux automobiles"],
        address: "Fougerolle - Yaoundé / Cameroun -",
        telephone: "655221155",
        formation: ["Formation en conduite automobile", "Formation en conduite défensive", "Recyclage automobile"]
    },
    // {
    //     name: "Auto-école Française (Agence de Yaoundé - Awae) (AEF)",
    //     speciality: ["Auto-écoles et Écoles de conduite", "Services en relation aux automobiles"],
    //     address: "Fougerolle - Yaoundé / Cameroun -",
    //     formation: ["Formation en conduite automobile", "Formation en conduite défensive", "Recyclage automobile"]
    // },
    // {
    //     name: "Auto-école Française (Agence de Yaoundé - Soa) (AEF)",
    //     speciality: ["Auto-écoles et Écoles de conduite", "Services en relation aux automobiles"],
    //     address: "Fougerolle - Yaoundé / Cameroun -",
    //     telephone: "655221155",
    //     formation: ["Formation en conduite automobile", "Formation en conduite défensive", "Recyclage automobile"]
    // },
]

const Ecole = () => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };
    const [searchValue, setSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const total = useRef<number>();
    const [loading, setLoading] = useState(true);
    const [dateEcole, setDataEcole] = useState<Documentation[]>()
    const [showPdf, setShowPdf] = useState<boolean>(false);
    const [currentDoc, setCurrentDoc] = useState<string | null>(null);

    const loadDataCEcole = () => {
        setLoading(true)
        mintClient.documentation.getAll({
            "page": currentPage,
            "pageSize": 10,
            researchValue: searchValue,
            "orderDirection": "ascending",
            "categoryId": 38,
            "isDocument": true,
            "eduCategory": false,
            "oder": true
        })
            .then((resp) => {
                setLoading(false)
                setDataEcole(resp.data.data)
                total.current = resp.data.total
            })
            .catch(() => null)
    }

    const handleDocumentClick = (file: string) => {
        setCurrentDoc(file);
        setShowPdf(true);
    };

    const handleFilteredDocumentsPageChange = (page: number) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        // @ts-ignore
        document.getElementById('sectionId').scrollIntoView({
            behavior: 'smooth'
        })
    }, [])

    useEffect(() => {
        loadDataCEcole()
    }, [currentPage, searchValue])

    return (
        <>
            <Header currentMenu={"service"} hasAutherImage={true} image={LandingPage33} title={globalT("nav.service.ecole")} subtitle={globalT("nav.service")} />
            <div className="container" id={"sectionId"}>
            <ImageSensibilisation
          image={newImage}
          texte={ `${globalT("ecole.text")} !!!`}
        />            </div>
            <div className="container mt-5">
                <h1 className="">
                    <span>{globalT("liste.centre.ecole")}</span>
                </h1>
                <div className="searchfield">
                    <div className="row justify-content-center">
                        <div className="col">
                            <span className="">
                                Recherche
                            </span>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col">
                            <Input type='text' value={searchValue} onChange={handleChange} className="input" placeholder='Search'/>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="row">
                        {!loading ? (
                            <></>
                        ) : (
                            <div className="text-end">
                                <Spinner size={"3rem"} color={"primary"} />
                            </div>
                        )}
                        <section className="container pt-3">
                            <div className="row py-4">
                                {dateEcole && dateEcole.length > 0 ? (
                                    <>
                                        <div className="d-flex justify-content-between mb-4">
                                            <span></span>
                                            {loading && <Spinner size="3rem" customColor="#aae0aa" />}
                                        </div>
                                        <div className="row">
                                            {dateEcole.map(pub => (
                                                <div className="col-4 mb-2">
                                                    <CardDocument
                                                        id={pub.id}
                                                        name={pub.name}
                                                        nameEn={pub.nameEn}
                                                        mp3={pub.file}
                                                        onClick={() => handleDocumentClick(pub.file)}
                                                        createAt={pub.createdAt}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                ) : (
                                    <div className="text-center mt-5 mb-5">
                                        <div className="text-center mt-5 mb-5">
                                            <div
                                                style={{
                                                    width: "400px", // or any size you want
                                                    height: "400px", // make it a square
                                                    backgroundImage: `url(${DataImage})`,
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "center",
                                                    backgroundSize: "cover",
                                                    margin: "0 auto", // this will center the div if the parent div has a text-align: center
                                                }}
                                            />
                                            <span
                                                className="fw-bold"
                                                style={{
                                                    display: "block",
                                                    marginTop: "20px",
                                                    fontSize: "24px",
                                                }}
                                            >
                                    {globalT("custum.list.empty")}
                                </span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {showPdf && currentDoc && (
                                <DialogComponent
                                    show={showPdf}
                                    size="lg"
                                    title=""
                                    onClose={() => setShowPdf(false)}
                                >
                                    <EncryptedPdfViewer base64EncryptedPdf={currentDoc} />
                                </DialogComponent>
                            )}
                            <PaginationComponent
                                pages={Math.ceil((total.current || 0) / 6)}
                                currentPage={currentPage}
                                onPageChange={handleFilteredDocumentsPageChange}
                                rangeLimit={5}
                            />
                        </section>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Ecole;