import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import * as actions from "./actions";

export const INITIAL_STATE: { botMessage: string | null } = {
    botMessage: null,
};

const reducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(actions.botMessage, (state, action) => {
            state.botMessage = action.payload;
        })
});

export default reducer;
