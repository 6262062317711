import React, { useEffect, useRef, useState } from "react";
import { WrapperLayout } from "../../layout/Wrapper/Wrapper";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Header from "./Header";
import Card, { CardBody } from "../../components/bootstrap/Card";
import Icon from "../../components/icon/Icon";
import "./Home.scss";
import { globalT } from "../../lang";
import minister from "../../assets/img/landing2/nouv/ministger4.png";
import Button from "../../components/bootstrap/Button";
import mintClient from "../../network";
import { recentDatas } from "../../store/slices/recentData/actions";
import { setRequestGlobalLoader } from "../../store/slices/requestGlobalLoader/actions";
import Slider from "react-slick";
import { useAppDispatch, useAppSelector } from "../../store/redux.types";
import Publication from "../../commons/models/Publication";
import Documentation from "../../commons/models/Documentation";
import Spinner from "../../components/bootstrap/Spinner";
import CardImage from "./card/CardImage";
import { useNavigate } from "react-router-dom";
import vid02 from "../../assets/img/landing2/playYutube.png";
import CardDocument from "./card/CardDocument";
import DialogComponent from "../../components/dialogs/DialogComponent";
import EncryptedPdfViewer from "../../components/ReadPdf";
import Draggable from "../../components/draggable";
import Footer from "./Footer";
import Backcopie from "../../assets/img/landing2/back_copie.png";

import ImageSensibilisation from "../../components/ImageSensibilisation";

const PatientHome = () => {
  const [publicationImage, setPublicationImage] = useState<{
    value: Publication[] | [];
    loading: boolean;
  }>({ value: [], loading: false });
  const [publicationVideo, setPublicationVideo] = useState<{
    value: Publication[] | [];
    loading: boolean;
  }>({ value: [], loading: false });
  const [audio, setAudio] = useState<{
    value: Documentation[] | [];
    loading: boolean;
  }>({ value: [], loading: false });
  const [document, setDocument] = useState<{
    value: Documentation[] | [];
    loading: boolean;
  }>({ value: [], loading: false });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showVideo, setShowVideo] = useState({
    vid1: false,
    vid2: false,
    vid3: false,
  });
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const [currentDoc, setCurrentDoc] = useState<string | null>(null);

  const loadPublication = async () => {
    setPublicationImage({ ...publicationImage, loading: true });
    await mintClient.publication
      .getAllImage({
        page: 1,
        pageSize: 6,
        order: true,
        orderDirection: "ascending",
        validationState: 1,
      })
      .then((resp) => {
        setPublicationImage({ value: resp.data.data, loading: false });
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(setRequestGlobalLoader(false)));
  };

  const loadVideo = async () => {
    setPublicationVideo({ ...publicationVideo, loading: true });
    await mintClient.publication
      .getAllVideo({
        page: 1,
        pageSize: 6,
        orderDirection: "ascending",
        validationState: 1,
      })
      .then((resp) => {
        setPublicationVideo({ value: resp.data.data, loading: false });
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(setRequestGlobalLoader(false)));
  };

  const loadAudio = async () => {
    setAudio({ ...audio, loading: true });
    await mintClient.documentation
      .getAll({
        page: 1,
        pageSize: 6,
        orderDirection: "ascending",
        categoryId: 1,
        isVideo: false,
        isDocument: false,
        isAudio: true,
        isImage: false,
        order: true,
      })
      .then((resp) => {
        setAudio({ value: resp.data.data, loading: false });
      })
      .catch((error) => console.log("EVENTS : ", error))
      .finally(() => dispatch(setRequestGlobalLoader(false)));
  };

  useEffect(() => {
    window.scrollTo({
      top: 20,
      behavior: "smooth",
    });
  }, []);

  const loadDocument = async () => {
    setDocument({ ...document, loading: true });
    await mintClient.documentation
      .getAll({
        page: 1,
        pageSize: 6,
        orderDirection: "ascending",
        isDocument: true,
        eduCategory: false,
        oder: true,
      })
      .then((resp) => {
        setDocument({ value: resp.data.data, loading: false });
      })
      .catch((error) => console.log("EVENTS : ", error))
      .finally(() => dispatch(setRequestGlobalLoader(false)));
  };

  const loadElement = () => {
    loadPublication();
    loadAudio();
    loadVideo();
    loadDocument();
  };

  const handleDocumentClick = (file: string) => {
    setCurrentDoc(file);
    setShowPdf(true);
  };

  useEffect(() => {
    loadElement();
  }, []);

  const sliderRef = useRef<Slider>(null);
  useEffect(() => {
    sliderRef.current?.slickPlay();
  }, []);

  useEffect(() => {
    loadElement();
  }, []);

  return (
    <>
      <>
        <Header currentMenu={"acceuil"} />

        <Page>
          <div className="slider-containerd">
            <div className="sliderd">
              <div className="slided">
                {" "}
                <ImageSensibilisation
                  image={Backcopie}
                  texte={globalT("decouv.resl")}
                  action={{
                    navigatez: () => navigate("/service/permis"),
                  }}
                />
              </div>
              <div className="slided">
                {" "}
                <ImageSensibilisation
                  image={Backcopie}
                  texte={globalT("dec.vid")}
                  action={{
                    navigatez: () => navigate("/education/video"),
                  }}
                />
              </div>
              <div className="slided">
                {" "}
                <ImageSensibilisation
                  image={Backcopie}
                  texte={globalT("con.biblio")}
                  action={{
                    navigatez: () => navigate("/revue"),
                  }}
                />
              </div>
              <div className="slided">
                {" "}
                <ImageSensibilisation
                  image={Backcopie}
                  texte={globalT("dec.im")}
                  action={{
                    navigatez: () => navigate("/immatriculation/usager"),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="sensibilisation">
              <div className="row">
                <div
                  className="col-md-4 col-sm-6 cursor-pointer"
                  onClick={() => navigate("/revue")}
                >
                  <Card>
                    <CardBody>
                      <div className="d-flex flex-column align-items-center">
                        <Icon
                          icon={"People"}
                          color={"orange"}
                          size={"4x"}
                        ></Icon>
                        <span className="homeEdu__title">{globalT("home.eduq")}</span>
                        <span className="homeEdu__subtitle">
                          {globalT("home.eduquer")}
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div
                  className="col-md-4 col-sm-6 cursor-pointer"
                  onClick={() => navigate("/actualite")}
                >
                  <Card>
                    <CardBody>
                      <div className="d-flex flex-column align-items-center">
                        <Icon
                          icon={"People"}
                          color={"orange"}
                          size={"4x"}
                        ></Icon>
                        <span className="homeEdu__title">{globalT("home.informe")}</span>
                        <span className="homeEdu__subtitle">
                          {globalT("home.inform")}
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div
                  className="col-md-4 col-sm-6 cursor-pointer"
                  onClick={() => navigate("/education/video")}
                >
                  <Card>
                    <CardBody>
                      <div className="d-flex flex-column align-items-center">
                        <Icon
                          icon={"People"}
                          color={"orange"}
                          size={"4x"}
                        ></Icon>
                        <span className="homeEdu__title">{globalT("home.sensi")}</span>
                        <span className="homeEdu__subtitle">
                          {globalT("home.sensibi")}
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>

            <div className="homeMinister">
              <Card>
                <CardBody>
                  <div className="">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 order-md-2 order-lg-1 d-flex flex-column homeMinister__globalMessage">
                        <span className="homeMinister__about">{globalT("home.about")}</span>
                        <span className="homeMinister__title">
                          {globalT("home.minister.title")}
                        </span>
                        <div className="homeMinister__message text-justify">
                          <p className="">{globalT("home.minister.msg1")}</p>
                          <p className="">{globalT("home.minister.msg2")}</p>
                          <p className="">{globalT("home.minister.msg3")}</p>
                          <p className="">{globalT("home.minister.msg4")}</p>
                          <p className="">{globalT("home.minister.msg5")}</p>
                        </div>
                      </div>
                      <div className="col-lg-6 text-center col-md-12 order-md-1 order-lg-2 d-flex align-items-center justify-content-md-center justify-content-lg-end">
                        <img
                          src={minister}
                          alt="minister"
                          className="imageMinister"
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="homeInfo">
              <div className="row">
                <div className="col-md-7 col-lg-8">
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <Card>
                        <CardBody>
                          <div className="d-flex flex-column">
                            <span className="homeInfo__icon"></span>
                            <span className="homeInfo__title">
                              {globalT("home.mission.title.1")}
                            </span>
                            <span className="homeInfo__message">
                              {globalT("home.mission.card.1")}
                              <br />
                              <br />
                              <br />
                            </span>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <Card>
                        <CardBody>
                          <div className="d-flex flex-column">
                            <span className="homeInfo__icon"></span>
                            <span className="homeInfo__title">
                              {globalT("home.mission.title.2")}
                            </span>
                            <span className="homeInfo__message">
                              {globalT("home.mission.card.2")}
                            </span>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <Card
                        className="text-white"
                        style={{ backgroundColor: "#FA6400" }}
                      >
                        <CardBody>
                          <div className="d-flex flex-column">
                            <span className="homeInfo__icon"></span>
                            <span className="homeInfo__title2">
                              {globalT("home.mission.title.3")}
                            </span>
                            <span className="homeInfo__message">
                              {globalT("home.mission.card.3")}
                            </span>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <Card>
                        <CardBody>
                          <div className="d-flex flex-column">
                            <span className="homeInfo__icon"></span>
                            <span className="homeInfo__title">
                              {globalT("home.mission.title.4")}
                            </span>
                            <span className="homeInfo__message">
                              {globalT("home.mission.card.4")}
                              <br />
                              <br />
                            </span>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-lg-4 d-flex align-items-center justify-content-center">
                  <div className="d-flex flex-column homeInfo__mission">
                    <span className="homeInfo__about">
                      {globalT("nos.missions")}
                    </span>
                    <span className="title">{globalT("engagement.sec")}</span>
                    <p className="homeInfo__message">
                      {globalT("notre.objectif")}
                    </p>
                    <Button
                      color={"secondaire"}
                      onClick={() => navigate("/actualite")}
                    >
                      <span className="homeInfo__button">
                        <a href="https://mintransports.cm/" className={"text-hover-black"} style={{textDecoration: "none"}} target={"_blank"}>{globalT("landing.header.savoir")}</a>
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="homeActualite">
              <div className="d-flex justify-content-between">
                <h1 className="homeVideo__title">{globalT("landing.actualite.title")}</h1>
                <div>
                  <Button
                      color={"secondaire"}
                      onClick={() => navigate("/actualite")}
                      size="lg"
                  >
                    <span className="text-white">{globalT("voir")}</span>
                  </Button>
                </div>
              </div>

              {publicationImage.loading ? (
                <div className="text-end">
                  <Spinner size={"3rem"} color={"orange"} />
                </div>
              ) : (
                <>
                  {publicationImage.value.length > 0 ? (
                    <div className="row">
                      {publicationImage.value.map((pub) => (
                        <CardImage
                          name={pub.name}
                          nameEn={pub.nameEn}
                          images={pub.attachement}
                          id={pub.id}
                          categorie={pub.categoryName}
                          createAt={pub.createdAt}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="text-center">liste vide</div>
                  )}
                </>
              )}
            </div>
            <div className="homeVideo">
              <div className="d-flex justify-content-between">
                <h1 className="homeVideo__title">{globalT("video")}</h1>

                <div>
                  <Button
                    color={"secondaire"}
                    onClick={() => navigate("/video")}
                    size="lg"
                  >
                    <span className="text-white">{globalT("voir")}</span>
                  </Button>
                </div>
              </div>

              <div className="row">
                {publicationVideo.loading ? (
                  <div className="text-end">
                    <Spinner size={"3rem"} color={"orange"} />
                  </div>
                ) : (
                  <div className="">
                    {publicationVideo.value.length > 0 ? (
                      <div className="row">
                        <div className="col-6 pe-0 ps-0">
                          {showVideo.vid1 ? (
                            <video
                              controls
                              autoPlay={true}
                              className="publicationImage"
                            >
                              <source
                                src={
                                  publicationVideo.value[0].attachement[0]
                                    .attachmentFile
                                }
                                type="video/mp4"
                              />
                              Votre navigateur ne prend pas en charge les vidéos
                              HTML5.
                            </video>
                          ) : (
                            <div className="position-relative w-100 h-100">
                              {/*<img src={ publicationVideo.value[0].attachement[0].thumbsnailsFile} alt={"img"}*/}
                              {/*     className= {classNames('d-block w-100 h-100 publicationImage')}*/}
                              {/*/>*/}
                              <div className="homeVideo__image1"></div>
                              <Button
                                className="homeVideo__button"
                                onClick={() =>
                                  setShowVideo({ ...showVideo, vid1: true })
                                }
                              >
                                <img src={vid02} className="img-fluid" />
                              </Button>
                              <div className="homeVideo__subtitle">
                                {publicationVideo.value[0].name}
                              </div>
                              <div className="homeVideo__categorie">
                                {publicationVideo.value[0].categoryName}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-6 pe-0 ps-0">
                          <div className="row">
                            {publicationVideo.value[1] && (
                              <div className="col-12">
                                {showVideo.vid2 ? (
                                  <video
                                    controls
                                    autoPlay={true}
                                    className="publicationImage"
                                  >
                                    <source
                                      src={
                                        publicationVideo.value[1].attachement[0]
                                          .attachmentFile
                                      }
                                      type="video/mp4"
                                    />
                                    Votre navigateur ne prend pas en charge les
                                    vidéos HTML5.
                                  </video>
                                ) : (
                                  <div
                                    className="position-relative cursor-pointer"
                                    onClick={() =>
                                      setShowVideo({ ...showVideo, vid2: true })
                                    }
                                  >
                                    {/*<img src={ publicationVideo.value[1].attachement[0].thumbsnailsFile} alt={"img"}*/}
                                    {/*     className= {classNames('d-block w-100 publicationImage')}*/}
                                    {/*/>*/}
                                    <div className="homeVideo__image"></div>
                                    <Button
                                      className="homeVideo__button1"
                                      onClick={() =>
                                        setShowVideo({
                                          ...showVideo,
                                          vid2: true,
                                        })
                                      }
                                    >
                                      <img src={vid02} className="imagePlay" />
                                    </Button>
                                    <div className="homeVideo__subtitle1">
                                      {publicationVideo.value[1].name}
                                    </div>
                                    <div className="homeVideo__categorie">
                                      {publicationVideo.value[1].categoryName}
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            {publicationVideo.value[2] && (
                              <div className="col-12">
                                {showVideo.vid3 ? (
                                  <video
                                    controls
                                    autoPlay={true}
                                    className="publicationImage"
                                  >
                                    <source
                                      src={
                                        publicationVideo.value[2].attachement[0]
                                          .attachmentFile
                                      }
                                      type="video/mp4"
                                    />
                                    Votre navigateur ne prend pas en charge les
                                    vidéos HTML5.
                                  </video>
                                ) : (
                                  <div
                                    className="position-relative cursor-pointer"
                                    onClick={() =>
                                      setShowVideo({ ...showVideo, vid3: true })
                                    }
                                  >
                                    <div className="homeVideo__image"></div>
                                    {/*<img src={ publicationVideo.value[2].attachement[0].thumbsnailsFile} alt={"img"}*/}
                                    {/*     className= {classNames('d-block w-100 publicationImage')}*/}
                                    {/*/>*/}
                                    <Button
                                      className="homeVideo__button1"
                                      onClick={() =>
                                        setShowVideo({
                                          ...showVideo,
                                          vid3: true,
                                        })
                                      }
                                    >
                                      <img src={vid02} className="imagePlay" />
                                    </Button>
                                    <div className="homeVideo__subtitle1">
                                      {publicationVideo.value[2].name}
                                    </div>
                                    <div className="homeVideo__categorie">
                                      {publicationVideo.value[2].categoryName}
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center">liste vide</div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/*<div className="homeAudio">
                <h1 className="homeAudio__title">Audio</h1>
                {audio.loading ? (
                    <div className="text-end">
                      <Spinner size={"3rem"} color={"orange"} />
                    </div>
                ) : (
                    <>
                      {audio.value.length > 0 ? (
                          <div className="row">
                            {audio.value.map((pub) => (
                                <div className="col-sm-6 col-md-4 mb-2">
                                  <CardMusique
                                      id={pub.id}
                                      name={pub.name}
                                      nameEn={pub.nameEn}
                                      mp3={pub.file}
                                      createAt={pub.createdAt}
                                  />
                                </div>
                            ))}
                          </div>
                      ) : (
                          <div className="text-center">liste vide</div>
                      )}
                    </>
                )}
              </div>*/}
            <div className="homeDocument">
              <div className="d-flex justify-content-between">
                <h1 className="homeDocument__title">{globalT("document")}</h1>

                <div>
                  <Button
                    color={"secondaire"}
                    onClick={() => navigate("/education/document")}
                    size="lg"
                  >
                    <span className="text-white">{globalT("voir")}</span>
                  </Button>
                </div>
              </div>
              {audio.loading ? (
                <div className="text-end">
                  <Spinner size={"3rem"} color={"orange"} />
                </div>
              ) : (
                <>
                  {document.value.length > 0 ? (
                    <div className="row">
                      {document.value.map((pub) => (
                        <div className="col-sm-6 col-md-4 mb-2">
                          <CardDocument
                            id={pub.id}
                            name={pub.name}
                            nameEn={pub.nameEn}
                            mp3={pub.file}
                            onClick={() => handleDocumentClick(pub.file)}
                            createAt={pub.createdAt}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center">liste vide</div>
                  )}
                </>
              )}
            </div>
          </div>
        </Page>
        <div className="partenaire">
          <div className="container w-100">
            <h1 className="partenaire__title">
              {globalT("landing.transPartenaire.infos.title")}
            </h1>
          </div>
          <div className="partenaire__content">
            <Draggable />
          </div>
        </div>
      </>
      <Footer />

      {showPdf && currentDoc && (
        <DialogComponent
          show={showPdf}
          size="lg"
          title=""
          onClose={() => setShowPdf(false)}
        >
          <EncryptedPdfViewer base64EncryptedPdf={currentDoc} />
        </DialogComponent>
      )}
    </>
  );
};

export default PatientHome;
