export default class Bot {

    public id: string;
    public question: string;
    public reponse: string;
    public questionEn: string;
    public reponseEn: string

    constructor(data: any) {
        this.id = data.id
        this.question = data.question;
        this.reponse = data.reponse
        this.questionEn = data.questionEn;
        this.reponseEn = data.reponseEn
    }
}