import * as FRONT from "./commons/urls/front";
import Permissions from './commons/permissions';
import { TIcons } from './type/icons-type';
import {
	ADMINISTRATION,
	PUBLICATION,
	CATEGORY,
	PARAGRAPH,
	DOCUMENTATION,
	EVENEMENT,
	INCIDENT,
	CAMPAGNE,
	BOOT,
	DISCUSION,
	ALERT, EDUCATION, MODERATEUR
} from "./commons/urls/front";
import Permission from "./commons/models/Permission";
import { globalT } from "./lang";

export type MenuItem = {
	id: string,
	text?: string,
	path?: string,
	icon?: TIcons,
	isDisable?: boolean,
	subMenu?: null | Record<string, MenuItem>,
	notification?: boolean | string,
	isMore?: boolean,
	hide?: boolean,
	some?: boolean,
	permissions?: Permissions[]
}

export const dashboardMenu: Record<
	'dashboard' , MenuItem> = {
	dashboard: {
		id: 'dashboard',
		text: 'dashboard',
		path: FRONT.DASHBOARD.INDEX,
		icon: 'Dashboard',
		subMenu: null,
	},
	// title: {
	// 	id: 'enrollment.pending.title',
	// 	text: 'enrollments',
	// 	icon: 'Extension',
	// 	some: true,
	// },
}

export const mainMenu: Record<'home'| 'contactUs' , MenuItem> = {
	home: {
		id: 'home',
		text: 'home',
		path: '/',
		icon: 'Home',
		subMenu: null,
	},

	contactUs: {
		id: 'contactUs',
		text: 'contactUs',
		path: '/contactUs',
		subMenu: null,
		icon: 'ArrowForward',
	}
}

export const publication: Record<'title' | 'publication' | 'cathegorie', MenuItem> = {
	title: {
		id: 'publication.management.title',
		text: 'publication.management',
		icon: 'Extension',
		some: true,
		permissions: [Permissions.dashboard.displaydashboard.publication.index],

	},

	publication: {
		id: 'publication',
		text: 'publication.management.title',
		path: PUBLICATION.PUBLICATION.INDEX,
		permissions: [Permissions.dashboard.displaydashboard.publication.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'publication.add',
				path: PUBLICATION.PUBLICATION.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.dashboard.displaydashboard.publication.list.create],
			},
			all: {
				id: 'listAll',
				text: 'publication.list.all',
				path: PUBLICATION.PUBLICATION.ALL,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.publication.list.index],
			},
			validate: {
				id: 'listValid',
				text: 'publication.list.valid',
				path: PUBLICATION.PUBLICATION.VALIDATE,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.publication.list.validate],
			},
			invalidate: {
				id: 'listInvalid',
				text: 'publication.list.invalid',
				path: PUBLICATION.PUBLICATION.INVALIDATE,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.publication.list.invalidate],
			},
			pending: {
				id: 'listPending',
				text: 'publication.list.pending',
				path: PUBLICATION.PUBLICATION.PENDING,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.publication.list.pending],
			}
		},
		icon: 'Post Add',
	},

	cathegorie: {
		id: 'cathegorie',
		text: 'category.management.title',
		path: '/cathegorie',
		icon: 'Category',
		permissions: [Permissions.dashboard.displaydashboard.publication.categorie.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'administration.user.add',
				path: PUBLICATION.CATHEGORIE.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.dashboard.displaydashboard.publication.categorie.list.create],
			},
			list: {
				id: 'list',
				text: 'administration.user.list',
				path: PUBLICATION.CATHEGORIE.INDEX,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.publication.categorie.list.view],
			}
		}

	}
	
}

export const category: Record<'title' | 'caterory', MenuItem> = {
	title: {
		id: 'category.management.title',
		text: 'category.management',
		icon: 'Extension',
		some: true,
	},

	caterory: {
		id: 'category',
		text: 'category.management.title',
		path: CATEGORY.INDEX,
		// permissions: [Permissions.dashboard.displaydashboard.categorie.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'category.add',
				path: CATEGORY.ADD,
				subMenu: null,
				icon: 'add_box',
				// permissions: [Permissions.dashboard.displaydashboard.categorie.list.create],

			},
			list: {
				id: 'list',
				text: 'category.list',
				path: CATEGORY.INDEX,
				subMenu: null,
				icon: 'ListAlt',
				// permissions: [Permissions.dashboard.displaydashboard.categorie.list.index],
			}
		},
		icon: 'Category',
	}
	
}

export const chat: Record<'title' | 'chat', MenuItem> = {
	title: {
		id: 'category.management.title',
		text: 'Chat',
		icon: 'Extension',
		some: true,
	},

	chat: {
		id: 'category',
		text: 'Chat',
		path: CATEGORY.INDEX,
		// permissions: [Permissions.dashboard.displaydashboard.categorie.index],
		subMenu: null,
		icon: 'chat',
	}

}



export const paragraph: Record<'title' | 'paragraph', MenuItem> = {
	title: {
		id: 'paragraph.management.title',
		text: 'paragraph.management',
		icon: 'Extension',
		some: true,
		// permissions: [Permissions.dashboard.displaydashboard.paragraphe.index],
	},

	paragraph: {
		id: 'paragraph',
		text: 'paragraph.management.title',
		path: PARAGRAPH.INDEX,
		subMenu: {
			add: {
				id: 'add',
				text: 'paragraph.add',
				path: PARAGRAPH.ADD,
				subMenu: null,
				icon: 'add_box',
				// permissions: [Permissions.dashboard.displaydashboard.paragraphe.list.create],
			},
			list: {
				id: 'list',
				text: 'paragraph.list',
				path: PARAGRAPH.INDEX,
				subMenu: null,
				icon: 'ListAlt',
				// permissions: [Permissions.dashboard.displaydashboard.paragraphe.list.index],
			}
		},
		icon: 'AlignHorizontalCenter',
	}

}

export const education: Record<'title' | 'education', MenuItem> = {
	title: {
		id: 'education.management.title',
		text: 'education.management',
		icon: 'Extension',
		some: true,
		permissions: [Permissions.dashboard.displaydashboard.document.index]
	},

	education: {
		id: 'education',
		text: 'education.management.title',
		path: EDUCATION.INDEX,
		permissions: [Permissions.dashboard.displaydashboard.document.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'education.add',
				path: EDUCATION.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.dashboard.displaydashboard.document.list.create]
			},
			list: {
				id: 'list',
				text: 'education.list',
				path: EDUCATION.INDEX,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.document.list.view]
			}
		},
		icon: 'CastForEducation',
	}

}

export const administration: Record<'title' |'role' | 'user' | 'usager', MenuItem> = {

	title: {
		id: 'administration.management.title',
		text: 'administration.management',
		icon: 'Extension',
		some: true,
		permissions: [Permissions.security.administration.index]
	},

	role: {
		id: 'role',
		text: 'administration.role.management.title',
		path: ADMINISTRATION.ROLE.INDEX,
		permissions: [Permissions.security.administration.role.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'administration.role.add',
				path: ADMINISTRATION.ROLE.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.security.administration.role.displayrolelist.add],
			},
			list: {
				id: 'list',
				text: 'administration.role.list',
				path: ADMINISTRATION.ROLE.INDEX,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.security.administration.role.displayrolelist.index],
			}
		},
		icon: 'security',
	},

	user: {
		id: 'user',
		text: 'administration.user.management.title',
		path: '/user',
		icon: 'PersonAddAlt1',
		permissions: [Permissions.security.administration.user.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'administration.user.add',
				path: ADMINISTRATION.USER.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.security.administration.user.displayuserlist.add],
			},
			list: {
				id: 'list',
				text: 'administration.user.list',
				path: ADMINISTRATION.USER.INDEX,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.security.administration.user.displayuserlist.index],
			},
		}

	},
	usager: {
		id: 'usager',
		text: 'administration.usager.management.title',
		path: ADMINISTRATION.USER.INDEX_,
		icon: 'PersonAddAlt1',
		permissions: [Permissions.security.administration.user.index],
		subMenu: null,
	}
}

export const document: Record<'title' |'ducument' | 'cathegorie', MenuItem> = {

	title: {
		id: 'document.management.title',
		text: 'document.management',
		icon: 'Extension',
		some: true,
		permissions: [Permissions.dashboard.displaydashboard.document.index]
	},

	ducument: {
		id: 'documentation',
		text: 'documentation.management.title',
		path: DOCUMENTATION.DOCUMENTATION.INDEX,
		permissions: [Permissions.dashboard.displaydashboard.document.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'administration.role.add',
				path: DOCUMENTATION.DOCUMENTATION.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.dashboard.displaydashboard.document.list.create],
			},
			list: {
				id: 'list',
				text: 'administration.role.list',
				path: DOCUMENTATION.DOCUMENTATION.INDEX,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.document.list.view],
			},
			
		},
		icon: 'Post Add',
	},

	cathegorie: {
		id: 'cathegorie',
		text: 'category.management.title',
		path: '/cathegorie',
		icon: 'Category',
		permissions: [Permissions.dashboard.displaydashboard.document.categorie.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'administration.user.add',
				path: DOCUMENTATION.CATHEGORIE.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.dashboard.displaydashboard.document.categorie.list.create],
			},
			list: {
				id: 'list',
				text: 'administration.user.list',
				path: DOCUMENTATION.CATHEGORIE.INDEX,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.document.categorie.list.view],
			}
		}

	}
}



export const evenement: Record<'title' | 'evenement', MenuItem> = {
	title: {
		id: 'evenement.management.title',
		text: 'evenement.management',
		icon: 'Extension',
		some: true,
		permissions: [Permissions.dashboard.displaydashboard.event.index],
	},

	evenement: {
		id: 'evenement',
		text: 'evenement.management.title',
		path: EVENEMENT.INDEX,
		permissions: [Permissions.dashboard.displaydashboard.event.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'evenement.add',
				path: EVENEMENT.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.dashboard.displaydashboard.event.list.create],
			},
			list: {
				id: 'list',
				text: 'evenement.list',
				path: EVENEMENT.INDEX,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.event.list.view],
			}
		},
		icon: 'Sensor Occupied',
	}

}

export const campagne: Record<'title' |'campagne' , MenuItem> = {

	title: {
		id: 'campagne.management.title',
		text: 'campagne.management',
		icon: 'Extension',
		some: true,
		permissions: [Permissions.dashboard.displaydashboard.campaign.index],
	},

	campagne: {
		id: 'campagne',
		text: 'campagne.management.title',
		path: CAMPAGNE.INDEX,
		permissions: [Permissions.dashboard.displaydashboard.campaign.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'administration.role.add',
				path: CAMPAGNE.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.dashboard.displaydashboard.campaign.list.create],
			},
			list: {
				id: 'list',
				text: 'administration.role.list',
				path: CAMPAGNE.INDEX,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.campaign.list.view],
			}
		},
		icon: 'campaign',
	},

}

export const chatbot: Record<'title' | 'chatbot' | 'cathegorie', MenuItem> = {
	title: {
		id: 'chatbot',
		text: 'chatbot',
		icon: 'chat',
		some: true,
		permissions: [Permissions.dashboard.displaydashboard.chatBot.index]
	},

	chatbot: {
		id: 'chatbot',
		text: 'chatbot',
		path: BOOT.BOOT.INDEX,
		permissions: [Permissions.dashboard.displaydashboard.chatBot.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'administration.role.user',
				path: BOOT.BOOT.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.dashboard.displaydashboard.chatBot.list.create],
			},
			list: {
				id: 'list',
				text: 'administration.role.list',
				path: BOOT.BOOT.INDEX,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.chatBot.list.view],
			},
			listQuestioUser: {
				id: 'listUser',
				text: 'bot.question.user.list',
				path: BOOT.BOOT.QuestioUser,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.chatBot.list.view],
			}
		},
		icon: 'chat',
	},
	cathegorie: {
		id: 'cathegorie',
		text: 'category.management.title',
		path: '/cathegorie',
		icon: 'Category',
		permissions: [Permissions.dashboard.displaydashboard.chatBot.categorie.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'administration.user.add',
				path: BOOT.CATHEGORIE.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.dashboard.displaydashboard.chatBot.categorie.list.create],
			},
			list: {
				id: 'list',
				text: 'administration.user.list',
				path: BOOT.CATHEGORIE.INDEX,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.chatBot.categorie.list.view],
			}
		}

	}

}

export const incident: Record<'title' |'incident' , MenuItem> = {

	title: {
		id: 'incident.management.title',
		text: 'incident.management',
		icon: 'Extension',
		some: true,
		permissions: [Permissions.dashboard.displaydashboard.incident.index],
	},

	incident: {
		id: 'role',
		text: 'incident.management.title',
		path: INCIDENT.INCIDENT.INDEX,
		permissions: [Permissions.dashboard.displaydashboard.incident.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'administration.role.add',
				path: INCIDENT.INCIDENT.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.dashboard.displaydashboard.incident.list.create],
			},
			all: {
				id: 'listAll',
				text: 'publication.list.all',
				path: INCIDENT.INCIDENT.ALL,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.incident.list.all],
			},
			validate: {
				id: 'listValid',
				text: 'publication.list.valid',
				path: INCIDENT.INCIDENT.VALIDATE,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.incident.list.validate],
			},
			invalidate: {
				id: 'listInvalid',
				text: 'publication.list.invalid',
				path: INCIDENT.INCIDENT.INVALIDATE,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.incident.list.invalidate],
			},
			pending: {
				id: 'listPending',
				text: 'publication.list.pending',
				path: INCIDENT.INCIDENT.PENDING,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.incident.list.pending],
			}
		},
		icon: 'ReportProblem',
	},

	
}


export const alert: Record<'title' |'alert'| 'cathegorie', MenuItem> = {
	title: {
		id: 'alert.management.title',
		text: 'alert.management',
		icon: 'Extension',
		some: true,
		permissions: [Permissions.dashboard.displaydashboard.alert.index]
	},


	alert: {
		id: 'alert',
		text: 'alert',
		path: ALERT.ALERT.INDEX,
		permissions: [Permissions.dashboard.displaydashboard.alert.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'administration.role.add',
				path: ALERT.ALERT.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.dashboard.displaydashboard.alert.list.create],
			},
			list: {
				id: 'list',
				text: 'administration.role.list',
				path: ALERT.ALERT.INDEX,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.alert.list.view],
			},
			
		},
		icon: 'Post Add',
	},

	cathegorie: {
		id: 'cathegorie',
		text: 'category.management.title',
		path: '/cathegorie',
		icon: 'Category',
		permissions: [Permissions.dashboard.displaydashboard.alert.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'administration.user.add',
				path: ALERT.CATHEGORIE.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.dashboard.displaydashboard.alert.list.create],
			},
			list: {
				id: 'list',
				text: 'administration.user.list',
				path: ALERT.CATHEGORIE.INDEX,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.alert.list.view],
			}
		}

	}
}

export const discusion: Record<'title' |'discussion' , MenuItem> = {

	title: {
		id: 'discussion.management.title',
		text: 'discussion.management',
		icon: 'Extension',
		some: true,
		permissions: [Permissions.dashboard.displaydashboard.discusion.index],
	},

	discussion: {
		id: 'role',
		text: 'discussion.management.title',
		path: DISCUSION.INDEX,
		permissions: [Permissions.dashboard.displaydashboard.discusion.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'administration.role.add',
				path: DISCUSION.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.dashboard.displaydashboard.discusion.list.create],
			},
			all: {
				id: 'listAll',
				text: 'administration.user.list',
				path: DISCUSION.INDEX,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.discusion.list.view],
			},
		},
		icon: 'discussion',
	},
	

}


export const moderateur: Record<'title' |'moderateur' , MenuItem> = {

	title: {
		id: 'moderateur.management.title',
		text: 'moderateur.management',
		icon: 'Extension',
		some: true,
		//permissions: [Permissions.dashboard.displaydashboard.discusion.index],
	},

	moderateur: {
		id: 'role',
		text: 'moderateur.management.title',
		path: MODERATEUR.MODERATEUR.INDEX,
		permissions: [Permissions.dashboard.displaydashboard.discusion.index],
		subMenu: {
			add: {
				id: 'add',
				text: 'administration.role.add',
				path: MODERATEUR.MODERATEUR.ADD,
				subMenu: null,
				icon: 'add_box',
				permissions: [Permissions.dashboard.displaydashboard.discusion.list.create],
			},
			all: {
				id: 'listAll',
				text: 'toute.liste',
				path: MODERATEUR.MODERATEUR.ALL,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.discusion.list.view],
			},

			active: {
				id: 'listValid',
				text: 'moderateur.list.actif',
				path: MODERATEUR.MODERATEUR.ACTIVER,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.discusion.list.view],
			},
			desactive: {
				id: 'listInvalid',
				text: 'moderateur.list.desactive',
				path: MODERATEUR.MODERATEUR.DESACTIVE,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.discusion.list.view],
			},
			suspendu: {
				id: 'listPending',
				text: 'moderateur.list.suspendue',
				path: MODERATEUR.MODERATEUR.SUSPENDRE,
				subMenu: null,
				icon: 'ListAlt',
				permissions: [Permissions.dashboard.displaydashboard.discusion.list.view],
			}


		},
		//icon: 'discussion',
	},
	

}

