
export default class UserData {
    public id: string;
    public firstName: string;
    public lastName: string;
    public phoneNumber: string;
    public email: string;
    public userName: string;
    public roleId: string;
    public roleName: string;
    public isActive: boolean;


    public constructor(data: any) {

        this.id = data.id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.phoneNumber = data.phoneNumber;
        this.email = data.email;
        this.userName = data.userName;
        this.roleId = data.roleId;
        this.roleName = data.roleName;
        this.isActive = data.isActive
    }
}