export const SET_REQUEST_GLOBAL_LOADER = 'SET_REQUEST_GLOBAL_LOADER';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_PARAMS = 'SET_PARAMS';
export const SET_TREE = 'SET_TREE';

export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_AUTH_USER_SUCCESS = 'SET_AUTH_USER_SUCCESS';
export const SET_AUTH_USER_FAILURE = 'SET_AUTH_USER_FAILURE';
export const CLEAR_AUTH_USER = 'CLEAR_AUTH_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const FILTER_PATIENTS_SEARCH = 'FILTER_PATIENTS_SEARCH';
export const ALERT_DATA = 'ALERT_DATA';
export const ALERT_LAST = 'ALERT_LAST';
export const CAT_ACTUALITE = 'CAT_ACTUALITE';
export const CAT_REVUE = 'CAT_REVUE';
export const BOT = 'Bot';
export const BOT_PHONE = 'BOT_PHONE';
export const BOT_MESSAGE = 'BOT_MESSAGE';
export const MESSAGE_PHONE = 'MESSAGE_PHONE';
export const RECENT_DATA = 'RECENT_DATA';
export const CAT_ALERT = 'CAT_ALERT';

export const VAR_BOOL = 'VAR_BOOL';
