import {combineReducers} from '@reduxjs/toolkit';

import authUser from './slices/authUser/reducer';
import settings from './slices/settings/reducer';
import requestGlobalLoader from './slices/requestGlobalLoader/reducer';
import filterPatients from './slices/filterPatients/reducer';
import params from './slices/globalParams/reducer'
import alert from './slices/alert/reducer'
import catActualite from './slices/catActualite/reducer'
import catRevue from './slices/catDocument/reducer'
import bot from './slices/bot/reducer'
import botPhone from './slices/botTelephone/reducer'
import botMessage from './slices/botMessage/reducer'
import messagePhone from './slices/MessagePhone/reducer'
import treeVisility from './slices/treeVisibility/reducer'
import recentData from './slices/recentData/reducer'
import variable from './slices/variable/reducer'
import catAlert from './slices/catAlert/reducer'
import alertLast from './slices/alertLast/reducer'

const rootReducer = combineReducers({
    authUser,
    settings,
    requestGlobalLoader,
    filterPatients,
    params,
    treeVisility,
    alert,
    catActualite,
    catRevue,
    bot,
    botPhone,
    messagePhone,
    botMessage,
    recentData,
    variable,
    catAlert,
    alertLast
});

export default rootReducer;
