import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import * as actions from "./actions";

export const INITIAL_STATE: { catAlert: any[] | null } = {
    catAlert: null,
};

const reducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(actions.catAlert, (state, action) => {
            state.catAlert = action.payload;
        })
});

export default reducer;
