import React, {useEffect, useRef, useState} from 'react';

const GlobalMessage = (props : any) => {

    const data = useRef<any[]>(props.payload)



    return (
        <div>
            custum of data
        </div>
    );
};

export default GlobalMessage;