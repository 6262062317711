import React, {useEffect, useState} from 'react';
import Dropdown, {DropdownItem, DropdownMenu, DropdownToggle} from "./Dropdown";
import Icon from "../icon/Icon";
import Button from "../bootstrap/Button";
import './RevueDocumentaire.scss';
import {useNavigate} from "react-router-dom";
import {joinUrlWithParamsId} from "../../commons/helpers/funcHelper";
import {useAppSelector} from "../../store/redux.types";
import pdfLogo from '../../assets/img/landing2/pdfLogo.png';
import { globalT } from '../../lang';
import vid02 from "../../assets/img/vid02.png";

interface CardAudio {
    id: string;
    name: string;
    onClick?: (file: string) => void; // Ajoutez ceci pour traiter le clic sur le bouton Voir
    file: string;
    hasAnotherAction?: boolean,
    type: 'word' | 'pdf' | 'excel' | 'presse'; // Spécifiez les types acceptés
    action?: { delete?: () => void, edit?: () => void, valider?: () => void, invalider?: () => void },
}

const CardAudio: React.FC<CardAudio> = ({ id, name, file, type,action, hasAnotherAction, onClick}) => {
    const navigate = useNavigate()

    const { authUserData } = useAppSelector(({ authUser, }) => ({
        authUserData: authUser.data
    }));


    return (
        <div className="card " id={id}>
            <div className=" d-flex flex-column">
                {authUserData && action && (
                    <Dropdown>
                        <DropdownToggle hasIcon={false}>
                            <Button>
                                <Icon icon='MoreVert' size='2x'  />
                            </Button>
                        </DropdownToggle>
                        <DropdownMenu>
                            {/*<DropdownItem>*/}
                            {/*    <button className="btn text-black" role="button" onClick={() => navigate(joinUrlWithParamsId('/actualite/:id', id as string)) }>Detail</button>*/}
                            {/*</DropdownItem>*/}
                            <DropdownItem>
                                <button onClick={action.edit} className="btn text-primary" role="button">{globalT("button.edit.pub")}</button>
                            </DropdownItem>
                            <DropdownItem>
                                <button onClick={action.delete} className="btn text-red" role="button">{globalT("button.delete.pub")}</button>
                            </DropdownItem>
                            {hasAnotherAction && (
                                <>
                                    <DropdownItem>
                                        <button onClick={action.valider} className="btn text-success" role="button"> {globalT("button.valid.pub")}</button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <button onClick={action.invalider} className="btn text-red" role="button"> {globalT("button.invalid.pub")}</button>
                                    </DropdownItem>
                                </>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                )}
                <div className="text-center">
                    <audio controls autoPlay={true} className="mt-5">
                        <source src={file} type="audio/mpeg"/>
                    </audio>
                    <h5 className="mt-4 fw-bold" style={{fontSize: "1.5rem"}}>{name}</h5>
                </div>

            </div>

        </div>
    );
}

export default CardAudio;
