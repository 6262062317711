import React, { useEffect, useState } from "react";
import Publication from "../../../commons/models/Publication";
import { useNavigate, useParams } from "react-router-dom";
import mintClient from "../../../network";
import Page from "../../../layout/Page/Page";
import ContentSkeletonLoader from "../../../components/loaders/ContentSkeletonLoader";
import classNames from "classnames";
import Card, {
  CardBody,
  CardHeader,
  CardTitle,
} from "../../../components/bootstrap/Card";
import { globalT } from "../../../lang";
import Carousel from "../../../components/bootstrap/Carousel";
import Navnav from "../../Navnav/Navnav";
import Footfoot from "../../Navnav/Footfoot";
import Incident from "../../../commons/models/Incident";
import "../revue/revu.scss";

const DetailIncident = () => {
  const [publication, setPublication] = useState<Incident | null>(null);
  const [attachment, setAttachment] = useState<object[] | null>();
  const publicationId = useParams().id;

  const loadPublication = () => {
    if (!publicationId) return;

    mintClient.incident
      .getOne(publicationId as string)
      .then((resp) => {
        const data = resp.data.data;
        const itemCarousel = data.attachement.map((attachment) => ({
          src: attachment.attachmentFile,
          type: attachment.attachmentTypeName,
          isDetail: true,
        }));
        setAttachment(itemCarousel);
        setPublication(data);
      })
      .catch((error) => null);
  };

  useEffect(() => {
    loadPublication();
  }, []);

  return (
    <>
      <Navnav />
      <Page>
        {publication ? (
          <div className="mb-3 detailIncident">
            <div className=" pb-3 d-flex align-items-center">
              <span className="display-4 fw-bold me-3 align-items-start">
                {publication.name}
              </span>
            </div>

            <div className="row pb-4">
              {attachment && (
                <Page>
                  <div className="col-10 align-items-center-center ps-3 pb-3 ">
                    <Carousel items={attachment} isControl={true} />
                  </div>
                </Page>
              )}
              <div className="des mt-4">
                <Card>
                  <CardBody>{publication.description}</CardBody>
                </Card>
              </div>
            </div>

            {/*<div className="">*/}

            {/*    <div className="">*/}
            {/*        {paragraph.map(para => (*/}
            {/*            <>*/}
            {/*                <div className='row mt-4'>*/}
            {/*                    {para.images.length > 0 && (*/}
            {/*                        <div className="col-5 mt-2">*/}
            {/*                            <Carousel*/}
            {/*                                items={para.images.map(img => ({src: img, type: 'IMAGE', isDetail: true}))}*/}
            {/*                            />*/}
            {/*                        </div>*/}
            {/*                    )}*/}
            {/*                    <div className="col-7">*/}
            {/*                        <span className=' fw-bold me-3 ms-3 display-6' >{para.name}</span>*/}
            {/*                        <div className='trait'/>*/}
            {/*                        <Card>*/}
            {/*                            <CardBody>*/}
            {/*                                {para.description}*/}
            {/*                            </CardBody>*/}
            {/*                        </Card>*/}
            {/*                        /!*<div className="mt-3">*!/*/}
            {/*                        /!*    <span>{para.description}</span>*!/*/}
            {/*                        /!*</div>*!/*/}

            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </>*/}

            {/*        ))}*/}
            {/*    </div>*/}
            {/*</div>*/}
          </div>
        ) : (
          <ContentSkeletonLoader />
        )}
      </Page>
      <Footfoot />
    </>
  );
};

export default DetailIncident;
