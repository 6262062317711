import {Enumify} from "./Enumify";

export class ModerateurStatus extends Enumify {
    static  all = new ModerateurStatus(4)
    static activate = new ModerateurStatus(2)
    static desactivate = new ModerateurStatus(1)
    static suspendu = new ModerateurStatus(3)

    constructor(value: number) {
        super();
        this.value = value
    }
}