import React, { useEffect, useState } from "react";
import mintClient from "../../../network";
import PublicationComponent from "../../../components/publication/PublicationComponent";
import Publication from "../../../commons/models/Publication";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Spinner from "../../../components/bootstrap/Spinner";
import { WrapperLayout } from "../../../layout/Wrapper/Wrapper";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import apple from "../../../assets/img/landing2/apple.png";
import google from "../../../assets/img/landing2/google.png";
import placeHold from "../../../assets/img/landing2/placeHold.png";
import Skeleton from "react-loading-skeleton";
import LinearIndeterminate from "../../../components/loaders/Linear";
import { AUTH } from "../../../commons/urls/front";
import { useAppDispatch, useAppSelector } from "../../../store/redux.types";
import { getJwtData } from "../../../commons/helpers/jwtHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setRequestGlobalLoader } from "../../../store/slices/requestGlobalLoader/actions";
import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../../components/bootstrap/Dropdown";
import Cathegorie from "../../../commons/models/Category";
import { joinUrlWithParamsId } from "../../../commons/helpers/funcHelper";
import AceuillNav from "../../acceuill/AceuillNav";
import Footfoot from "../../Navnav/Footfoot";
import Navnav from "../../Navnav/Navnav";
import CardImg from "../../../components/bootstrap/CardImg";
import CardImgNoBorder from "../../../components/CardImgNoBorder";
import RecentPublicationComponent from "../../../components/publication/RecentPublicationComponent";
import SearchComponent from "../../../components/SearchComponent";
import { globalT } from "../../../lang";
import DataImage from "../../../assets/img/landing2/vide.webp";
import PaginationComponent from "../../../components/PaginationComponent";
import CardVid from "../../../components/bootstrap/CardVid";

const VideoCategorie = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const categorieName = location.state?.categorie || "";
  const [filteredPublicationsPage, setFilteredPublicationsPage] =
    useState<number>(1);
  const [publication, setPublication] = useState<Publication[] | null>(null);
  const [filteredPublications, setFilteredPublications] = useState<
    Publication[] | null
  >(null);
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const { settings, authUserData } = useAppSelector(
    ({ settings, authUser }) => ({
      settings,
      authUserData: authUser.data,
    })
  );
  const handleFilteredPublicationsPageChange = (page: number) => {
    setFilteredPublicationsPage(page);
  };
  const jwtData = getJwtData();
  const { catActualite } = useAppSelector(({ catActualite }) => catActualite);
  const categorieId = useParams().id;

  const loadPublication = () => {
    // dispatch(setRequestGlobalLoader(true))
    setLoading(true);
    mintClient.publication
      .getAllVideo({
        page: currentPage,
        pageSize: 10,
        orderDirection: "ascending",
        validationState: 1,
        order: true,
        researchValue: searchValue,
        hasPagination: true,
        langue: "fr",
        categoryId: categorieId,
      })
      .then((resp) => {
        const allPublications = resp.data.data;
        setPublication(allPublications);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    let filtered: Publication[] | null = null;
    if (publication) {
      filtered = publication.filter((pub) =>
        pub.name.toLowerCase().includes(value.toLowerCase())
      );
    }
    setFilteredPublications(filtered);
  };

  const loadData = async () => {
    if (!publication) await loadPublication();
  };
  useEffect(() => {
    let filtered: Publication[] | null = null;
    if (publication) {
      filtered = publication.slice(
        (filteredPublicationsPage - 1) * 10,
        filteredPublicationsPage * 10
      );
    }
    setFilteredPublications(filtered);
  }, [publication, filteredPublicationsPage]);

  useEffect(() => {
    let filtered: Publication[] | null = null;
    if (publication) {
      filtered = publication.filter((pub) =>
        pub.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    setFilteredPublications(filtered);
  }, [publication, searchValue]);

  useEffect(() => {
    loadPublication();
  }, [currentPage, categorieId]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Navnav />
      {/* <div className="POI pb-5 d-flex align-items-center">
                    <span className='display-4 fw-bold me-3'>{`Liste de toutes les publications: ${categorieName}`}</span>
                </div> */}
      {publication ? (
        <div className="container " style={{ marginTop: "150px" }}>
          <div className="d-flex">
            <h1 className="mb-4" style={{ fontWeight: "bold" }}>
              Video:
            </h1>
            <span className="IO fs-5 fw-bold">{` ${categorieName}`}</span>
          </div>

          <div className="row">
            <div className="col-sm-8 mb-4">
              {publication && publication[0] && (
                <CardImgNoBorder
                  title={publication[0].name}
                  titleEn={publication[0].nameEn}
                  images={publication[0].attachement[0].attachmentFile || ""}
                  date={publication[0].createdAt}
                  id={publication[0].id}
                  type="video"
                  // newLabel="New"
                  // description={publication[0].categoryName}
                  views={120}
                  comments={30}
                />
              )}
            </div>
            <div className="col-sm-3 ml-auto " style={{ marginLeft: "auto" }}>
              <h2
                className="text-danger text-uppercase fw-bold mb-4"
                style={{ fontSize: "1.5rem" }}
              >
                Video recente
              </h2>
              <div
                style={{
                  height: "3px",
                  background:
                    "linear-gradient(to left , yellow 80%, green 20%)",
                }}
                className="mb-4"
              ></div>
              <div className="d-flex flex-column align-items-start mb-4">
                {publication && publication[0] && (
                  <div className="mb-4">
                    <RecentPublicationComponent
                      title={publication[0].name}
                      titleEn={publication[0].nameEn}
                      image={
                        publication[0]?.attachement[0].attachmentFile || ""
                      }
                      id={publication[0].id}
                      views={120}
                      comments={30}
                      type="video"
                      // description={publication[0].categoryName}
                    />
                  </div>
                )}
                {publication && publication[1] && (
                  <div className="mb-4">
                    <RecentPublicationComponent
                      title={publication[1].name}
                      titleEn={publication[1].nameEn}
                      image={
                        publication[1]?.attachement[0]?.attachmentFile || ""
                      }
                      id={publication[1].id}
                      views={120}
                      type="video"
                      comments={30}
                      // description={publication[1].categoryName}
                    />
                  </div>
                )}
                {publication && publication[2] && (
                  <div className="mb-4">
                    <RecentPublicationComponent
                      title={publication[2].name}
                      titleEn={publication[2].nameEn}
                      image={
                        publication[2]?.attachement[0]?.attachmentFile || ""
                      }
                      id={publication[2].id}
                      views={120}
                      type="video"
                      comments={30}
                      // description={publication[3].categoryName}
                    />
                  </div>
                )}
              </div>

              <h2
                className="text-danger text-uppercase fw-bold mb-4"
                style={{ fontSize: "1.5rem" }}
              >
                {globalT("landing.category.title")}
              </h2>
              <div
                style={{
                  height: "3px",
                  background:
                    "linear-gradient(to left , yellow 80%, green 20%)",
                }}
                className="mb-4"
              ></div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "200px" }}
        >
          <div
            className="spinner-border"
            style={{ height: "6rem", width: "6rem", color: "#aae0aa" }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <section className="container pt-5 composant3">
        <h1 style={{ fontWeight: "bold" }}>{globalT("video.list")}</h1>
        <br></br>
        <div className="text-end">
          {loading && (
            <>
              <Spinner customColor="#aae0aa" size="6rem" />
            </>
          )}{" "}
          <br />
          <SearchComponent onSearch={handleSearch} />
        </div>
        <div className="row py-4">
          {filteredPublications && filteredPublications.length > 0 ? (
            <>
              {filteredPublications.map((pub, index) => (
                <div className="col-lg-4 col-md-6 mb-4" id={`no${index}`}>
                  <CardVid
                    title={pub.name}
                    titleEn={pub.nameEn}
                    images={pub?.attachement}
                    id={pub.id}
                    date={pub.createdAt}
                    action={{
                      detail: () =>
                        navigate(joinUrlWithParamsId("/video/:id", pub.id)),
                    }}
                  />
                </div>
              ))}
            </>
          ) : (
            <div className="text-center mt-5 mb-5">
              <div
                style={{
                  width: "400px", // or any size you want
                  height: "400px", // make it a square
                  backgroundImage: `url(${DataImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  margin: "0 auto", // this will center the div if the parent div has a text-align: center
                }}
              />
              <span
                className="fw-bold"
                style={{
                  display: "block",
                  marginTop: "20px",
                  fontSize: "24px",
                }}
              >
                {globalT("no.vidoe.available")}
              </span>
            </div>
          )}
        </div>
        <PaginationComponent
          pages={Math.ceil((publication?.length || 0) / 10)}
          currentPage={filteredPublicationsPage}
          onPageChange={handleFilteredPublicationsPageChange}
          rangeLimit={5}
        />
      </section>
      <Footfoot />
    </>
  );
};

export default VideoCategorie;
