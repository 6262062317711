import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import mintClient from "../../../network";
import Page from "../../../layout/Page/Page";
import DataImage from "../../../assets/img/landing2/vide.webp";
import { useAppDispatch, useAppSelector } from "../../../store/redux.types";
import ContentSkeletonLoader from "../../../components/loaders/ContentSkeletonLoader";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Carousel from "../../../components/bootstrap/Carousel";
import { Campagne } from "../../../commons/models/Campagne";
import { setRequestGlobalLoader } from "../../../store/slices/requestGlobalLoader/actions";
import Navnav from "../../Navnav/Navnav";
import Footfoot from "../../Navnav/Footfoot";
import dayjs from "dayjs";
import { globalT } from "../../../lang";
// import styled from 'styled-components';
import { BsCalendar, BsFlag } from "react-icons/bs"; // Importer des icônes

// Ajouter des styles CSS pour le composant Description    maxHeight: '200px',

// const ScrollableDiv = styled.div`
//     height: 200px;  // Ajustez la hauteur en fonction de vos besoins
//     overflow: auto;
// `;

const CampagneDetail = () => {
  const [campagne, setCampagne] = useState<Campagne | null>(null);
  const [attachment, setAttachment] = useState<object[] | null>();
  const dispatch = useAppDispatch();
  const campagneId = useParams().id;
  const loadCampagne = () => {
    if (!campagneId) return;
    // dispatch(setRequestGlobalLoader(true))
    mintClient.campagne
      .getOne(campagneId as string)
      .then((resp) => {
        const allData = resp.data.data;
        const itemCarousel = allData.attachement.map((attachment: any) => ({
          src: attachment.attachmentFile,
          type: attachment.attachmentTypeName,
          isDetail: true,
        }));
        setAttachment(itemCarousel);
        setCampagne(allData);
      })
      .catch((error) => null)
      .finally(() => dispatch(setRequestGlobalLoader(false)));
  };

  useEffect(() => {
    loadCampagne();
  }, []);

  return (
    <>
      <Navnav />
      <div className="container">
        {campagne ? (
          <div className="mb-3" style={{ marginTop: "150px" }}>
            <div className="pt-2 pb-3 d-flex flex-column align-items-start">
              <h1 className="display-4 fw-bold me-3">{campagne.name}</h1>
            </div>
            <div style={{ marginBottom: "30px", marginLeft: "20px" }}>
              <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                <span>
                  <BsFlag color="red" />
                </span>
                <span> Objectif de la campagne : {campagne.goal}</span>
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                <span>
                  <BsCalendar color="blue" />
                </span>
                <span>
                  {" "}
                  Campagne mise à jour le :{" "}
                  {dayjs(campagne.endDate).format("DD/MM/YYYY")}
                </span>
              </p>
            </div>
            <div className="row pb-4">
              {attachment && (
                <div>
                  <div className="col-10 align-items-center-center ps-3 pb-3 ">
                    <Carousel items={attachment} isControl={true} />
                  </div>
                </div>
              )}
              <div
                style={{ marginLeft: "-8%" }}
                className="d-flex justify-content-center"
              >
                <div
                  style={{ fontWeight: "bold", borderRadius: "8px" }}
                  className=" top-5 end-5 bg-danger text-warning p-2"
                >
                  Début : {dayjs(campagne.startDate).format("DD/MM/YYYY")}
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    borderRadius: "8px",
                    marginLeft: "20px",
                  }}
                  className=" top-5 end-5 bg-danger text-warning p-2"
                >
                  Fin : {dayjs(campagne.endDate).format("DD/MM/YYYY")}
                </div>
              </div>

              <div className="des mt-4">
                <Card
                  style={{
                    marginLeft: "10px",
                    width: "120%",
                    marginTop: "20px",
                  }}
                >
                  <CardBody
                    style={{
                      height: "300px",
                      overflow: "auto",
                      fontSize: "15px",
                    }}
                  >
                    {campagne.description}
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        ) : (
          <div className="imageContainer" style={{ marginTop: "100px" }}>
            <div className="text-center mt-5 mb-5">
              <div
                style={{
                  width: "400px", // or any size you want
                  height: "400px", // make it a square
                  backgroundImage: `url(${DataImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  margin: "0 auto", // this will center the div if the parent div has a text-align: center
                }}
                className="image"
              />
              <div className="d-flex justify-content-center spinnerContainer">
                <div
                  className="spinner-border"
                  style={{
                    height: "6rem",
                    width: "6rem",
                    color: "#aae0aa",
                    marginTop: "85px",
                    marginRight: "15px",
                  }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
              <span
                className="fw-bold"
                style={{
                  marginTop: "20px",
                  fontSize: "24px",
                }}
              >
                {globalT("empty.event")}
              </span>
            </div>
          </div>
        )}
      </div>
      <Footfoot />
    </>
  );
};
export default CampagneDetail;
