import React, { useState } from "react";
import { Evenements } from "../../commons/models/Evenements";
import { Campagne } from "../../commons/models/Campagne";
import Card, { CardBody, CardHeader } from "../../components/bootstrap/Card";
import dayjs from "dayjs";
import "./Event.scss";
import { globalT } from "../../lang";
import DialogComponent from "../../components/dialogs/DialogComponent";
import Button from "../../components/bootstrap/Button";
import LANG from "../../lang/language";
import {useAppSelector} from "../../store/redux.types";

type propsEvent = {
    data: Evenements | Campagne;
    type: "campagne" | "evenement";
};

const EventComponent = ({ data, type }: propsEvent) => {
    const { settings, authUserData } = useAppSelector(({ settings, authUser, }) => ({
        settings,
        authUserData: authUser.data
    }));
    return (
        <div className="ms-2">
            <p className="fw-bold">{ settings.language === LANG.fr.locale ? data?.name : data?.nameEn}</p>
            <p>
                {" "}
                <span className="fw-bold">{globalT("form.field.endate")}:</span>{" "}
                {dayjs(data?.startDate).format("ll")}{" "}
            </p>
            <p>
                {" "}
                <span className="fw-bold">{globalT("form.field.startdate")}:</span>{" "}
                {dayjs(data?.endDate).format("ll")}{" "}
            </p>
            {type === "evenement" ? (
                <p>
                    {" "}
                    <span className="fw-bold">{globalT("form.field.type")}:</span>{" "}
                    {(data as Evenements)?.type === 1 ? "Séminaire" : "Atélier"}{" "}
                </p>
            ) : (
                <div>
                    <p>
                        {" "}
                        <span className="fw-bold">{globalT("form.field.goal")}:</span> {(data as Campagne)?.goal}{" "}
                    </p>
                    <p>
                        {" "}
                        <span className="fw-bold">{globalT("form.field.place")}:</span> { (data as Campagne)?.place}{" "}
                    </p>
                </div>
            )}

            <p>
                {" "}
                <span className="fw-bold">description:</span> { settings.language === LANG.fr.locale ? data?.description : data?.descriptionEn ? data?.descriptionEn : data?.description}{" "}
            </p>
        </div>
    );
};

export default EventComponent;
