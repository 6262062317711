import React from 'react';
import classNames from 'classnames'; // Ne pas oublier d'importer classNames
import { globalT } from '../../lang';
import {useAppSelector} from "../../store/redux.types";
import LANG from "../../lang/language";

interface NewsCardProps {
    image: string, // Ici, j'assume que l'image est une chaîne en base64
    title: string;
    titleEn: string;
    description?: string;
    type?: string
    views?: number;
    id?: string;
    comments?: number;
    isDetail?: boolean; // Si vous avez besoin de cette propriété, vous devez l'ajouter ici
}

const RecentPublicationComponent: React.FC<NewsCardProps> = ({type='image',image,titleEn, title, description, id , views, comments, isDetail}) => {

    const { settings, authUserData } = useAppSelector(({ settings, authUser, }) => ({
        settings,
        authUserData: authUser.data
    }));
    return (
        <div className="d-flex align-items-center mb-4" id={id}>
            <div style={{height: '60px', width: '80px', marginRight: '15px', objectFit: 'cover'}} >
                {type === 'image' ? (
                    <img src={image} alt={title}
                         style={{
                             borderRadius:"10px",
                             width: '100%',
                             height: '100%',
                             objectFit: 'cover',
                         }}
                    />
                ) : (
                    <video controls style={{
                        borderRadius:"10px",
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                    >
                        <source src={image} type="video/mp4" />
                        Votre navigateur ne prend pas en charge les vidéos HTML5.
                    </video>
                )}

            </div>
           
            <div>
                <h5 style={{fontSize: '13px'}}>{settings.language === LANG.fr.locale ? title : titleEn ? titleEn : title}</h5>
                <p className="card-text">
                    {/*<i className="fas fa-eye"></i> {views} {globalT("vues")} | */}
                    {/*<i className="fas fa-comments"></i> {comments}*/}
                </p>
            </div>
        </div>
    );
};

export default RecentPublicationComponent;
