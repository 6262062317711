import React, { useEffect, useState } from "react";
import { WrapperLayout } from "../../layout/Wrapper/Wrapper";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import google from "../../assets/img/landing2/google.png";
import placeHold from "../../assets/img/landing2/placeHold.png";
import apple from "../../assets/img/landing2/apple.png";

import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/redux.types";
import { getJwtData } from "../../commons/helpers/jwtHelper";
import Publication from "../../commons/models/Publication";
import Cathegorie from "../../commons/models/Category";
import { setRequestGlobalLoader } from "../../store/slices/requestGlobalLoader/actions";
import mintClient from "../../network";
import PublicationComponent from "../../components/publication/PublicationComponent";
import Spinner from "../../components/bootstrap/Spinner";
import { AUTH } from "../../commons/urls/front";
import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../components/bootstrap/Dropdown";
import { joinUrlWithParamsId } from "../../commons/helpers/funcHelper";
import DialogComponent from "../../components/dialogs/DialogComponent";
import FrontAdd from "./incident/FrontAdd";
import AceuillNav from "../acceuill/AceuillNav";
import Incident_ from "../../commons/models/Incident";
import { globalT } from "../../lang";

const Incident = () => {
  const navigate = useNavigate();
  const [publication, setPublication] = useState<Incident_[] | null>(null);
  const dispatch = useAppDispatch();
  const { authUserData } = useAppSelector(({ authUser }) => ({
    authUserData: authUser.data,
  }));
  const [hasCategorie, setHasCathegorie] = useState(false);
  const [cathegorie, setCathegorie] = useState<Cathegorie[] | null>(null);
  const jwtData = getJwtData();
  const [show, setShow] = useState<boolean>(false);

  const loadCathegorie = () => {
    dispatch(setRequestGlobalLoader(true));
    mintClient.incidencCategory
      .getAll()
      .then((resp) => {
        setCathegorie(resp.data.data);
        setHasCathegorie(true);
      })
      .catch((error) => console.log(error));
    // .finally(() => dispatch(setRequestGlobalLoader(false)))
  };

  const loadPublication = () => {
    dispatch(setRequestGlobalLoader(true));
    mintClient.incident
      .getAll({
        page: 1,
        pageSize: 10,
        orderDirection: "ascending",
        validationState: 1,
      })
      .then((resp) => {
        setPublication(resp.data.data);
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(setRequestGlobalLoader(false)));
  };

  const loadData = async () => {
    if (!cathegorie) await loadCathegorie();
    if (!publication) await loadPublication();
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <WrapperLayout>
        <PageWrapper>
          <AceuillNav />

          <div className="container animate__animated animate__bounceIn">
            <div className="row">
              <div className="col-md-8">
                {publication && publication[0] && (
                  <PublicationComponent
                    name={publication[0].name}
                    images={publication[0].attachement}
                    dateCreation={publication[0].createdAt}
                    id={publication[0].id}
                  />
                )}
              </div>

              <div className=" col-md-4 ">
                <div className="fw-bold">Incident recent</div>
                <div className="trait"></div>
                <div className="fw-bold mt-4">
                  Sécurité routière: nouvelle campagne spéciale de trois mois de
                  sensibilisation
                </div>
                <div className="text-light">8:00</div>

                <div className="fw-bold mt-4">
                  Sécurité routière: nouvelle campagne spéciale de trois mois de
                  sensibilisation
                </div>
                <div className="text-light">8:00</div>

                <div className="fw-bold mt-4">
                  Sécurité routière: nouvelle campagne spéciale de trois mois de
                  sensibilisation
                </div>
                <div className="text-light mb-5">8:00</div>
                {/*<button type="button"*/}
                {/*        className="btn btn-outline-success btn-lg my-4"*/}
                {/*        onClick={() => navigate('/actualite/categorie/:id')}*/}
                {/*>*/}
                {/*    Voir tout</button>*/}
              </div>
            </div>
          </div>

          <>
            <div className="container my-4">
              <div className="d-flex justify-content-between">
                <div className="fw-bold fs-2">Incident les plus récents</div>
                <div className="col-md-3">
                  <button
                    type="button"
                    onClick={() => setShow(true)}
                    className="btn btn-outline-danger "
                  >
                    glo
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              {publication && publication.length > 0 ? (
                <>
                  {publication?.map((pub, index) => (
                    <div className="col-md-3 " id={`no${index}`}>
                      <PublicationComponent
                        name={pub.name}
                        images={pub.attachement}
                        dateCreation={pub.createdAt}
                        id={pub.id}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <div className="text-center mt-5 mb-5">
                  <span className="fw-bold">Aucun Incident</span>
                </div>
              )}
            </div>
          </>

          {/*<div className='container'>*/}
          {/*    <div className='row'>*/}
          {/*        <div className='col-sm-3'>*/}
          {/*            <CartBoot*/}
          {/*                imageTrap={actual}*/}

          {/*                titre="SECURITE"*/}
          {/*                text=" Sécurité routière: nouvelle campagne spéciale de trois mois de sensibilisation "*/}
          {/*            />*/}
          {/*        </div>*/}

          {/*        <div className='col-sm-3'>*/}
          {/*            <CartBoot*/}
          {/*                imageTrap={actual}*/}

          {/*                titre="SECURITE"*/}
          {/*                text=" Sécurité routière: nouvelle campagne spéciale de trois mois de sensibilisation "*/}
          {/*            />*/}
          {/*        </div>*/}

          {/*        <div className='col-sm-3'>*/}
          {/*            <CartBoot*/}
          {/*                imageTrap={actual}*/}

          {/*                titre="SECURITE"*/}
          {/*                text=" Sécurité routière: nouvelle campagne spéciale de trois mois de sensibilisation "*/}
          {/*            />*/}
          {/*        </div>*/}

          {/*        <div className='col-sm-3'>*/}
          {/*            <CartBoot*/}
          {/*                imageTrap={actual}*/}

          {/*                titre="SECURITE"*/}
          {/*                text=" Sécurité routière: nouvelle campagne spéciale de trois mois de sensibilisation "*/}
          {/*            />*/}
          {/*        </div>*/}

          {/*    </div>*/}

          {/*</div>*/}

          <div className="container-fluid overflow-scroll">
            <div className="row">
              <div className="col-sm-12 vert">
                <div className=" text-white fw-bold mx-4 rest">
                  Restez connecté à la plateforme grace à notre application
                  mobile
                </div>

                <div className="fit">
                  <img
                    src={apple}
                    alt="google play"
                    className="img-fluid apple m-4"
                  />
                  <img
                    src={google}
                    alt="google play"
                    className="img-fluid google m-4"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-sm-8 mt-4">
                <img
                  src={placeHold}
                  alt="google play"
                  className="img-fluid placeHold m-4"
                />
                <div className="d-flex">
                  <a className="btn text-black m-1" href="/#" role="button">
                    Accueils
                  </a>
                  <a className="btn text-black  m-1" href="/#" role="button">
                    A propos
                  </a>
                  <a className="btn text-black  m-1" href="/#" role="button">
                    News
                  </a>
                  <a className="btn text-black  m-1" href="/#" role="button">
                    Devenir pro
                  </a>
                  <a className="btn text-black  m-1" href="/#" role="button">
                    FAQs
                  </a>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="letter fw-bold">
                  Connectez-vous à notre news letter
                </div>

                <div className="form-floating mt-4 d-flex">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInputGrid"
                    placeholder="name@example.com"
                  />
                  <label htmlFor="floatingInputGrid">Email address</label>
                  <button
                    type="button"
                    className="btn btn-success text-white btn-sm ml-1"
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row lign"></div>
          </div>

          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-sm-9 reserv">
                © 2023 APSR. {globalT("tous.droit")}.
              </div>

              <div className="col-sm-3">
                <div className="letter fw-bold">
                  <a className="btn text-black  m-1" href="/#" role="button">
                    Terms
                  </a>
                  <a className="btn text-black  m-1" href="/#" role="button">
                    Privacy
                  </a>
                  <a className="btn text-black  m-1" href="/#" role="button">
                    Cookies
                  </a>
                </div>
              </div>
            </div>
          </div>
        </PageWrapper>
      </WrapperLayout>
    </>
  );
};

export default Incident;
