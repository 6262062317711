import {createReducer} from "@reduxjs/toolkit";
import * as actions from "./actions";
import {recentData} from "../../redux.types";

export const INITIAL_STATE: { recentData: recentData } = {
    recentData: {
        imagePub: {
            data: null,
            hasChange: false
        },
        videoPub: {
            data: null,
            hasChange: false
        },
        campagne: {
            data: null,
            hasChange: false
        },
        evenement: {
            data: null,
            hasChange: false
        }
    }
}

const reducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(actions.recentDatas, (state, action) => {
            state.recentData = action.payload;
        })
});

export default reducer;
