import React, { useEffect, useRef, useState } from "react";
import CardImg from "../../../components/bootstrap/CardImg";
import DataImage from "../../../assets/img/landing2/vide.webp";
import { NavLink, useNavigate } from "react-router-dom";
import { globalT } from "../../../lang";
import { useAppDispatch, useAppSelector } from "../../../store/redux.types";
import { setRequestGlobalLoader } from "../../../store/slices/requestGlobalLoader/actions";
import mintClient from "../../../network";
import Communicator from "../../../components/Communicator";
import { joinUrlWithParamsId } from "../../../commons/helpers/funcHelper";
import "../../Actualite-recente/Actualite.scss";
import Spinner from "../../../components/bootstrap/Spinner";
import SearchComponent from "../../../components/SearchComponent";
import PaginationComponent from "../../../components/PaginationComponent";
import RecentPublicationComponent from "../../../components/publication/RecentPublicationComponent";
import CardImgNoBorder from "../../../components/CardImgNoBorder";
import Navnav from "../../Navnav/Navnav";
import Footfoot from "../../Navnav/Footfoot";
import { Campagne } from "../../../commons/models/Campagne";

const CampagneHome = () => {
  const navigate = useNavigate();
  const [campagne, setCampagne] = useState<Campagne[] | null>(null);
  const [campagneHeader, setCampagneHeader] = useState<Campagne[] | null>(null);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const total = useRef<number>();
  const { settings, authUserData } = useAppSelector(
    ({ settings, authUser }) => ({
      settings,
      authUserData: authUser.data,
    })
  );

  const { catActualite } = useAppSelector(({ catActualite }) => catActualite);

  const loadCampagneHeader = () => {
    // dispatch(setRequestGlobalLoader(true))
    mintClient.campagne
      .getAll({
        page: page,
        pageSize: 10,
        orderDirection: "ascending",
        validationState: 1,
        researchValue: search,
        hasPagination: true,
        langue: "fr",
      })
      .then((resp) => {
        const allCampagne = resp.data.data;
        setCampagneHeader(allCampagne);
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(setRequestGlobalLoader(false)));
  };
  const loadCampagne = () => {
    setLoading(true);
    mintClient.campagne
      .getAll({
        page: page,
        pageSize: 10,
        orderDirection: "ascending",
        validationState: 1,
        researchValue: search,
        hasPagination: true,
        langue: "fr",
      })
      .then((resp) => {
        const allCampagne = resp.data.data;
        total.current = resp.data.total;
        setCampagne(allCampagne);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  const handleSearch = (e: any) => {
    setLoading(true);
    setSearch(e.target.value);
  };

  const loadData = async () => {
    if (!campagne) await loadCampagneHeader();
  };
  useEffect(() => {
    loadCampagneHeader();
  }, []);
  useEffect(() => {
    loadCampagne();
  }, [search, page]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Navnav />
      {campagneHeader ? (
        <div className="container">
          <div className="ContainerActualite" style={{ marginTop: "150px" }}>
            <h1 className="mb-4" style={{ fontWeight: "bold" }}>
              {globalT("landing.campagne.title")}
            </h1>
            <div className="row">
              <div className="col-sm-8 mb-4">
                {campagneHeader && campagneHeader[0] ? (
                  <CardImgNoBorder
                    title={campagneHeader[0].name}
                    titleEn={campagneHeader[0].nameEn}
                    images={
                      campagneHeader[0].attachement[0].attachmentFile || ""
                    }
                    date={campagneHeader[0].startDate}
                    id={campagneHeader[0].id}
                    newLabel="New"
                    description={campagneHeader[0].description}
                    views={120}
                    comments={30}
                    action={{
                      detail: () =>
                        navigate(
                          joinUrlWithParamsId(
                            "/actualite/:id",
                            campagneHeader[0].id
                          )
                        ),
                    }}
                  />
                ) : (
                  <>
                    <div className="text-center mt-5 mb-5">
                      <div
                        style={{
                          width: "500px", // or any size you want
                          height: "500px", // make it a square
                          backgroundImage: `url(${DataImage})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          margin: "0 auto", // this will center the div if the parent div has a text-align: center
                        }}
                      />
                      <span
                        className="fw-bold"
                        style={{
                          display: "block",
                          marginTop: "20px",
                          fontSize: " 24px",
                        }}
                      >
                        {globalT("custum.list.empty")}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="col-sm-3 ml-auto " style={{ marginLeft: "auto" }}>
                <h2
                  className="text-danger text-uppercase fw-bold mb-4"
                  style={{ fontSize: "1.5rem" }}
                >
                  {globalT("landing.transPartenaire.campagne.title")}
                </h2>
                <div
                  style={{
                    height: "3px",
                    background:
                      "linear-gradient(to left , yellow 80%, green 20%)",
                  }}
                  className="mb-4"
                ></div>
                {campagneHeader && campagneHeader.length > 0 ? (
                  <div className="d-flex flex-column align-items-start mb-4">
                    {campagneHeader && campagneHeader[0] && (
                      <div className="mb-4">
                        <RecentPublicationComponent
                          title={campagneHeader[0].name}
                          titleEn={campagneHeader[0].nameEn}
                          image={
                            campagneHeader[0]?.attachement[0].attachmentFile ||
                            ""
                          }
                          id={campagneHeader[0].id}
                          views={120}
                          comments={30}
                          description={campagneHeader[0].description}
                        />
                      </div>
                    )}
                    {campagneHeader && campagneHeader[1] && (
                      <div className="mb-4">
                        <RecentPublicationComponent
                          title={campagneHeader[1].name}
                          titleEn={campagneHeader[1].nameEn}
                          image={
                            campagneHeader[1]?.attachement[0]?.attachmentFile ||
                            ""
                          }
                          id={campagneHeader[1].id}
                          views={120}
                          comments={30}
                          description={campagneHeader[1].description}
                        />
                      </div>
                    )}
                    {campagneHeader && campagneHeader[2] && (
                      <div className="mb-4">
                        <RecentPublicationComponent
                          title={campagneHeader[2].name}
                          titleEn={campagneHeader[2].nameEn}
                          image={
                            campagneHeader[2]?.attachement[0]?.attachmentFile ||
                            ""
                          }
                          id={campagneHeader[2].id}
                          views={120}
                          comments={30}
                          description={campagneHeader[2].description}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="text-center mt-5 mb-5">
                      <div
                        style={{
                          width: "250px", // or any size you want
                          height: "250px", // make it a square
                          backgroundImage: `url(${DataImage})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          margin: "0 auto", // this will center the div if the parent div has a text-align: center
                        }}
                      />
                      <span
                        className="fw-bold"
                        style={{
                          display: "block",
                          marginTop: "20px",
                          fontSize: "14px",
                        }}
                      >
                        {globalT("empty.news")}
                      </span>
                    </div>
                  </>
                )}

                <h2
                  className="text-danger text-uppercase fw-bold mb-4"
                  style={{ fontSize: "1.5rem" }}
                >
                  {globalT("listOfCommunication")}
                </h2>
                <div
                  style={{
                    height: "3px",
                    background:
                      "linear-gradient(to left , yellow 80%, green 20%)",
                  }}
                  className="mb-4"
                ></div>
                {campagneHeader && campagneHeader[2] ? (
                  <>
                    {campagneHeader[2] && (
                      <Communicator
                        name={campagneHeader[2].name}
                        nameEn={campagneHeader[2].nameEn}
                        creationDate={campagneHeader[2].startDate}
                        updateDate={campagneHeader[2].endDate}
                      />
                    )}
                    {campagneHeader[3] && (
                      <Communicator
                        name={campagneHeader[3].name}
                        nameEn={campagneHeader[3].nameEn}
                        creationDate={campagneHeader[3].startDate}
                        updateDate={campagneHeader[3].endDate}
                      />
                    )}
                    {campagneHeader[4] && (
                      <Communicator
                        name={campagneHeader[4].name}
                        nameEn={campagneHeader[4].nameEn}
                        creationDate={campagneHeader[4].startDate}
                        updateDate={campagneHeader[4].endDate}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <div className="text-center mt-5 mb-5">
                      <div
                        style={{
                          width: "250px", // or any size you want
                          height: "250px", // make it a square
                          backgroundImage: `url(${DataImage})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          margin: "0 auto", // this will center the div if the parent div has a text-align: center
                        }}
                      />
                      <span
                        className="fw-bold"
                        style={{
                          display: "block",
                          marginTop: "20px",
                          fontSize: "14px",
                        }}
                      >
                        {globalT("empty.news")}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "200px" }}
        >
          <div
            className="spinner-border"
            style={{ height: "6rem", width: "6rem", color: "#aae0aa" }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      <section className="container pt-5 composant3">
        <h1 style={{ fontWeight: "bold" }}>
          {globalT("paragraph.management.list.campagne")}
        </h1>
        <br></br>
        <div className="text-end">
          {loading && (
            <>
              <Spinner customColor="#aae0aa" size="6rem" />
            </>
          )}{" "}
          <br />
          <SearchComponent onSearch={handleSearch} />
        </div>
        <div className="row py-4">
          {campagne && campagne.length > 0 ? (
            <>
              {campagne.map((pub, index) => (
                <div className="col-lg-4 col-md-6 mb-4" id={`no${index}`}>
                  <CardImg
                      titleEn={pub.nameEn}
                    title={pub.name}
                    images={pub.attachement}
                    date={pub.startDate}
                    id={pub.id}
                    newLabel="New"
                    description={pub.description}
                    action={{
                      detail: () =>
                        navigate(joinUrlWithParamsId("/campagne/:id", pub.id)),
                    }}
                  />
                </div>
              ))}
            </>
          ) : (
            <div className="text-center mt-5 mb-5">
              <div
                style={{
                  width: "400px", // or any size you want
                  height: "400px", // make it a square
                  backgroundImage: `url(${DataImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  margin: "0 auto", // this will center the div if the parent div has a text-align: center
                }}
              />
              <span
                className="fw-bold"
                style={{
                  display: "block",
                  marginTop: "20px",
                  fontSize: "24px",
                }}
              >
                {globalT("empty.campagnes")}
              </span>
            </div>
          )}
        </div>
        <PaginationComponent
          pages={Math.ceil((campagne?.length || 0) / 6)}
          currentPage={page}
          onPageChange={(page) => setPage(page)}
          rangeLimit={5}
        />
      </section>
      <section className="container OOO">
        <div className="row">
          <div className="col-md-8"></div>
        </div>
      </section>
      <Footfoot />
    </>
  );
};

export default CampagneHome;
