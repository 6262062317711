import React, { useEffect, useRef, useState } from "react";
import Navnav from "../../Navnav/Navnav";
import Footfoot from "../../Navnav/Footfoot";
import { globalT } from "../../../lang";
import SearchComponent from "../../../components/SearchComponent";
import DataImage from "../../../assets/img/landing2/vide.webp";
import AudioLanding from "../../../assets/img/landing2/audioL.png";
import PaginationComponent from "../../../components/PaginationComponent";
import mintClient from "../../../network";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Documentation from "../../../commons/models/Documentation";
import * as yup from "yup";
import CategoryDoc from "../../../commons/models/CategorieDoc";
import "./doc.scss";
import CardAudio from "../../../components/bootstrap/CardAudio";
import Header from "../Header";
import Footer from "../Footer";
import CardMusique from "../card/CardMusique";
import Select from "../../../components/bootstrap/forms/Select";
import Option from "../../../components/bootstrap/Option";

interface OptionType {
  label: string;
  value: string;
  id: number;
}
interface IFormInput {
  categorie: OptionType | null;
}

const schema = yup.object().shape({
  categorie: yup.object().nullable().required("This field is required"),
});

const Audio = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });
  const [page, setPage] = useState(1);
  const total = useRef<number>();
  const [categorie, setCategorie] = useState<CategoryDoc[] | null>(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [documentationDocument, setDocumentationDocument] = useState<
    Documentation[] | null
  >();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("1");
  const sectionRef = React.useRef<HTMLDivElement | null>(null);

  const handleDocumentClick = (file: string) => {
    // Handle the document click here
  };
  const scrollToSection = () => {
    const section = sectionRef.current;
    if (section) {
      const sectionTop =
        section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: sectionTop, behavior: "smooth" });
    }
  };

  const loadDocumentationDocument = () => {
    setLoading(true);
    // dispatch(setRequestGlobalLoader(true))
    mintClient.documentation
      .getAll({
        page: page,
        pageSize: 6,
        categoryId: selectedCategoryId,
        order: true,
        orderDirection: "ascending",
        validationState: 1,
        researchValue: search,
        eduCategory: true,
        isAudio: true,
      })
      .then((resp) => {
        const allDocumentationDocuments = resp.data.data;
        total.current = resp.data.total;
        setDocumentationDocument(allDocumentationDocuments);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };
  const loadCategorie = () => {
    mintClient.category
      .getAll({ isParent: true, parentId: 1, page: 1, pageSize: 100 })
      .then((resp) => {
        setCategorie(resp.data.data);
      })
      .catch(() => null);
  };

  useEffect(() => {
    if (!categorie) loadCategorie();
    loadDocumentationDocument();
  }, [selectedCategoryId, search]);

  const handleSearch = (value: any) => {
    setSearch(value);
    setPage(1);
  };

  return (
    <>
      <Header currentMenu={"education"} />
        <div className="container" id={"sectionId"}>

        </div>
      <div className="container section-11" style={{ marginTop: "50x" }}>
        <div className="row mb-4" style={{ marginTop: "20px" }}>
          <div className="row mb-4 ">
            {/* Code for the first section */}
            <div className="col-md-5">
              <h1
                className="mb-3"
                style={{
                  fontSize: "38px",
                  marginBottom: "20px",
                  fontWeight: "blod",
                }}
              >
                {globalT("edu.mp3.para.1")}
              </h1>
              <p
                style={{
                  fontSize: "17px",
                  marginBottom: "20px",
                  fontWeight: 500,
                  color: "black",
                }}
              >
                {globalT("edu.mp3.para.2")}
              </p>
              <button
                type="button"
                className="btn btn-danger btn-lg"
                style={{ marginTop: "20px", backgroundColor: "#FFA500" }}
                onClick={scrollToSection}
              >
                <i
                  className="fas fa-file-alt"
                  style={{ marginRight: "10px", color: "white" }}
                ></i>
                {globalT("landing.header.audio")}
              </button>
            </div>
            <div className="col-md-6">
              <img
                src={AudioLanding}
                alt="Your Image"
                style={{ height: "500px", width: "500px" }}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="container section-3"
        ref={sectionRef}
        style={{ marginTop: "80px" }}
      >
        <div
          style={{
            marginTop: "50px",
            height: "3px",
            background: "linear-gradient(to left , yellow 80%, red 20%)",
          }}
          className="mb-4"
        ></div>



      <div className="row mb-4 ">
          <div className="col-md-7">
            {/*<h2 className="mb-4">{globalT("educ.research.video")}</h2>*/}
          </div>
          <div className="col-md-5 d-flex row mb-4">
            {/* Replace with your search component */}
            <div className="col-md-6 mt-10">
              <p className="fw-bold" style={{ marginLeft: "40px" }}>
                {globalT("educ.research.vid")}
              </p>
              <SearchComponent onSearch={handleSearch} />
            </div>
            {/* Directly place SelectFromRemote component here */}
            <div className="col-md-5 ms-3">
              {categorie ? (
                <div className="d-flex flex-column">
                  <span className="text-start fw-bold mb-3">
                    {globalT("video.category")}
                  </span>
                  <Select
                    size="sm"
                    ariaLabel="Per"
                    onChange={(e: { target: { value: string } }) => {
                      setDocumentationDocument(null);
                      setSelectedCategoryId(e.target.value);
                    }}
                    value={selectedCategoryId}
                  >
                    <Option key="all" value="1">
                      tous
                    </Option>

                    {categorie.map((cat) => (
                      <Option key={cat.id} value={cat.id}>
                        {cat.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              ) : (
                <div
                  className="spinner-border"
                  style={{ height: "3rem", width: "3rem", color: "#aae0aa" }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </div>
          </div>
        </div>



        <div className="row py-4">
          <>
            {loading && (
              <div
                className="d-flex justify-content-between"
                style={{ color: "red" }}
              >
                <span></span>
                <div
                  className="spinner-border"
                  style={{
                    height: "3rem",
                    width: "3rem",
                    color: "#F7B500",
                    marginTop: "40px",
                    marginRight: "10px",
                  }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </>
          {documentationDocument && documentationDocument.length > 0 ? (
            <>
              <div className="row">
                {documentationDocument.map((pub) => (
                  <div className="col-4 mb-2">
                    <CardMusique
                      id={pub.id}
                      name={pub.name}
                      nameEn={pub.nameEn}
                      mp3={pub.file}
                      createAt={pub.createdAt}
                    />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="imageContainer" style={{ marginTop: "100px" }}>
              <div className="text-center mt-5 mb-5">
                <div
                  style={{
                    width: "400px", // or any size you want
                    height: "400px", // make it a square
                    backgroundImage: `url(${DataImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    margin: "0 auto", // this will center the div if the parent div has a text-align: center
                  }}
                  className="image"
                />
                {loading ? (
                  <div className="d-flex justify-content-center spinnerContainer">
                    <div
                      className="spinner-border"
                      style={{
                        height: "6rem",
                        width: "6rem",
                        color: "#aae0aa",
                        marginTop: "85px",
                        marginRight: "15px",
                      }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <span
                    className="fw-bold"
                    style={{
                      marginTop: "20px",
                      fontSize: "24px",
                    }}
                  >
                    {globalT("empty.doc")}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        <PaginationComponent
          pages={Math.ceil((total.current || 0) / 6)}
          currentPage={page}
          onPageChange={(page) => setPage(page)}
          rangeLimit={5}
        />
      </div>

      <Footer />
    </>
  );
};
export default Audio;
