import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import "../Home.scss";
import "./services.scss";
import Forum from "../card/Forum";
import { globalT } from "../../../lang";
import Button from "../../../components/bootstrap/Button";
import Input from "../../../components/bootstrap/forms/Input";
import { useNavigate } from "react-router-dom";
import LandingPage33 from "../../../assets/img/landing2/mint2.jpg";
import Documentation from "../../../commons/models/Documentation";
import mintClient from "../../../network";
import Spinner from "../../../components/bootstrap/Spinner";
import CardDocument from "../card/CardDocument";
import DataImage from "../../../assets/img/landing2/vide.webp";
import DialogComponent from "../../../components/dialogs/DialogComponent";
import EncryptedPdfViewer from "../../../components/ReadPdf";
import PaginationComponent from "../../../components/PaginationComponent";
import newImage from "../../../assets/img/landing2/back_copie.png";
import ImageSensibilisation from "../../../components/ImageSensibilisation";

const Ong = () => {
  const navigate = useNavigate();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const total = useRef<number>();
  const [loading, setLoading] = useState(true);
  const [dateCenter, setDataCenter] = useState<Documentation[]>();
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const [currentDoc, setCurrentDoc] = useState<string | null>(null);

  const loadDataCenter = () => {
    setLoading(true);
    mintClient.documentation
      .getAll({
        page: currentPage,
        pageSize: 10,
        orderDirection: "ascending",
        researchValue: searchValue,
        hasPagination: true,
        isDocument: true,
        langue: "fr",
        categoryId: "37",
      })
      .then((resp) => {
        setLoading(false);
        setDataCenter(resp.data.data);
        total.current = resp.data.total;
      })
      .catch(() => null);
  };

  const handleDocumentClick = (file: string) => {
    setCurrentDoc(file);
    setShowPdf(true);
  };

  const handleFilteredDocumentsPageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // @ts-ignore
    document.getElementById("sectionId").scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    loadDataCenter();
  }, [currentPage, searchValue]);

  return (
    <>
      <Header
        currentMenu={"service"}
        hasAutherImage={true}
        image={LandingPage33}
        title={globalT("nav.service.centre")}
        subtitle={globalT("nav.service")}
      />
      <div className="container" id={"sectionId"} style={{ marginBottom: "4rem" }}>
        <ImageSensibilisation
          image={newImage}
          texte={ `${globalT("visite.text")} !!!`}
        />
      </div>
      <br />
      <br />
      <div className="container ">
        <h1 className="d-flex justify-content-center align-items-center">
          <span className="">{globalT("condition.ouverture")}</span>
        </h1>
        <br />
        <p className="fs-5 text-justify">
          <span>{globalT("gamme.depliant")}</span>
        </p>
        <br />
        <p className="fs-5 text-justify">
          <span>{globalT("lire.depliant")}</span>
        </p>
      </div>
      <br /> <br />
      <div className="container ">
        <h1 className="d-flex justify-content-center align-items-center">
          <span>{globalT("suspension.fermeture")}</span>
        </h1>
        <br />
        <p className="fs-5 text-justify">
          <span>{globalT("gamme.depliant")}</span>
        </p>
        <br />
        <p className="fs-5 text-justify">
          <span>{globalT("lire.depliant")}</span>
        </p>
      </div>
      <br /> <br />
      {/*   <div className="container w-40">
                <h1>
                    <span>{globalT("liste.centre")}</span>
                </h1>
            </div><br/>
            <br/><br/>
            <div className="container w-55">
                <div className="searchfield">
                    <div className="row justify-content-center">
                        <div className="col">
                            <span className="">
                                Recherche
                            </span>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col">
                                <Input type='text' value={searchValue} onChange={handleChange} className="input" placeholder='Search'/>
                        </div>
                    </div>
                </div>

            </div><br/><br/> */}
      {/*  <div className="">
                <div className="row">
                    {!loading ? (
                        <></>
                    ) : (
                        <div className="text-end">
                            <Spinner size={"3rem"} color={"primary"} />
                        </div>
                    )}
                    <section className="container pt-3">
                        <div className="row py-4">
                            {dateCenter && dateCenter.length > 0 ? (
                                <>
                                    <div className="d-flex justify-content-between mb-4">
                                        <span></span>
                                        {loading && <Spinner size="3rem" customColor="#aae0aa" />}
                                    </div>
                                    <div className="row">
                                        {dateCenter.map(pub => (
                                            <div className="col-4 mb-2">
                                                <CardDocument
                                                    id={pub.id}
                                                    name={pub.name}
                                                    nameEn={pub.nameEn}
                                                    mp3={pub.file}
                                                    onClick={() => handleDocumentClick(pub.file)}
                                                    createAt={pub.createdAt}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <div className="text-center mt-5 mb-5">
                                    <div className="text-center mt-5 mb-5">
                                        <div
                                            style={{
                                                width: "400px", // or any size you want
                                                height: "400px", // make it a square
                                                backgroundImage: `url(${DataImage})`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundPosition: "center",
                                                backgroundSize: "cover",
                                                margin: "0 auto", // this will center the div if the parent div has a text-align: center
                                            }}
                                        />
                                        <span
                                            className="fw-bold"
                                            style={{
                                                display: "block",
                                                marginTop: "20px",
                                                fontSize: "24px",
                                            }}
                                        >
                    {globalT("custum.list.empty")}
                  </span>
                                    </div>
                                </div>
                            )}
                        </div>

                        {showPdf && currentDoc && (
                            <DialogComponent
                                show={showPdf}
                                size="lg"
                                title=""
                                onClose={() => setShowPdf(false)}
                            >
                                <EncryptedPdfViewer base64EncryptedPdf={currentDoc} />
                            </DialogComponent>
                        )}
                        <PaginationComponent
                            pages={Math.ceil((total.current || 0) / 6)}
                            currentPage={currentPage}
                            onPageChange={handleFilteredDocumentsPageChange}
                            rangeLimit={5}
                        />
                    </section>
                </div>
            </div> */}

            <Footer />

        </>
    );
};

export default Ong;
