import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import "./permis.scss";
import SearchComponent from "../../../components/SearchComponent";
import Spinner from "../../../components/bootstrap/Spinner";
import CardDocument from "../card/CardDocument";
import { globalT } from "../../../lang";
import DialogComponent from "../../../components/dialogs/DialogComponent";
import EncryptedPdfViewer from "../../../components/ReadPdf";
import PaginationComponent from "../../../components/PaginationComponent";
import DataImage from "../../../assets/img/landing2/vide.webp";
import mintClient from "../../../network";
import { useAppDispatch, useAppSelector } from "../../../store/redux.types";
import { setRequestGlobalLoader } from "../../../store/slices/requestGlobalLoader/actions";
import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../../components/bootstrap/Dropdown";
import Button from "../../../components/bootstrap/Button";
import Select from "../../../components/bootstrap/forms/Select";
import Option from "../../../components/bootstrap/Option";
import V1 from "../../../assets/img/landing2/infracts.gif";
import V2 from "../../../assets/img/landing2/revuDoc1.png";
import V3 from "../../../assets/img/landing2/stats.gif";
import V4 from "../../../assets/img/landing2/Process.gif";
import V5 from "../../../assets/img/landing2/Agreement.gif";
import V6 from "../../../assets/img/landing2/reglementation.jpg";
import V7 from "../../../assets/img/landing2/reglementation.jpg";
import { useLocation, useParams } from "react-router-dom";
import CategoryDoc from "../../../commons/models/CategorieDoc";
import Tooltips from "../../../components/bootstrap/Tooltips";
import LANG from "../../../lang/language";
import newImage from "../../../assets/img/landing2/back_copie.png";
import ImageSensibilisation from "../../../components/ImageSensibilisation";

type CategoryImagesType = {
  [key: string]: any;
  // ... autres clés ...
};

const PermisConduire = () => {
  const categoryImages: CategoryImagesType = {
    "12": {
      img: V2,
      text: "v2.text",
    },
    "8": {
      img: V1,
      text: "v1.text",
    },
    "11": {
      img: V3,
      text: "v3.text",
    },
    "9": {
      img: V4,
      text: "v4.text",
    },
    "7": {
      img: V5,
      text: "v5.text",
    },
    "6": {
      img: V6,
      text: "v6.text",
    },
    "Règlementation et constitution": {
      img: V7,
      text: "v7.text",
    },
    // autres catégories...
  };
  const [documents, setDocuments] = useState<any[] | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const [currentDoc, setCurrentDoc] = useState<string | null>(null);
  const location = useLocation();
  const categorieName = location.state?.categorie || "";
  const categorieId = useParams().id;
  const imageToDisplay = categoryImages[`${categorieId}`] || DataImage;
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    null
  );
  const [categorie, setCategorie] = useState<CategoryDoc[] | null>(null);
  const total = useRef<number>();
  const [loading, setLoading] = useState(true);
  const [loadingCat, setLoadingCat] = useState(false);
  const dispatch = useAppDispatch();
  const filter = useRef<{
    year: string;
    month: string;
    region: string;
  }>({
    year: "",
    month: "",
    region: "",
  });
  const { settings, authUserData } = useAppSelector(
    ({ settings, authUser }) => ({
      settings,
      authUserData: authUser.data,
    })
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };
  const handleDocumentClick = (file: string) => {
    setCurrentDoc(file);
    setShowPdf(true);
  };
  const handleFilteredDocumentsPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const loadCategorie = () => {
    setLoadingCat(true);
    mintClient.category
      .getAll({
        isParent: true,
        parentId: categorieId as string,
        page: 1,
        pageSize: 100,
      })
      .then((resp) => {
        setCategorie(resp.data.data);
        setLoadingCat(false);
      })
      .catch(() => null);
  };

  const loadDocumentation = () => {
    setLoading(true); // Start loading
    mintClient.documentation
      .getAll({
        page: currentPage,
        pageSize: 10,
        orderDirection: "ascending",
        researchValue: searchValue,
        hasPagination: true,
        isDocument: true,
        langue: "fr",
        categoryId: selectedCategoryId
          ? selectedCategoryId
          : (categorieId as string),
      })
      .then((resp) => {
        const allDocuments = resp.data.data;
        total.current = resp.data.total;
        setDocuments(allDocuments);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  const numberYear = useRef(new Date().getFullYear() - 2017 + 1);

  useEffect(() => {
    loadCategorie();
    loadDocumentation();
  }, [currentPage, searchValue, categorieId, selectedCategoryId]);

  const defaultYear = () => {
    let tabYear = [];
    for (let i = 1; i <= numberYear.current; i++) {
      tabYear.push(2024 - i);
    }
    return tabYear;
  };

  useEffect(() => {
    // @ts-ignore
    document.getElementById("sectionId").scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header currentMenu={"service"} />
      <div className="container">
        <div
          className=""
          id={"sectionId"}
          style={{ marginTop: "3rem", width: "100%" }}
        >
          <ImageSensibilisation
            texte={globalT("con.biblio")}
            image={newImage}
          />
        </div>
        {/*<img
          src={newImage}
          className="img-fluid"
          id={"sectionId"}
          alt="imageNew"
          style={{ marginTop: "3rem", width: "100%" }}
        />*/}
      </div>
      <div className="container global-presentation-style">
        <div className="row">
          <div></div>

          <div className="row d-flex justify-content-center ">
            <div className="fs-1 fw-bold">{globalT("type.per")}</div>
          </div>

          <div
            className=" d-flex justify-content-center"
            style={{ paddingTop: "3vh" }}
          >
            <div className="taille-text">
              {globalT("camr.exist")}
              <ul>
                <li>
                  {" "}
                  <span className="fw-bold">{globalT("perm.a")}</span>
                  {globalT("permi.dest")}{" "}
                </li>
                <li>
                  <span className="fw-bold">{globalT("perm.b")}</span>
                  {globalT("permis.voit")}{" "}
                </li>
                <li>
                  <span className="fw-bold">{globalT("perm.c")}</span>
                  {globalT("permi.nec")}{" "}
                </li>
                <li>
                  <span className="fw-bold">{globalT("perm.d")}</span>
                  {globalT("permi.conduc")}{" "}
                </li>
                <li>
                  <span className="fw-bold">{globalT("perm.e")}</span>
                  {globalT("permi.veh")}
                </li>
                <li>
                  <span className="fw-bold">{globalT("perm.f")}</span>
                  {globalT("permi.ce")}
                </li>
                <li>
                  <span className="fw-bold">{globalT("perm.g")}</span>
                  {globalT("permi.est")}{" "}
                </li>
              </ul>
            </div>
          </div>

          <div
            className="row d-flex justify-content-center "
            style={{ paddingTop: "3vh" }}
          >
            <div className="fs-1 fw-bold">{globalT("pro.obt")}</div>
          </div>

          <div
            className="row d-flex justify-content-center "
            style={{ paddingTop: "1vh" }}
          >
            <div className="taille-text">
              {globalT("obt.cond")}
              <div className="">
                <h3 className="mt-3">{globalT("insc.aut")}</h3>
                <ul>
                  <li>{globalT("choi.aut")}</li>
                  <li>{globalT("fourn.doc")}</li>
                </ul>
              </div>
              <div className="">
                <h3 className="mt-3">{globalT("for.the")}</h3>
                <ul>
                  <li>{globalT("part.aux")}</li>
                  <li>{globalT("vous.dev")}</li>
                </ul>
              </div>
              <div className="">
                <h3 className="mt-3">{globalT("ex.the")}</h3>
                <ul>
                  <li>{globalT("pass.exa")}</li>
                  <li>{globalT("cet.exa")}</li>
                </ul>
              </div>
              <div className="">
                <h3 className="mt-3">{globalT("for.pra")}</h3>
                <ul>
                  <li>{globalT("apres.avoi")}</li>
                  <li>{globalT("la.dur")}</li>
                </ul>
              </div>
              <div className="">
                <h3 className="mt-3">{globalT("exa.pra")}</h3>
                <ul>
                  <li>{globalT("une.fois")}</li>
                  <li>{globalT("exa.consi")}</li>
                </ul>
              </div>
              <div className="">
                <h3 className="mt-3">{globalT("obt.per")}</h3>
                <ul>
                  <li>{globalT("si.vous.re")}</li>
                  <li>{globalT("pre.cert")}</li>
                  <li>{globalT("le.per")}</li>
                </ul>
              </div>
              <div className="">
                <h3 className="mt-3">{globalT("frai.a")}</h3>
                <ul>
                  <li>{globalT("frai.varient")}</li>
                </ul>
              </div>
              <div className="">
                <h3 className="mt-3">{globalT("delai.liv")}</h3>
                <ul>
                  <li>{globalT("toute.les")}</li>
                </ul>
              </div>
              <p>{globalT("est.import")}</p>
            </div>
          </div>

          <div
            className="row d-flex justify-content-center "
            style={{ paddingTop: "5vh" }}
          >
            <div className="fs-1 fw-bold">{globalT("resu.cond")}</div>
          </div>
        </div>
      </div>

      <div className="container " style={{ marginTop: "90px" }}>
        <div className="row mb-4">
          <div className="col-md-6">
            {/*<h2 className="mb-3">{globalT("educ.research")}</h2>*/}
          </div>
          <div className="col-md-6">
            <div className="row">
              <Tooltips title={globalT("year.session.region")}>
                <div className="col-md-5">
                  <p className="fw-bold" style={{ marginLeft: "40px" }}>
                    {globalT("educ.research.vid")}
                  </p>
                  <SearchComponent onSearch={handleSearch} />
                </div>
              </Tooltips>

              <div className="col-md-3">
                <span className="text-start fw-bold mb-3">
                  {globalT("video.category")}
                </span>
                <Dropdown canUseClick={true} direction="end">
                  <DropdownToggle>
                    <Button>
                      <span>{globalT("filter.year")}</span>
                    </Button>
                  </DropdownToggle>
                  <DropdownMenu>
                    {defaultYear().map((elt) => (
                      <DropdownItem>
                        <Dropdown direction="end">
                          <DropdownToggle>
                            <Button
                              onClick={() => (filter.current.year = `${elt}`)}
                            >
                              <span>{elt}</span>
                            </Button>
                          </DropdownToggle>
                          <DropdownMenu direction="end">
                            <DropdownItem>
                              <Dropdown direction="end">
                                <DropdownToggle>
                                  <Button
                                    onClick={() =>
                                      (filter.current.month = "janvier")
                                    }
                                  >
                                    <span>{globalT("doc.month.1")}</span>
                                  </Button>
                                </DropdownToggle>
                                <DropdownMenu direction="end">
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "EN";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.1")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "AD";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.2")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "ES";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.3")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "CE";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.4")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.5")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NO";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.6")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "LT";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.7")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "OU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.8")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.9")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.10")}
                                    </Button>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </DropdownItem>
                            <DropdownItem>
                              <Dropdown direction="end">
                                <DropdownToggle>
                                  <Button
                                    onClick={() =>
                                      (filter.current.month = "février")
                                    }
                                  >
                                    <span>{globalT("doc.month.2")}</span>
                                  </Button>
                                </DropdownToggle>
                                <DropdownMenu direction="end">
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "EN";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.1")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "AD";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.2")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "ES";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.3")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "CE";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.4")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.5")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NO";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.6")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "LT";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.7")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "OU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.8")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.9")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.10")}
                                    </Button>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </DropdownItem>
                            <DropdownItem>
                              <Dropdown direction="end">
                                <DropdownToggle>
                                  <Button
                                    onClick={() =>
                                      (filter.current.month = "mars")
                                    }
                                  >
                                    <span>{globalT("doc.month.3")}</span>
                                  </Button>
                                </DropdownToggle>
                                <DropdownMenu direction="end">
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "EN";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.1")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "AD";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.2")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "ES";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.3")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "CE";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.4")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.5")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NO";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.6")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "LT";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.7")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "OU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.8")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.9")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.10")}
                                    </Button>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </DropdownItem>
                            <DropdownItem>
                              <Dropdown direction="end">
                                <DropdownToggle>
                                  <Button
                                    onClick={() =>
                                      (filter.current.month = "avril")
                                    }
                                  >
                                    <span>{globalT("doc.month.4")}</span>
                                  </Button>
                                </DropdownToggle>
                                <DropdownMenu direction="end">
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "EN";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.1")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "AD";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.2")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "ES";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.3")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "CE";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.4")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.5")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NO";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.6")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "LT";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.7")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "OU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.8")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.9")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.10")}
                                    </Button>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </DropdownItem>
                            <DropdownItem>
                              <Dropdown direction="end">
                                <DropdownToggle>
                                  <Button
                                    onClick={() =>
                                      (filter.current.month = "mai")
                                    }
                                  >
                                    <span>{globalT("doc.month.5")}</span>
                                  </Button>
                                </DropdownToggle>
                                <DropdownMenu direction="end">
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "EN";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.1")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "AD";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.2")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "ES";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.3")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "CE";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.4")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.5")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NO";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.6")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "LT";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.7")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "OU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.8")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.9")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.10")}
                                    </Button>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </DropdownItem>
                            <DropdownItem>
                              <Dropdown direction="end">
                                <DropdownToggle>
                                  <Button
                                    onClick={() =>
                                      (filter.current.month = "juin")
                                    }
                                  >
                                    <span>{globalT("doc.month.6")}</span>
                                  </Button>
                                </DropdownToggle>
                                <DropdownMenu direction="end">
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "EN";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.1")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "AD";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.2")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "ES";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.3")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "CE";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.4")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.5")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NO";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.6")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "LT";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.7")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "OU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.8")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.9")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.10")}
                                    </Button>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </DropdownItem>
                            <DropdownItem>
                              <Dropdown direction="end">
                                <DropdownToggle>
                                  <Button
                                    onClick={() =>
                                      (filter.current.month = "juillet")
                                    }
                                  >
                                    <span>{globalT("doc.month.7")}</span>
                                  </Button>
                                </DropdownToggle>
                                <DropdownMenu direction="end">
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "EN";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.1")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "AD";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.2")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "ES";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.3")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "CE";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.4")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.5")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NO";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.6")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "LT";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.7")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "OU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.8")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.9")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.10")}
                                    </Button>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </DropdownItem>
                            <DropdownItem>
                              <Dropdown direction="end">
                                <DropdownToggle>
                                  <Button
                                    onClick={() =>
                                      (filter.current.month = "août")
                                    }
                                  >
                                    <span>{globalT("doc.month.8")}</span>
                                  </Button>
                                </DropdownToggle>
                                <DropdownMenu direction="end">
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "EN";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.1")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "AD";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.2")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "ES";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.3")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "CE";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.4")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.5")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NO";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.6")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "LT";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.7")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "OU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.8")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.9")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.10")}
                                    </Button>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </DropdownItem>
                            <DropdownItem>
                              <Dropdown direction="end">
                                <DropdownToggle>
                                  <Button
                                    onClick={() =>
                                      (filter.current.month = "septembre")
                                    }
                                  >
                                    <span>{globalT("doc.month.9")}</span>
                                  </Button>
                                </DropdownToggle>
                                <DropdownMenu direction="end">
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "EN";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.1")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "AD";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.2")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "ES";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.3")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "CE";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.4")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.5")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NO";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.6")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "LT";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.7")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "OU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.8")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.9")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.10")}
                                    </Button>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </DropdownItem>
                            <DropdownItem>
                              <Dropdown direction="end">
                                <DropdownToggle>
                                  <Button
                                    onClick={() =>
                                      (filter.current.month = "octobre")
                                    }
                                  >
                                    <span>{globalT("doc.month.10")}</span>
                                  </Button>
                                </DropdownToggle>
                                <DropdownMenu direction="end">
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "EN";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.1")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "AD";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.2")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "ES";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.3")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "CE";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.4")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.5")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NO";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.6")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "LT";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.7")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "OU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.8")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.9")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.10")}
                                    </Button>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </DropdownItem>
                            <DropdownItem>
                              <Dropdown direction="end">
                                <DropdownToggle>
                                  <Button
                                    onClick={() =>
                                      (filter.current.month = "novembre")
                                    }
                                  >
                                    <span>{globalT("doc.month.11")}</span>
                                  </Button>
                                </DropdownToggle>
                                <DropdownMenu direction="end">
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "EN";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.1")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "AD";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.2")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "ES";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.3")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "CE";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.4")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.5")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NO";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.6")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "LT";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.7")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "OU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.8")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.9")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.10")}
                                    </Button>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </DropdownItem>
                            <DropdownItem>
                              <Dropdown direction="end">
                                <DropdownToggle>
                                  <Button
                                    onClick={() =>
                                      (filter.current.month = "decembre")
                                    }
                                  >
                                    <span>{globalT("doc.month.12")}</span>
                                  </Button>
                                </DropdownToggle>
                                <DropdownMenu direction="end">
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "EN";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.1")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "AD";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.2")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "ES";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.3")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "CE";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.4")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.5")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NO";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.6")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "LT";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.7")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "OU";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.8")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "NW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.9")}
                                    </Button>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <Button
                                      onClick={() => {
                                        filter.current.region = "SW";
                                        setSearchValue(
                                          `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                        );
                                      }}
                                    >
                                      {globalT("doc.region.10")}
                                    </Button>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="col-md-4">
                <button
                  type="button"
                  style={{ backgroundColor: " #F7B500" }}
                  className="btn fv btn-sm"
                  onClick={() => setSearchValue("")}
                >
                  {globalT("button.reset")}
                </button>
                {/*<Button color='success' onClick={() => setSearchValue('')}>{globalT('button.reset')}</Button>*/}
              </div>
            </div>
          </div>
        </div>
        {documents ? (
          <></>
        ) : (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "200px" }}
          >
            <div
              className="spinner-border"
              style={{ height: "6rem", width: "6rem", color: "#FA6400" }}
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <section className="container pt-3">
          <div className="row py-4">
            {documents && documents.length > 0 ? (
              <>
                <div className="d-flex justify-content-between mb-4">
                  <span></span>
                  {loading && <Spinner size="3rem" customColor="#FA6400" />}
                </div>
                <div className="row">
                  {documents.map((pub) => (
                    <div className="col-4 mb-2">
                      <CardDocument
                        id={pub.id}
                        name={pub.name}
                        nameEn={pub.nameEn}
                        mp3={pub.file}
                        onClick={() => handleDocumentClick(pub.file)}
                        createAt={pub.createdAt}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="text-center mt-5 mb-5">
                <div className="text-center mt-5 mb-5">
                  <div
                    style={{
                      width: "400px", // or any size you want
                      height: "400px", // make it a square
                      backgroundImage: `url(${DataImage})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      margin: "0 auto", // this will center the div if the parent div has a text-align: center
                    }}
                  />
                  <span
                    className="fw-bold"
                    style={{
                      display: "block",
                      marginTop: "20px",
                      fontSize: "24px",
                    }}
                  >
                    {globalT("custum.list.empty")}
                  </span>
                </div>
              </div>
            )}
          </div>

          {showPdf && currentDoc && (
            <DialogComponent
              show={showPdf}
              size="lg"
              title=""
              onClose={() => setShowPdf(false)}
            >
              <EncryptedPdfViewer base64EncryptedPdf={currentDoc} />
            </DialogComponent>
          )}
          <PaginationComponent
            pages={Math.ceil((total.current || 0) / 6)}
            currentPage={currentPage}
            onPageChange={handleFilteredDocumentsPageChange}
            rangeLimit={5}
          />
        </section>
      </div>

      <Footer />
    </>
  );
};

export default PermisConduire;
