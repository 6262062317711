import React from "react";
import { Dayjs } from "dayjs";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import pdfLogo from "../../../assets/img/landing2/pdfLogo.png";
import { useAppSelector } from "../../../store/redux.types";
import LANG from "../../../lang/language";
import "./card.scss";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";

type cardProps = {
  name: string;
  id: string;
  nameEn: string;
  mp3: string;
  onClick: (file: string) => void;
  createAt: Dayjs;
};

const CardDocument = ({
  id,
  name,
  nameEn,
  mp3,
  onClick,
  createAt,
}: cardProps) => {
  const { settings, authUserData } = useAppSelector(
    ({ settings, authUser }) => ({
      settings,
      authUserData: authUser.data,
    })
  );
  const textToShow =
    settings.language === LANG.fr.locale ? name : nameEn || name;

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {textToShow}
    </Tooltip>
  );
  const handleDownload = () => {
    // const blob = base64ToFile(file, `application/${type}`);
    // const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = mp3;
    link.target = "_blank";
    link.download = `${name}.pdf`;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="cardDocument">
      <Card>
        <CardBody>
          <div className="">
            <div className="d-flex justify-content-center">
              <Button
                onClick={() => onClick(mp3)}
                icon="Visibility"
                color={"litleDark"}
                className=""
              />
              <img
                src={pdfLogo}
                alt="pdfLogo"
                className="cardDocument__image"
              />
              <Button
                onClick={handleDownload}
                icon="Download"
                color={"litleDark"}
                className=""
              />
            </div>
            <OverlayTrigger placement="top" overlay={renderTooltip}>
              <div className="text-truncate fw-bold" style={{ maxWidth: "600px" }}>
                {textToShow}
              </div>
            </OverlayTrigger>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default CardDocument;
