import {Enumify} from "./Enumify";

export class PublicationStatus extends Enumify {
    static  all = new PublicationStatus(4)
    static pending = new PublicationStatus(0)
    static validate = new PublicationStatus(1)
    static invalidate = new PublicationStatus(2)

    constructor(value: number) {
        super();
        this.value = value
    }
}