import React, {useState, useRef, useEffect} from 'react';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import * as YupShema from '../../../components/forms/yupShema'
import {setRequestGlobalLoader} from "../../../store/slices/requestGlobalLoader/actions";
import csuClient from "../../../network";
import {ADMINISTRATION, INCIDENT, PUBLICATION} from "../../../commons/urls/front";
import NotificationManager from "../../../components/notifications/NotificationManager";
import {globalT} from "../../../lang";
import DragAndDrop, {FileItem} from "../../../components/DragAndDrop";
import {useAppDispatch} from "../../../store/redux.types";

import IntlMessages from "../../../components/IntlMessages";
import Page from "../../../layout/Page/Page";
import Card, {CardBody, CardHeader, CardTitle} from "../../../components/bootstrap/Card";
import InputComponent from "../../../components/forms/InputComponent";
import * as yupSchema from "../../../components/forms/yupShema";
import Button from "../../../components/bootstrap/Button";
import {useNavigate} from "react-router-dom";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";




type FormType = {
    name?: string,
    description?: string,
    phoneNumber?:string,
    Nom?:string,
    Prenom?:string,
    latitude: number,
    longitude: number
}




const schema = yup.object().shape({
    name: YupShema.name,
    Nom: YupShema.name,
    description: YupShema.description,
    phoneNumber: yupSchema.phone,
})

type AddIncident = {
    onClose: () => void
}

const Add = ({onClose}: AddIncident) => {

    const [files, setFiles] = useState<FileItem[]>([])
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormType>({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

   const [coordonee, setCoordonee] = useState<FormType | null>(null)
    

    const isFormValid = (data: FormType) => {
        let isValid = true;

        if (files.length === 0) {
            isValid = false;
            NotificationManager.warning(globalT('form.error.image'))
        }

        return isValid
    }

    const onSubmit: SubmitHandler<FormType> = (data) => {
        if (!isFormValid(data))
            return;

            const formData = new FormData();
            formData.append('Name', data.name as string);
            formData.append('Description', data.description as string);
            formData.append('DeclarerPhoneNumber', data.phoneNumber as string);
            formData.append('DeclarerName', data.Nom as string);
            formData.append('DeclarerSurname', data?.Prenom as string);
            formData.append('Latitude', String(coordonee?.latitude) as string);
            formData.append('Longitude', String(coordonee?.latitude) as string);

        for (let i = 0; i < files.length; i++) {
            formData.append("Attachments", files[i]);
        }
        // return;

        dispatch(setRequestGlobalLoader(true))


        csuClient
            .incident
            .create(formData)
            .then((res) => {
                NotificationManager.success(globalT('incident.add.success'))
                // navigate(joinUrlWithParamsId(ADMINISTRATION.USER.INDEX, res.data.results.id))
                onClose()
            })
            .catch(() => null)
            .finally(() => dispatch(setRequestGlobalLoader(false)))
    }

  
    const handleLocalisation = () => {

      if (navigator.geolocation) {

        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;

            setCoordonee({latitude,longitude})

          },
          (error) => {
            console.log('Error:', error);
          }
        );
      } else {
        console.log('Geolocation is not supported by this browser.');
      }
    } 

    useEffect(() => {
       handleLocalisation();
    }, []);

    return (
        <PageWrapper>
            <Page className='px-sm-3'>
                <div className='row'>
                    <div className='col-12'>
                        <Card>
                            <CardHeader>
                                <CardTitle tag='h3'>
                                    <IntlMessages id='incident.add.title'/>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <form className='row g-4 mx-3' onSubmit={handleSubmit(onSubmit)}>

                                    <div className='col-sm-12 '>
                                        <InputComponent
                                            name="name"
                                            errors={errors}
                                            control={control}
                                            displayRequiredAsterisk
                                            label={<IntlMessages id={globalT("form.field.incident.name")}/>}
                                        />
                                    </div>

                                    <div className='col-sm-12 '>
                                        <InputComponent
                                            name="Nom"
                                            errors={errors}
                                            control={control}
                                            displayRequiredAsterisk
                                            label={<IntlMessages id='nom.declarant'/>}
                                        />
                                    </div>

                                    <div className='col-sm-12 '>
                                        <InputComponent
                                            name="Prenom"
                                            errors={errors}
                                            control={control}
                                            isRequired={false}
                                            label={<IntlMessages id='prenom.declarant'/>}
                                        />
                                    </div>

                                    <div className='col-md-12'>
                                        <InputComponent
                                            errors={errors}
                                            control={control}
                                            displayRequiredAsterisk
                                            isRequired={true}
                                            name='phoneNumber'
                                            type='number'
                                            label={<IntlMessages id='form.field.phoneNumber'/>}
                                            otherValidators={{
                                                validate: (val: string | undefined) => !val ? true : yupSchema.regex.cameroonPhoneNumber.test(val || '')
                                                    || (<IntlMessages id="form.errors.phone.cmr" />)
                                            }}
                                        />
                                    </div>

                                    <div className='col-sm-12'>
                                        <InputComponent
                                            name="description"
                                            errors={errors}
                                            control={control}
                                            displayRequiredAsterisk
                                            label={<IntlMessages id='form.field.description'/>}
                                            componentType={"textarea"}
                                        />
                                    </div>

                                    <Card>
                                        <CardBody>
                                            <span className='pb-2'>{globalT('form.filed.pub.file')}</span>
                                            <DragAndDrop
                                                files={files}
                                                maxFiles={5}
                                                // options={{ noClick: true }}
                                                types={['image/*,video/*']}
                                                setFiles={(files) => setFiles(files)}/>
                                        </CardBody>
                                    </Card>

                                   

                                    <div className="col-sm-12 col-md-10">
                                        <Button
                                            icon='Save'
                                            type='submit'
                                            color='primary'
                                           
                                            // iconPosition='right'
                                            className="text-nowrap bg-gradient ms-2 bg-orange">
                                            <IntlMessages id={globalT("button.send")} />
                                        </Button>
                                    </div>

                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
};

export default Add;