import React, { useContext } from 'react';
import Brand from '../../../layout/Brand/Brand';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideHead } from '../../../layout/Aside/Aside';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import {
	administration,
	dashboardMenu,
	publication,
	document,
	category,
	paragraph,
	evenement,
	campagne, incident, chat, chatbot, discusion,alert, education, moderateur
} from "../../../menu";
import Permissions from "../../../commons/permissions";
import {AbilityContext} from "../../../commons/permissions/Can";


const DashboardAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const ability = useContext(AbilityContext);

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation menu={{ dashboard: dashboardMenu.dashboard }} id='aside-dashboard' />
				<NavigationLine />
				<Navigation menu={administration} id='admin'></Navigation>
				{ability.can(Permissions.security.administration.index, Permissions) && (
					<NavigationLine />
				)}

				<Navigation menu={publication} id='publication'></Navigation>
				{ability.can(Permissions.dashboard.displaydashboard.publication.index, Permissions) && (
					<NavigationLine />
				)}
				<Navigation menu={incident} id='incident'></Navigation>
				{ability.can(Permissions.dashboard.displaydashboard.incident.index, Permissions) && (
					<NavigationLine />
				)}
				
				<Navigation menu={document} id='document'></Navigation>
				{ability.can(Permissions.dashboard.displaydashboard.document.index, Permissions) && (
					<NavigationLine />
				)}
				<Navigation menu={education} id='education'></Navigation>
				{ability.can(Permissions.dashboard.displaydashboard.document.index, Permissions) && (
					<NavigationLine />
				)}
				<Navigation menu={campagne} id='campagne'></Navigation>
				{ability.can(Permissions.dashboard.displaydashboard.campaign.index, Permissions) && (
					<NavigationLine />
				)}
				<Navigation menu={evenement} id='publication'></Navigation>
				{ability.can(Permissions.dashboard.displaydashboard.event.index, Permissions) && (
					<NavigationLine />
				)}

				<Navigation menu={alert} id='alert'></Navigation>
				{ability.can(Permissions.dashboard.displaydashboard.alert.index, Permissions) && (
					<NavigationLine />
				)}

			

				{/*<Navigation menu={chat} id='paragraph'></Navigation>*/}
				{/*<NavigationLine />*/}
				<Navigation menu={chatbot} id='chatbot'></Navigation>
				{ability.can(Permissions.dashboard.displaydashboard.chatBot.index, Permissions) && (
					<NavigationLine />
				)}
				{/*<Navigation menu={paragraph} id='paragraph'></Navigation>*/}
				{/*<NavigationLine />*/}
				<Navigation menu={discusion} id='discusion'></Navigation>
				
                <NavigationLine />

				<Navigation menu={moderateur} id='moderateur'></Navigation>

			</AsideBody>
		</Aside>
	);
};

export default DashboardAside;
