import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { globalT } from '../lang';
import {useAppSelector} from "../store/redux.types";
import LANG from "../lang/language";

interface CommunicatorProps {
  name: string;
  nameEn: string;
  creationDate: Dayjs | null;
  updateDate: Dayjs | null;
}

const Communicator: React.FC<CommunicatorProps> = ({ name,nameEn, creationDate, updateDate }) => {
    const { settings, authUserData } = useAppSelector(({ settings, authUser, }) => ({
        settings,
        authUserData: authUser.data
    }));
    return (
    <div className="communicator" style={{border: '1px solid #ccc', borderRadius: '8px', padding: '1rem', marginBottom: '1rem'}}>
      <h5 className="communicator-name">{settings.language === LANG.fr.locale ? name : nameEn ? nameEn : name}</h5>
      <p className="communicator-dates" style={{display: 'flex', justifyContent: 'space-between'}}>
        {creationDate && <span style={{color: 'green',}}><i className="fas fa-plus-circle"></i> {dayjs(creationDate).format('ll')}</span>}
        {updateDate && <span style={{color: 'green'}}><i className="fas fa-sync-alt"></i> {dayjs(updateDate).format('ll')}</span>}
      </p>
      <a href="https://mintransports.cm/" className={"text-hover-black"} style={{backgroundColor :"#FFA500"}} target={"_blank"}>{globalT("landing.header.savoir")}</a>
    </div>
  );
};

export default Communicator;
