import React, { useEffect, useRef, useState } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Header from "../acceuill/Header";
import Footer from "../acceuill/Footer";
import { globalT } from "../../lang";
import Button from "../../components/bootstrap/Button";
import { useNavigate } from "react-router-dom";
import LandingPage33 from "../../assets/img/landing2/MINT_11.webp";
import PaginationComponent from "../../components/PaginationComponent";
import RevueDocumentaire from "../../components/bootstrap/RevueDocumentaire";
import SearchComponent from "../../components/SearchComponent";
import Select from "../../components/bootstrap/forms/Select";
import Option from "../../components/bootstrap/Option";
import CategoryDoc from "../../commons/models/CategorieDoc";
import Documentation from "../../commons/models/Documentation";
import DataImage from "../../assets/img/landing2/vide.webp";
import mintClient from "../../network";
import newImage from "../../assets/img/landing2/back_copie.png";
import ImageSensibilisation from "../../components/ImageSensibilisation";
//import Backcopie from "../../assets/img/landing2/nouv/back_copie.png";

const Immatriculation = () => {
  const navigate = useNavigate();
  const total = useRef<number>();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [categorie, setCategorie] = useState<CategoryDoc[] | null>(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("1");
  const [loading, setLoading] = useState(true);
  const [documentationDocument, setDocumentationDocument] = useState<
    Documentation[] | null
  >();
  const sectionRef = React.useRef<HTMLDivElement | null>(null);
  const handleSearch = (value: any) => {
    setSearch(value);
    setPage(1);
  };
  const handleDocumentClick = (file: string) => {
    // Handle the document click here
  };
  const loadDocumentationDocument = () => {
    setLoading(true);
    // dispatch(setRequestGlobalLoader(true))
    mintClient.documentation
      .getAll({
        page: page,
        pageSize: 6,
        categoryId: selectedCategoryId,
        order: true,
        orderDirection: "ascending",
        validationState: 1,
        researchValue: search,
        eduCategory: true,
        isDocument: true,
      })
      .then((resp) => {
        const allDocumentationDocuments = resp.data.data;
        total.current = resp.data.total;
        setDocumentationDocument(allDocumentationDocuments);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };
  const loadCategorie = () => {
    mintClient.category
      .getAll({ isParent: true, parentId: 1, page: 1, pageSize: 100 })
      .then((resp) => {
        setCategorie(resp.data.data);
      })
      .catch(() => null);
  };

  useEffect(() => {
    if (!categorie) loadCategorie();
    loadDocumentationDocument();
  }, [selectedCategoryId, search]);

  return (
    <>
      {" "}
      <Header
        currentMenu={"service"}
        hasAutherImage={true}
        title={globalT("nav.service.immatriculation")}
        subtitle={globalT("nav.service")}
        image={LandingPage33}
      />
      <div className="container" id={"sectionId"} style={{ marginBottom: "4rem" }}>
        <ImageSensibilisation
          image={newImage}
          texte={ `${globalT("immatriculation.text")} !!!`}
        />{" "}
      </div>
      <div className="container global-presentation-style mb-5">
        <h1>
          <span>{globalT("proc.imma")}</span>
        </h1>
        <br />
        <p className="fs-5 text-justify">
          <span>{globalT("gamme.depliant")}</span>
        </p>

        <div className="mb-4">
          <h3 className="mt-3">{globalT("acquisition")}</h3>
          <ul>
            <li>{globalT("achete.vehic")}</li>
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="mt-3">{globalT("obt.cart")}</h3>
          <ul>
            {/*   <li>{globalT("achete.vehic")}</li> */}
            <li>
              {globalT("doc.nec")}
              <ul>
                <li>{globalT("fac.achat")}</li>
                <li>{globalT("cert.ded")}</li>
                <li>{globalT("cart.nat")}</li>
                <li>{globalT("justif")}</li>
              </ul>
            </li>
            <li>{globalT("ave.doc")}</li>
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="mt-3">{globalT("cont.tec")}</h3>
          <ul>
            <li>{globalT("pre.ve")}</li>
            <li>{globalT("certi.at")}</li>
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="mt-3">{globalT("ob.certi")}</h3>
          <ul>
            <li>{globalT("pour.vehi")}</li>
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="mt-3">{globalT("ass.vehi")}</h3>
          <ul>
            <li>{globalT("sous.ass")}</li>
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="mt-3">{globalT("de.ima")}</h3>
          <ul>
            <li>{globalT("rend.vous")}</li>
            <li>
              {globalT("doc.nec")}
              <ul>
                <li>{globalT("formu")}</li>
                <li>{globalT("cart")}</li>
                <li>{globalT("certi.vi")}</li>
                <li>{globalT("certi.non")}</li>
                <li>{globalT("preuve.ass")}</li>
                <li>{globalT("preu.po")}</li>
                <li>{globalT("piece.id")}</li>
              </ul>
            </li>
            <li>{globalT("paye")}</li>
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="mt-3">{globalT("obt.gri")}</h3>
          <ul>
            <li>{globalT("fois")}</li>
            <li>{globalT("contient")}</li>
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="mt-3">{globalT("plaque")}</h3>
          <ul>
            <li>{globalT("plaque.avoir")}</li>
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="mt-3">{globalT("delai")}</h3>
          <ul>
            <li>{globalT("delai.trait")}</li>
          </ul>
        </div>

        <p>{globalT("sui.eta")}</p>
      </div>
      <Footer />
    </>
  );
};

export default Immatriculation;
