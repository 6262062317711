/**
 * Auth Actions
 */
import {AppThunk} from "../../redux.types";
import mintClient from "../../../network";
import {setJwtData} from "../../../commons/helpers/jwtHelper";
import StorageHelper, {StorageKeys} from "../../../commons/helpers/StorageHelper";
import {LoginRequest, LoginSuperAdminRequest} from "../../../network/network.types";
import {LOGOUT_USER, SET_AUTH_USER, SET_AUTH_USER_FAILURE, SET_AUTH_USER_SUCCESS} from '../../actionTypes';
import {AUTH} from '../../../commons/urls/front';
import {setRequestGlobalLoader} from "../requestGlobalLoader/actions";

/**
 * Redux Action get auth information
 */
// export const setAuthUser = (): AppThunk<Promise<void>> => (dispatch) => {
//     dispatch({ type: SET_AUTH_USER });
//
//     return mintClient.users.auth
//         .getDetails()
//         .then(async (response) => {
//             dispatch({ type: SET_AUTH_USER_SUCCESS, payload: response.data.results });
//             return Promise.resolve();
//         })
//         .catch(async () => {
//             dispatch({ type: SET_AUTH_USER_FAILURE, payload: "error" });
//             // return setTimeout(() => Promise.reject(), 5000);
//             return Promise.reject();
//         });
// };

export const loginUser = (
    data: LoginRequest | LoginSuperAdminRequest
): AppThunk<Promise<void>> => (dispatch) => {
    return new Promise<void>((resolve, reject) => {

        dispatch({ type: SET_AUTH_USER });
        mintClient.users.auth.login(data)
            .then(async (res) => {
                setJwtData(
                    res.data.data.token,
                );
                StorageHelper.setItem(StorageKeys.USER_DATA, JSON.stringify(res.data.data))
                dispatch({ type: SET_AUTH_USER_SUCCESS, payload: res.data.data });
                resolve();
            })
            .catch((error) => {
                console.log('error :', error)
                dispatch({ type: SET_AUTH_USER_FAILURE, payload: "error" });
                reject(error)
            })
    });
};

export const logoutUser = (): AppThunk<Promise<void>> => async (dispatch) => {
    // await csuClient.users.auth.logout();
    const currentLocation = window.location.href;
    document.location.replace(AUTH.LOGIN)
    StorageHelper.removeItem(StorageKeys.USER_DATA);
    StorageHelper.removeItem(StorageKeys.JWT);
    StorageHelper.setItem(StorageKeys.LOCATION, currentLocation)

    // For later, we'll wrap the whole store to listen to this event and if received, clear the whole store
    // dispatch({ type: CLEAR_AUTH_USER_DATA, payload: null });
};
