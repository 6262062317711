import React, { useState } from "react";
import google from "../../assets/img/landing2/google.png";
import apple from "../../assets/img/landing2/apple.png";
import { dashboardMenu, mainMenu } from "../../menu";
import { globalT } from "../../lang";
import Button from "../../components/bootstrap/Button";
import { useAppSelector } from "../../store/redux.types";
import { useNavigate } from "react-router-dom";
import "./Home.scss";
import DialogComponent from "../../components/dialogs/DialogComponent";
import { DASHBOARD } from "../../commons/urls/front";
import LogoText from "../../components/LogoText";
import classNames from "classnames";
import IntlMessages from "../../components/IntlMessages";
//import Button from 'react-bootstrap/Button';

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import ContactAdd from "../NewsLetter/NewsLetterAdd";

const Footfoot = () => {
  const navigate = useNavigate();

  const [showContact, setShowContact] = useState<boolean>(false);

  const { authUserData } = useAppSelector(({ authUser }) => ({
    authUserData: authUser.data,
  }));

  const currentPath = window.location.href;
  return (
    <>
      <div className="container-fluid pt-4">
        <div className="row" style={{ height: "250px" }}>
          <div className="col-md-12  info  h-100">
            <div className=" text-white text-center fw-bold mx-4 rest">
              {globalT("landing.connect.platform.title")}
            </div>

            <div className="fit">
              <img
                src={apple}
                alt="google play"
                className="img-fluid apple m-4"
              />
              <img
                src={google}
                alt="google play"
                className="img-fluid google m-4"
              />
            </div>
          </div>
        </div>
      </div>
      <footer
        style={{ fontSize: "1.4rem" }}
        className="footer-section"
        id="section6"
      >
        <div className="container">
          <div className="footer-cta pt-5 ">
            <div className="row">
              {/*<div className="col-xl-4 col-md-4 mb-30">*/}
              {/*    <div className="single-cta">*/}
              {/*        <i className="fas fa-map-marker-alt"></i>*/}
              {/*        <div className="cta-text" >*/}
              {/*            <h4 className='cta-text2'> {globalT("retrouvez.nous")}</h4>*/}
              {/*            <span>{globalT("retrouvez.nous.sur.nos")}</span>*/}
              {/*        </div>*/}
              {/*    </div>*/}
              {/*</div>*/}
              <div className="col-xl-8 col-md-8 mb-30">
                <div className="single-cta">
                  <i className="fas fa-phone text-danger"></i>
                  <div className="cta-text">
                    <h4 className="cta-text2">{globalT("applelez.nous")}</h4>
                    <div className="d-flex flex-column">
                      <span>
                        {" "}
                        <span style={{ color: "orange" }}>
                          {globalT("footer.number.1")}
                        </span>{" "}
                        : (+237) 222 220 547{" "}
                      </span>
                      <span>
                        {" "}
                        <span style={{ color: "orange" }}>
                          {globalT("footer.number.2")}
                        </span>{" "}
                        : 222 220 741{" "}
                      </span>
                      <span>
                        {" "}
                        <span style={{ color: "orange" }}>
                          {globalT("footer.number.3")}
                        </span>{" "}
                        : 222 232 893{" "}
                      </span>
                      <span>
                        {" "}
                        <span style={{ color: "orange" }}>
                          {globalT("footer.number.4")}
                        </span>{" "}
                        : 222.232.893{" "}
                      </span>
                      <span>
                        {" "}
                        <span style={{ color: "orange" }}>
                          {globalT("footer.number.5")}
                        </span>{" "}
                        : 222 231 763{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="far fa-envelope-open"></i>
                  <div className="d-flex flex-column">
                    {/*<div className="cta-text">*/}
                    {/*    <h4 className='cta-text2'>{globalT("notre.mail")}</h4>*/}
                    {/*    <span>Mintransport@gmail.com</span>*/}
                    {/*</div>*/}
                    <div className="cta-text">
                      <h4 className="cta-text2">
                        {globalT("notre.mail.cellcom")}
                      </h4>
                      <span>mintransportscameroon@yahoo.com</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-content pt-5 pb-1">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <a href="index.html" className="RF">
                      {" "}
                      {!authUserData &&
                        !currentPath?.includes(DASHBOARD.INDEX) && (
                          <LogoText className="fw-bold" />
                        )}
                      <span
                        className="fw-bold fs-4 mt-2 cta-text2 "
                        style={{ marginLeft: "10px" }}
                      >
                        APSR
                      </span>
                    </a>
                  </div>
                  <div className="footer-text">
                    <p className="mt-3" style={{ fontSize: "1.3rem" }}>
                      {globalT("bilan.accident")}
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    <span className="cta-text2">{globalT("suivez.nous")}</span>
                    <ul className="social_icon">
                      <li>
                        <a
                          href="https://www.facebook.com/RoadsafetyCameroon/"
                          target={"_blank"}
                        >
                          <i className="fa-brands fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/people/Pr%C3%A9vention-et-s%C3%A9curit%C3%A9-routi%C3%A8res-du-Cameroun/100065217904287/"
                          target={"_blank"}
                        >
                          <i className="fa-brands fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/i/flow/login?redirect_after_login=%2Fcamertrans%3Flang%3Dfr"
                          target={"_blank"}
                        >
                          <i className="fa-brands fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/p/Cobt4esoogb/"
                          target={"_blank"}
                        >
                          <i className="fa-brands fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCV5Ldw9fSjMEoxtiyBLirQw/videos"
                          target={"_blank"}
                        >
                          <i className="fa-brands fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3 className="cta-text2">{globalT("lien.utiles")}</h3>
                  </div>
                  <ul>
                    <li>
                      <Button tag="a" isLink onClick={() => navigate("/")}>
                        {globalT("home")}
                      </Button>
                    </li>
                    <li>
                      <Button
                        tag="a"
                        isLink
                        onClick={() => navigate("/Education")}
                      >
                        {globalT("education.management")}
                      </Button>
                    </li>
                    <li>
                      <Button
                        tag="a"
                        isLink
                        onClick={() => navigate("/actualite")}
                      >
                        {globalT("landing.actualite.title")}
                      </Button>
                    </li>
                    <li>
                      <Button tag="a" isLink onClick={() => navigate("/")}>
                        {globalT("apropos")}
                      </Button>
                    </li>

                    <li>
                      <Button tag="a" isLink onClick={() => navigate("/video")}>
                        {globalT("landivideong.ministre.infos.")}
                      </Button>
                    </li>
                    <li>
                      <Button
                        tag="a"
                        isLink
                        onClick={() => navigate("/evenement")}
                      >
                        {globalT("evenement.management")}
                      </Button>
                    </li>

                    <li>
                      <Button
                        tag="a"
                        isLink
                        onClick={() => navigate("/accueil")}
                      >
                        FAQ
                      </Button>
                    </li>
                    <li>
                      <Button
                        tag="a"
                        isLink
                        onClick={() => navigate("/Campagne")}
                      >
                        {globalT("landing.campagne.title")}
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3 className="cta-text2">{globalT("souscrire")} </h3>
                  </div>
                  <div className="footer-text mb-25"></div>
                  <div className="footer-text mb-25">
                    <p className="" style={{ fontSize: "1.3rem" }}>
                      {globalT("ne.manquez")}
                    </p>
                  </div>
                  <form>
                    <div className="align-items-center col-auto d-block d-md-flex text-sm-left text-center mt-box">
                      {!authUserData && (
                        <div className="col-md-12">
                          <Button
                            icon="ReportProblem"
                            onClick={() => setShowContact(true)}
                            className="btn btn-outline-danger mx-2"
                          >
                            <IntlMessages id="form.field.title.contact" />
                          </Button>
                        </div>
                      )}
                      {authUserData &&
                        document.location.href.includes(DASHBOARD.INDEX) && (
                          <Button
                            tag="a"
                            isLink
                            color="primary"
                            to={mainMenu.home.path}
                            icon={mainMenu.home.icon}
                            aria-label={globalT(mainMenu.home.text as string)}
                            isLight={currentPath === mainMenu.home.path}
                            className={classNames("ms-md-3 mb-md-0")}
                          >
                            <IntlMessages id={mainMenu.home.text as string} />
                          </Button>
                        )}

                      {authUserData &&
                        !currentPath?.includes(
                          dashboardMenu.dashboard.path as string
                        ) && (
                          <>
                            <Button
                              icon="ReportProblem"
                              onClick={() => setShowContact(true)}
                              className="btn btn-outline-danger mx-2"
                            >
                              <IntlMessages id="form.field.title.contact" />
                            </Button>
                          </>
                        )}
                    </div>

                    <DialogComponent
                      show={showContact}
                      title=""
                      onClose={() => setShowContact(false)}
                      size="lg"
                    >
                      <ContactAdd onClose={() => setShowContact(false)} />
                    </DialogComponent>
                  </form>

                  <div
                    className="flash-arrow"
                    style={{ marginLeft: "97%", marginBottom: "2vw" }}
                  >
                    <a href="#section7">
                      <i
                        className="fa fa-angle-double-up"
                        style={{ fontSize: "80px", color: "orange" }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area bg-light">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-center text-lg-left fw-bold fs-4"
                style={{ marginLeft: "-2.3%" }}
              >
                <div className="copyright-text">
                  <p>
                    Copyright &copy; 2023, APSR. {globalT("tous.droit")}
                    <a href="https://mintransports.cm" target={"_blank"}>
                      {globalT("ministere")}
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-none d-lg-block text-right">
                <div
                  className="footer-menu fw-bold fs-8"
                  style={{ marginLeft: "35%" }}
                >
                  <ul>
                    {/*<li><a href="" className='RF'>{globalT("home")}</a></li>*/}
                    {/*<li><a href="terms" className='RF' >{globalT("termes")}</a></li>*/}
                    {/*<li><a href="confidentialite" className='RF'>{globalT("prive")}</a></li>*/}
                    <li>
                      <Button tag="a" isLink onClick={() => navigate("/")}>
                        {globalT("home")}
                      </Button>
                    </li>
                    <li>
                      <Button tag="a" isLink onClick={() => navigate("/terms")}>
                        {globalT("termes")}
                      </Button>
                    </li>
                    <li>
                      <Button
                        tag="a"
                        isLink
                        onClick={() => navigate("/confidentialite")}
                      >
                        {globalT("prive")}
                      </Button>
                    </li>
                    {/*<li><a href="confidentialite" className='RF'>{globalT("politique")}</a></li>*/}
                    {/*<li><a href="#" className='RF'>Contact</a></li>*/}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footfoot;
