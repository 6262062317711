export const HOME = '/';

export const AUTH = {
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password'
};

export const DASHBOARD = {
    ROUTES_ENGINE: {
        INDEX: 'dashboard/*',
        INDEX_CHANGEPASS: 'change',
    },
    INDEX: '/dashboard',
   
    INDEX_CHANGEPASS: '/dashboard/change',
}

export const ADMINISTRATION = {
    ROUTES_ENGINE: {
       INDEX: 'administration/*',
        ROLE: {
            INDEX: 'role/*',
            ADD: 'add',
            UPDATE: ':id/update'
        },
        USER: {
            INDEX: 'user/*',
            INDEX_: 'usager',
            ADD: 'add',
            UPDATE: ':id/update',
            DETAILS: ':id'
        }
    },
    ROLE: {
        INDEX: '/dashboard/administration/role',
        ADD: '/dashboard/administration/role/add',
        UPDATE: '/dashboard/administration/role/:id/update'
    },

    USER: {
        INDEX: '/dashboard/administration/user',
        INDEX_: '/dashboard/administration/user/usager',
        ADD: '/dashboard/administration/user/add',
        UPDATE: '/dashboard/administration/user/:id/update',
        DETAILS: '/dashboard/administration/user/:id',
    }
}

export const PUBLICATION = {
    ROUTES_ENGINE: {
        INDEX: 'publication/*',
        PUBLICATION: {
            INDEX: 'publication/*',
            ADD: 'add',
            UPDATE: ':id/update',
            DETAILS: ':id',
            ALL: 'all',
            PENDING: 'pending',
            VALIDATE: 'validate',
            INVALIDATE: 'invalidate',
        },
        CATHEGORIE: {
            INDEX: 'category/*',
            ADD: 'add',
            UPDATE: ':id/update',
            DETAILS: ':id',
        }
    },
    PUBLICATION: {
        INDEX: '/dashboard/publication/publication',
        ADD: '/dashboard/publication/publication/add',
        UPDATE: '/dashboard/publication/publication/:id/update',
        DETAILS: '/dashboard/publication/publication/:id',
        ALL: '/dashboard/publication/publication/aLL',
        PENDING: '/dashboard/publication/publication/pending',
        VALIDATE: '/dashboard/publication/publication/validate',
        INVALIDATE: '/dashboard/publication/publication/invalidate',
    },
    CATHEGORIE: {
        INDEX: '/dashboard/publication/category',
        ADD: '/dashboard/publication/category/add',
        UPDATE: '/dashboard/publication/category/:id/update',
        DETAILS: '/dashboard/publication/category/:id',
    }

}

export const INCIDENT = {
    ROUTES_ENGINE: {
        INDEX: 'incident/*',
        INCIDENT: {
            INDEX: 'incident/*',
            ADD: 'add',
            UPDATE: ':id/update',
            DETAILS: ':id',
            ALL: 'all',
            PENDING: 'pending',
            VALIDATE: 'validate',
            INVALIDATE: 'invalidate',
        },
        CATHEGORIE: {
            INDEX: 'category/*',
            ADD: 'add',
            UPDATE: ':id/update',
            DETAILS: ':id',
        }
    },
    INCIDENT: {
        INDEX: '/dashboard/incident/incident',
        ADD: '/dashboard/incident/incident/add',
        UPDATE: '/dashboard/incident/incident/:id/update',
        DETAILS: '/dashboard/incident/incident/:id',
        ALL: '/dashboard/incident/incident/aLL',
        PENDING: '/dashboard/incident/incident/pending',
        VALIDATE: '/dashboard/incident/incident/validate',
        INVALIDATE: '/dashboard/incident/incident/invalidate',
    },
    CATHEGORIE: {
        INDEX: '/dashboard/incident/category',
        ADD: '/dashboard/incident/category/add',
        UPDATE: '/dashboard/incident/category/:id/update',
        DETAILS: '/dashboard/incident/category/:id',
    }

}

export const CHAT = {
    INDEX: '/dashboard/chat'
}


export const CATEGORY = {
    ROUTES_ENGINE: {
        INDEX: 'category/*',
        ADD: 'add',
        UPDATE: ':id/update',
        DETAILS: ':id',
    },
    INDEX: '/dashboard/category',
    ADD: '/dashboard/category/add',
    UPDATE: '/dashboard/category/:id/update',
    DETAILS: '/dashboard/category/:id',
}



export const BOOT = {
    ROUTES_ENGINE: {
        INDEX: 'chatbot/*',
        BOOT: {
            INDEX: 'chatbot/*',
            ADD: 'add',
            UPDATE: ':id/update',
            DETAILS: ':id',
            QuestionUser: 'questionUser',
            ALL: 'all',
         
        },
        CATHEGORIE: {
            INDEX: 'category/*',
            ADD: 'add',
            UPDATE: ':id/update',
            DETAILS: ':id',
        }
    },
    BOOT: {
        INDEX: '/dashboard/chatbot/chatbot',
        ADD: '/dashboard/chatbot/chatbot/add',
        UPDATE: '/dashboard/chatbot/chatbot/:id/update',
        DETAILS: '/dashboard/chatbot/chatbot/:id',
        QuestioUser: '/dashboard/chatbot/chatbot/questionUser',
        ALL: '/dashboard/chatbot/chatbot/aLL',
      
    },
    CATHEGORIE: {
        INDEX: '/dashboard/chatbot/category',
        ADD: '/dashboard/chatbot/category/add',
        UPDATE: '/dashboard/chatbot/category/:id/update',
        DETAILS: '/dashboard/chatbot/category/:id',
    }
}

export const PARAGRAPH = {
    ROUTES_ENGINE: {
        INDEX: 'paragraph/*',
        ADD: 'add',
        UPDATE: ':id/update',
        DETAILS: ':id',
    },
    INDEX: '/dashboard/paragraph',
    ADD: '/dashboard/paragraph/add',
    UPDATE: '/dashboard/paragraph/:id/update',
    DETAILS: '/dashboard/paragraph/:id',
}


export const EDUCATION = {
    ROUTES_ENGINE: {
        INDEX: 'education/*',
        ADD: 'add',
        UPDATE: ':id/update',
        DETAILS: ':id',
    },
    INDEX: '/dashboard/education',
    ADD: '/dashboard/education/add',
    UPDATE: '/dashboard/education/:id/update',
    DETAILS: '/dashboard/education/:id',
}


export const DOCUMENTATION = {
    ROUTES_ENGINE: {
        INDEX: 'documentation/*',
        DOCUMENTATION: {
            INDEX: 'documentation/*',
            ADD: 'add',
            UPDATE: ':id/update',
            DETAILS: ':id',
            ALL: 'all',
          
        },
        CATHEGORIE: {
            INDEX: 'category/*',
            ADD: 'add',
            UPDATE: ':id/update',
            DETAILS: ':id',
        }
    },
    DOCUMENTATION: {
        INDEX: '/dashboard/documentation/documentation',
        ADD: '/dashboard/documentation/documentation/add',
        UPDATE: '/dashboard/documentation/documentation/:id/update',
        DETAILS: '/dashboard/documentation/documentation/:id',
        ALL: '/dashboard/documentation/documentation/aLL',
     
    },
    CATHEGORIE: {
        INDEX: '/dashboard/documentation/category',
        ADD: '/dashboard/documentation/category/add',
        UPDATE: '/dashboard/documentation/category/:id/update',
        DETAILS: '/dashboard/documentation/category/:id',
    }

}


export const ALERT = {
    ROUTES_ENGINE: {
        INDEX: 'alert/*',
        ALERT: {
            INDEX: 'alert/*',
            ADD: 'add',
            UPDATE: ':id/update',
            DETAILS: ':id',
            ALL: 'all',
          
        },
        CATHEGORIE: {
            INDEX: 'category/*',
            ADD: 'add',
            UPDATE: ':id/update',
            DETAILS: ':id',
        }
    },
    ALERT: {
        INDEX: '/dashboard/alert/alert',
        ADD: '/dashboard/alert/alert/add',
        UPDATE: '/dashboard/alert/alert/:id/update',
        DETAILS: '/dashboard/alert/alert/:id',
        ALL: '/dashboard/alert/alert/aLL',
     
    },
    CATHEGORIE: {
        INDEX: '/dashboard/alert/category',
        ADD: '/dashboard/alert/category/add',
        UPDATE: '/dashboard/alert/category/:id/update',
        DETAILS: '/dashboard/alert/category/:id',

}

}

export const EVENEMENT = {
    ROUTES_ENGINE: {
        INDEX: 'evenement/*',
        ADD: 'add',
        UPDATE: ':id/update',
        DETAILS: ':id',
    },
    INDEX: '/dashboard/evenement',
    ADD: '/dashboard/evenement/add',
    UPDATE: '/dashboard/evenement/:id/update',
    DETAILS: '/dashboard/evenement/:id',
}

export const DISCUSION = {
    ROUTES_ENGINE: {
        INDEX: 'discussion/*',
        ADD: 'add',
        UPDATE: ':id/update',
        DETAILS: ':id',
    },
    INDEX: '/dashboard/discussion',
    ADD: '/dashboard/discussion/add',
    UPDATE: '/dashboard/discussion/:id/update',
    DETAILS: '/dashboard/discussion/:id',
}

export const MODERATEUR = {
    ROUTES_ENGINE: {
        INDEX: 'moderateur/*',
        MODERATEUR: {
            INDEX: 'moderateur/*',
            ADD: 'add',
            UPDATE: ':id/update',
            DETAILS: ':id',
            ALL: 'all',
            ACTIVER: 'active',
            DESACTIVE: 'desactive',
            SUSPENDRE: 'suspendre',
        },
   
    },
    MODERATEUR: {
        INDEX: '/dashboard/moderateur/moderateur',
        ADD: '/dashboard/moderateur/moderateur/add',
        UPDATE: '/dashboard/moderateur/moderateur/:id/update',
        DETAILS: '/dashboard/moderateur/moderateur/:id',
        ALL: '/dashboard/moderateur/moderateur/aLL',
        ACTIVER: '/dashboard/moderateur/moderateur/active',
        DESACTIVE: '/dashboard/moderateur/moderateur/desactive',
        SUSPENDRE: '/dashboard/moderateur/moderateur/suspendre',
    },
}

export const CAMPAGNE = {
    ROUTES_ENGINE: {
        INDEX: 'campagne/*',
        ADD: 'add',
        UPDATE: ':id/update',
        DETAILS: ':id',
    },
    INDEX: '/dashboard/campagne',
    ADD: '/dashboard/campagne/add',
    UPDATE: '/dashboard/campagne/:id/update',
    DETAILS: '/dashboard/campagne/:id',
}

export const ERRORS = {
    ROUTES_ENGINE: {
        INDEX: 'errors/*',
        '403': '403',
        '404': '404',
    },
    '404': '/errors/404',
    '403': '/errors/403',
}

