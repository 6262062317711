import React, { useEffect, useRef, useState } from "react";
import DataImage from "../../../assets/img/landing2/vide.webp";
import { NavLink, useNavigate } from "react-router-dom";
import { globalT } from "../../../lang";
import { useAppDispatch, useAppSelector } from "../../../store/redux.types";
import Publication from "../../../commons/models/Publication";
import { setRequestGlobalLoader } from "../../../store/slices/requestGlobalLoader/actions";
import mintClient from "../../../network";
import Communicator from "../../../components/Communicator";
import Spinner from "../../../components/bootstrap/Spinner";
import { joinUrlWithParamsId } from "../../../commons/helpers/funcHelper";
import "../../Actualite-recente/Actualite.scss";
import SearchComponent from "../../../components/SearchComponent";
import PaginationComponent from "../../../components/PaginationComponent";
import RecentPublicationComponent from "../../../components/publication/RecentPublicationComponent";
import CardImgNoBorder from "../../../components/CardImgNoBorder";
import Navnav from "../../Navnav/Navnav";
import Footfoot from "../../Navnav/Footfoot";
import CardVid from "../../../components/bootstrap/CardVid";
import Header from "../Header";
import Footer from "../Footer";
import { LanguageDict } from "../../../commons/types";
import { setLanguage } from "../../../store/slices/settings/actions";
import Tooltips from "../../../components/bootstrap/Tooltips";
import Button from "../../../components/bootstrap/Button";
import LANG from "../../../lang/language";
import Nav, { NavItem } from "../../../components/bootstrap/Nav";
import classNames from "classnames";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../components/bootstrap/Dropdown";
import { AUTH } from "../../../commons/urls/front";
import DialogComponent from "../../../components/dialogs/DialogComponent";
import DrawerComponent from "../../../components/DrawerComponent";
import FrontAdd from "../../Incident/incident/FrontAdd";
import DetailFunc from "../../../components/DetailFunc";
import intero from "../../../assets/img/intero.png";
import logo from "../../../assets/img/landing2/nouv/logo3.png";
import logo2 from "../../../assets/img/landing2/nouv/mintlogo.png";



type headerProps = {
  currentMenu?:
    | "acceuil"
    | "actualite"
    | "about"
    | "presentation"
    | "analyse"
    | "revue"
    | "service"
    | "education";
  hasAutherImage?: boolean;
  image?: string;
  title?: string;
  subtitle?: string;
};

const Video = ({
  currentMenu = "acceuil",
  hasAutherImage = false,
  image,
  title,
  subtitle,
}: headerProps) => {
  const [publication, setPublication] = useState<Publication[] | null>(null);
  const [publicationHeader, setPublicationHeader] = useState<
    Publication[] | null
  >(null);
  const total = useRef<number>();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");


  const [active, setActive] = useState<
  | "acceuil"
  | "actualite"
  | "presentation"
  | "about"
  | "analyse"
  | "revue"
  | "service"
  | "education"
>(currentMenu);

const [show, setShow] = useState<boolean>(false);
const navigate = useNavigate();
const [showInfpanel, setShowInfPanel] = useState<boolean>(false);
const [showMobile, setShowMobile] = useState<boolean>(false);

const { catActualite } = useAppSelector(({ catActualite }) => catActualite);
const educationCat = useAppSelector(({ catRevue }) => catRevue);

const dispatch = useAppDispatch();

const { settings, authUserData } = useAppSelector(
  ({ settings, authUser }) => ({
    settings,
    authUserData: authUser.data,
  })
);

const changeLanguage = (locale: LanguageDict) => {
  if (locale !== settings.language) {
    dispatch(setLanguage(locale));
  }
};

  const loadPublicationHeader = () => {
    // dispatch(setRequestGlobalLoader(true))
    mintClient.publication
      .getAllVideo({
        page: 1,
        pageSize: 6,
        order: true,
        // "orderDirection": "ascending",
        validationState: 1,
        // "reseachValue": search,
        hasPagination: true,
      })
      .then((resp) => {
        const allPublications = resp.data.data;
        setPublicationHeader(allPublications);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        dispatch(setRequestGlobalLoader(false)); // Stop global loader in any case
      });
    // Start loading
  };

  const loadPublication = () => {
    setLoading(true); // Start loading
    mintClient.publication
      .getAllVideo({
        page: page,
        pageSize: 6,
        // "orderDirection": "ascending",
        validationState: 1,
        researchValue: search,
        order: true,
        hasPagination: true,
        langue: "fr",
      })
      .then((resp) => {
        const allPublications = resp.data.data;
        total.current = resp.data.total;
        setPublication(allPublications);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  const handleSearch = (e: any) => {
    setSearch(e);
    setPage(1);
    setLoading(true);
    loadPublication();
  };

  const loadData = async () => {
    if (!publication) await loadPublicationHeader();
  };

  useEffect(() => {
    loadPublication();
    loadPublicationHeader();
  }, [search, page]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
 <div>
        <div className="z-index-10 position-fixed w-100 ">
          <div className="">
            <div className="">
              <div className="row home-subNav z-index-10 ps-2 pe-3">
                <div className="col-3">
                  <div className="d-flex flex-column flex-sm-row">
                    <div className="d-flex align-items-center">
                      <img
                        src={logo2}
                        alt="logo2"
                        className="home-subNav__image2"
                      />
                      <img
                        src={logo}
                        alt="logo"
                        className="home-subNav__image1"
                      />
                      <span className="home-subNav__texte">APSR</span>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <Tooltips
                    title={"Vous êtes témoin d'un incident, signalez-le"}
                    placement={"right"}
                  >
                    {!authUserData && (
                      <div className="col-md-7 text-end">
                        <Button
                          isOutline
                          color="danger"
                          icon="ReportProblem"
                          onClick={() => setShow(true)}
                          className="text-danger text-hover-black"
                        >
                          <span
                            className="text-danger text-hover-black text-center"
                            style={{ fontSize: "1.3rem" }}
                          >
                            {globalT("incident.add.title")}
                          </span>
                        </Button>
                      </div>
                    )}
                  </Tooltips>
                </div>
                <div className="col-3 text-end">
                  <Button
                    isOutline
                    color="white"
                    onClick={() => changeLanguage(LANG.en.locale)}
                    aria-label="Change language"
                    className="text-hover-black"
                    data-tour="lang-selector"
                  >
                    <span className="text-hover-black">
                      {globalT(LANG.en.text)}
                    </span>
                  </Button>
                  <Button
                    isOutline
                    color="white"
                    onClick={() => changeLanguage(LANG.fr.locale)}
                    aria-label="Change language"
                    className="ms-2 mt-1 text-hover-black"
                    data-tour="lang-selector"
                  >
                    <div className="text-hover-black">
                      {globalT(LANG.fr.text)}
                    </div>
                  </Button>
                </div>
              </div>
              <div className="home-nav">
                <div className="row w-100 hideNav">
                  <div className="col-10 d-flex justify-content-end ">
                    <div className="d-flex align-items-center ">
                      {/*<Icon onClick={() => null} className="cursor-pointer d-xl-block d-xxl-none" color={"dark"} icon="ChevronLeft"></Icon>*/}
                      <Nav design={"pills"}>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "acceuil"}
                          onClick={() => {
                            setActive("acceuil");
                            navigate("*");
                          }}
                        >
                          <span>
                            <a
                              className={classNames("btn", {
                                "text-orange": active === "acceuil",
                              })}
                              onClick={() => navigate("/")}
                              role="button"
                            >
                              {globalT("home").toUpperCase()}
                            </a>
                          </span>
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "actualite"}
                          onClick={() => {
                            setActive("actualite");
                          }}
                        >
                          <span className="">
                            <Dropdown canUseClick={false} direction={"down"}>
                              <DropdownToggle>
                                <span
                                  className={classNames("btn", {
                                    "text-orange": active === "actualite",
                                  })}
                                >
                                  {globalT(
                                    "landing.actualite.title"
                                  ).toUpperCase()}
                                </span>
                              </DropdownToggle>
                              <DropdownMenu>
                                {catActualite?.map((cat) => (
                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange"
                                      onClick={() =>
                                        navigate(
                                          joinUrlWithParamsId(
                                            "/actualite/categorie/:id",
                                            cat.id
                                          ),
                                          {
                                            state: {
                                              categorie: {
                                                en: cat.nameEn,
                                                fr: cat.name,
                                              },
                                            },
                                          }
                                        )
                                      }
                                    >
                                      {(settings.language === "fr"
                                        ? cat.name
                                        : cat.nameEn
                                        ? cat.nameEn
                                        : cat.nameEn
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </span>

                          {/*<span>*/}
                          {/*    <a className={classNames("btn", {"text-orange": active === "actualite"})} onClick={() => navigate('/')} role="button">Actualité</a>*/}
                          {/*</span>*/}
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "presentation"}
                          onClick={() => {
                            setActive("presentation");
                          }}
                        >
                          <span>
                            <a
                              className={classNames("btn", {
                                "text-orange": active === "presentation",
                              })}
                              onClick={() => navigate("/presentation")}
                              role="button"
                            >
                              {globalT("nav.presentation").toUpperCase()}
                            </a>
                          </span>
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "education"}
                          onClick={() => {
                            setActive("education");
                          }}
                        >
                          <span className="">
                            <Dropdown canUseClick={false} direction={"down"}>
                              <DropdownToggle>
                                <span
                                  className={classNames("btn", {
                                    "text-orange": active === "education",
                                  })}
                                >
                                  {globalT(
                                    "education.management"
                                  ).toUpperCase()}
                                </span>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem>
                                  <span>
                                    <Dropdown
                                      canUseClick={false}
                                      direction={"start"}
                                    >
                                      <DropdownToggle>
                                        <span
                                          className={classNames(
                                            "btn text-hover-orange"
                                          )}
                                        >
                                          {globalT(
                                            "nav.education.sensibilisation"
                                          ).toUpperCase()}
                                        </span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem>
                                          <span
                                            className="text-hover-orange"
                                            onClick={() =>
                                              navigate("/campagne/after")
                                            }
                                          >
                                            {globalT(
                                              "campagne.management"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownItem>
                                        <DropdownItem>
                                          <span
                                            className="text-hover-orange"
                                            onClick={() =>
                                              navigate("/evenement/after")
                                            }
                                          >
                                            {globalT(
                                              "nav.events"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                </DropdownItem>
                                <DropdownItem>
                                  <span>
                                    <Dropdown
                                      canUseClick={false}
                                      direction={"down"}
                                    >
                                      <DropdownToggle>
                                        <span
                                          className={classNames(
                                            "btn text-hover-orange"
                                          )}
                                        >
                                          {globalT(
                                            "nav.education.media"
                                          ).toUpperCase()}
                                        </span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem>
                                          <span
                                            className="text-hover-orange"
                                            onClick={() =>
                                              navigate("/education/audio")
                                            }
                                          >
                                            {globalT(
                                              "education.audio"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownItem>
                                        <DropdownItem>
                                          <span
                                            className="text-hover-orange"
                                            onClick={() =>
                                              navigate("/education/video")
                                            }
                                          >
                                            {globalT(
                                              "education.video"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownItem>
                                        <DropdownItem>
                                          <span
                                            className="text-hover-orange"
                                            onClick={() =>
                                              navigate("/education/depliant")
                                            }
                                          >
                                            {globalT(
                                              "education.depliant"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "analyse"}
                          onClick={() => {
                            setActive("analyse");
                          }}
                        >
                          <span>
                            <a
                              className={classNames("btn", {
                                "text-orange": active === "analyse",
                              })}
                              onClick={() => navigate("/analyse")}
                              role="button"
                            >
                              {globalT("nav.analyse").toUpperCase()}
                            </a>
                          </span>
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "service"}
                          onClick={() => {
                            setActive("service");
                          }}
                        >
                          <span className="">
                            <Dropdown canUseClick={false} direction={"down"}>
                              <DropdownToggle>
                                <span
                                  className={classNames("btn", {
                                    "text-orange": active === "service",
                                  })}
                                >
                                  {globalT("nav.service").toUpperCase()}
                                </span>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem>
                                  <span
                                    className="text-hover-orange ms-4 text-black"
                                    onClick={() =>
                                      navigate("/immatriculation/usager")
                                    }
                                  >
                                    {globalT(
                                      "nav.service.immatriculation"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownItem>
                                <DropdownItem>
                                  <span
                                    className="text-hover-orange ms-4 text-black"
                                    onClick={() => navigate("/service/permis")}
                                  >
                                    {globalT(
                                      "nav.service.permis"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownItem>

                                <DropdownItem>
                                  <span
                                    className="text-hover-orange ms-4 text-black"
                                    onClick={() =>
                                      navigate("/services/autoEcole")
                                    }
                                  >
                                    {globalT("nav.service.ecole").toUpperCase()}
                                  </span>
                                </DropdownItem>
                                <DropdownItem>
                                  <span
                                    className="text-hover-orange ms-4 text-black"
                                    onClick={() => navigate("/services/centre")}
                                  >
                                    {globalT(
                                      "nav.service.centre"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownItem>
                                <DropdownItem>
                                  <span
                                    className="text-hover-orange ms-4 text-black"
                                    onClick={() => navigate("/services/ong")}
                                  >
                                    {globalT(
                                      "nav.service.demande"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                        </NavItem>
                        <NavItem
                          className="cursor-pointer"
                          isActive={active === "revue"}
                          onClick={() => {
                            setActive("revue");
                          }}
                        >
                          <span className="">
                            <Dropdown canUseClick={false} direction={"down"}>
                              <DropdownToggle>
                                <span
                                  className={classNames("btn", {
                                    "text-orange": active === "revue",
                                  })}
                                >
                                  {globalT("document.management").toUpperCase()}
                                </span>
                              </DropdownToggle>
                              <DropdownMenu>
                                {educationCat.catRevue?.map((cat) => (
                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange"
                                      onClick={() =>
                                        navigate(
                                          joinUrlWithParamsId(
                                            "/revue/categorie/:id",
                                            cat.id
                                          ),
                                          {
                                            state: {
                                              categorie: {
                                                en: cat.nameEn,
                                                fr: cat.name,
                                              },
                                            },
                                          }
                                        )
                                      }
                                    >
                                      {(settings.language === "fr"
                                        ? cat.name
                                        : cat.nameEn
                                        ? cat.nameEn
                                        : cat.nameEn
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                        </NavItem>
                      </Nav>
                      {/*<Icon onClick={() => null} className="cursor-pointer d-xl-block d-xxl-none" color={"dark"} icon={"ChevronRight"}></Icon>*/}
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="d-flex justify-content-end">
                      <Button onClick={() => navigate("/About")}>
                        <img
                          src={intero}
                          alt="imf"
                          className="img-fluid imageIntero me-3"
                        />
                      </Button>
                      <Button
                        color={"secondaire"}
                        onClick={() => navigate(AUTH.LOGIN)}
                      >
                        <span className="text-white">
                          {globalT("se.connecter")}
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="home-nav-moble">
                <div className="hideMobileButton w-100 mt-2 d-flex justify-content-end">
                  <div className="me-2 mb-2">
                    <div className="d-flex justify-content-end">
                      <Button onClick={() => setShowInfPanel(true)}>
                        <img
                          src={intero}
                          alt="imf"
                          className="img-fluid imageIntero me-3"
                        />
                      </Button>
                      <Button
                        color={"secondaire"}
                        onClick={() => navigate(AUTH.LOGIN)}
                      >
                        <span className="text-white mb-1">
                          {globalT("se.connecter")}
                        </span>
                      </Button>
                    </div>
                  </div>
                  <Button
                    color={"secondaire"}
                    icon={"Menu"}
                    className="me-3"
                    onClick={() => setShowMobile(!showMobile)}
                  />
                </div>
                {showMobile && (
                  <div className={classNames("row w-100 hideMobileButton")}>
                    <div className="col-12 d-flex justify-content-center">
                      <div className="d-flex align-items-center">
                        {/*<Icon onClick={() => null} className="cursor-pointer d-xl-block d-xxl-none" color={"dark"} icon="ChevronLeft"></Icon>*/}
                        <Nav
                          verticalBreakpoint={"sm"}
                          tag={"ul"}
                          design={"pills"}
                        >
                          <NavItem
                            className="cursor-pointer"
                            isActive={active === "acceuil"}
                            onClick={() => {
                              setActive("acceuil");
                              navigate("*");
                            }}
                          >
                            <span>
                              <a
                                className={classNames("btn", {
                                  "text-orange": active === "acceuil",
                                })}
                                onClick={() => navigate("/")}
                                role="button"
                              >
                                {globalT("home").toUpperCase()}
                              </a>
                            </span>
                          </NavItem>
                          <NavItem
                            className="cursor-pointer"
                            isActive={active === "actualite"}
                            onClick={() => {
                              setActive("actualite");
                            }}
                          >
                            <span className="">
                              <Dropdown direction={"down"}>
                                <DropdownToggle>
                                  <span
                                    className={classNames("btn", {
                                      "text-orange": active === "actualite",
                                    })}
                                  >
                                    {globalT(
                                      "landing.actualite.title"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {catActualite?.map((cat) => (
                                    <DropdownItem>
                                      <span
                                        className="text-hover-orange"
                                        onClick={() =>
                                          navigate(
                                            joinUrlWithParamsId(
                                              "/actualite/categorie/:id",
                                              cat.id
                                            ),
                                            {
                                              state: {
                                                categorie: {
                                                  en: cat.nameEn,
                                                  fr: cat.name,
                                                },
                                              },
                                            }
                                          )
                                        }
                                      >
                                        {(settings.language === "fr"
                                          ? cat.name
                                          : cat.nameEn
                                          ? cat.nameEn
                                          : cat.nameEn
                                        ).toUpperCase()}
                                      </span>
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </Dropdown>
                            </span>

                            {/*<span>*/}
                            {/*    <a className={classNames("btn", {"text-orange": active === "actualite"})} onClick={() => navigate('/')} role="button">Actualité</a>*/}
                            {/*</span>*/}
                          </NavItem>
                          <NavItem
                            className="cursor-pointer"
                            isActive={active === "presentation"}
                            onClick={() => {
                              setActive("presentation");
                            }}
                          >
                            <span>
                              <a
                                className={classNames("btn", {
                                  "text-orange": active === "presentation",
                                })}
                                onClick={() => navigate("/presentation")}
                                role="button"
                              >
                                {globalT("nav.presentation").toUpperCase()}
                              </a>
                            </span>
                          </NavItem>
                          <NavItem
                            className="cursor-pointer"
                            isActive={active === "education"}
                            onClick={() => {
                              setActive("education");
                            }}
                          >
                            <span className="">
                              <Dropdown direction={"down"}>
                                <DropdownToggle>
                                  <span
                                    className={classNames("btn", {
                                      "text-orange": active === "education",
                                    })}
                                  >
                                    {globalT(
                                      "education.management"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem>
                                    <span>
                                      <Dropdown direction={"start"}>
                                        <DropdownToggle>
                                          <span
                                            className={classNames(
                                              "btn text-hover-orange"
                                            )}
                                          >
                                            {globalT(
                                              "nav.education.sensibilisation"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem>
                                            <span
                                              className="text-hover-orange"
                                              onClick={() =>
                                                navigate("/campagne/after")
                                              }
                                            >
                                              {globalT(
                                                "campagne.management"
                                              ).toUpperCase()}
                                            </span>
                                          </DropdownItem>
                                          <DropdownItem>
                                            <span
                                              className="text-hover-orange"
                                              onClick={() =>
                                                navigate("/evenement/after")
                                              }
                                            >
                                              {globalT(
                                                "nav.events"
                                              ).toUpperCase()}
                                            </span>
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </span>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <span>
                                      <Dropdown direction={"down"}>
                                        <DropdownToggle>
                                          <span
                                            className={classNames(
                                              "btn text-hover-orange"
                                            )}
                                          >
                                            {globalT(
                                              "nav.education.media"
                                            ).toUpperCase()}
                                          </span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem>
                                            <span
                                              className="text-hover-orange"
                                              onClick={() =>
                                                navigate("/education/audio")
                                              }
                                            >
                                              {globalT(
                                                "education.audio"
                                              ).toUpperCase()}
                                            </span>
                                          </DropdownItem>
                                          <DropdownItem>
                                            <span
                                              className="text-hover-orange"
                                              onClick={() =>
                                                navigate("/education/video")
                                              }
                                            >
                                              {globalT(
                                                "education.video"
                                              ).toUpperCase()}
                                            </span>
                                          </DropdownItem>
                                          <DropdownItem>
                                            <span
                                              className="text-hover-orange"
                                              onClick={() =>
                                                navigate("/education/depliant")
                                              }
                                            >
                                              {globalT(
                                                "education.depliant"
                                              ).toUpperCase()}
                                            </span>
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </span>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </span>
                          </NavItem>
                          <NavItem
                            className="cursor-pointer"
                            isActive={active === "analyse"}
                            onClick={() => {
                              setActive("analyse");
                            }}
                          >
                            <span>
                              <a
                                className={classNames("btn", {
                                  "text-orange": active === "analyse",
                                })}
                                onClick={() => navigate("/analyse")}
                                role="button"
                              >
                                {globalT("nav.analyse").toUpperCase()}
                              </a>
                            </span>
                          </NavItem>
                          <NavItem
                            className="cursor-pointer"
                            isActive={active === "service"}
                            onClick={() => {
                              setActive("service");
                            }}
                          >
                            <span className="">
                              <Dropdown direction={"start"}>
                                <DropdownToggle>
                                  <span
                                    className={classNames("btn", {
                                      "text-orange": active === "service",
                                    })}
                                  >
                                    {globalT("nav.service").toUpperCase()}
                                  </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange ms-4 text-black"
                                      onClick={() =>
                                        navigate("/immatriculation/usager")
                                      }
                                    >
                                      {globalT(
                                        "nav.service.immatriculation"
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange ms-4 text-black"
                                      onClick={() =>
                                        navigate("/service/permis")
                                      }
                                    >
                                      {globalT(
                                        "nav.service.permis"
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>

                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange ms-4 text-black"
                                      onClick={() =>
                                        navigate("/services/autoEcole")
                                      }
                                    >
                                      {globalT(
                                        "nav.service.ecole"
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange ms-4 text-black"
                                      onClick={() =>
                                        navigate("/services/centre")
                                      }
                                    >
                                      {globalT(
                                        "nav.service.centre"
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <span
                                      className="text-hover-orange ms-4 text-black"
                                      onClick={() => navigate("/services/ong")}
                                    >
                                      {globalT(
                                        "nav.service.demande"
                                      ).toUpperCase()}
                                    </span>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </span>
                          </NavItem>
                          <NavItem
                            className="cursor-pointer"
                            isActive={active === "revue"}
                            onClick={() => {
                              setActive("revue");
                            }}
                          >
                            <span className="">
                              <Dropdown direction={"down"}>
                                <DropdownToggle>
                                  <span
                                    className={classNames("btn", {
                                      "text-orange": active === "revue",
                                    })}
                                  >
                                    {globalT(
                                      "document.management"
                                    ).toUpperCase()}
                                  </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {educationCat.catRevue?.map((cat) => (
                                    <DropdownItem>
                                      <span
                                        className="text-hover-orange"
                                        onClick={() =>
                                          navigate(
                                            joinUrlWithParamsId(
                                              "/revue/categorie/:id",
                                              cat.id
                                            ),
                                            {
                                              state: {
                                                categorie: {
                                                  en: cat.nameEn,
                                                  fr: cat.name,
                                                },
                                              },
                                            }
                                          )
                                        }
                                      >
                                        {(settings.language === "fr"
                                          ? cat.name
                                          : cat.nameEn
                                          ? cat.nameEn
                                          : cat.nameEn
                                        ).toUpperCase()}
                                      </span>
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </Dropdown>
                            </span>
                          </NavItem>
                        </Nav>
                        {/*<Icon onClick={() => null} className="cursor-pointer d-xl-block d-xxl-none" color={"dark"} icon={"ChevronRight"}></Icon>*/}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <DialogComponent
          show={show}
          title={globalT("incident.add.title")}
          onClose={() => setShow(false)}
          size="lg"
        >
          <FrontAdd onClose={() => setShow(false)} />
        </DialogComponent>
        <DrawerComponent
          type={"right"}
          onclose={() => setShowInfPanel(false)}
          onOpen={() => setShowInfPanel(true)}
          data={null}
          label={globalT("detail.info")}
          open={showInfpanel}
        >
          <DetailFunc />
        </DrawerComponent>
      </div>      {publicationHeader ? (
        <div className="container">
          <div className="ContainerActualite" style={{ marginTop: "150px" }}>
            <h1 className="mb-4" style={{ fontWeight: "bold" }}>
              {globalT("video")}
            </h1>
            <div className="row">
              <div className="col-sm-8 mb-4">
                {publicationHeader && publicationHeader[0] ? (
                  <CardImgNoBorder
                    title={publicationHeader[0].name}
                    titleEn={publicationHeader[0].nameEn}
                    images={
                      publicationHeader[0].attachement[0].attachmentFile || ""
                    }
                    date={publicationHeader[0].createdAt}
                    id={publicationHeader[0].id}
                    newLabel="New"
                    description={publicationHeader[0].categoryName}
                    views={120}
                    type="video"
                    comments={30}
                    action={{
                      detail: () =>
                        navigate(
                          joinUrlWithParamsId(
                            "/actualite/:id",
                            publicationHeader[0].id
                          )
                        ),
                    }}
                  />
                ) : (
                  <>
                    <div className="text-center mt-5 mb-5">
                      <div
                        style={{
                          width: "500px", // or any size you want
                          height: "500px", // make it a square
                          backgroundImage: `url(${DataImage})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          margin: "0 auto", // this will center the div if the parent div has a text-align: center
                        }}
                      />
                      <span
                        className="fw-bold"
                        style={{
                          display: "block",
                          marginTop: "20px",
                          fontSize: " 24px",
                        }}
                      >
                        {globalT("no.vidoe.available")}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="col-sm-3 ml-auto " style={{ marginLeft: "auto" }}>
                <h2
                  className="text-danger text-uppercase fw-bold mb-4"
                  style={{ fontSize: "1.5rem" }}
                >
                  VIDEOS RECENTES
                </h2>
                <div
                  style={{
                    height: "3px",
                    background:
                      "linear-gradient(to left , yellow 80%, green 20%)",
                  }}
                  className="mb-4"
                ></div>
                {publicationHeader && publicationHeader.length > 0 ? (
                  <div className="d-flex flex-column align-items-start mb-4">
                    {publicationHeader && publicationHeader[0] && (
                      <div className="mb-4">
                        <RecentPublicationComponent
                          title={publicationHeader[0].name}
                          titleEn={publicationHeader[0].nameEn}
                          image={
                            publicationHeader[0]?.attachement[0]
                              .attachmentFile || ""
                          }
                          id={publicationHeader[0].id}
                          type="video"
                          views={120}
                          comments={30}
                          description={publicationHeader[0].categoryName}
                        />
                      </div>
                    )}
                    {publicationHeader && publicationHeader[1] && (
                      <div className="mb-4">
                        <RecentPublicationComponent
                          title={publicationHeader[1].name}
                          titleEn={publicationHeader[1].nameEn}
                          image={
                            publicationHeader[1]?.attachement[0]
                              ?.attachmentFile || ""
                          }
                          id={publicationHeader[1].id}
                          views={120}
                          type="video"
                          comments={30}
                          description={publicationHeader[1].categoryName}
                        />
                      </div>
                    )}
                    {publicationHeader && publicationHeader[2] && (
                      <div className="mb-4">
                        <RecentPublicationComponent
                          title={publicationHeader[2].name}
                          titleEn={publicationHeader[2].nameEn}
                          image={
                            publicationHeader[2]?.attachement[0]
                              ?.attachmentFile || ""
                          }
                          id={publicationHeader[2].id}
                          views={120}
                          type="video"
                          comments={30}
                          description={publicationHeader[2].categoryName}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="text-center mt-5 mb-5">
                      <div
                        style={{
                          width: "250px", // or any size you want
                          height: "250px", // make it a square
                          backgroundImage: `url(${DataImage})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          margin: "0 auto", // this will center the div if the parent div has a text-align: center
                        }}
                      />
                      <span
                        className="fw-bold"
                        style={{
                          display: "block",
                          marginTop: "20px",
                          fontSize: "14px",
                        }}
                      >
                        {globalT("no.vidoe.available")}
                      </span>
                    </div>
                  </>
                )}

                <h2
                  className="text-danger text-uppercase fw-bold mb-4"
                  style={{ fontSize: "1.5rem" }}
                >
                  {globalT("listOfCommunication")}
                </h2>
                <div
                  style={{
                    height: "3px",
                    background:
                      "linear-gradient(to left , yellow 80%, green 20%)",
                  }}
                  className="mb-4"
                ></div>
                {publicationHeader && publicationHeader[4] ? (
                  <>
                    {publicationHeader && (
                      <Communicator
                        name={publicationHeader[4].name}
                        nameEn={publicationHeader[4].nameEn}
                        creationDate={publicationHeader[4].createdAt}
                        updateDate={publicationHeader[4].updatedAt}
                      />
                    )}

                    {publicationHeader[5] && (
                      <Communicator
                        name={publicationHeader[5].name}
                        nameEn={publicationHeader[5].nameEn}
                        creationDate={publicationHeader[5].createdAt}
                        updateDate={publicationHeader[5].updatedAt}
                      />
                    )}
                    {publicationHeader[6] && (
                      <Communicator
                        name={publicationHeader[6].name}
                        nameEn={publicationHeader[6].nameEn}
                        creationDate={publicationHeader[6].createdAt}
                        updateDate={publicationHeader[6].updatedAt}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <div className="text-center mt-5 mb-5">
                      <div
                        style={{
                          width: "250px", // or any size you want
                          height: "250px", // make it a square
                          backgroundImage: `url(${DataImage})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          margin: "0 auto", // this will center the div if the parent div has a text-align: center
                        }}
                      />
                      <span
                        className="fw-bold"
                        style={{
                          display: "block",
                          marginTop: "20px",
                          fontSize: "14px",
                        }}
                      >
                        {globalT("no.vidoe.available")}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "200px" }}
        >
          <div
            className="spinner-border"
            style={{ height: "6rem", width: "6rem", color: "#aae0aa" }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      <section className="container pt-5 composant3">
        <h1 style={{ fontWeight: "bold" }}>{globalT("video.list")}</h1>
        <br></br>
        <div className="text-end">
          {loading && (
            <>
              <Spinner customColor="#aae0aa" size="6rem" />
            </>
          )}{" "}
          <br />
          <SearchComponent onSearch={handleSearch} />
        </div>

        <div className="row py-4">
          {publication && publication.length > 0 ? (
            <>
              {publication.map((pub, index) => (
                <div className="col-lg-3 col-md-6 mb-4" id={`no${index}`}>
                  <CardVid
                    title={pub.name}
                    titleEn={pub.nameEn}
                    images={pub?.attachement}
                    id={pub.id}
                    date={pub.createdAt}
                    action={{
                      detail: () =>
                        navigate(joinUrlWithParamsId("/video/:id", pub.id)),
                    }}
                  />
                </div>
              ))}
            </>
          ) : (
            <div className="text-center mt-5 mb-5">
              <div
                style={{
                  width: "400px", // or any size you want
                  height: "400px", // make it a square
                  backgroundImage: `url(${DataImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  margin: "0 auto", // this will center the div if the parent div has a text-align: center
                }}
              />
              <span
                className="fw-bold"
                style={{
                  display: "block",
                  marginTop: "20px",
                  fontSize: "24px",
                }}
              >
                {globalT("no.vidoe.available")}
              </span>
            </div>
          )}
        </div>
        <PaginationComponent
          pages={Math.ceil((total.current || 0) / 6)}
          currentPage={page}
          onPageChange={(page) => setPage(page)}
          rangeLimit={5}
        />
      </section>

      <Footer />
    </>
  );
};

export default Video;
