export default class Discusion {
    public id: string;
    public description: string;
    public descriptionEn: string;
    public name: string;
    public nameEn: string;
    public totalMessage: number;

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.nameEn = data.nameEn;
        this.totalMessage = data.totalMessage;
        this.description = data.description;
        this.descriptionEn = data.descriptionEn;
    }
}