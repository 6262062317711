import dayjs from "dayjs";

export class Paragraph {
    public id: string;
    public images: string[];
    public publicationId: number;
    public description: string;
    public descriptionEn: string;
    public name: string;
    public nameEn: string;
    public createdAt: dayjs.Dayjs;
    public updatedAt: dayjs.Dayjs;

    constructor(data: any) {
        this.id = data.id;
        this.images = data.image;
        this.publicationId = data.publicationId;
        this.description = data.description;
        this.descriptionEn = data.descriptionEn;
        this.name = data.name;
        this.nameEn = data.nameEn;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt
    }

}