export default class Role {
	public id: string;
	public name: string;
	public claims: any[];

	constructor(data: any) {
		this.id = data.id;
		this.name = data.name;
		this.claims = data.claims;
	}
}
