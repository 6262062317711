import React from 'react';

const Question3 = () => {
    return (
        <div className='d-flex flex-column text-start ms-3'>
            <span>1- La signalisation.</span>
            <span>2- Les intersections.</span>
            <span>3- Partager l'espace routier</span>
            <span>4- La vitesse de circulation.</span>
        </div>
    );
};

export default Question3;