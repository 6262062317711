import dayjs from 'dayjs';
import Permission from './Permission';
import UserData from "./UserData";

export default class User {
	public id: string;
	public menuClaims: any;
	public jobClaims: any;
	public allUserClaims: string[];
	public token: string;
	public name: string;
	public user: UserData;


	constructor(data: any) {
		this.id = data.id;
		this.menuClaims = data.menuClaims;
		this.jobClaims = data.jobClaims;
		this.allUserClaims = data.allUserClaims
		this.token = data.token;
		this.name = data.name;
		this.user = data.user
	}

	getAllPermissionNames = (): string[] => this.allUserClaims

	// getFullGenderKey = () => {
	// 	return this.gender === 'M' ? `form.field.gender.male` : `form.field.gender.female`;
	// }
}
