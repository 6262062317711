import React, { useEffect, useRef, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import newImage from "../../assets/img/landing2/back_copie.png";
import { globalT } from "../../lang";
import LANG from "../../lang/language";
import Tooltips from "../../components/bootstrap/Tooltips";
import SearchComponent from "../../components/SearchComponent";
import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../components/bootstrap/Dropdown";
import Button from "../../components/bootstrap/Button";
import Select from "../../components/bootstrap/forms/Select";
import Option from "../../components/bootstrap/Option";
import Spinner from "../../components/bootstrap/Spinner";
import CardDocument from "./card/CardDocument";
import DataImage from "../../assets/img/landing2/vide.webp";
import DialogComponent from "../../components/dialogs/DialogComponent";
import EncryptedPdfViewer from "../../components/ReadPdf";
import PaginationComponent from "../../components/PaginationComponent";
import V3 from "../../assets/img/landing2/stats.gif";
import mintClient from "../../network";
import ImageSensibilisation from "../../components/ImageSensibilisation";

const Analyse = () => {
  const [searchValue, setSearchValue] = useState("");
  const [documents, setDocuments] = useState<any[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const [currentDoc, setCurrentDoc] = useState<string | null>(null);
  const total = useRef<number>();

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleDocumentClick = (file: string) => {
    setCurrentDoc(file);
    setShowPdf(true);
  };

  const handleFilteredDocumentsPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const loadDocumentation = () => {
    setLoading(true); // Start loading
    mintClient.documentation
      .getAll({
        page: currentPage,
        pageSize: 10,
        orderDirection: "ascending",
        researchValue: searchValue,
        hasPagination: true,
        isDocument: true,
        langue: "fr",
        categoryId: 11,
      })
      .then((resp) => {
        const allDocuments = resp.data.data;
        total.current = resp.data.total;
        setDocuments(allDocuments);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadDocumentation();
  }, [currentPage, searchValue]);

  return (
    <>
      <Header currentMenu={"analyse"} />
      <div className="container" id={"sectionId"}>
        <ImageSensibilisation
          image={newImage}
          texte={ `${globalT("v3.text")} !!!`}
        />{" "}
      </div>

      <div className="container " style={{ marginTop: "150px" }}>
        <div className="d-flex">
          <h1 className="mb-4" style={{ fontWeight: "bold" }}>
            {globalT("analyse.stat.title")}{" "}
          </h1>
        </div>

        <div className="row mb-4" style={{ marginTop: "20px" }}>
          <div className="col-md-9">
            {/*<h2 className="mb-3">{globalT("edu.h3.pa")}</h2>*/}
            <p style={{ fontSize: "17px" }}>{globalT("v3.text")}</p>
          </div>
          <div className="col-md-3">
            <img
              src={V3}
              alt={"categorieName"}
              style={{ height: "300px", width: "100%" }}
              className="img-fluid"
            />
          </div>
        </div>

        <div
          style={{
            marginTop: "50px",
            height: "3px",
            background: "linear-gradient(to left , yellow 80%, red 20%)",
          }}
          className="mb-4"
        ></div>

        <div className="row mb-4">
          <div className="col-md-6">
            {/*<h2 className="mb-3">{globalT("educ.research")}</h2>*/}
          </div>
          <div className="col-md-6">
            <div className="row mb-4">
              {/* Replace with your search component */}
              <div className="col-md-6 mt-10">
                <p className="fw-bold" style={{ marginLeft: "40px" }}>
                  {globalT("educ.research.vid")}
                </p>
                <SearchComponent onSearch={handleSearch} />
              </div>
              {/* Directly place SelectFromRemote component here */}
            </div>
          </div>
        </div>
        {documents ? (
          <></>
        ) : (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "200px" }}
          >
            <div
              className="spinner-border"
              style={{ height: "6rem", width: "6rem", color: "#aae0aa" }}
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <section className="container pt-3">
          <div className="row py-4">
            {documents && documents.length > 0 ? (
              <>
                <div className="d-flex justify-content-between mb-4">
                  <span></span>
                  {loading && <Spinner size="3rem" customColor="#aae0aa" />}
                </div>
                <div className="row">
                  {documents.map((pub) => (
                    <div className="col-4 mb-2">
                      <CardDocument
                        id={pub.id}
                        name={pub.name}
                        nameEn={pub.nameEn}
                        mp3={pub.file}
                        onClick={() => handleDocumentClick(pub.file)}
                        createAt={pub.createdAt}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="text-center mt-5 mb-5">
                <div className="text-center mt-5 mb-5">
                  <div
                    style={{
                      width: "400px", // or any size you want
                      height: "400px", // make it a square
                      backgroundImage: `url(${DataImage})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      margin: "0 auto", // this will center the div if the parent div has a text-align: center
                    }}
                  />
                  <span
                    className="fw-bold"
                    style={{
                      display: "block",
                      marginTop: "20px",
                      fontSize: "24px",
                    }}
                  >
                    {globalT("custum.list.empty")}
                  </span>
                </div>
              </div>
            )}
          </div>

          {showPdf && currentDoc && (
            <DialogComponent
              show={showPdf}
              size="lg"
              title=""
              onClose={() => setShowPdf(false)}
            >
              <EncryptedPdfViewer base64EncryptedPdf={currentDoc} />
            </DialogComponent>
          )}
          <PaginationComponent
            pages={Math.ceil((total.current || 0) / 6)}
            currentPage={currentPage}
            onPageChange={handleFilteredDocumentsPageChange}
            rangeLimit={5}
          />
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Analyse;
