export default class CategoryDoc {

    public id: string;
    public description: string;
    public descriptionEn: string;
    public name: string;
    public nameEn: string;
    public parentId: CategoryDoc;
    public parentName: string;

    constructor(data: any) {
        
        this.id = data.id;
        this.name = data.name;
        this.nameEn = data.nameEn;
        this.parentId = data.category;
        this.parentName = data.parentName;
        this.description = data.description;
        this.descriptionEn = data.descriptionEn;

    }
}