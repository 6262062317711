import React, {useEffect, useRef, useState} from 'react';
import Options from "./Options";
import {useAppSelector} from "../../store/redux.types";

const GlobalMessage = (props : any) => {


    const categorieBot = useAppSelector(({bot}) => bot.bot)

    let finalMessage = null
    // const otherOption = useRef([
    //     {
    //         name: "Quels sont les conseils pour éviter les accidents de la route ?",
    //         handler: props.actionProvider.handleQuestion1,
    //         id: '1o'
    //     },
    //     {
    //         name: "Quelles sont les 5 grandes familles de la signalisation routière ?",
    //         handler: props.actionProvider.handleQuestion2,
    //         id: '2o'
    //     },
    //     {
    //         name: "Quels sont les différents types d'accidents ?",
    //         handler: props.actionProvider.handleQuestion3,
    //         id: '3o'
    //     },
    //     {
    //         name: "Quelles sont les 5 grandes familles de la signalisation routière ?",
    //         handler: props.actionProvider.handleQuestion4,
    //         id: '5o'
    //     },
    // ])
    const allMessage = categorieBot?.map(d => ({
        name:d.name,
        id: d.id,
        handler: () => props.actionProvider.handleQuestuinByCatId(d.id, d.name)
    }))

    // if (allMessage) {
    //     finalMessage = otherOption.current.concat(allMessage)
    // }


    return (
        <div>
            {allMessage && allMessage.length > 0 && (
                <Options options={ allMessage } title={"Categories"} {...props} />
            )}
        </div>
    );
};

export default GlobalMessage;