import React, {useEffect, useLayoutEffect, useRef} from "react";
import "./draggable.scss";
import Carousel from "react-bootstrap/Carousel";
import logoMin from "../assets/img/landing2/logoMin-imageonline.png";
import pad from "../assets/img/landing2/pad-imageonline.png";
import camer from "../assets/img/landing2/camer-imageonline.png";
import mintp from "../assets/img/landing2/téléchargement.jpeg";
import sed from "../assets/img/landing2/SED-imageonline.png";
import rouad from "../assets/img/landing2/nouv/logoRouad.png";
import "./Foott.scss"
import Icon from "./icon/Icon";
import Button from "./bootstrap/Button";

const Draggable = () => {

    const currentIndex = useRef(0)

  useLayoutEffect(() => {
      const slider = document.getElementById('toto');
      console.log("aaaaaaaa,", slider)
      if (slider) {
          slider.addEventListener('mouseover', function () {
              slider.style.animationPlayState = 'paused';
          });

          slider.addEventListener('mouseout', function () {
              slider.style.animationPlayState = 'running';
          });
      }
  }, []);


  const prevButton = () => {
      const track = document.getElementById('toto');
      if (track) {
          const slides = Array.from(track.children);
          const slideWidth = slides[0].getBoundingClientRect().width;
          currentIndex.current = currentIndex.current - 1;
          if (currentIndex.current < 0) {
              currentIndex.current = slides.length / 2 - 1; // Aller à la dernière slide
          }
          track.style.transform = `translateX(-${slideWidth * currentIndex.current}px)`;
      }


  }

    const nextButton = () => {
        const track = document.getElementById('toto');
        if (track) {
            const slides = Array.from(track.children);
            const slideWidth = slides[0].getBoundingClientRect().width;
            currentIndex.current = currentIndex.current +1;
            if (currentIndex.current > slides.length / 2) {
                currentIndex.current = 0; // Retour à la première slide
            }
            track.style.transform = `translateX(-${slideWidth * currentIndex.current}px)`;
        }
    }



  return (
      <div className={"row w-100"}>
          <div  className={"slider-logo col-12"}>
              <div className="slide-track-logo" id={"toto"}>
                  <a href="https://mintransports.cm/" target="_blank" className="slide-logo cursor-pointer"><img src={logoMin} alt="Logo 1"/></a>
                  <a href="https://www.minsante.cm/" target="_blank" className="slide-logo cursor-pointer"><img src={pad} alt="Logo 2"/></a>
                  <a href="https://www.dgsn.cm/" target="_blank" className="slide-logo cursor-pointer"><img src={camer} alt="Logo 3"/></a>
                  <a href="https://www.mintp.cm/" target="_blank" className="slide-logo cursor-pointer"><img src={mintp} alt="Logo 4"/></a>
                  <a href="#" className="slide-logo cursor-pointer"><img src={sed} alt="Logo 1"/></a>
                  <a href="https://fondsroutiercameroun.org/en/home/" target="_blank" className="slide-logo cursor-pointer"><img src={rouad} alt="Logo 2"/></a>
              </div>
          </div>
      </div>


  );
};

export default Draggable;
