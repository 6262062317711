import axios, {AxiosInstance, AxiosResponse} from "axios";

import * as mintBackend from '../commons/urls/back'

import {
    AxiosRequestConfigExtended, MintClientApiResponse,
} from './network.types';
import config from '../config';

import { mapDataToQueryString } from '../commons/helpers/funcHelper';
import User from "../commons/models/User";
import StorageHelper, {StorageKeys} from "../commons/helpers/StorageHelper";
import Role from "../commons/models/Role";
import {a} from "@react-spring/web";
import UserData from "../commons/models/UserData";
import Publication from "../commons/models/Publication";
import  Category  from "../commons/models/Category";
import Documentation from "../commons/models/Documentation";
import {Paragraph} from "../commons/models/Paragraph";
import Incident from "../commons/models/Incident";
import { Evenements } from "../commons/models/Evenements";
import Bot from "../commons/models/Bot";
import { Campagne } from "../commons/models/Campagne";
import Discusion from "../commons/models/Discusion";
import {MessageDiscusion} from "../commons/models/Message";
import BotCategorie from "../commons/models/BotCategorie";
import  Alert  from "../commons/models/Alert";
import QuestionUser from "../commons/models/QuestionUser";
import CategoryDoc from "../commons/models/CategorieDoc";
import CategoriAlert from "../commons/models/AlertCategories";
import {rejects} from "assert";
import Moderateur from "../commons/models/Moderateur";
import Usager from "../commons/models/Usager";
import NEWSLETTER from "../commons/models/NewsLetter";
import NewsLetter from "../commons/models/NewsLetter";




export default class Client {

    public instance: AxiosInstance;
    private baseUrl: string;

    constructor(baseUrl: string, instance: AxiosInstance) {
        this.baseUrl = baseUrl;
        this.instance = instance;
    }

    /**
     * The rf_epay gateway
     * @param verb
     * @param url
     * @param data
     * @param config object which might have following keys:
     *                  noFormData: {boolean} Indicate whether or not to parse data into form data. Default to false
     *                  fileData: {Array<string>} List all files object of the request data
     *                  multipart: {boolean} Indicate if the request contains files thus multi-part form
     *                  shouldSkipToken: {boolean} Indicate if we do not have to bind token to the request
     * @return {Promise<unknown>}
     */
    makeRequest = <ResourceType = any>(
        verb: 'get' | 'post' | 'put' | 'patch' | 'delete' ,
        url: string,
        data: any = null,
        config: AxiosRequestConfigExtended = {}
    ): Promise<AxiosResponse<MintClientApiResponse<ResourceType>, AxiosRequestConfigExtended>> => {
        return new Promise((resolve, reject) => {
            const lang = StorageHelper.getItem(StorageKeys.LANGUAGE);
            let _url = url ;
            if ((verb === 'get' || verb === 'delete') && data) {
                _url = mapDataToQueryString({
                    data,
                    url,
                    transformToUrl: true,
                    removeTrashValue: !!config.removeTrashValueRequest,
                    transformToSnakeCase: false
                }).url;

                /*Object.entries(data).forEach((item) => {
                    if (Array.isArray(item[1])) {
                        item[1].forEach(val => {
                            // @ts-ignore
                            const encoded = encodeURIComponent(val);
                            const character = _url.includes('?') ? '&' : '?';
                            const key = config.skipSnakeCaseTransformRequest ? item[0] : toSnakeCase(item[0]);
                            _url = `${_url}${character}${key}=${encoded}`;
                        });
                    } else {
                        // @ts-ignore
                        const encoded = encodeURIComponent(item[1]);
                        const character = _url.includes('?') ? '&' : '?';
                        const key = config.skipSnakeCaseTransformRequest ? item[0] : toSnakeCase(item[0]);
                        _url = `${_url}${character}${key}=${encoded}`;
                    }
                });*/
            }

            _url = _url.includes('?') ? `${_url}&langue=${lang}` : `${_url}?langue=${lang}` ;
            const params =
                verb === 'get' || verb === 'delete'
                    ? [_url, config]
                    : [_url, data, config];
            // @ts-ignore
            this.instance[verb](...params)
                .then((result) => resolve(result))
                .catch((error) =>
                    reject(
                        error || new Error('An error occurred while fetching'),
                    ),
                );
        });
    };

    /**
     * Bind params to a given url
     * @param to
     * @param params
     * @param withFullUrl
     */
    joinUrlWithParams = (to: string, params: Array<{ param: string; value: any }>, withFullUrl = false) => {
        let url = withFullUrl ? `${config.mintClient}${to}` : to;
        params.forEach(param => {
            url = url.replace(`{${param.param}}`, `${encodeURIComponent(param.value)}`);
        });

        return url;
    };

    /**
     * Shortcut of joinUrlWithParams for Id
     * @param to
     * @param id
     * @param withFullUrl
     */
    joinUrlWithParamsId = (to: string, id: string | number, withFullUrl = false) => this.joinUrlWithParams(to, [{ param: 'id', value: id }], withFullUrl);

    checkCard = (url: string, data: any) => {
        return new Promise((resolve, reject) => {
            const url_ = `${config.mintClient}${url}?langue=fr`;


            axios.post(url_, data)
                .then((resp) => {
                    resolve(resp)
                })
                .catch((error) => reject(error))
        })
    }

    claims = {
        // getAllMenu: () => this.makeRequest('get', mintBackend.CLAIMS.MENU_ALL, {langue: 'fr'}),
        getAllMenu: () => this.makeRequest('get', mintBackend.CLAIMS.MENU_ALL),
        getAllMenuJob: () => this.makeRequest('get', mintBackend.CLAIMS.MENU_JOB_ALL),
        getAllJob: () => this.makeRequest('get', mintBackend.CLAIMS.JOB_ALL),
        getOneJob: () => this.makeRequest('get', mintBackend.CLAIMS.JOB_ONE),
    }


    users = {
        auth: {
            login: (data: any) => {
                return new Promise<AxiosResponse<MintClientApiResponse<User>>>((resolve, reject) => {
                    this.makeRequest<User>('post', mintBackend.USERS.AUTH.LOGIN, data, {skipErrorCodes: [401, 400],})
                        .then((resp) => {
                            resolve({
                                ...resp,
                                data: {
                                    data: new User(resp.data)
                                }
                            })
                        })
                        .catch(err => reject(err))
                })
            },
            
            changePasword: (id: string, data: any) => this.makeRequest('patch', this.joinUrlWithParamsId(mintBackend.USERS.CHANGE_PASSWORD, id), data)

            // login: (data: any) => this.checkCard(mintBackend.USERS.AUTH.LOGIN, data)
        },


    }

    administration = {
        role: {
            create: (claims: any, data:any) => this.makeRequest('post', `${mintBackend.ROLE.CREATE}?roleName=${data.name}` , claims),
            getAll: (parmas : any) => {
                return new Promise<AxiosResponse<MintClientApiResponse<Role[]>>>((resolve, reject) => {
                    this.makeRequest<Role[]>('get', mintBackend.ROLE.GET_ALL, parmas)
                        .then((respone) => {

                            resolve({
                                ...respone,
                                data: {
                                    data: respone.data.data.map(role => new Role(role))
                                }
                            })
                        })
                        .catch(err => reject(err))
                })},

            getOne: (id: string) => {
                return new Promise<AxiosResponse<MintClientApiResponse<Role>>>((resolve, reject) => {
                    this.makeRequest<Role>('get', this.joinUrlWithParamsId(mintBackend.ROLE.GET_ONE,id) )
                        .then((respone) => {
                            resolve({
                                ...respone,
                                data: {
                                    data: new Role(respone.data.data)
                                }
                            })
                        })
                        .catch(err => reject(err))
                })},
            delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.ROLE.DELETE, id)),
            update: (id: string, claims: any, data: any) => this.makeRequest('put', `${this.joinUrlWithParamsId(mintBackend.ROLE.UPDATE, id)}?roleName=${data.name}`, claims)

        },

        user: {
            getAll: () => {
                return new Promise<AxiosResponse<MintClientApiResponse<UserData[]>>>((resolve, reject) => {
                    this.makeRequest<UserData[]>('get', mintBackend.USERS.GET_ALL)
                        .then((response) => {
                            resolve({
                                ...response,
                                data: {
                                    data: response.data.data.map(user => new UserData(user))
                                }
                            })
                        })
                        .catch(err => reject(err))
                })
            },

            getAllUseger: () => {
                return new Promise<AxiosResponse<MintClientApiResponse<Usager[]>>>((resolve, reject) => {
                    this.makeRequest<Usager[]>('get', mintBackend.USERS.GET_ALL)
                        .then((response) => {
                            resolve({
                                ...response,
                                data: {
                                    data: response.data.data.map(user => new Usager(user))
                                }
                            })
                        })
                        .catch(err => reject(err))
                })
            },

            getOne: (id: string) => {
                return new Promise<AxiosResponse<MintClientApiResponse<UserData>>>((resolve, reject) => {
                    this.makeRequest<UserData>('get', this.joinUrlWithParamsId(mintBackend.USERS.GET_ONE,id) )
                        .then((respone) => {
                            resolve({
                                ...respone,
                                data: {
                                    data: new UserData(respone.data.data)
                                }
                            })
                        })
                        .catch(err => reject(err))
                })},
            delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.USERS.DELETE, id)),

            create: (data:any) => this.makeRequest('post', mintBackend.USERS.CREATE , data),

            update: (id: string, data: any) => this.makeRequest('patch', this.joinUrlWithParamsId(mintBackend.USERS.UPDATE, id), data)


        }
    };



    publication = {
        getAll: (filterParams: any | null) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Publication[]>>>((resolve, reject) => {
                this.makeRequest<Publication[]>('post', `${mintBackend.PUBLICATION.GET_ALL}?page=${filterParams.page}&pageSize=${filterParams.pageSize}&hasPagination=${true}` , filterParams )
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(p => new Publication(p)),
                                total: response.data.total
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },

        getAllImage: (filterParams: any | null) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Publication[]>>>((resolve, reject) => {
                this.makeRequest<Publication[]>('post', `${mintBackend.PUBLICATION.GET_IMAGE}?page=${filterParams.page}&pageSize=${filterParams.pageSize}&hasPagination=${true}` , filterParams )
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(p => new Publication(p)),
                                total: response.data.total
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },

        getAllVideo: (filterParams: any | null) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Publication[]>>>((resolve, reject) => {
                this.makeRequest<Publication[]>('post', `${mintBackend.PUBLICATION.GET_VIDEO}?page=${filterParams.page}&pageSize=${filterParams.pageSize}&hasPagination=${true}` , filterParams )
                    .then((response) => {


                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(p => new Publication(p)),
                                total: response.data.total
                            }                        
                        })

                        
                    })
                  
                    .catch(err => reject(err))
            })
        },
        
        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Publication>>>((resolve, reject) => {
                this.makeRequest<Publication>('get', this.joinUrlWithParamsId(mintBackend.PUBLICATION.GET_ONE, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new Publication(response.data.data)
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.PUBLICATION.DELETE, id)),
        create: (data:any) => this.makeRequest('post', mintBackend.PUBLICATION.CREATE , data),
        update: (id: string, data: any) => {
            return  this.makeRequest('patch', this.joinUrlWithParamsId(mintBackend.PUBLICATION.UPDATE, id) , data)
        },
        validate: (id: string) => this.makeRequest('patch', this.joinUrlWithParamsId(mintBackend.PUBLICATION.VALIDATE, id) ),
        invalidate: (id: string) => this.makeRequest('patch', this.joinUrlWithParamsId(mintBackend.PUBLICATION.INVALIDATE, id) ),
        getParagraphByPublication: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Paragraph[]>>>((resolve,reject) => {
                this.makeRequest<Paragraph[]>('get', this.joinUrlWithParamsId(mintBackend.PUBLICATION.GET_PARAGRAPH, id))
                    .then((resp) => {
                        resolve({
                            ...resp,
                            data: {
                                data: resp.data.data.map(d => new Paragraph(d))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        }
    }

    incident = {
        getAll: (filterParams: object | null) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Incident[]>>>((resolve, reject) => {
                this.makeRequest<Incident[]>('post', mintBackend.INCIDENT.GET_ALL, filterParams )
                    .then((response) => {
                        console.log("kkkkk", response.data.data)
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(p => new Incident(p))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getLastAll: (filterParams: object | null) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Incident[]>>>((resolve, reject) => {
                this.makeRequest<Incident[]>('post', mintBackend.INCIDENT.GET_LAST_ALL, filterParams )
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(p => new Incident(p))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Incident>>>((resolve, reject) => {
                this.makeRequest<Incident>('get', this.joinUrlWithParamsId(mintBackend.INCIDENT.GET_ONE, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new Incident(response.data.data)
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.INCIDENT.DELETE, id)),
        create: (data:any) => this.makeRequest('post', mintBackend.INCIDENT.CREATE , data),
        update: (id: string, data: any) => {
            return  this.makeRequest('put', this.joinUrlWithParamsId(mintBackend.INCIDENT.UPDATE, id) , data)
        },
        validate: (id: string, cathegorieId: string) => this.makeRequest('patch', `${this.joinUrlWithParamsId(mintBackend.INCIDENT.VALIDATE, id)}?categoryId=${cathegorieId}` ),
        invalidate: (id: string) => this.makeRequest('patch', this.joinUrlWithParamsId(mintBackend.INCIDENT.INVALIDATE, id) ),
    }

    category= {
        getAll: (filterParams: any | null) => {
            return new Promise<AxiosResponse<MintClientApiResponse<CategoryDoc[]>>>((resolve, reject) => {
                this.makeRequest<CategoryDoc[]>('post', `${mintBackend.CATEGORY.GET_ALL}?page=${filterParams.page}&pageSize=${filterParams.pageSize}&hasPagination=${true}`, filterParams)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(p => new CategoryDoc(p))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<CategoryDoc>>>((resolve, reject) => {
                this.makeRequest<CategoryDoc>('get', this.joinUrlWithParamsId(mintBackend.CATEGORY.GET_ONE, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new CategoryDoc(response.data.data)
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.CATEGORY.DELETE, id)),
        create: (data:any) => this.makeRequest('post', mintBackend.CATEGORY.CREATE , data),
        // update: (id: string, data: any) => this.makeRequest('put', `${mintBackend.CATEGORY.UPDATE}/${id}`, data),
        update: (id: string, data: any) => this.makeRequest('put', this.joinUrlWithParamsId(mintBackend.CATEGORY.UPDATE, id) , data),
    }

    incidencCategory= {
        getAll: () => {
            return new Promise<AxiosResponse<MintClientApiResponse<Category[]>>>((resolve, reject) => {
                this.makeRequest<Category[]>('get', mintBackend.INCIDENT_CATEGORY.GET_ALL)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(p => new Category(p))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Category>>>((resolve, reject) => {
                this.makeRequest<Category>('get', this.joinUrlWithParamsId(mintBackend.INCIDENT_CATEGORY.GET_ONE, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new Category(response.data.data)
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.INCIDENT_CATEGORY.DELETE, id)),
        create: (data:any) => this.makeRequest('post', mintBackend.INCIDENT_CATEGORY.CREATE , data),
        // update: (id: string, data: any) => this.makeRequest('put', `${mintBackend.CATEGORY.UPDATE}/${id}`, data),
        update: (id: string, data: any) => this.makeRequest('put', this.joinUrlWithParamsId(mintBackend.INCIDENT_CATEGORY.UPDATE, id) , data),
    }

    categoryPublication= {
        getAll: () => {
            return new Promise<AxiosResponse<MintClientApiResponse<Category[]>>>((resolve, reject) => {
                this.makeRequest<Category[]>('get', mintBackend.CATEGORY_PUBLICATION.GET_ALL)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(p => new Category(p))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Category>>>((resolve, reject) => {
                this.makeRequest<Category>('get', this.joinUrlWithParamsId(mintBackend.CATEGORY_PUBLICATION.GET_ONE, id))
                    .then((response) => {
                        console.log('aaaaaaaaaaaaaa', response.data.data)
                        resolve({
                            ...response,
                            data: {
                                data: new Category(response.data.data)
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.CATEGORY_PUBLICATION.DELETE, id)),
        create: (data:any) => this.makeRequest('post', mintBackend.CATEGORY_PUBLICATION.CREATE , data),
        // update: (id: string, data: any) => this.makeRequest('put', `${mintBackend.CATEGORY.UPDATE}/${id}`, data),
        update: (id: string, data: any) => this.makeRequest('put', this.joinUrlWithParamsId(mintBackend.CATEGORY_PUBLICATION.UPDATE, id) , data),
    }

    documentation = {
        getAll: (filterParams: any | null) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Documentation[]>>>((resolve, reject) => {
                this.makeRequest<Documentation[]>('post', `${mintBackend.DOCUMENTATION.GET_ALL}?page=${filterParams.page}&pageSize=${filterParams.pageSize}&hasPagination=${true}`,filterParams)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(d => new Documentation(d)),
                                total: response.data.total
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },

        getAllOnDoc: (filterParams: any | null) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Documentation[]>>>((resolve, reject) => {
                this.makeRequest<Documentation[]>('post', `${mintBackend.DOCUMENTATION.GET_ALL_ON_DOC}?page=${filterParams.page}&pageSize=${filterParams.pageSize}&hasPagination=${true}`,filterParams)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(d => new Documentation(d)),
                                total: response.data.total
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },

        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Documentation>>>((resolve, reject) => {
                this.makeRequest<Documentation>('get', this.joinUrlWithParamsId(mintBackend.DOCUMENTATION.GET_ONE, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new Documentation(response.data.data)
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },

        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.DOCUMENTATION.DELETE, id) ),
        create: (data:any) => this.makeRequest('post', mintBackend.DOCUMENTATION.CREATE , data),
        update: (id: string, data: any) => this.makeRequest('patch', this.joinUrlWithParamsId(mintBackend.DOCUMENTATION.UPDATE, id) , data),
       
    }

    dash = {
        incidentDash: ({ year, month }: { year: number, month?: number }, otherParams: any | null) => {
            let requestParams: Record<string, any> = { year: year, ...otherParams }
            if (month !== undefined) {
                requestParams = {
                    ...requestParams,
                    ...otherParams,
                    month: month
                }
            }
            return new Promise<AxiosResponse<MintClientApiResponse<any>>>((resolve, reject) => {
                this.makeRequest<any>('post', mintBackend.DASH.INCIDENT_DASH, requestParams)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getTotal: () => {
            return new Promise<AxiosResponse<MintClientApiResponse<any>>>((resolve, reject) => {
                this.makeRequest<any>('get', mintBackend.DASH.TOTAL_DASH)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getTotalPublication: () => {
            return new Promise<AxiosResponse<MintClientApiResponse<any>>>((resolve, reject) => {
                this.makeRequest<any>('get', mintBackend.DASH.TOTAL_DASH_PUB)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        }
    }

    paragraph = {
        getAll: () => {
            return new Promise<AxiosResponse<MintClientApiResponse<Paragraph[]>>>((resolve, reject) => {
                this.makeRequest<Paragraph[]>('get', mintBackend.PARAGRAPH.GET_ALL)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(p => new Paragraph(p))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Paragraph>>>((resolve, reject) => {
                this.makeRequest<Category>('get', this.joinUrlWithParamsId(mintBackend.PARAGRAPH.GET_ONE, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new Paragraph(response.data.data)
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.PARAGRAPH.DELETE, id)),
        create: (data:any) => this.makeRequest('post', mintBackend.PARAGRAPH.CREATE , data),
        // update: (id: string, data: any) => this.makeRequest('put', `${mintBackend.CATEGORY.UPDATE}/${id}`, data),
        update: (id: string, data: any) => this.makeRequest('put', this.joinUrlWithParamsId(mintBackend.PARAGRAPH.UPDATE, id) , data),
    }

    evenement = {
        getAll: (filterParams: any | null) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Evenements[]>>>((resolve, reject) => {
                this.makeRequest<Evenements[]>('post', `${mintBackend.EVENEMENT.GET_ALL}?page=${filterParams.page}&pageSize=${filterParams.pageSize}&hasPagination=${true}`, filterParams)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(e => new Evenements(e))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Evenements>>>((resolve, reject) => {
                this.makeRequest<Evenements>('get', this.joinUrlWithParamsId(mintBackend.EVENEMENT.GET_ONE, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new Evenements(response.data.data),
                                
                            }
                        })
                    })
                   
                    .catch(err => reject(err))
            })

            
        },
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.EVENEMENT.DELETE, id)),
        create: (data:any) => this.makeRequest('post', mintBackend.EVENEMENT.CREATE , data),
        // update: (id: string, data: any) => this.makeRequest('put', `${mintBackend.CATEGORY.UPDATE}/${id}`, data),
        update: (id: string, data: any) => this.makeRequest('patch', this.joinUrlWithParamsId(mintBackend.EVENEMENT.UPDATE, id) , data),
    }

    alerte = {
        getAll: (filterParams: any | null) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Alert[]>>>((resolve, reject) => {
                this.makeRequest<Alert[]>('get', mintBackend.ALERT.GET_ALL)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(e => new Alert(e))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        
       // getAllLast: () => this.makeRequest('get', mintBackend.ALERT.GET_LAST),

       getAllLast: () => {

        return new Promise<AxiosResponse<MintClientApiResponse<Alert>>>((resolve, reject) => {
            this.makeRequest<Alert>('get', mintBackend.ALERT.GET_LAST)
                .then((response) => {
                   // console.log("onealert", response.data.data)
                    resolve({
                        ...response,
                        data: {
                            data: new Alert(response.data.data),

                        }
                    })
                })

                .catch(err => reject(err))
        })
    }, 

        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Alert>>>((resolve, reject) => {
                this.makeRequest<Alert>('get', this.joinUrlWithParamsId(mintBackend.ALERT.GET_ONE, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new Alert(response.data.data),
                                
                            }
                        })
                    })
                   
                    .catch(err => reject(err))
            })

            
        },
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.ALERT.DELETE, id)),
        create: (data:any) => this.makeRequest('post', mintBackend.ALERT.CREATE , data),
        // update: (id: string, data: any) => this.makeRequest('put', `${mintBackend.CATEGORY.UPDATE}/${id}`, data),
        update: (id: string, data: any) => this.makeRequest('put', this.joinUrlWithParamsId(mintBackend.ALERT.UPDATE, id) , data),
    }

    categoryAlert= {
        getAll: () => {
            return new Promise<AxiosResponse<MintClientApiResponse<CategoriAlert[]>>>((resolve, reject) => {
                this.makeRequest<CategoriAlert[]>('get', mintBackend.ALERT_CATEGORY.GET_ALL)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(p => new CategoriAlert(p))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<CategoriAlert>>>((resolve, reject) => {
                this.makeRequest<CategoriAlert>('get', this.joinUrlWithParamsId(mintBackend.ALERT_CATEGORY.GET_ONE, id))
                    .then((response) => {
                        console.log('aaaaaaaaaaaaaa', response.data.data)
                        resolve({
                            ...response,
                            data: {
                                data: new CategoriAlert(response.data.data)
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.ALERT_CATEGORY.DELETE, id)),
        create: (data:any) => this.makeRequest('post', mintBackend.ALERT_CATEGORY.CREATE , data),
        // update: (id: string, data: any) => this.makeRequest('put', `${mintBackend.CATEGORY.UPDATE}/${id}`, data),
        update: (id: string, data: any) => this.makeRequest('put', this.joinUrlWithParamsId(mintBackend.ALERT_CATEGORY.UPDATE, id) , data),
    }

    bot = {
        getAll: () => {
            return new Promise<AxiosResponse<MintClientApiResponse<Bot[]>>>((resolve, reject) => {
                this.makeRequest<Bot[]>('get', mintBackend.BOOT.GET_ALL)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(e => new Bot(e))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getAllUserQuestion: () => {
            return new Promise<AxiosResponse<MintClientApiResponse<QuestionUser[]>>>((resolve, reject) => {
                this.makeRequest<QuestionUser[]>('get', mintBackend.BOOT.GET_ALL_QUESTION_USER)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(e => new QuestionUser(e))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getAllById: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Bot[]>>>((resolve, reject) => {
                this.makeRequest<Bot[]>('get', `${mintBackend.BOOT.GET_BOT_BY_CATHEGORIE}?categoryId=${id}`)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(e => new Bot(e))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Bot>>>((resolve, reject) => {
                this.makeRequest<Bot>('get', this.joinUrlWithParamsId(mintBackend.BOOT.GET_ONE, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new Bot(response.data.data),

                            }
                        })
                    })

                    .catch(err => reject(err))
            })
        },
        getOneUserQuestion: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<QuestionUser>>>((resolve, reject) => {
                this.makeRequest<QuestionUser>('get', this.joinUrlWithParamsId(mintBackend.BOOT.GET_QUESTION_USER_BY_ID, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new QuestionUser(response.data.data),

                            }
                        })
                    })

                    .catch(err => reject(err))
            })
        },
        getReponseByQuestion: (question: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Bot>>>((resolve, reject) => {
                this.makeRequest<Bot>('get', `${mintBackend.BOOT.GET_RESPONSE_QUESTION}?question=${question}`)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new Bot(response.data.data),

                            }
                        })
                    })

                    .catch(err => reject(err))
            })
        },
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.BOOT.DELETE, id)),
        create: (data:any) => this.makeRequest('post', mintBackend.BOOT.CREATE , data),
        createUserQuestion: (data:any) => this.makeRequest('post', mintBackend.BOOT.CREATE_USER_QUESTION , data),
        // update: (id: string, data: any) => this.makeRequest('put', `${mintBackend.CATEGORY.UPDATE}/${id}`, data),
        update: (id: string, data: any) => this.makeRequest('put', this.joinUrlWithParamsId(mintBackend.BOOT.UPDATE, id) , data),
    }


    botCategorie = {
        
        getAll: () => {
            return new Promise<AxiosResponse<MintClientApiResponse<BotCategorie[]>>>((resolve, reject) => {
                this.makeRequest<BotCategorie[]>('get', mintBackend.BOOT_CATEGORIE.GET_ALL)
                    .then((response) => {
                        
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(e => new BotCategorie(e))
                            }
                        })


                    })
                    .catch(err => reject(err))
            })
        },

        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<BotCategorie>>>((resolve, reject) => {
                this.makeRequest<BotCategorie>('get', this.joinUrlWithParamsId(mintBackend.BOOT_CATEGORIE.GET_ONE, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new BotCategorie(response.data.data),

                            }
                        })
                    })

                    .catch(err => reject(err))
            })
        },
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.BOOT_CATEGORIE.DELETE, id)),
        create: (data:any) => this.makeRequest('post', mintBackend.BOOT_CATEGORIE.CREATE , data),
        // update: (id: string, data: any) => this.makeRequest('put', `${mintBackend.CATEGORY.UPDATE}/${id}`, data),
        update: (id: string, data: any) => this.makeRequest('put', this.joinUrlWithParamsId(mintBackend.BOOT_CATEGORIE.UPDATE, id) , data),
    }

    
    campagne = {
        getAll: (filterParams: any | null) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Campagne[]>>>((resolve, reject) => {
                this.makeRequest<Campagne[]>('post', mintBackend.CAMPAGNE.GET_ALL, filterParams)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(e => new Campagne(e))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Campagne>>>((resolve, reject) => {
                this.makeRequest<Campagne>('get', this.joinUrlWithParamsId(mintBackend.CAMPAGNE.GET_ONE, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new Campagne(response.data.data),

                            }
                        })
                    })
                    .catch(err => reject(err))
            })

            
        },
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.CAMPAGNE.DELETE, id)),
        create: (data:any) => this.makeRequest('post', mintBackend.CAMPAGNE.CREATE , data),
/*          update: (id: string, data: any) => this.makeRequest('put', `${mintBackend.CATEGORY.UPDATE}/${id}`, data),
 */      update: (id: string, data: any) => this.makeRequest('patch', this.joinUrlWithParamsId(mintBackend.CAMPAGNE.UPDATE, id) , data),
    }

    discusion = {
        getAll: (filterParams: any) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Discusion[]>>>((resolve, reject) => {
                this.makeRequest<Discusion[]>('post', `${mintBackend.DISCUSION.GET_ALL}?page=${filterParams.page}&pageSize=${filterParams.pageSize}&hasPagination=${true}`, filterParams)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(e => new Discusion(e))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Discusion>>>((resolve, reject) => {
                this.makeRequest<Discusion>('get', this.joinUrlWithParamsId(mintBackend.DISCUSION.GET_ONE, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new Discusion(response.data.data),

                            }
                        })
                    })

                    .catch(err => reject(err))
            })


        },
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.DISCUSION.DELETE, id)),
        create: (data:any) => this.makeRequest('post', mintBackend.DISCUSION.CREATE , data),
        // update: (id: string, data: any) => this.makeRequest('put', `${mintBackend.CATEGORY.UPDATE}/${id}`, data),
        update: (id: string, data: any) => this.makeRequest('put', this.joinUrlWithParamsId(mintBackend.DISCUSION.UPDATE, id) , data),
    }

    message = {
        getAll: (filterParams: any) => {
            return new Promise<AxiosResponse<MintClientApiResponse<MessageDiscusion[]>>>((resolve, reject) => {
                this.makeRequest<MessageDiscusion[]>('post', `${mintBackend.MESSAGE_DISCUSSION.GET_ALL}?page=${filterParams.page}&pageSize=${filterParams.pageSize}&hasPagination=${true}`, filterParams)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(e => new MessageDiscusion(e))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<MessageDiscusion>>>((resolve, reject) => {
                this.makeRequest<MessageDiscusion>('get', this.joinUrlWithParamsId(mintBackend.MESSAGE_DISCUSSION.GET_ONE, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new MessageDiscusion(response.data.data),
                            }
                        })
                    })

                    .catch(err => reject(err))
            })
        },
        getAllById: (filterParams: any) => {
            return new Promise<AxiosResponse<MintClientApiResponse<MessageDiscusion[]>>>((resolve, reject) => {
                this.makeRequest<MessageDiscusion[]>('get', mintBackend.MESSAGE_DISCUSSION.GET_ALL_ID, filterParams)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(e => new MessageDiscusion(e)),
                                total: response.data.total
                            }
                        })
                    })

                    .catch(err => reject(err))
            })


        },
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.MESSAGE_DISCUSSION.DELETE, id)),
        create: (data:any) => this.makeRequest('post', mintBackend.MESSAGE_DISCUSSION.CREATE , data),
        // update: (id: string, data: any) => this.makeRequest('put', `${mintBackend.CATEGORY.UPDATE}/${id}`, data),
        update: (id: string, data: any) => this.makeRequest('put', this.joinUrlWithParamsId(mintBackend.MESSAGE_DISCUSSION.UPDATE, id) , data),
    }

    newsletter = {
        getAll: (filterParams: object | null) => {
            return new Promise<AxiosResponse<MintClientApiResponse<NewsLetter[]>>>((resolve, reject) => {
                this.makeRequest<NewsLetter[]>('get', mintBackend.NEWSLETTER.GET_ALL, filterParams )
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(p => new NEWSLETTER(p))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
       
        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<NewsLetter>>>((resolve, reject) => {
                this.makeRequest<NewsLetter>('get', this.joinUrlWithParamsId(mintBackend.NEWSLETTER.GET_ONE, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new NEWSLETTER(response.data.data)
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        create: (data:any) => this.makeRequest('post', mintBackend.NEWSLETTER.CREATE , data),
        update: (id: string, data: any) => this.makeRequest('put', this.joinUrlWithParamsId(mintBackend.NEWSLETTER.UPDATE, id) , data),
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(mintBackend.NEWSLETTER.DELETE, id)),
    }




    moderateur = {
        getAll: (filterParams: any) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Moderateur[]>>>((resolve, reject) => {
                this.makeRequest<Moderateur[]>('post', `${mintBackend.MODERATEUR.GET_ALL}?page=${filterParams.page}&pageSize=${filterParams.pageSize}&hasPagination=${true}`,filterParams)
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: response.data.data.map(e => new Moderateur(e))
                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string) => {
            return new Promise<AxiosResponse<MintClientApiResponse<Moderateur>>>((resolve, reject) => {
                this.makeRequest<Moderateur>('get', this.joinUrlWithParamsId(mintBackend.MODERATEUR.GET_ONE, id))
                    .then((response) => {
                        resolve({
                            ...response,
                            data: {
                                data: new Moderateur(response.data.data),

                            }
                        })
                    })
                    .catch(err => reject(err))
            })
        },
        create: (data:any) => this.makeRequest('post', mintBackend.MODERATEUR.CREATE , data),
        activer: (id: string) => this.makeRequest('patch', this.joinUrlWithParamsId(mintBackend.MODERATEUR.ACTIVER_MODERATOR, id), null, {skipErrorCodes: [404, 401]}),
        desactiver: (id: string) => this.makeRequest('patch', this.joinUrlWithParamsId(mintBackend.MODERATEUR.DESACTIVER_MODERATOR, id)),
        suspendre: (id: string) => this.makeRequest('patch', this.joinUrlWithParamsId(mintBackend.MODERATEUR.SUSPENDRE_MODERATOR, id)),

    }

}
