import React from 'react';

const Question = () => {
    return (
        <div className='d-flex flex-column text-start ms-3'>
            <span>1- Toujours porter la ceinture de sécurité.</span>
            <span>2- Limiter la consommation d'alcool.</span>
            <span>3- Port du casque</span>
            <span>4- Surveiller la vitesse.</span>
            <span>5- Éviter les distractions.</span>
            <span>6- Soyez un défenseur de la sécurité routière.</span>
        </div>
    );
};

export default Question;