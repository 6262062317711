import dayjs from "dayjs";
import {PublicationStatus} from "../enums/PublicationStatus";

export default class Publication {
    public id: string;
    public description: string;
    public descriptionEn: string;
    public categoryName: string;
    public categoryId: string;
    public name: string;
    public nameEn: string;
    public attachement: any[];
    public state: number;
    public createdAt: dayjs.Dayjs;
    public updatedAt: dayjs.Dayjs;

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.nameEn = data.nameEn;
        this.description = data.description;
        this.descriptionEn = data.descriptionEn;
        this.attachement = data.attachments;
        this.categoryName = data.categoryName;
        this.categoryId = data.categoryId;
        this.state = data.state;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt
    }

    getStatusInfo = () => {
        switch (this.state) {
            case PublicationStatus.pending.value:
                return {
                    color: 'dark',
                    name: 'publication.pending'
                }

            case PublicationStatus.validate.value:
                return {
                    color: 'success',
                    name: 'publication.valid'
                }
            case PublicationStatus.invalidate.value:
                return {
                    color: 'danger',
                    name: 'publication.invalid'
                }
        }
    }
}