import { moderateur } from './../../menu';
/**
 * This file list all permissions handled by the app
 */

export default class Permissions {
	static security = {
		administration: {
			index: 'administration',
			role: {
				index: 'role',
				displayrolelist: {
					index: 'displayrolelist',
					update: 'updaterole',
					delete: 'deleterole',
					add: 'addrole'
				},
			},
			user: {
				index: 'user',
				displayuserlist: {
					index: 'displayuserlist',
					update: 'updateuser',
					delete: 'deleteuser',
					add: 'createuser'
				},
			},

		},

	}

	static dashboard = {
		displaydashboard: {
			index: 'displaydashboard',
			publication: {
				index: 'publication',
				validate: 'validatepublication',
				invalidate: 'invalidatepublication',
				list: {
					validate: 'listvalidpublication',
					invalidate: 'listinvalidpublication',
					pending: 'listpendingpublication',
					all: 'listpublication',
					index:'listpublication',
					create: 'createpublication',
					delete: 'deletepublication',
					update: 'updatepublication',

				},
				categorie: {
					index: 'publicationcategory',
					list: {
						view: 'listpublicationcategory',
						index:'listpublicationcategory',
						create: 'createpublicationcategory',
						delete: 'deletepublicationcategory',
						update: 'updatepublicationcategory',

					},
				}

			},

			document: {
				index: 'document',
				list: {
					view: 'listdocument',
					index:'listdocument',
					create: 'createdocument',
					delete: 'deletedocument',
					update: 'updatedocument',

				},
				categorie: {
					index: 'documentcategory',
					list: {
						view: 'listdocumentcategory',
						index:'listdocumentcategory',
						create: 'createdocumentcategory',
						delete: 'deletedocumentcategory',
						update: 'updatedocumentcategory',

					},
				}

			},

			chatBot: {
				index: 'chatbot',
				list: {
					view: 'listchat',
					index:'listchatbot',
					create: 'createchat',
					delete: 'deletechat',
					update: 'updatechat',

				},
				categorie: {
					index: 'chatcategory',
					list: {
						view: 'getchatbycategory',
						index:'getchatbycategory',
						create: 'createchatbycategory',
						delete: 'deletechatbycategory',
						update: 'updatechatbycategory',

					},
				}

			},

			incident: {
				index: 'incidence',
				validate: 'validateincidence',
				invalidate: 'invalidateincidence',
				list: {
					validate: 'listvalidincidence',
					invalidate: 'listinvalidincidence',
					pending: 'listpendingincidence',
					all: 'listincidence',
					index:'listincidence',
					create: 'createincidence',
					delete: 'deleteincidence',
					update: 'updateincidence',

				},
			},

			campaign: {
				index: 'campaign',
				list: {
					view: 'listcampaign',
					index:'listcampaign',
					create: 'createcampaign',
					delete: 'deletecampaign',
					update: 'updatecampaign',

				},
			},

			alert: {
				index: 'alert',
				list: {
					view: 'listalert',
					index:'listalert',
					create: 'createalert',
					delete: 'deletealert',
					update: 'updatealert',

				},

				categorie: {
					index: 'chatcategory',
					list: {
						view: 'getalertbycategory',
						index:'getalertbycategory',
						create: 'createalertbycategory',
						delete: 'deletealertbycategory',
						update: 'updatealertbycategory',

					},
				}
			},

			discusion: {
				index: 'discussion',
				list: {
					view: 'listdiscussion',
					index:'listdiscussion',
					create: 'creatediscussion',
					delete: 'deletediscussion',
					update: 'updatediscussion',

				},
			},

			message: {
				index: 'message',
				list: {
					view: 'listmessage',
					index:'listmessage',
					create: 'creatmessage',
					delete: 'deletemessage',
					update: 'updatemessage',

				},
			},

			event: {
				index: 'event',
				list: {
					view: 'listevent',
					index:'listevent',
					create: 'createevent',
					delete: 'deleteevent',
					update: 'updateevent',

				},
			},

			moderateur: {
				index: 'moderateur',
				active: 'activemoderateur',
				desactive: 'desactivemoderateur',
				suspendre: 'suspendremoderateur',
				list: {
					active: 'listmoderateuractive',
					desactive: 'listmoderateurdesactive',
					suspendre: 'listmoderateursuspendre',
					all: 'listmoderateur',
					index:'listmoderateur',
					create: 'createmoderateur',
					delete: 'deletemoderateur',
					update: 'updatemoderateur',


				},
			},

		}
	}

}
