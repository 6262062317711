import { ModerateurStatus } from "../enums/ModerateurStatus";

export default class Moderateur {
    public id: string;
    public firstName: string;
    public lastName: string;
    public phoneNumber: string;
    public state: number;
    
    constructor(data: any) {
        this.id = data.id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.phoneNumber = data.phoneNumber;
        this.state = data.state;

    }

     getStatusInfo = () => {
        switch (this.state) {
            case ModerateurStatus.suspendu.value:
                return {
                    color: 'dark',
                    name: 'moderateur.pending'
                }

            case ModerateurStatus.activate.value:
                return {
                    color: 'success',
                    name: 'moderateur.valid'
                }
            case ModerateurStatus.desactivate.value:
                return {
                    color: 'danger',
                    name: 'moderateur.invalid'
                }
        }
    } 
}