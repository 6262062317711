// SearchComponent.tsx


import React from 'react';
import { globalT } from '../lang';
import {debounceTime} from "../helpers/helpers";

interface SearchProps {
  onSearch: (value: string) => void;
}

const SearchComponent: React.FC<SearchProps> = ({ onSearch }) => {

    const funDebonce = (message: string) => {
        onSearch(message)
    }

    const debouncedShowMessage = debounceTime(funDebonce, 1000);

  return (
    <div className="search-component">
        <i className="bi bi-search search-icon" /> 
            <input
                type="text"
                 className="form-control" 
                 placeholder={globalT("search.education")}
                onChange={(e) => debouncedShowMessage(e.target.value)}
            />
            <style >{`
                .search-component {
                    width: 90%;
                    height: 55%;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 10px;
                    margin-left: 15%;
                }
                .search-input {
                    width: 50%;
                    padding: 10px;
                    font-size: 18px;
                    border: 1px solid #ddd;
                    border-radius: 10px;
                }
            `}</style>
        </div>
  );
};

export default SearchComponent;
