import React from "react";
import newImage from "../assets/img/landing2/CAMERNEWS.png";
import Button from "./bootstrap/Button";
import { globalT } from "../lang";

type imageProps = {
  image?: string;
  texte?: string;

  action?: {
    navigatez?: () => void;
  };
};

const ImageSensibilisation = ({ image, action, texte }: imageProps) => {
  return (
    <div className="global-content-image">
      <img src={image} alt="" className="w-100" style={{ zIndex: "-1", height:"100px" }} />
      <div className="position-absolute top-0 left-0 w-100 h-100 d-flex align-items-center">
        <div className="global-content-image__content w-100 d-flex justify-content-between">
          <span>{texte}</span>
          {action && (
              <>
                <Button
                    color={"secondary"}
                    className="me-5"
                    size={"lg"}
                    onClick={action?.navigatez}
                >
                  <span>{globalT("voir")}</span>
                </Button>
              </>
          )}

        </div>
      </div>
    </div>
  );
};

export default ImageSensibilisation;
