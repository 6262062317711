import dayjs from "dayjs";
export class Campagne {
    public id: string;
    public attachement: any[];
    public description: string;
    public descriptionEn: string;
    public name: string;
    public place: string;
    public nameEn: string;
    public startDate: dayjs.Dayjs;
    public endDate: dayjs.Dayjs;
    public goal: string

    constructor(data: any) {
        this.id = data.id;
        this.attachement = data.attachments;
        this.name = data.name;
        this.place = data.place;
        this.nameEn = data.nameEn;
        this.description = data.description;
        this.descriptionEn = data.descriptionEn;
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.goal = data.goal
    }

}

