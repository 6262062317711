import React, {useRef, useState} from 'react';
import mintClient from "../../network";
import {useAppDispatch, useAppSelector} from "../../store/redux.types";
import {botMessage} from "../../store/slices/botMessage/actions";
import {botPhone} from "../../store/slices/botTelephone/actions";
import * as yupSchema from '../../components/forms/yupShema';
import NotificationManager from "../../components/notifications/NotificationManager";
import {globalT} from "../../lang";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

    const dataCat = useRef(null)
    const dispatch = useAppDispatch()
    const bobMessageUser = useAppSelector(({botMessage}) => botMessage.botMessage)
    const botPhoneUser = useAppSelector(({botPhone}) => botPhone.botPhone)

    const handleQuestuinByCatId = async (id, name) => {

        const message1 = createChatBotMessage('Veillez patienté')

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message1],
        }));

        await mintClient.bot.getAllById(id)
            .then((resp) => {
                dataCat.current = resp.data.data
            })
        const message = createChatBotMessage(
            name,
            {
                widget: "response",
                payload: dataCat.current
            }
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
        }));

        const messsage3 = createChatBotMessage('Si vous ne trouvez pas votre question veillez la saisir')
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, messsage3],
        }));
    };

    const handleCategorie = async () => {

        const message = createChatBotMessage(
            'Sélectionner une catégorie',
            {
                widget: "global",
            }
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
        }));
    };

    const handleResponse = async (reponse) => {
        const message = createChatBotMessage(
            reponse,
            // {
            //     widget: "global",
            // }
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
        }));
    };

    const handleResponseCustum = async (reponse, question) => {
        const message = createChatBotMessage(
            question,
            {
                widget: "responseCustom",
                payload: reponse
            }
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
        }));
    };



    const handleUserQuestion =  (messageUser) => {
        dispatch(botMessage(messageUser))
        const message = createChatBotMessage(
            "Entrez votre numéro de telephone",
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
        }));
    };

    const handlerSendMessage = (telephone) => {
        handlerMessageSuccess()
        handleCategorie()
        const formData = new FormData()
        formData.append('Question', bobMessageUser)
        formData.append('PhoneNumber', telephone)
        mintClient.bot.createUserQuestion(formData)
            .then(() => {
                // NotificationManager.success(globalT('bot.message.success'))
            })
            .catch(() => null)
    }

    const handlerMessageSuccess = () => {
        const message = createChatBotMessage(
            "Votre question à bien été pris en compte",
        );
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
        }));
    }

    const handleUserTelephone =  (telephoneUser) => {

        let message =''
        if (yupSchema.regex.cameroonPhoneNumber.test(telephoneUser)) {
            dispatch(botPhone(telephoneUser))

            handlerSendMessage(telephoneUser)
        }
        else {
            message = createChatBotMessage(
                "Veillez entrer un numéro de téléphone valide",
            );
        }


        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
        }));
    };


    // Put the handleHello function in the actions object to pass to the MessageParser
    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        handleQuestuinByCatId,
                        handleResponseCustum,
                        handleCategorie,
                        handleResponse,
                        handleUserQuestion,
                        handleUserTelephone,
                        handlerMessageSuccess
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;