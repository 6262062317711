import React from "react";
import Navnav from "../Navnav/Navnav";
import Footfoot from "../Navnav/Footfoot";
import { globalT } from "../../lang";
import './confi.scss'

const Terms = () => {
  return (
    <>
      <Navnav />

      <div className="espacement">
      <section className="container" style={{ marginTop: "7vw"}}>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xl-12">
            <div
              className="fs-1 my-5 fw-bold"
              style={{ marginLeft: "0.5vw", marginBottom: "10vw" }}
            >
              <span className='confidentialite-title'>{globalT("conditionG")}</span>
            </div>

            <p
              className="fs-4 fw-bold"
              style={{ marginTop: "3vw" }}
            >
             <span className='confidentialite-title'>{globalT("articleIn")}</span>
            </p>

            <p>
              {globalT("enVertu")}
            </p>

            <p>
            <span>{globalT("editpar")}</span>
            </p>
            <p><span>{globalT("dontSiege")}</span></p>

            <p><span>{globalT("adresseMail")}</span> <a href="https://mintransports.cm/" target="_blank">mintransport@gmail.com.</a></p>
            <p><span>{globalT("laDirectrice")}</span></p>
            <p><span>{globalT("laPlatforme")}</span></p>


            <p
              className="fs-4 fw-bold confidentialite-title mt-5"
            >
              {globalT("presentationDu")}
            </p>

            <div>
            {globalT("platformeOb")}
            </div>

            <div style={{ marginLeft: "2vw" }}>
              <div>
            
                {globalT("faciliterla")}
              </div>

              <div>
             
                {globalT("mettreadispovid")}
              </div>

              <div>
          
                {globalT("mettreadispoactual")}
              </div>
            </div>

            <p
              className="fs-4 confidentialite-title fw-bold mt-5"
            >
              {globalT("article3")}
            </p>

            <div>
              {globalT("pourtoutequestion")}
            </div>

            <p
              className="fs-4 fw-bold confidentialite-title mt-5"
            >
              {globalT("article4")}
            </p>

            <div>
            
              {globalT("acceptation")}
            </div>

            <div>
         
              {globalT("droit")}
            </div>

            <div>
            
              {globalT("utilisateur")}
            </div>

            <p
              className="fs-4 confidentialite-title fw-bold mt-5"
            >
              {globalT("article5")}
            </p>

            <div>
        
              {globalT("minimum")}
            </div>

            <div>
             
              {globalT("techniques")}
            </div>

            <div>
         
              {globalT("connexion")}
            </div>

            <div>
           
              {globalT("CGU")}
            </div>

            <p
              className="fs-4 fw-bold confidentialite-title mt-5"
            >
              {globalT("article6")}
            </p>

            <div>
        
              {globalT("gestion")}
            </div>

            <div style={{ marginLeft: "2vw" }}>
              <div>
             
                {globalT("interrompre")}
              </div>

              <div>
         

                {globalT("perturber")}
              </div>

              <div>
              
              {globalT("procéder")}
              </div>
            </div>

            <div
              className="fs-4 confidentialite-title fw-bold mt-5"
            >
              {globalT("RÉSERVÉS")}
            </div>

            <div
              className="fs-4 fw-bold confidentialite-title mt-4 ms-5"
            >
              <span>1. </span>{globalT("CREATION")}
            </div>

            <p>
              {globalT("back")}
            </p>

            <p>
              {globalT("exclusivement")}
            </p>

            <p>
              {globalT("exactes")}
            </p>

            <p>
              {globalT("adresse")}
            </p>

            <p>
              {globalT("communication")}
            </p>

            <p>
              {globalT("admise")}
            </p>

            <p>
              {globalT("identifiant")}
            </p>

            <p>
              {globalT("modifiable")}
            </p>

            <p>
              {globalT("refuser")}
            </p>

            <div
              className="fs-4 fw-bold confidentialite-title mt-4 ms-5"
            >
              <span>2. </span>{globalT("DESACTIVATION")}
            </div>

            <p>
              {globalT("demander")}
            </p>

            <div
              className="fs-4 confidentialite-title fw-bold mt-5 ms-5"
            >
              <span>3. </span>{globalT("SUPPRESSION")}
            </div>

            <p>
              {globalT("connaissance")}
            </p>

            <div style={{ marginLeft: "2vw" }}>
              <p>
              {globalT("illicite")}
              </p>

              <p>
                {globalT("volontairement")}
              </p>

              <p>
                {globalT("actif")}
              </p>
            </div>

            <div>
      

              {globalT("raisons")}
            </div>

            <div>
      
              {globalT("renonciation")}
            </div>

            <p
              className="fs-4 confidentialite-title fw-bold mt-5"
            >
              {globalT("article8")}
            </p>

            <p>
              {globalT("contenu")}
            </p>

            <p>
            {globalT("responsable ")}
            </p>

            <div style={{ marginLeft: "2vw" }}>
              <p>
                {globalT("défaillances")}
              </p>

              <p>
                {globalT("dommages")}
              </p>

              <p>
                {globalT("intrinsèques")}
              </p>

              <p>
                {globalT("utilisant")}
              </p>
            </div>

            <p>
            
              {globalT("exactitude")}
            </p>

            <p>
            {globalT("utilisateurrespon")}
            </p>

            <div style={{ marginLeft: "2vw" }}>
              <p>
              {globalT("protection")}
              </p>

              <p>
           
                {globalT("services")}
              </p>

              <p>
              
                {globalT("lettre")}
              </p>
            </div>

            <p
              className="fs-4 confidentialite-title fw-bold mt-5"
            >
              {globalT("article9")}
            </p>

            <p>

              {globalT("hypertextes")}
            </p>

            <p>
              {globalT("liens")}
            </p>

            <p>
              {globalT("outre")}
            </p>

            <p>
              {globalT("exclus")}
            </p>

            <p>
              {globalT("supprimer")}
            </p>

            <p
              className="fs-4 confidentialite-title fw-bold mt-5"
            >
              {globalT("article10")}
            </p>

            <p>
              {globalT("dispose")}
            </p>

            <div>
              <a href="#">
                https://apsr-mint.cm/fr//faq/politique-confidentialite
              </a>
            </div>

            <div>
              
              {globalT("naviguant")}
            </div>

            <p
              className="fs-4 confidentialite-title fw-bold mt-5"
            >
              {globalT("article11")}
            </p>

            <p>
              {globalT("structuration")}
            </p>

            <p>
              {globalT("adaptation")}
            </p>

            <p>
              {globalT("reconnaissance")}
            </p>

            <p>
              {globalT("modifieraient")}
            </p>


            <p
              className="fs-4 confidentialite-title fw-bold mt-5"
            >
              {globalT("JURIDICTION")}
            </p>

            <p>
              {globalT("Conditions")}
            </p>
          </div>
        </div>
      </section>
      </div>

   

      <Footfoot />
    </>
  );
};

export default Terms;
