import React, {useEffect, useRef, useState} from 'react';
import Navnav from "../../Navnav/Navnav";
import Button from "../../../components/bootstrap/Button";
import IntlMessages from "../../../components/IntlMessages";
import Page from "../../../layout/PageWrapper/Page";
import {globalT} from "../../../lang";
import Agenda from "../../../components/Agendar";
import Spinner from "../../../components/bootstrap/Spinner";
import SearchComponent from "../../../components/SearchComponent";
import EventComponent from "../../evenement/EventComponent";
import DataImage from "../../../assets/img/landing2/vide.webp";
import PaginationComponent from "../../../components/PaginationComponent";
import Footfoot from "../../Navnav/Footfoot";
import {useNavigate} from "react-router-dom";
import {Evenements} from "../../../commons/models/Evenements";
import {useAppDispatch, useAppSelector} from "../../../store/redux.types";
import mintClient from "../../../network";
import {setRequestGlobalLoader} from "../../../store/slices/requestGlobalLoader/actions";
import {Campagne} from "../../../commons/models/Campagne";

const CampagneCurrent = () => {
    const navigate = useNavigate()
    const [campagne, setCampagne] = useState<Campagne[] | null>(null);
    const [campagneHeader, setCampagneHeader] = useState<Campagne[] | null>(null)
    const [page, setPage] = useState(1);
    const total = useRef<number>()
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("");
    const dispatch = useAppDispatch()
    const [showCalendar, setShowCalendar] = useState(true)
    const { settings, authUserData } = useAppSelector(({ settings, authUser, }) => ({
        settings,
        authUserData: authUser.data
    }));
    const loadCampagneHeader = () => {
        // dispatch(setRequestGlobalLoader(true))
        mintClient.campagne.getAll({
            "page": 1 ,
            "pageSize": 100,
            period: 2,
            "orderDirection": "ascending",
            "validationState": 1,
            "hasPagination": true,
            "langue": "fr"
        }).then((resp) => {
            const allCampagnes = resp.data.data;
            setCampagneHeader(allCampagnes);
        })
            .catch((error) => console.log(error))
            .finally(() => dispatch(setRequestGlobalLoader(false)))
    }

    const loadCampagne = () => {
        setLoading(true)
        mintClient.campagne.getAll({
            "page": page,
            "pageSize": 6,
            "orderDirection": "ascending",
            "validationState": 1,
            period: 2,
            "researchValue": search,
            "hasPagination": true,
            "langue": "fr"
        }).then((resp) => {
            const allCampagne = resp.data.data;
            total.current = resp.data.total
            setCampagne(allCampagne);
            setLoading(false)
        })
            .catch((error) => console.log(error))
    }
    const handleSearch = (e: any) => {
        setLoading(true)
        setSearch( e)
    };


    useEffect(() => {
        if (!campagneHeader)
            loadCampagneHeader()
        loadCampagne()
    }, [search, page]);
    return (
        <>
            <Navnav/>
            {campagneHeader ? (
                <div className="d-flex justify-content-between composant3">
                    <span></span>
                    <button
                        type="button"
                        className="btn fv btn-sm"
                        onClick={() => setShowCalendar((prevState => !prevState))}>
                        {showCalendar ? (
                            <IntlMessages id='button.hide.calendar' />
                        ) : (
                            <IntlMessages id='button.show.calendar' />
                        )}
                    </button>
                </div>
            ) : (
                <div className='d-flex justify-content-between'>
                    <span></span>
                    <Spinner size='3rem' customColor='#aae0aa' className='composant3' />
                </div>

            )}

            {showCalendar && campagneHeader && (
                <div className="App mb-5">
                    <Page>
                        <header className="App-header">
                            <h1>{globalT('campagne.agenda.current')}</h1>
                        </header>
                        <main>
                            <Agenda type={"campagne"} data={campagneHeader} />
                        </main>
                    </Page>
                </div>
            )}

            <section className='container pt-5 '>
                <h1 style={{ fontWeight: 'bold'}}>
                    {globalT("campagne.management.list")}
                </h1>
                <div className="text-end">
                    {loading && campagne && (
                        <Spinner customColor='#aae0aa' size='3rem' />
                    )} <br/>
                    <div className="row">
                        <div className="col-7"></div>
                        <div className="col-5">
                            <SearchComponent onSearch={handleSearch} />
                        </div>
                    </div>

                </div>

                <div className='row py-4'>
                    {campagne && campagne.length > 0 ? (
                        <>
                            {campagne?.map((event,index) => (
                                <div className='col-lg-4 col-md-6 mb-4' id={`no${index}`}>
                                    <EventComponent data={event} type='campagne'/>
                                </div>

                            ))}
                        </>
                    ) : (
                        <div className="imageContainer" style={{marginTop :'100px'}}>
                            <div className='text-center mt-5 mb-5'>
                                <div style={{
                                    width: '400px', // or any size you want
                                    height: '400px', // make it a square
                                    backgroundImage: `url(${DataImage})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    margin: '0 auto', // this will center the div if the parent div has a text-align: center
                                }} className="image"/>
                                {loading ? (
                                    <div className="d-flex justify-content-center spinnerContainer">
                                        <div className="spinner-border" style={{height: "6rem", width: "6rem", color: '#aae0aa' ,marginTop:"85px" , marginRight:"15px"}} role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <span  className='fw-bold' style={{
                                        marginTop: '20px',
                                        fontSize: '24px'
                                    }}>
                                {globalT("empty.campagne")}
            </span>
                                )}
                            </div>
                        </div>

                    )}

                </div>
                <PaginationComponent pages={Math.ceil((total.current || 1) / 6)} currentPage={page} onPageChange={(page) => setPage(page)} rangeLimit={5} />

            </section>
            <section className='container OOO'>
                <div className='row'>

                    <div className='col-md-8'>

                    </div>
                </div>
            </section>
            <Footfoot/>
        </>
    );
};

export default CampagneCurrent;