import React from 'react';
import aa from "../../assets/img/landing2/aa.png";
import {useAppSelector} from "../../store/redux.types";
import Carousel from "../bootstrap/Carousel";
import Card, {CardActions, CardBody, CardHeader, CardTitle} from "../bootstrap/Card";
import {CardMedia} from "@mui/material";
import dayjs, {Dayjs} from "dayjs";
import {joinUrlWithParamsId} from "../../commons/helpers/funcHelper";
import {useNavigate} from "react-router-dom";
import Dropdown, {DropdownItem, DropdownMenu, DropdownToggle} from "../bootstrap/Dropdown";
import Icon from "../icon/Icon";
import Button from "../bootstrap/Button";
import '../../views/publication/style/index.scss'
import classNames from "classnames";
import { globalT } from '../../lang';

type publicationProps = {
    images: any[],
    name: string,
    categoryName?: string,
    action?: { delete: () => void, edit: () => void, valider?: () => void, invalider?: () => void }
    dateCreation?: Dayjs,
    id?:string,
    hasAnotherAction?: boolean,
    isNew?: boolean
}

const PublicationComponent = ({images, name, action, dateCreation, id, categoryName, hasAnotherAction, isNew}: publicationProps) => {


    const {  authUserData } = useAppSelector(({ authUser, }) => ({
        authUserData: authUser.data
    }));
    const navigate = useNavigate()

    return (
        <div className=" ">
            <Card stretch>
                <CardHeader>
                    <CardTitle>

                    </CardTitle>
                    <CardActions>
                        <div className="d-flex justify-content-between w-100">
                            <div className="">
                                {/*{dayjs(dateCreation).format('ll')}*/}
                            </div>
                            <Dropdown>
                                <DropdownToggle hasIcon={false}>
                                    <Button>
                                        <Icon icon='MoreVert' size='2x'  />
                                    </Button>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem>
                                        <button className="btn text-black" role="button" onClick={() => navigate(joinUrlWithParamsId('/actualite/:id', id as string)) }>{globalT("button.detail.pub")}</button>
                                    </DropdownItem>

                                    {authUserData && action && (
                                        <>
                                            <DropdownItem>
                                                <button onClick={action.edit} className="btn text-primary" role="button">{globalT("button.edit.pub")}</button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <button onClick={action.delete} className="btn text-red" role="button">{globalT("button.delete.pub")}</button>
                                            </DropdownItem>
                                            {hasAnotherAction && (
                                                <>
                                                    <DropdownItem>
                                                        <button onClick={action.valider} className="btn text-success" role="button">{globalT("button.valid.pub")}</button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <button onClick={action.invalider} className="btn text-red" role="button">{globalT("button.invalid.pub")}</button>
                                                    </DropdownItem>
                                                </>
                                            )}
                                        </>

                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </div>

                    </CardActions>
                </CardHeader>
                <CardBody>
                    <div className="position-relative">
                        <div className="pubDate">
                            {dayjs(dateCreation).format('ll')}
                        </div>
                        {isNew && (
                            <div className= {classNames({"pubNew": isNew} )} >
                                <span className=''>new</span>
                            </div>
                        )}
                        <CardMedia>
                            <Carousel
                                items={images.map(img => ({src: img.attachmentFile, type: img.attachmentTypeName, isDetail: false}))}
                            />
                        </CardMedia>
                        <br/>
                        <div className='fw-bolt text-dark d-flex flex-column'>
                            <span className='display-6 mb-3 fw-bold catName'>{categoryName}: </span>
                            <span className='fw-bold pubName' >{name}</span>
                        </div>
                    </div>
                </CardBody>
            </Card>

            {/*<img src={aa} alt="image paul biya" className='img-responsive w-100 rounded ' />*/}

        </div>
    );
};

export default PublicationComponent;