import React, { useState } from 'react'
import Navnav from '../Navnav/Navnav'
import Footfoot from '../Navnav/Footfoot'
import './profil.scss'
import {useNavigate} from "react-router-dom";
import { globalT } from '../../lang';
import { DASHBOARD } from '../../commons/urls/front';
import { useAppDispatch, useAppSelector } from '../../store/redux.types';
import { setBool } from '../../store/slices/variable/actions';


interface ProfilUserProps {

  firstName?: string;
  lastName?: string;
  id?: string;
  phoneNumber?: string;
  email?: string;
  userName?: number;
  role?: string

}

const ProfilUser : React.FC<ProfilUserProps>  =  ({firstName, lastName, phoneNumber, id, email, userName,role  })  => {
const navigate = useNavigate()
const dispatch = useAppDispatch();



const onLogout = () => {
  dispatch(setBool(false));
}



  return (
    <>
      <div className="wrapperr my-4" >
  <div className="img-areaa">
    <div className="inner-areaa">
      <img src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjxivAs4UknzmDfLBXGMxQkayiZDhR2ftB4jcIV7LEnIEStiUyMygioZnbLXCAND-I_xWQpVp0jv-dv9NVNbuKn4sNpXYtLIJk2-IOdWQNpC2Ldapnljifu0pnQqAWU848Ja4lT9ugQex-nwECEh3a96GXwiRXlnGEE6FFF_tKm66IGe3fzmLaVIoNL/s1600/img_avatar.png" alt=""/>
    </div>
  </div>
  {/*<div className="icon arrow"><i className="fas fa-arrow-left"></i></div>*/}
  <div className="icon dots"><i className="fas fa-ellipsis-v"></i></div>

  <div className="name">{userName} </div>

  <div className="about my-3">{globalT('NOM')}:{firstName}</div>

  <div className="about my-3">{globalT('Prenom')}:{lastName}</div>

  <div className="about my-3">{globalT('Tel')}:{phoneNumber}</div>

  <div className="about my-3">{globalT('email')}:{email}</div>

 {/*  <div className="about my-3">{globalT('Role')}:{role}</div> */}

  <div className="buttons">

    <button
   
    onClick={()=>{navigate(DASHBOARD.INDEX_CHANGEPASS);onLogout()}}

    >{globalT('changePassWord')}</button>
    
  </div>

</div>
     
    </>
  )
}

export default ProfilUser
