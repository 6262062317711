import React from 'react';
import {useAppDispatch, useAppSelector} from "../../store/redux.types";
import mintClient from "../../network";
import NotificationManager from "../../components/notifications/NotificationManager";
import {globalT} from "../../lang";
import {botMessage} from "../../store/slices/botMessage/actions";

const MessageParser = ({ children, actions }) => {
    const bobMessageUser = useAppSelector(({botMessage}) => botMessage.botMessage)
    const {botPhone} = useAppSelector(({botPhone}) => botPhone)


    const parse = (message) => {
        if (botPhone) {
            const formData = new FormData()
            formData.append('Question', message)
            formData.append('PhoneNumber', botPhone)
            mintClient.bot.createUserQuestion(formData)
                .then(() => {
                    // NotificationManager.success(globalT('bot.message.success'))
                })
                .catch(() => null)
            actions.handlerMessageSuccess()
            actions.handleCategorie()
        }

        else if (bobMessageUser) actions.handleUserTelephone(message)
        else {
            actions.handleUserQuestion(message)
        }


    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    parse: parse,
                    actions,
                });
            })}
        </div>
    );
};

export default MessageParser;